import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { NoApplicationsSVG } from "src/assets/svg";
import CustomTab from "src/components/buttons/CustomTab";
import NoItemCard from "src/components/cards/NoItemCard";
import ServiceCard from "src/components/cards/ServiceCard";
import CustomBroudcamp from "src/components/layout/CustomBroudcamp";
import { GetServicesConfig } from "src/service/api/services/inde";
import { language } from "src/static/const/auth";
import { serviceSidebarData, serviceTabButtons } from "./const";
import { SeriviceTypeAttribute, ServiceTypes } from "./type";

function Services() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [services, setServices] = useState<ServiceTypes[]>([]);
  const [allServices, setAllServices] = useState<ServiceTypes[]>([]);
  const [searchParams] = useSearchParams();
  const serviceTag = searchParams.get("tag");
  const serviceKey = searchParams.get("key") as SeriviceTypeAttribute;

  const giveActiveTab = (key: string) => {
    return location.search.includes(key) ? "sidebarBtn__active" : "";
  };

  const getFunc = () => {
    GetServicesConfig().then((res) => {
      setAllServices(
        res.data.results.sort((a: ServiceTypes, b: ServiceTypes) => a?.priority - b?.priority),
      );
      handleFilter(res.data.results);
    });
  };

  const handleFilter = (list: ServiceTypes[]) => {
    let arr = list || [...allServices];
    if (serviceTag) arr = arr.filter((el) => el.level === serviceTag);
    else if (serviceKey) {
      if (serviceKey === "admissions") {
        arr = arr.filter((el) => el.is_admission == true);
      } else if (serviceKey !== "all") {
        arr = arr.filter((el) => el.types.includes(serviceKey));
      }
    }
    setServices([...arr]);
  };

  useEffect(() => {
    getFunc();
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    handleFilter(allServices);
  }, [serviceTag, serviceKey]);

  return (
    <>
      <div className='service'>
        <div className='container'>
          <CustomBroudcamp />

          <h1>{t("home.services.link")}</h1>
          <div className='flex'>
            <div className='service__sidebar'>
              {serviceSidebarData.map((item) => {
                return (
                  <div key={item.key}>
                    <Link to={`/service?key=${item.key}`}>
                      <div className={`sidebarBtn ${giveActiveTab(item.key)}`}>
                        {item.icon} {t(item.title)}
                      </div>
                    </Link>
                    {item.divider && <div className='divider' />}
                  </div>
                );
              })}
            </div>

            <div className='service__body'>
              {searchParams.get("key") === "all" && <CustomTab buttons={serviceTabButtons} />}

              <div className='service__list'>
                {services.length > 0 ? (
                  services.map((item) => {
                    return <ServiceCard data={item} key={item.id} />;
                  })
                ) : (
                  <NoItemCard
                    title='Xizmatlar yaqin orada ishga tushadi !'
                    icon={<NoApplicationsSVG />}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
