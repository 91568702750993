import React from "react";
import { Link } from "react-router-dom";

interface ICaraousel {
  icon: React.ReactNode;
  className: string;
  title: string;
  info: string;
  link: string;
  website: string;
}

function CaraouselCard({
  icon,
  className,
  title,
  info,
  link,
  website,
}: ICaraousel) {
  return (
    <div className={`home__slider-link ${className}`}>
      <div>
        <h4>{title}</h4>
        <p>{info}</p>
        <Link rel="stylesheet" to={link} target="_blank">
          {website}
        </Link>
      </div>
      {icon}
    </div>
  );
}

export default CaraouselCard;
