import { Label, Textarea } from "flowbite-react";
import { HelpSvg } from "src/assets/svg";
import { InputItemProps } from "src/static/interface/common";

function TextareaItem({
  label,
  placeholder,
  size,
  error,
  message,
  field,
  type,
  openDrower,
  className,
  rows,
  ...rest
}: InputItemProps) {
  return (
    <div className={`custom-input ${className}`}>
      <div className="custom-input-top">
        <Label htmlFor={label} className="custom-input-label">
          {label}
        </Label>
        {openDrower && (
          <button
            className="custom-input-help"
            type="button"
            onClick={openDrower}
          >
            <HelpSvg />
          </button>
        )}
      </div>
      <Textarea
        {...field}
        id={label}
        type={type || "text"}
        color={error ? "failure" : "gray"}
        helperText={
          <li className="custom-form-error">{error ? message : ""}</li>
        }
        placeholder={placeholder}
        sizing={size}
        rows={rows}
        {...rest}
      />
    </div>
  );
}

export default TextareaItem;
