import { GetFunc } from "src/service/config";
import { CreateFunc as CreateMulti } from "src/service/config/multipart";

export const CreateAchievmentConfig = (data: any) => {
  return CreateMulti(`/portfolios/achievement-certificates/`, data);
};

export const GetAchievmentsConfig = () => {
  return GetFunc(`/portfolios/achievement-certificates/`);
};
