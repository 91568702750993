import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function AdmissionPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/service/admission") {
      navigate("/service");
    }
  }, []);
  return <Outlet />;
}

export default AdmissionPage;
