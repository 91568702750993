import { GetFunc } from "src/service/config";

//Main search
export const GetSearchQueriesConfig = () => {
  return GetFunc(`/classifiers/search-queries/`);
};
export const GetTagsConfig = () => {
  return GetFunc(`/classifiers/tags/`);
};

// Achievments
export const GetAchievmentsConfig = () => {
  return GetFunc(`/classifiers/achievement-types/?limit=20`);
};

//Location
export const GetCountriesConfig = () => {
  return GetFunc(`/classifiers/countries/?limit=1000`);
};
export const GetRegionsConfig = () => {
  return GetFunc(`/classifiers/regions/?limit=100`);
};
export const GetDistrictsConfig = (id: string) => {
  return GetFunc(`/classifiers/districts/?limit=100&region=${id}`);
};

//Profile
export const GetCitizenshipsConfig = () => {
  return GetFunc(`/classifiers/citizenships/?limit=1000`);
};
export const GetNationalitiesConfig = () => {
  return GetFunc(`/classifiers/nationalities/?limit=1000`);
};
export const GetSciencesConfig = () => {
  return GetFunc(`/classifiers/sciences/`);
};
export const GetLanguageTypesConfig = (id: string) => {
  return GetFunc(
    `/classifiers/foreign-language-certificate-types/?foreign_language=${id}&limit=1000`,
  );
};
export const GetLanguagesConfig = () => {
  return GetFunc(`/classifiers/foreign-languages/?limit=100`);
};
export const GetRelatedServicesConfig = () => {
  return GetFunc(`/classifiers/related-services/`);
};

//Education
export const GetEducationCoursesConfig = () => {
  return GetFunc(`/classifiers/education-courses/`);
};
export const GetEducationFormsConfig = () => {
  return GetFunc(`/classifiers/education-forms/`);
};
export const GetEducationInstitutionsConfig = () => {
  return GetFunc(`/classifiers/education-institutions/`);
};
export const GetEducationLanguagesConfig = () => {
  return GetFunc(`/classifiers/education-languages/`);
};
export const GetEducationSpecialitiesConfig = () => {
  return GetFunc(`/classifiers/education-specialities/`);
};
export const GetSchoolsConfig = () => {
  return GetFunc(`/classifiers/schools/`);
};
