import { CreateFunc, GetFunc } from "src/service/config";

export const GetLanCertificatesConfig = () => {
  return GetFunc(`/portfolios/foreign-language-certificates/`);
};
export const GetSubjectCerConfig = () => {
  return GetFunc(`/portfolios/science-certificates/`);
};
export const ReloadSubjectCerConfig = () => {
  return CreateFunc(`/portfolios/science-certificates/reload/`, "");
};
export const GetCefrCerConfig = () => {
  return GetFunc(`/portfolios/achievement-certificates/`);
};
export const ReloadLanCertificatesConfig = () => {
  return CreateFunc(`/portfolios/foreign-language-certificates/reload/`, "");
};
