import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { interviewAdmissionStepsData } from "src/static/data";
import CustomSteps from "src/components/common/steps";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { INTERVIEW_ADMISSION_TOKEN, language } from "src/static/const/auth";

function InterviewAdmissionService() {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const current = location.pathname.split("step")[1];

  useEffect(() => {
    i18n.changeLanguage(language);
    if (
      location.pathname === "/service/admission/interview_admission" ||
      !localStorage.getItem(INTERVIEW_ADMISSION_TOKEN)
    ) {
      navigate("/service");
    }
  }, [location]);

  return (
    <div className='application container'>
      <div className='application-steps'>
        <CustomSteps items={interviewAdmissionStepsData} current={+current} />
      </div>
      <div className='application-content'>{<Outlet />}</div>
    </div>
  );
}

export default InterviewAdmissionService;
