export const USER_ID = "MY_EDU_USER_ID";
export const USER_CODE = "MY_EDU_USER_CODE";
export const USER_DATA = "MY_EDU_USER_DATA";
export const SESSION_DATA = "MY_EDU_USER_SESSION_DATA";
export const USERNAME = "MY_EDU_USERNAME";
export const TOKEN = "MY_EDU_TOKEN";
export const LANGUAGE = "MY_EDU_LANGUAGE";

// -----------------------------------------------
export const LANGUAGE_DATA = "LANGUAGE_DATA";
export const LYCEUM_ADMISSION_DATA = "LYCEUM_ADMISSION_DATA";
export const LYCEUM_ADMISSION_TOKEN = "LYCEUM_ADMISSION_TOKEN";
export const VOCATION_ADMISSION_DATA = "VOCATION_ADMISSION_DATA";
export const VOCATION_ADMISSION_TOKEN = "VOCATION_ADMISSION_TOKEN";
export const SECONDARY_ADMISSION_DATA = "SECONDARY_ADMISSION_DATA";
export const SECONDARY_ADMISSION_TOKEN = "SECONDARY_ADMISSION_TOKEN";
export const INTERVIEW_ADMISSION_DATA = "INTERVIEW_ADMISSION_DATA";
export const INTERVIEW_ADMISSION_TOKEN = "INTERVIEW_ADMISSION_TOKEN";

// -----------------------------------------------
export const ROLE = "MY_EDU_ROLE";
export const ROLE_U = "ROLE_USER";

export const baseUrl = "https://my.edu.uz/";
export const token = localStorage.getItem(TOKEN);
export const userLocalData = localStorage.getItem(USER_DATA);
export const sessionData = localStorage.getItem(SESSION_DATA);

export const role = localStorage.getItem(ROLE);
export const userCode = localStorage.getItem(USER_CODE);
export const language = localStorage.getItem(LANGUAGE) || "uz";
export const applicationID = localStorage.getItem("appID");

export const isUser = role === ROLE_U && token;
