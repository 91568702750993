import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { LoaderSVG } from "src/assets/svg";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { GetDiplomConfig, GetDiplomSyncConfig } from "../config";
import { InterviewTechnikumDiplom, subject } from "../type";

function InterviewAdmissionStep3() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  const [grades, setGrades] = useState<any>([]);
  const [badGrades, setBadGrades] = useState<any>([]);
  const [data, setData] = useState<InterviewTechnikumDiplom>();

  const getInfo = async () => {
    const { data } = await GetDiplomConfig();
    if (data?.grades) {
      AllGrades(data?.grades);
    }
    setData(data);
  };
  const AllGrades = (grades: any) => {
    const FullSemesters: subject[] = [];
    const BadSemesters: subject[] = [];
    grades.forEach((semestr: any) => {
      for (let item in semestr) {
        FullSemesters.push(semestr[item]);

        // Get all 3 baholilar
        semestr[item].map((sub: subject) => {
          if (sub.ball == 3 || (sub.ball > 50 && sub.ball <= 70)) {
            BadSemesters.push(sub);
          }
        });
      }
    });
    setGrades(FullSemesters);
    setBadGrades(BadSemesters);
  };
  const giveGrade = (grade: number) => {
    const miniNumbers = [0, 1, 2, 3, 4, 5];

    if (miniNumbers.includes(grade)) {
      return [0, 1, 2].includes(grade)
        ? "(qoniqarsiz)"
        : grade == 3
        ? "(qoniqarli)"
        : grade == 4
        ? "(yaxshi)"
        : "(a'lo)";
    } else {
      return grade >= 5 && grade <= 55
        ? "(qoniqarsiz)"
        : grade >= 56 && grade <= 70
        ? "(qoniqarli)"
        : grade >= 71 && grade <= 85
        ? "(yaxshi)"
        : "(a'lo)";
    }
  };
  const Sync = async () => {
    setSpin(true);
    await GetDiplomSyncConfig();
    await getInfo();
    setSpin(false);
  };
  const nextStep = () => {
    if (!data?.is_have_three) {
      navigate("/service/admission/interview_admission/step4");
    } else {
      toast.error("Sizda 3 baho mavjud");
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <div className='application-box'>
        <div className='application-box-title'>
          {t("interview.semestr_grade")}{" "}
          <CustomButton
            icon={<LoaderSVG />}
            left_icon
            color='green'
            outline
            loading={spin}
            onClick={Sync}
          >
            {t("profile.cabinet.refresh_info")}
          </CustomButton>
        </div>
        {data?.grades ? (
          <div className='application-box-form'>
            {data?.is_have_three && (
              <CustomAlert
                type='warning'
                info=''
                footer={
                  <p>
                    Hurmatli bituruvchi sizning{" "}
                    {badGrades.map((sub: subject) => (
                      <b key={sub.subject}>
                        {`${String(sub.semester)}-semestr ${sub.subject} fanidan 3
              baho, `}
                      </b>
                    ))}
                    bo’lganligi sababli ariza topshira ololmaysiz.
                  </p>
                }
              />
            )}

            <CustomAlert
              info={t("interview.alert_info1") || ""}
              footer={
                <div className='interview__percent'>
                  <p
                    className='customAlert__body'
                    dangerouslySetInnerHTML={{ __html: t("interview.alert_info2") }}
                  />
                  <div className='interview__percent-box'>
                    <div>
                      <p style={{ width: `${data?.percent_of_five}%` }}></p>
                    </div>
                    <span>{data?.percent_of_five}%</span>
                  </div>
                </div>
              }
            />
            <div className='full'>
              {grades.map((semestr: subject[], index: number) => (
                <div className='interview__semestr' key={index}>
                  <div className='interview__semestr-title'>{index + 1} - semestr</div>
                  <div>
                    {semestr.map((sub: subject) => (
                      <div
                        key={sub.subject}
                        className={
                          [`(qoniqarsiz)`, `(qoniqarli)`].includes(giveGrade(sub?.ball))
                            ? "error flex"
                            : " flex"
                        }
                      >
                        <h3>{sub.subject}</h3>
                        <h4>
                          {sub.ball} {giveGrade(sub?.ball)}
                        </h4>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className='application-box-form'>
            <CustomAlert
              type='warning'
              info='Hurmatli texnikum bituruvchisi, <b>my.edu.uz</b> axborot
                tizimiga sizning semestr baholaringiz kiritilmagan. Bitirgan
                texnikumingizga murojaat qiling !'
            />
          </div>
        )}
      </div>

      <div className='application-box-footer'>
        <Link to={"/service/admission/interview_admission/step2"}>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps} onClick={nextStep} disabled={!data?.can_apply}>
          {t("profile.common.continue") || ""}
        </CustomButton>
      </div>
    </>
  );
}

export default InterviewAdmissionStep3;
