import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RightLinkSVG } from "src/assets/svg";
import { ServiceTypes } from "src/pages/services/type";
import { GetServicesConfig } from "src/service/api/services/inde";
import { language } from "src/static/const/auth";

import ServiceCard from "src/components/cards/ServiceCard";
import CustomButton from "src/components/common/button";
import HomeServiceCard from "./components/HomeServiceCard";
import ContentLoader from "react-content-loader";

function HomeServices() {
  const { t, i18n } = useTranslation();
  const [services, setServices] = useState<ServiceTypes[]>([]);

  const getFunc = () => {
    GetServicesConfig().then((res) => {
      setServices(
        res.data.results
          .filter((item: ServiceTypes) => item.is_hot)
          .sort((a: ServiceTypes, b: ServiceTypes) => a?.priority - b?.priority),
      );
    });
  };

  useEffect(() => {
    getFunc();
    i18n.changeLanguage(language);
  }, []);

  return (
    <section className='home__services'>
      <div className='flex home__services-header'>
        <h2>{t("home.services.title")}</h2>
        <Link to='/service?key=all' className='moreBtn'>
          <CustomButton icon={<RightLinkSVG />} color='menu'>
            {t("home.services.link") || ""}
          </CustomButton>
        </Link>
      </div>

      <div className='flex home__services-list'>
        {services.length > 1
          ? services.map((item) => <HomeServiceCard data={item} key={item.id} />)
          : [1, 2, 3, 4, 5, 6].map((item) => (
              <div className='home__services-item' style={{ height: 104 }} key={item}>
                <ContentLoader
                  className='loader-home'
                  speed={2}
                  width={400}
                  height={72}
                  viewBox='0 0 400 72'
                  backgroundColor='#f3f3f3'
                  foregroundColor='#ecebeb'
                >
                  <rect x='82' y='34' rx='3' ry='3' width='256' height='11' />
                  <rect x='81' y='13' rx='3' ry='3' width='76' height='9' />
                  <circle cx='32' cy='32' r='32' />
                  <circle cx='369' cy='34' r='13' />
                </ContentLoader>
              </div>
            ))}
      </div>
      <Link to='/service?key=all'>
        <CustomButton color='menu' icon={<RightLinkSVG />} className='mobileMoreBtn'>
          {t("home.services.link") || ""}
        </CustomButton>
      </Link>
    </section>
  );
}

export default HomeServices;
