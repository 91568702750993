import React from "react";
import { Link } from "react-router-dom";

interface CategoryButtonProps {
  icon: React.ReactNode;
  active?: boolean;
  title: string;
  link: string;
}

function CategoryButton({
  icon,
  title,
  link,
  active = false,
}: CategoryButtonProps) {
  return (
    <Link
      to={link}
      className={`home-top__category-item ${
        active ? "home-top__category-active" : ""
      }`}
    >
      <div>
        <button>{icon}</button>
      </div>
      <h4>{title}</h4>
    </Link>
  );
}

export default CategoryButton;
