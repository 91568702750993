import { GetFuncVocation, EditFuncVocation, CreateFuncVocation } from "src/service/config/others";

// --------------------------------------------------------------------
export const CreateLyceumAppConfig = (data: any) => {
  return CreateFuncVocation(`/applications/`, data);
};

export const UpdateLyceumAppConfig = (id: string, data: any) => {
  return EditFuncVocation(`/applications/${id}/`, data, "PATCH");
};

// --------------------------------------------------------------------
export const GetVocationRegionsConfig = () => {
  return GetFuncVocation(`/classifiers/regions/?limit=1000`);
};
export const GetVocationEduTypesConfig = () => {
  return GetFuncVocation(`/classifiers/education-forms/?limit=1000`);
};
export const GetVocationSchoolsConfig = (id?: string) => {
  return GetFuncVocation(`/classifiers/vocational-schools/?district=${id}`);
};
export const GetVocationDirectionConfig = (schoolID?: string, typeID?: string, langID?: string) => {
  return GetFuncVocation(
    `/quotas/?vocational_school=${schoolID}&education_form=${typeID}&education_language=${langID}&limit=1000`,
  );
};
export const GetVocationLanguagesConfig = () => {
  return GetFuncVocation(`/classifiers/education-languages/`);
};
export const GetVocationPrivilageConfig = () => {
  return GetFuncVocation(`/users/privileges/`);
};

export const CreateVocationApplication = (data: any) => {
  return CreateFuncVocation(`/applications/`, data);
};
export const UpdateVocationApplication = (id: string, data: any) => {
  return EditFuncVocation(`/applications/${id}/`, data);
};
export const GETVocationApplication = (id: string) => {
  return GetFuncVocation(`/applications/${id}/`);
};
