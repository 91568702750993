import CustomBroudcamp from "src/components/layout/CustomBroudcamp";
import LanguageForm from "./components/LanguageForm";
import CustomSteps from "src/components/common/steps";
import LyceumAdmissionStep1 from "../admission/lyceum/step1";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "src/components/common/button";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function LanguageService() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const [langID, setLangID] = useState<any>();
  const currentStep = searchParam.get("step") || 1;
  const [submit, setSubmit] = useState(1);
  const [step, setStep] = useState(+currentStep);

  useEffect(() => {
    setStep(+currentStep);
  }, [searchParam]);

  useEffect(() => {
    if (langID?.id) {
      toast.success("Ariza muvaffaqiyatli yuborildi");
      navigate("/profile/applications");
    }
  }, [langID]);

  return (
    <div className="service service-language">
      <div className="container">
        <CustomBroudcamp
          nav={[
            { name: "nav.service", link: "/service?key=all" },
            {
              name: "nav.apply",
              link: "",
            },
          ]}
        />

        <div className="application-steps">
          <CustomSteps
            items={[
              {
                title: "profile.common.personal_info",
                info: "profile.common.passport_info",
              },
              { title: "language.title", info: "language.title" },
            ]}
            current={step}
          />
        </div>
        <div className="application-content">
          {step === 1 ? (
            <LyceumAdmissionStep1 nextLink="/service/language?step=2" />
          ) : (
            <>
              <div className="application-box">
                <LanguageForm
                  submitForm={submit}
                  setLangID={setLangID}
                  isStep
                />
              </div>

              <div className="application-box-footer">
                <Link to="/service/language?step=1">
                  <CustomButton {...backButtonProps}>
                    {t("profile.common.back") || ""}
                  </CustomButton>
                </Link>
                <CustomButton
                  {...forwardButtonProps}
                  onClick={() => setSubmit(submit + 1)}
                >
                  {t("nav.apply") || ""}
                </CustomButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LanguageService;
