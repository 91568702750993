import { useState } from "react";
import { useContext, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  AllServicesSVG,
  ArrowRightSvg,
  BakalavrSVG,
  BoshqaSVG,
  BottomIconSVG,
  BurgerIconSVG,
  CheckNarrowIconSVG,
  CloseIconSVG,
  DiplomaSVG,
  KasbSVG,
  KollejSVG,
  LaptopIconSVG,
  LitseySVG,
  LogoGhostSvg,
  LogoSvg,
  LogoutSVG,
  MagistrSVG,
  NotificationIconSVG,
  QabulSVG,
  RuFlagSVG,
  SoroqIconSVG,
  TexnikumSVG,
  UzFlagSVG,
} from "src/assets/svg";
import CustomButton from "src/components/common/button";
import { MainContext } from "src/hooks/context";
import {
  LANGUAGE,
  USER_DATA,
  language,
  token,
  userLocalData,
} from "src/static/const/auth";
import { GetUserMeConfig } from "src/service/api/profile";
import Login from "src/pages/login";
import DropDown from "src/components/form/dropdown";
import { handleLogoutFunc } from "src/utils";
import { useTranslation } from "react-i18next";

function LanguageDropdown({ dropdownOpen, setOpen, close }: any) {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(language || "uz");

  return (
    <DropDown
      dropdownOpen={dropdownOpen}
      mainButton={
        <CustomButton
          leftIcon={lang === "uz" ? <UzFlagSVG /> : <RuFlagSVG />}
          onClick={() => {
            setOpen(!dropdownOpen);
            close();
          }}
          icon={<BottomIconSVG color="#1F2A37" />}
        >
          {lang === "uz" ? t("navbar.langUZ") : t("navbar.langRU")}
        </CustomButton>
      }
      body={
        <>
          <CustomButton
            leftIcon={<UzFlagSVG />}
            onClick={() => {
              close();
              setLang("uz");
              setOpen(false);
              i18n.changeLanguage("uz");
              localStorage.setItem(LANGUAGE, "uz");
              window.location.reload();
            }}
            icon={lang === "uz" ? <CheckNarrowIconSVG /> : <></>}
          >
            O'zbek
          </CustomButton>
          <CustomButton
            leftIcon={<RuFlagSVG />}
            onClick={() => {
              close();
              setLang("ru");
              setOpen(false);
              i18n.changeLanguage("ru");
              localStorage.setItem(LANGUAGE, "ru");
              window.location.reload();
            }}
            icon={lang === "ru" ? <CheckNarrowIconSVG /> : <></>}
          >
            Русский
          </CustomButton>
        </>
      }
    />
  );
}

function ProfileDropdown({ dropdownOpen, setOpen, close }: any) {
  const { t, i18n } = useTranslation();
  const { userData } = useContext(MainContext);
  return (
    <DropDown
      className="customDropDown__profile"
      dropdownOpen={dropdownOpen}
      mainButton={
        <div
          className="flex"
          onClick={() => {
            close();
            setOpen(!dropdownOpen);
          }}
        >
          <div>
            {userData?.first_name[0]}
            {userData?.last_name[0]}
          </div>
          <BottomIconSVG color="#ffffff" />
        </div>
      }
      body={
        <div className="customDropDown__profile-menu">
          <Link
            to={"/profile"}
            onClick={() => {
              setOpen(false);
              close();
            }}
          >
            <div className="menu-profile">
              <div>
                {userData?.first_name[0]}
                {userData?.last_name[0]}
              </div>
              <div className="flex">
                <h2>
                  {userData?.first_name} {userData?.last_name}
                </h2>
                <p>ID:{userData?.id}</p>
              </div>
              <ArrowRightSvg color="#6B7280" />
            </div>
          </Link>
          <Link
            to={"/profile/settings?key=notification"}
            onClick={() => {
              setOpen(false);
              close();
            }}
          >
            <CustomButton
              leftIcon={<NotificationIconSVG />}
              color="menu"
              size="xl"
            >
              {t("profile.settings.notification")}
            </CustomButton>
          </Link>
          <Link to={"/profile/applications"}>
            <CustomButton
              leftIcon={<DiplomaSVG />}
              onClick={() => {
                setOpen(false);
                close();
              }}
              color="menu"
              size="xl"
            >
              {t("profile.applications.title")}
            </CustomButton>
          </Link>
          <Link to={"/profile/settings?key=devices"}>
            <CustomButton
              leftIcon={<LaptopIconSVG />}
              onClick={() => {
                setOpen(false);
                close();
              }}
              color="menu"
              size="xl"
            >
              {t("profile.settings.active_devices")}
            </CustomButton>
          </Link>
          <CustomButton
            leftIcon={<LogoutSVG />}
            onClick={handleLogoutFunc}
            color="red"
            size="xl"
            outline
          >
            {t("profile.cabinet.logout")}
          </CustomButton>
        </div>
      }
    />
  );
}

function Navbar() {
  const { userData, setUserData } = useContext(MainContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const ghost = location.pathname === "/";
  const [dropdownOpenLang, setOpenLang] = useState(false);
  const [dropdownOpenProfile, setOpenProfile] = useState(false);
  const [lang, setLang] = useState(language || "uz");
  const [navOpen, setNavOpen] = useState(false);

  const getFunc = () => {
    if (token) {
      if (userLocalData) {
        const data = JSON.parse(userLocalData);
        setUserData?.(data);
      } else {
        GetUserMeConfig().then((res) => {
          setUserData?.(res.data);
          localStorage.setItem(USER_DATA, JSON.stringify(res.data));
        });
      }
    }
  };
  useEffect(() => {
    getFunc();
  }, []);

  const [visible, setVisible] = useState(false);
  const [defaultPage, setDefaultPage] = useState("main");
  const [searchParams] = useSearchParams();
  const checkDefaultLogin = async () => {
    let key = searchParams.get("key");
    let open = searchParams.get("modal");
    if (open === "open" && key === "main") {
      setVisible(true);
      setDefaultPage("main");
    }
    if (open === "open" && key === "finished") {
      setVisible(true);
      setDefaultPage("finished");
    }

    await new Promise((res) => setTimeout(res, 1000));
  };

  useEffect(() => {
    checkDefaultLogin();
  }, []);

  return (
    <div className={`navbar ${ghost ? "navbar-ghost" : "navbar-profile"}`}>
      <div className="container">
        {ghost ? (
          <BurgerIconSVG
            onClick={() => setNavOpen(true)}
            className="navbar-burger"
          />
        ) : (
          <BurgerIconSVG
            color={"black"}
            onClick={() => setNavOpen(true)}
            className="navbar-burger"
          />
        )}

        <Link to="/" className="navbar-logo">
          {ghost ? <LogoGhostSvg /> : <LogoSvg />}
          my.edu.uz
        </Link>
        <div className="flex gap-4 navbar__login">
          <CustomButton icon={<SoroqIconSVG />} className="yordamBtn">
            {t("navbar.help")}
          </CustomButton>

          <LanguageDropdown
            close={() => setOpenProfile(false)}
            dropdownOpen={dropdownOpenLang}
            setOpen={setOpenLang}
          />
          <div style={!token ? { display: "none" } : {}}>
            <ProfileDropdown
              close={() => setOpenLang(false)}
              dropdownOpen={dropdownOpenProfile}
              setOpen={setOpenProfile}
            />
          </div>
          <div style={token ? { display: "none" } : {}}>
            <Login
              visible={visible}
              setVisible={setVisible}
              defaultPage={defaultPage}
              setDefaultPage={setDefaultPage}
            />
          </div>
        </div>
      </div>

      <div
        className={`navbar__mobile ${navOpen ? "navbar__mobile-active" : ""}`}
      >
        <div className="navbar__mobile-top">
          <CloseIconSVG onClick={() => setNavOpen(false)} />

          <Link to="/" className="navbar-logo">
            <LogoSvg />
            my.edu.uz
          </Link>

          <CustomButton icon={<SoroqIconSVG />} className="yordamBtn">
            {t("navbar.help")}
          </CustomButton>
        </div>
        <div className="navbar__mobile-nav">
          <Link to={"/service?key=all"}>
            <CustomButton
              icon={<AllServicesSVG />}
              left_icon
              onClick={() => setNavOpen(false)}
            >
              {t("profile.common.all_services")}
            </CustomButton>
          </Link>
          <Link to={"/service?key=all"} onClick={() => setNavOpen(false)}>
            <CustomButton icon={<QabulSVG />} left_icon>
              {t("home.actions.admission")}
            </CustomButton>
          </Link>
          <Link to={"/service?key=all"} onClick={() => setNavOpen(false)}>
            <CustomButton icon={<BakalavrSVG />} left_icon>
              {t("home.actions.bachelor")}
            </CustomButton>
          </Link>
          <Link to={"/service?key=all"} onClick={() => setNavOpen(false)}>
            <CustomButton icon={<MagistrSVG />} left_icon>
              {t("home.actions.magistr")}
            </CustomButton>
          </Link>
          <Link to={"/service?key=all"} onClick={() => setNavOpen(false)}>
            <CustomButton icon={<LitseySVG />} left_icon>
              {t("home.actions.lyceum")}
            </CustomButton>
          </Link>
          <Link to={"/service?key=all"} onClick={() => setNavOpen(false)}>
            <CustomButton icon={<KollejSVG />} left_icon>
              {t("home.actions.college")}
            </CustomButton>
          </Link>
          <Link to={"/service?key=all"} onClick={() => setNavOpen(false)}>
            <CustomButton icon={<TexnikumSVG />} left_icon>
              {t("home.actions.technikum")}
            </CustomButton>
          </Link>
          <Link to={"/service?key=all"} onClick={() => setNavOpen(false)}>
            <CustomButton icon={<KasbSVG />} left_icon>
              {t("home.actions.kasb")}
            </CustomButton>
          </Link>
          <Link to={"/service?key=all"} onClick={() => setNavOpen(false)}>
            <CustomButton icon={<BoshqaSVG />} left_icon>
              {t("home.actions.others")}
            </CustomButton>
          </Link>
        </div>
        <div className={`customDropDown__menu customDropDown__active`}>
          <CustomButton
            leftIcon={<UzFlagSVG />}
            onClick={() => {
              setLang("uz");
              i18n.changeLanguage("uz");
              localStorage.setItem(LANGUAGE, "uz");
              window.location.reload();
            }}
            icon={lang === "uz" ? <CheckNarrowIconSVG /> : <></>}
          >
            {t("navbar.langUZ")}
          </CustomButton>

          <CustomButton
            leftIcon={<RuFlagSVG />}
            onClick={() => {
              setLang("ru");
              i18n.changeLanguage("ru");
              localStorage.setItem(LANGUAGE, "ru");
              window.location.reload();
            }}
            icon={lang === "ru" ? <CheckNarrowIconSVG /> : <></>}
          >
            {t("navbar.langRU")}
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
