import { useTranslation } from "react-i18next";
import CustomButton from "../common/button";
import { DeleteIconSVG, EditIconSVG } from "src/assets/svg";
import { giveLan } from "src/utils";

function DiplomCard({
  data,
  type = "bakalavr",
}: {
  data: any;
  type?: "bakalavr" | "litsey" | "maktab" | "yutuq" | "chettili" | "work" | "science";
}) {
  const { t } = useTranslation();

  if (type === "litsey") {
    return (
      <div className='diplomCard'>
        <div className='diplomCard__header'>
          <h2>{t("profile.diplom.lyceum_diplom")}</h2>
          <div className='flex'>
            <span>
              {t("profile.diplom.status")}:{" "}
              <CustomButton size='xs' color='success'>
                {t("profile.diplom.accepted")}
              </CustomButton>
            </span>
            <button className='diplomCard__editBtn'>
              <EditIconSVG />
            </button>
            <button className='diplomCard__deleteBtn'>
              <DeleteIconSVG />
            </button>
          </div>
        </div>
        <div className='diplomCard__body'>
          <div className='flex'>
            <h2>{t("profile.edu.edu_name")}:</h2>
            <p></p>
            <h1>
              Toshkent shahridagi ‘A.I.Gersen nomidagi Rossiya Davlat pedagogika universiteti‘
              Federal davlat budjeti oliy ta‘lim muassasasi filiali
            </h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.edu.edu_type")}:</h2>
            <p></p>
            <h1>Madaniyat vazirligi ixtisoslashtirilgan maktab-internati</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.common.specialites")}:</h2>
            <p></p>
            <h1>Kompyuter injiniringi: Kompyuter injiniringi</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.edu.edu_years")}:</h2>
            <p></p>
            <h1>2017-2021</h1>
          </div>
          <div className='flex'>
            <h2>{t("language.seriesandNumber")}:</h2>
            <p></p>
            <h1>AA1234567</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.diplom.file")}:</h2>
            <p></p>
            <a href='' download target='_blank'>
              {t("service.serviceID.download")}
            </a>
          </div>
          <div className='flex'>
            <h2>{t("profile.diplom.copy")}:</h2>
            <p></p>
            <a href='' download target='_blank'>
              {t("service.serviceID.download")}
            </a>
          </div>
        </div>
      </div>
    );
  } else if (type === "maktab") {
    return (
      <div className='diplomCard'>
        <div className='diplomCard__header'>
          <h2>{t("profile.diplom.school_diplom")}</h2>
          <div className='flex'>
            <span>
              {t("profile.diplom.status")}:{" "}
              <CustomButton size='xs' color='success'>
                {t("profile.diplom.accepted")}
              </CustomButton>
            </span>
          </div>
        </div>
        <div className='diplomCard__body'>
          <div className='flex'>
            <h2>{t("profile.edu.edu_name")}:</h2>
            <p></p>
            <h1>{data.school}</h1>
          </div>
          {/* <div className="flex">
            <h2>{t("profile.diplom.doc_type")}:</h2>
            <p></p>
            <h1>9-sinf Shahodatnoma</h1>
          </div> */}
          <div className='flex'>
            <h2>{t("language.seriesandNumber")}:</h2>
            <p></p>
            <h1>
              {data.serial} {data.number}
            </h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.edu.school_area")}:</h2>
            <p></p>
            <h1>
              {data.region}, {giveLan(data.country)}
            </h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.choices.edu_lang")} :</h2>
            <p></p>
            <h1>{giveLan(data.education_language)}</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.awards.english")} :</h2>
            <p></p>
            <h1>{giveLan(data.foreign_language)}</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.diplom.end_date")} :</h2>
            <p></p>
            <h1>{data.graduate_year}</h1>
          </div>
        </div>
      </div>
    );
  } else if (type === "yutuq") {
    return (
      <div className='diplomCard'>
        <div className='diplomCard__header'>
          <h2>{t("profile.awards.prizes")}</h2>
          <div className='flex'>
            {/* <span>
              {t("profile.diplom.status")}:{" "}
              <CustomButton size="xs" color="success">
                {t("profile.diplom.accepted")}
              </CustomButton>
            </span> */}
          </div>
        </div>
        <div className='diplomCard__body'>
          <div className='flex'>
            <h2>{t("language.seriesandNumber")}:</h2>
            <p></p>
            <h1>{data?.serial_number}</h1>
          </div>
          <div className='flex'>
            <h2>Nomi:</h2>
            <p></p>
            <h1>{data?.name}</h1>
          </div>
          <div className='flex'>
            <h2>Berilgan sana:</h2>
            <p></p>
            <h1>{data?.given_date}</h1>
          </div>
          <div className='flex'>
            <h2>Turi:</h2>
            <p></p>
            <h1>{giveLan(data?.type)}</h1>
          </div>
          <div className='flex'>
            <h2>Hujjat fayli::</h2>
            <p></p>
            <a href={data?.file || data?.url} download target='_blank' rel='noreferrer'>
              {t("service.serviceID.download")}
            </a>
          </div>
        </div>
      </div>
    );
  } else if (type === "science") {
    return (
      <div className='diplomCard'>
        <div className='diplomCard__header'>
          <h2>Fan sertifikati</h2>
          <div className='flex'>
            {/* <span>
              {t("profile.diplom.status")}:{" "}
              <CustomButton size="xs" color="success">
                {t("profile.diplom.accepted")}
              </CustomButton>
            </span> */}
          </div>
        </div>
        <div className='diplomCard__body'>
          <div className='flex'>
            <h2>{t("language.seriesandNumber")}:</h2>
            <p></p>
            <h1>{data?.serial_number}</h1>
          </div>
          <div className='flex'>
            <h2>Fan nomi:</h2>
            <p></p>
            <h1>{giveLan(data?.science)}</h1>
          </div>
          <div className='flex'>
            <h2>Berilgan sana:</h2>
            <p></p>
            <h1>{data?.given_date}</h1>
          </div>
          <div className='flex'>
            <h2>Ball:</h2>
            <p></p>
            <h1>{data?.ball}</h1>
          </div>
          <div className='flex'>
            <h2>Hujjat fayli::</h2>
            <p></p>
            <a href={data?.file || data?.url} download target='_blank' rel='noreferrer'>
              {t("service.serviceID.download")}
            </a>
          </div>
        </div>
      </div>
    );
  } else if (type === "work") {
    return (
      <div className='diplomCard'>
        <div className='diplomCard__header'>
          <h2>Mehnat faoliyati</h2>
        </div>
        <div className='diplomCard__body'>
          <div className='flex'>
            <h2>Tashkilot nomi:</h2>
            <p></p>
            <h1>{data?.organization}</h1>
          </div>
          <div className='flex'>
            <h2>Bo‘lim:</h2>
            <p></p>
            <h1>{data?.department}</h1>
          </div>
          <div className='flex'>
            <h2>Lavozim:</h2>
            <p></p>
            <h1>{data?.position}</h1>
          </div>
          <div className='flex'>
            <h2>STIR:</h2>
            <p></p>
            <h1>{data?.inn}</h1>
          </div>
          <div className='flex'>
            <h2>Ishlagan davr:</h2>
            <p></p>
            <h1>
              {data?.start_date} {data?.end_date && " , "} {data?.end_date}
            </h1>
          </div>
        </div>
      </div>
    );
  } else if (type === "chettili") {
    return (
      <div className='diplomCard'>
        <div className='diplomCard__header'>
          <h2>{t("language.title")}</h2>
          <div className='flex'>
            <span>
              {t("profile.diplom.status")}:{" "}
              <CustomButton size='xs' color='success'>
                {data?.status === "accepted"
                  ? t("profile.diplom.accepted")
                  : t("statuses.app_view")}
              </CustomButton>
            </span>
            {/* <button className="diplomCard__editBtn">
              <EditIconSVG />
            </button>
            <button className="diplomCard__deleteBtn">
              <DeleteIconSVG />
            </button> */}
          </div>
        </div>
        <div className='diplomCard__body'>
          <div className='flex'>
            <h2>{t("profile.awards.english")}:</h2>
            <p></p>
            <h1>{giveLan(data?.foreign_language)}</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.awards.type_cer")}:</h2>
            <p></p>
            <h1>{giveLan(data?.type)}</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.awards.level")}:</h2>
            <p></p>
            <h1>{giveLan(data?.degree)}</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.awards.series")}:</h2>
            <p></p>
            <h1>{data?.serial_number}</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.awards.docend_date")}:</h2>
            <p></p>
            <h1>
              {data?.given_date} - {data?.expire_date}
            </h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.awards.file")}:</h2>
            <p></p>
            <a href={data?.file || data?.url} download target='_blank' rel='noreferrer'>
              {t("service.serviceID.download")}
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='diplomCard'>
        <div className='diplomCard__header'>
          <h2>{t("profile.diplom.bachelor_diplom")}</h2>
          <div className='flex'>
            <span>
              {t("profile.diplom.status")}:{" "}
              <CustomButton size='xs' color='success'>
                {t("profile.diplom.accepted")}
              </CustomButton>
            </span>
            <button className='diplomCard__editBtn'>
              <EditIconSVG />
            </button>
            <button className='diplomCard__deleteBtn'>
              <DeleteIconSVG />
            </button>
          </div>
        </div>
        <div className='diplomCard__body'>
          <div className='flex'>
            <h2>{t("profile.edu.edu_name")}:</h2>
            <p></p>
            <h1>
              Toshkent shahridagi ‘A.I.Gersen nomidagi Rossiya Davlat pedagogika universiteti‘
              Federal davlat budjeti oliy ta‘lim muassasasi filiali
            </h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.edu.edu_type")}:</h2>
            <p></p>
            <h1>Madaniyat vazirligi ixtisoslashtirilgan maktab-internati</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.common.specialites")}:</h2>
            <p></p>
            <h1>Kompyuter injiniringi: Kompyuter injiniringi</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.edu.edu_years")}:</h2>
            <p></p>
            <h1>2017-2021</h1>
          </div>
          <div className='flex'>
            <h2>{t("language.seriesandNumber")}:</h2>
            <p></p>
            <h1>AA1234567</h1>
          </div>
          <div className='flex'>
            <h2>{t("profile.diplom.file")}:</h2>
            <p></p>
            <a href='' download target='_blank'>
              {t("service.serviceID.download")}
            </a>
          </div>
          <div className='flex'>
            <h2>{t("profile.diplom.copy")}:</h2>
            <p></p>
            <a href='' download target='_blank'>
              {t("service.serviceID.download")}
            </a>
          </div>
          <div className='flex'>
            <h2>{t("profile.diplom.average")}:</h2>
            <p></p>
            <h1>88.78</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default DiplomCard;
