import { useTranslation } from "react-i18next";
import CustomButton from "src/components/common/button";
import { SeriviceSlugTypeAttribute } from "src/pages/services/type";

const CardStatus = ({
  status = 1,
  onClick,
  slug,
}: {
  status: number;
  onClick?: (val: string) => void;
  slug?: SeriviceSlugTypeAttribute;
}) => {
  const { t } = useTranslation();

  if (
    ["foreign_language_certificate", "vocational_school_admission", "interview_admission"].includes(
      slug || "",
    )
  ) {
    return [1, "1", "CREATED"].includes(status) ? (
      <CustomButton color='info' size='xs'>
        {t("statuses.app_view")}
      </CustomButton>
    ) : [3].includes(status) ? (
      <CustomButton color='success' size='xs'>
        {t("statuses.accept")}
      </CustomButton>
    ) : (
      <CustomButton color='error' size='xs'>
        {t("statuses.reject")}
      </CustomButton>
    );
  } else if (slug === "secondary_education_admission") {
    return status == 1 ? (
      <CustomButton color='info' size='xs'>
        {t("second_edu.step1")}
      </CustomButton>
    ) : status == 2 ? (
      <CustomButton color='success' size='xs'>
        {t("statuses.accept")}
      </CustomButton>
    ) : status == 3 ? (
      <CustomButton color='error' size='xs' onClick={() => onClick?.("reject")}>
        {t("statuses.reject")}
      </CustomButton>
    ) : (
      <CustomButton color='info' size='xs'>
        {t("statuses.app_view")}
      </CustomButton>
    );
  } else {
    return status == 1 ? (
      <CustomButton color='info' size='xs'>
        {t("statuses.app_view")}
      </CustomButton>
    ) : [6, "done"].includes(status) ? (
      <CustomButton color='success' size='xs'>
        {t("statuses.accept")}
      </CustomButton>
    ) : (
      <CustomButton color='error' size='xs' onClick={() => onClick?.("reject")}>
        {t("statuses.reject")}
      </CustomButton>
    );
  }
};

export default CardStatus;
