import { Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoApplicationsSVG } from "src/assets/svg";
import NoItemCard from "src/components/cards/NoItemCard";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import { GetApplicationHistory, GetApplications } from "./config";
import ApplicationCard from "src/components/cards/ApplicationCard";
import toast from "react-hot-toast";
import { giveError } from "src/service/host";
import { Link } from "react-router-dom";

function ProfileApplications() {
  const { t } = useTranslation();
  const [apps, setApps] = useState([]);

  const GetApplicationsList = async () => {
    const { data } = await GetApplications();
    setApps(
      data.results.sort(
        (a: any, b: any) =>
          new Date(b?.last_updated_time).getTime() - new Date(a?.last_updated_time).getTime(),
      ),
    );
  };

  const GetApplicationID = async (id: string) => {
    // try {
    //   await GetApplicationHistory(id);
    // } catch ({ response }: any) {

    // }
    toast.error(giveError("Sizda arxiv arizalar mavjud emas !"));
  };

  useEffect(() => {
    GetApplicationsList();
  }, []);

  return (
    <div>
      <CustomAlert
        title={t("profile.cabinet.info") || ""}
        info={t("profile.applications.infoApplications") || ""}
      />
      <div className='profile__education_header'>
        <h2>{t("profile.applications.title")}</h2>
        <Link to={"/archive"}>
          <CustomButton color='primary' outline>
            {t("profile.applications.arxiv")}
          </CustomButton>
        </Link>
      </div>

      {apps.length > 0 ? (
        apps.map((app: any) => (
          <ApplicationCard
            data={app}
            key={crypto.randomUUID()}
            getHistory={() => GetApplicationID(app?.external_id)}
          />
        ))
      ) : (
        <NoItemCard
          link='/service?key=all'
          icon={<NoApplicationsSVG />}
          title={t("profile.applications.no_application")}
          info={"profile.applications.text1"}
        />
      )}
    </div>
  );
}

export default ProfileApplications;
