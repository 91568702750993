import { TextInput, Label } from "flowbite-react";
import { HelpSvg } from "src/assets/svg";
import { InputItemProps } from "src/static/interface/common";
import InputMask from "react-input-mask";

function MaskedInputItem({
  label,
  placeholder,
  addon,
  mask,
  size,
  error,
  message,
  field,
  type,
  openDrower,
  className,
  ...rest
}: InputItemProps) {
  return (
    <div className={`custom-input ${className}`}>
      <div className="custom-input-top">
        <Label htmlFor={label} className="custom-input-label">
          {label}
        </Label>
        {openDrower && (
          <button
            onClick={openDrower}
            className="custom-input-help"
            type="button"
          >
            <HelpSvg />
          </button>
        )}
      </div>
      <InputMask mask={mask} maskChar="_" {...field}>
        {() => (
          <TextInput
            {...field}
            id={label}
            type={type || "text"}
            color={error ? "failure" : "gray"}
            helperText={
              <li className="custom-form-error">{error ? message : ""}</li>
            }
            placeholder={placeholder}
            sizing={size}
            addon={addon}
            {...rest}
          />
        )}
      </InputMask>
    </div>
  );
}

export default MaskedInputItem;
