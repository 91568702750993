import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { LoaderSVG, PlusIconSVG, NoInformationSVG } from "src/assets/svg";
import NoItemCard from "src/components/cards/NoItemCard";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import ProfileAwardsAdd from "./components/ProfileAwardsAdd";
import { GetAchievmentsConfig } from "./components/config";
import DiplomCard from "src/components/cards/DiplomCard";
import { language } from "src/static/const/auth";
import { GetSubjectCerConfig, ReloadSubjectCerConfig } from "../language/config";

function ProfilePortfoli() {
  const { t, i18n } = useTranslation();
  const [drower, setDrower] = useState(false);
  const [achievments, setAchievments] = useState<any[]>();
  const [subjects, setSubjects] = useState<any[]>();

  const getAchievments = async () => {
    const { data } = await GetAchievmentsConfig();
    setAchievments(data.results);
  };
  const getSciences = async () => {
    const { data } = await GetSubjectCerConfig();
    setSubjects(data.results);
  };

  const [loadingReload, setLaodingReload] = useState(false);

  const handleReload = () => {
    setLaodingReload(true);
    ReloadSubjectCerConfig()
      .then((res) => {
        getSciences();
      })
      .finally(() => setLaodingReload(false));
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    getAchievments();
    getSciences();
  }, []);

  return (
    <div className='profile__education'>
      <CustomAlert
        title={t("profile.cabinet.info") || ""}
        info={t("profile.applications.infoPortfolio") || ""}
      />

      <div className='profile__education_header'>
        <h2>{t("profile.cabinet.portfolio")}</h2>
        <div className='flex gap-4'>
          <CustomButton
            icon={<LoaderSVG />}
            left_icon
            color='green'
            onClick={handleReload}
            loading={loadingReload}
            outline
          >
            {t("profile.cabinet.refresh_info")}
          </CustomButton>
          <CustomButton
            icon={<PlusIconSVG />}
            left_icon
            color='primary'
            onClick={() => setDrower(true)}
          >
            {t("profile.portfolio.add_portfolio")}
          </CustomButton>
        </div>
      </div>

      <div className='profile__education_table'>
        {achievments?.length !== 0 || subjects?.length !== 0 ? (
          <>
            {achievments?.map((item: any) => (
              <DiplomCard type='yutuq' data={item} key={crypto.randomUUID()} />
            ))}
            {subjects?.map((item: any) => (
              <DiplomCard type='science' data={item} key={crypto.randomUUID()} />
            ))}
          </>
        ) : (
          <NoItemCard
            longArrow
            icon={<NoInformationSVG />}
            title={t("profile.portfolio.info")}
            info={"profile.portfolio.noPortfolio"}
          />
        )}
      </div>

      <ProfileAwardsAdd drower={drower} setDrower={setDrower} getAchievments={getAchievments} />
    </div>
  );
}

export default ProfilePortfoli;
