import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";
import { CreateFunc } from "src/service/config";
import { language } from "src/static/const/auth";
import { forwardButtonProps } from "src/static/const/props";
import { studyForm_service_schema } from "src/static/const/schema";
import { PhdDocumentPDF, ProfStudyDocumentPDF, StudyDocumentPDF } from "src/utils";
import { StudyFormServiceTypes } from "../type";

function DocumentForm({
  isStep = false,
  submitForm,
  loading,
  setLoading,
}: {
  isStep?: boolean;
  submitForm?: number;
  loading: boolean;
  setLoading: (val: boolean) => void;
}) {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<any>();

  //Form section
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const {
    control,
    setValue,
    formState: { errors },
  } = useForm<StudyFormServiceTypes>({
    resolver: yupResolver(studyForm_service_schema),
  });

  const getFuncPatcher = async () => {
    if (pathname == "/service/studyProf") {
      getFuncStudyProf();
    } else if (pathname == "/service/studyDocument") {
      getFuncStudyDoc();
    } else if (pathname == "/service/phdProf") {
      getFuncPhdProf();
    }
  };
  const getFuncStudyDoc = async () => {
    setLoading(true);
    CreateFunc(`https://my.edu.uz/api/services/studying-certificate/add_new_application/`, {})
      .then((res) => {
        setValue("education_type_name", res.data?.data?.education_type_name);
        setValue("education_form_name", res.data?.data?.education_form_name);
        setValue("payment_type_name", res.data?.data?.payment_type_name);
        setValue("education_year", res.data?.data?.education_year);
        setValue("university_name", res.data?.data?.university_name);
        setValue("faculty_name", res.data?.data?.faculty_name);
        setValue("speciality_name", res.data?.data?.speciality_name);
        setValue("course", res.data?.data?.course);
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getFuncStudyProf = async () => {
    setLoading(true);
    CreateFunc(`https://my.edu.uz/api/services/prof-studying-certificate/add_new_application/`, {})
      .then((res) => {
        setValue("education_type_name", res.data?.data?.edu_type);
        setValue("education_form_name", res.data?.data?.edu_form);
        setValue("payment_type_name", res.data?.data?.edu_payment_type);
        setValue("education_year", res.data?.data?.edu_starting_year);
        setValue("university_name", res.data?.data?.edu_institution_name);
        setValue("faculty_name", res.data?.data?.faculty_name);
        setValue("speciality_name", res.data?.data?.edu_speciality);
        setValue("course", res.data?.data?.edu_study_level);
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getFuncPhdProf = async () => {
    setLoading(true);
    CreateFunc(
      `https://my.edu.uz/api/services/academic-degree-certificate/add_new_application/`,
      {},
    )
      .then((res) => {
        setValue("work_place", res.data?.data?.title_details?.work_place);
        setValue("title", res.data?.data?.title_details?.title);
        setValue("science_sector", res.data?.data?.title_details?.science_sector);
        setValue("code_and_name", res.data?.data?.title_details?.code_and_name);
        setValue("position", res.data?.data?.title_details?.position);
        setValue("confirmed_date", res.data?.data?.title_details?.confirmed_date);
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (submitForm && submitForm > 1) {
      if (data?.application_id) {
        if (pathname == "/service/studyProf") {
          ProfStudyDocumentPDF(data?.data, data?.application_id).then(() =>
            navigate("/service?key=all"),
          );
        } else if (pathname == "/service/studyDocument") {
          StudyDocumentPDF(data?.data, data?.application_id).then(() =>
            navigate("/service?key=all"),
          );
        } else if (pathname == "/service/phdProf") {
          PhdDocumentPDF(data?.data, data?.application_id).then(() => navigate("/service?key=all"));
        }
      } else {
        toast.error(
          pathname == "/service/phdProf"
            ? `Sizning ma'lumotlaringiz Mehnat tizimida topilmadi.`
            : `Sizning ta'lim ma'lumotlaringiz HEMIS yoki EMIS tizimida topilmadi.`,
        );
      }
    }
  }, [submitForm]);

  useEffect(() => {
    getFuncPatcher();
    i18n.changeLanguage(language);
  }, []);

  return (
    <>
      {loading ? (
        <div className='ring' style={{ height: 300 }}>
          <div className='lds-dual-ring'></div>
        </div>
      ) : (
        <>
          {data?.application_id ? (
            pathname == "/service/phdProf" ? (
              <div className='application-box'>
                <div className='application-box-subtitle'>O‘qiyotganlik haqida ma’lumotnoma</div>
                <form className='application-box-form'>
                  <Controller
                    name='work_place'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Ta'lim muassasasi` || ""}
                        message={`Ta'lim muassasasi` || ""}
                        field={field}
                        error={errors.work_place && validated}
                      />
                    )}
                  />
                  <Controller
                    name='position'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Lavozim` || ""}
                        message={`Lavozim` || ""}
                        field={field}
                        error={errors.position && validated}
                      />
                    )}
                  />
                  <Controller
                    name='code_and_name'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Kafedra` || ""}
                        message={`Kafedra` || ""}
                        field={field}
                        error={errors.code_and_name && validated}
                      />
                    )}
                  />
                  <Controller
                    name='science_sector'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Ilmiy daraja` || ""}
                        message={`Ilmiy daraja` || ""}
                        field={field}
                        error={errors.science_sector && validated}
                      />
                    )}
                  />
                  <Controller
                    name='title'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Ilmiy unvon` || ""}
                        message={`Ilmiy unvon` || ""}
                        field={field}
                        error={errors.title && validated}
                      />
                    )}
                  />
                  <Controller
                    name='confirmed_date'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Tasdiqlangan sanasi` || ""}
                        message={`Tasdiqlangan sanasi` || ""}
                        field={field}
                        error={errors.confirmed_date && validated}
                      />
                    )}
                  />
                  {!isStep ? (
                    <div className='application-box-footer full'>
                      <div></div>
                      <CustomButton {...forwardButtonProps} onClick={() => setValidated(true)}>
                        {t("nav.apply")}
                      </CustomButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </form>
              </div>
            ) : (
              <div className='application-box'>
                <div className='application-box-subtitle'>O‘qiyotganlik haqida ma’lumotnoma</div>
                <form className='application-box-form'>
                  <Controller
                    name='education_type_name'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Ta'lim turi` || ""}
                        message={`Ta'lim turi` || ""}
                        field={field}
                        error={errors.education_type_name && validated}
                      />
                    )}
                  />
                  <Controller
                    name='education_form_name'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Ta'lim shakli` || ""}
                        message={`Ta'lim shakli` || ""}
                        field={field}
                        error={errors.education_form_name && validated}
                      />
                    )}
                  />
                  <Controller
                    name='payment_type_name'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Qabul turi` || ""}
                        message={`Qabul turi` || ""}
                        field={field}
                        error={errors.payment_type_name && validated}
                      />
                    )}
                  />
                  <Controller
                    name='education_year'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`O‘qishga kirgan yili` || ""}
                        message={`O‘qishga kirgan yili` || ""}
                        field={field}
                        error={errors.education_year && validated}
                      />
                    )}
                  />
                  <Controller
                    name='university_name'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Ta'lim muassasasi` || ""}
                        message={`Ta'lim muassasasi` || ""}
                        field={field}
                        error={errors.university_name && validated}
                      />
                    )}
                  />
                  {pathname === "/service/studyDocument" && (
                    <Controller
                      name='faculty_name'
                      control={control}
                      render={({ field }) => (
                        <InputItem
                          disabled
                          className='half'
                          label={`Fakultet` || ""}
                          message={`Fakultet` || ""}
                          field={field}
                          error={errors.faculty_name && validated}
                        />
                      )}
                    />
                  )}
                  <Controller
                    name='speciality_name'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`Yo‘nalish` || ""}
                        message={`Yo‘nalish` || ""}
                        field={field}
                        error={errors.speciality_name && validated}
                      />
                    )}
                  />
                  <Controller
                    name='course'
                    control={control}
                    render={({ field }) => (
                      <InputItem
                        disabled
                        className='half'
                        label={`O‘quv kursi` || ""}
                        message={`O‘quv kursi` || ""}
                        field={field}
                        error={errors.course && validated}
                      />
                    )}
                  />
                  {!isStep ? (
                    <div className='application-box-footer full'>
                      <div></div>
                      <CustomButton {...forwardButtonProps} onClick={() => setValidated(true)}>
                        {t("nav.apply")}
                      </CustomButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </form>
              </div>
            )
          ) : (
            <CustomAlert
              type='error'
              info={
                pathname == "/service/phdProf"
                  ? "Sizning ma'lumotlaringiz Mehnat tizimida topilmadi."
                  : "Sizning ta'lim ma'lumotlaringiz HEMIS yoki EMIS tizimida topilmadi."
              }
              style={{ marginBottom: 100 }}
            />
          )}
        </>
      )}
    </>
  );
}

export default DocumentForm;
