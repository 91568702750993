import { Label } from "flowbite-react";
import { HelpSvg } from "src/assets/svg";
import { InputItemProps } from "src/static/interface/common";
import DatePicker from "react-tailwindcss-datepicker";
import { useState } from "react";
import { DatePickerType } from "src/static/interface/login";

export const defDate = {
  startDate: "",
  endDate: "",
};

function DatepickerItem({
  label,
  placeholder,
  size,
  error,
  message,
  field,
  type,
  openDrower,
  className = "",
  onChange,
  ...rest
}: InputItemProps) {
  const [defaultDate, setDefaultDate] = useState<DatePickerType>(defDate);

  return (
    <div className={`custom-input ${className} custom-input-${size}`} style={{ marginBottom: 12 }}>
      <div className='custom-input-top'>
        <Label htmlFor={label} className='custom-input-label'>
          {label}
        </Label>
        {openDrower && (
          <button className='custom-input-help' type='button' onClick={openDrower}>
            <HelpSvg />
          </button>
        )}
      </div>
      <DatePicker
        {...field}
        id={label}
        i18n='uz-latn'
        asSingle
        useRange={false}
        type={type || "text"}
        color={error ? "failure" : "gray"}
        placeholder={placeholder}
        onChange={(val: DatePickerType) => {
          setDefaultDate(val);
          onChange?.(val);
        }}
        value={field.value ? field.value : defaultDate}
        {...rest}
      />

      <li className='custom-form-error'>{error ? message : ""}</li>
    </div>
  );
}

export default DatepickerItem;
