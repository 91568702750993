import CustomBroudcamp from "src/components/layout/CustomBroudcamp";
import DocumentForm from "./components/DocumentForm";
import CustomSteps from "src/components/common/steps";
import LyceumAdmissionStep1 from "../admission/lyceum/step1";
import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import CustomButton from "src/components/common/button";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { useTranslation } from "react-i18next";

function StudyDocumentService() {
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const currentStep = searchParam.get("step") || 1;
  const [submit, setSubmit] = useState(1);
  const [step, setStep] = useState(+currentStep);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    setStep(+currentStep);
  }, [searchParam]);

  return (
    <div className='service service-language'>
      <div className='container'>
        <CustomBroudcamp
          nav={[
            { name: "nav.service", link: "/service?key=all" },
            {
              name: "nav.apply",
              link: "",
            },
          ]}
        />

        <div className='application-steps'>
          <CustomSteps
            items={[
              {
                title: "profile.common.personal_info",
                info: "profile.common.passport_info",
              },
              {
                title:
                  pathname == "/service/phdProf"
                    ? `Ilmiy unvon to'g'risidagi ma'lumotnoma`
                    : "O‘qiyotganlik haqida ma’lumotnoma",
                info:
                  pathname == "/service/phdProf"
                    ? `Ilmiy unvon to'g'risidagi ma'lumotnoma`
                    : "O‘qiyotganlik haqida ma’lumotnoma",
              },
            ]}
            current={step}
          />
        </div>

        <div className='application-content'>
          {step === 1 ? (
            <LyceumAdmissionStep1
              nextLink={
                pathname == "/service/studyProf"
                  ? "/service/studyProf?step=2"
                  : pathname == "/service/phdProf"
                  ? "/service/phdProf?step=2"
                  : "/service/studyDocument?step=2"
              }
            />
          ) : (
            <>
              <DocumentForm submitForm={submit} loading={loading} setLoading={setLoading} isStep />
              <div className='application-box-footer'>
                <Link
                  to={
                    pathname == "/service/studyProf"
                      ? "/service/studyProf?step=1"
                      : pathname == "/service/phdProf"
                      ? "/service/phdProf?step=1"
                      : "/service/studyDocument?step=1"
                  }
                >
                  <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
                </Link>
                <CustomButton
                  {...forwardButtonProps}
                  onClick={() => setSubmit(submit + 1)}
                  disabled={loading}
                >
                  Hujjatni yuklab olish
                </CustomButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default StudyDocumentService;
