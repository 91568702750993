export const phone_regex = /^\(\d{2}\) \d{3} \d{2} \d{2}$/;
export const pinfl_regex =
  /^\d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1}/;
export const docum_number_regex =
  /^\d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1} \d{1}/;
export const sms_code_regex = /^\d{1} \d{1} \d{1} \d{1} \d{1} \d{1}/;

export const phone_mask = "(99) 999 99 99";
export const pinfl_mask = "9 9 9 9 9 9 9 9 9 9 9 9 9 9";
export const docum_number_mask = "9 9 9 9 9 9 9 9";
export const sms_code_mask = "9 9 9 9 9 9";

export const pdfType = "application/pdf";

// /\b\d{7,8}\b/
