import { ServiceTypes } from "src/pages/services/type";

export const StaticSites = [
  {
    slug: `master_admission`,
    url: `https://magistr.edu.uz/`,
  },
  {
    slug: `bachelor_admission`,
    url: `https://my.uzbmb.uz/`,
  },
  // {
  //   slug: `getting_certificate_about_studying`,
  //   url: `https://my.gov.uz/oz/service/323`,
  // },
  {
    slug: `transfer_education`,
    url: `https://transfer.edu.uz/uz`,
  },
  {
    slug: `payment_contract`,
    url: `https://kontrakt.edu.uz`,
  },
  {
    slug: `unilibrary`,
    url: `https://unilibrary.uz/`,
  },
  {
    slug: `olympiad`,
    url: `https://olimpiada.edu.uz/`,
  },
  {
    slug: `literature`,
    url: `https://adabiyot.edu.uz/`,
  },
  {
    slug: `technical_school_admission`,
    url: `https://my.uzbmb.uz/service/67`,
  },
  {
    slug: `college_admission`,
    url: `https://prof.edu.uz/login`,
  },
  {
    slug: `getting_education_sheet`,
    url: `https://my.gov.uz/uz/service/851`,
  },
  // {
  //   slug: `interview_admission`,
  //   url: `https://my.gov.uz/oz/service/870`,
  // },
  {
    slug: `higher_education_diploma_adding_or_editing`,
    url: `https://my.gov.uz/oz/service/742`,
  },
  {
    slug: `secondary_education_diploma_adding_or_editing`,
    url: `https://my.gov.uz/oz/service/744`,
  },
];
export const giveStaticUrl = (data: ServiceTypes, page = "home") => {
  if (page === "home") {
    let staticUrl = StaticSites.find((item) => item.slug === data.slug);
    return staticUrl?.url
      ? staticUrl.url
      : data.is_active
      ? `/service/${data.id}?slug=${data.slug}`
      : ``;
  }
  if (page === "service") {
    let staticUrl = StaticSites.find((item) => item.slug === data.slug);
    return staticUrl?.url
      ? staticUrl.url
      : data.is_active
      ? `/service/${data.id}?slug=${data.slug}`
      : window.location.href;
  }
};
