import { useTranslation } from "react-i18next";
import { LoaderSVG, NoInformationSVG, PlusIconSVG } from "src/assets/svg";
import DiplomCard from "src/components/cards/DiplomCard";
import NoItemCard from "src/components/cards/NoItemCard";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import { GetSchoolInfo, ReloadDiploms, ReloadSchoolCert } from "./config";
import { useEffect, useState } from "react";
import { SchoolCerTypes } from "./type";
import toast from "react-hot-toast";

function ProfileDiplom() {
  const { t } = useTranslation();
  const [certificates, setSertificates] = useState<SchoolCerTypes>();

  const GetDiplomInfo = async () => {
    const { data } = await GetSchoolInfo();
    const list = data?.results || data;
    setSertificates(list[0]);
  };

  const LoadDiploma = async () => {
    try {
      await ReloadDiploms();
      await ReloadSchoolCert();
      GetDiplomInfo();
      toast.success("Muvaffaqiyatli yakunlandi !");
    } catch {}
  };

  useEffect(() => {
    GetDiplomInfo();
  }, []);

  return (
    <div className='profile__education'>
      <CustomAlert
        title={t("profile.cabinet.info") || ""}
        info={t("profile.applications.infoDiplom") || ""}
      />

      <div className='profile__education_header'>
        <h2>{t("profile.cabinet.diplom_info")}</h2>

        <div className='flex gap-4'>
          <CustomButton icon={<LoaderSVG />} left_icon color='green' outline onClick={LoadDiploma}>
            {t("profile.cabinet.refresh_info")}
          </CustomButton>
          <CustomButton
            icon={<PlusIconSVG />}
            left_icon
            color='primary'
            onClick={() => toast.success("Tez orada qo'shiladi !")}
          >
            {t("profile.diplom.add_diplom")}
          </CustomButton>
        </div>
      </div>

      <div className='profile__education_table'>
        {certificates ? (
          <DiplomCard type='maktab' data={certificates} />
        ) : (
          <NoItemCard
            longArrow
            icon={<NoInformationSVG />}
            title={t("profile.applications.no_application")}
            info={"profile.diplom.noDiplom"}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileDiplom;
