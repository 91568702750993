import {
  CreateFuncInterview,
  EditFuncInterview,
  GetFuncInterview,
} from "src/service/config/others";

export const GetDiplomConfig = () => {
  return GetFuncInterview(`/users/diploma/`);
};
export const GetUniverConfig = () => {
  return GetFuncInterview(`/university/universities/`);
};
export const GetDirectionConfig = (id: any) => {
  return GetFuncInterview(`/university/direction/${id}/`);
};
export const SubmitConfig = (data: any) => {
  return CreateFuncInterview("/users/application/", data);
};
export const EditAppConfig = (data: any) => {
  return EditFuncInterview("/users/my-application/", data);
};
export const GetInterviewApplicationConfig = () => {
  return GetFuncInterview(`/users/my-application/`);
};
export const GetDiplomSyncConfig = () => {
  return GetFuncInterview(`/users/diploma/sync/`);
};
export const GetUserConfig = () => {
  return GetFuncInterview(`/users/profile/`);
};
export const AppealConfig = (data: any) => {
  return CreateFuncInterview("/users/appeal/", data);
};
