import { Label, Checkbox } from "flowbite-react";
import { CheckboxItemProps } from "src/static/interface/common";

function CheckBoxItem({ label, field }: CheckboxItemProps) {
  return (
    <div className="flex items-center gap-2 ">
      <Checkbox id={"remember"} {...field} />
      <Label htmlFor={"remember"}>{label}</Label>
    </div>
  );
}

export default CheckBoxItem;
