import { Label, Radio } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { RadioItemProps } from "src/static/interface/common";

function RadioItem({
  items,
  label,
  field,
  error,
  message,
  active,
  defaultChecked,
  disabled,
  ...rest
}: RadioItemProps) {
  const { t } = useTranslation();

  return (
    <div className="custom-radio" {...rest}>
      <div className="custom-radio-label">{label}</div>
      <div className="custom-radio-wrap">
        {items.map((item) => {
          return (
            <Label
              htmlFor={item.label}
              className={`${active === item.value && "active"}`}
              key={item.value}
            >
              <Radio
                {...field}
                id={item.label}
                value={item.value}
                defaultChecked={defaultChecked === item.value}
                disabled={disabled}
              />
              {t(item.label)}
            </Label>
          );
        })}
      </div>
      <div className="custom-form-error">{error ? message : ""}</div>
    </div>
  );
}

export default RadioItem;
