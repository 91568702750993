import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "src/components/common/button";
import SelectItem from "src/components/form/select";
import { SECONDARY_ADMISSION_TOKEN } from "src/static/const/auth";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { second_edu_step3_schema } from "src/static/const/schema";
import { OptionTypes } from "src/static/interface/common";
import {
  AddAppSEConfig,
  EditAppSEConfig,
  GetAppDetailSEConfig,
  GetEduFormSEConfig,
  GetLanguageSEConfig,
  GetSpecialitySEConfig,
  GetUniversitiesSEConfig,
} from "../config";
import {
  makeEduFormAppItem,
  makeLanguageItem,
  makeSpecialityAppItem,
  makeUniversityItem,
} from "../const";
import { IAppDetailSE, IStep3SE } from "../type";
import ResultCard from "src/components/cards/ResultCard";
import { object } from "yup";
import { GetApplications } from "src/pages/profile/applications/config";

function SecondaryEducationAdmissionStep3() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem(SECONDARY_ADMISSION_TOKEN);

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<IStep3SE>({
    resolver: yupResolver(second_edu_step3_schema),
  });

  const onSubmit = (data: IStep3SE) => {
    if (token) {
      if (detailSE) {
        EditAppSEConfig(token, data.kvotaId).then((res) => {
          navigate(
            `/service/admission/detail?id=${res.data?.object}&key=secondary_education_admission`,
          );
        });
      } else {
        AddAppSEConfig(token, data.kvotaId).then((res) => {
          openModal();
          setAppID(String(res.data?.object));
        });
      }
    }
  };

  //Get classificators
  const [universities, setUniversities] = useState<OptionTypes[]>([]);
  const [detailSE, setDetailSE] = useState<IAppDetailSE>();
  const getFunc = () => {
    if (token) {
      //Universities
      GetUniversitiesSEConfig().then((res) => {
        setUniversities(makeUniversityItem(res.data));
      });

      //Detail
      GetApplications().then((res) => {
        const { results } = res.data;
        let hasApp = false;
        results &&
          results.forEach((item: any) => {
            if (item?.service?.slug === "secondary_education_admission") hasApp = true;
          });
        if (hasApp) {
          GetAppDetailSEConfig(token).then((res: { data: { object: IAppDetailSE } }) => {
            const { kvota } = res.data.object;
            if (kvota?.id) {
              setDetailSE(res.data.object);
              const { universityCode, eduFormCode, languageCode, id } = kvota;
              setValue("university", universityCode);
              setValue("formEdu", eduFormCode);
              setValue("language", languageCode);
              setValue("kvotaId", String(id));

              GetEduFormSEConfig(universityCode).then((res) => {
                setEduForms(makeEduFormAppItem(res.data));
              });
              GetLanguageSEConfig(eduFormCode, universityCode).then((res) => {
                setLanguages(makeLanguageItem(res.data));
              });
              GetSpecialitySEConfig(eduFormCode, universityCode, languageCode).then((res) => {
                setSpecialities(makeSpecialityAppItem(res.data));
              });
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    getFunc();
  }, []);

  //Form item functions
  const [eduForms, setEduForms] = useState<OptionTypes[]>([]);
  const [languages, setLanguages] = useState<OptionTypes[]>([]);
  const [specialities, setSpecialities] = useState<OptionTypes[]>([]);

  const handleChangeUniversity = (val: string) => {
    // Edu Forms
    GetEduFormSEConfig(val).then((res) => {
      setEduForms(makeEduFormAppItem(res.data));
    });

    //set value
    setValue("university", val);
    clearErrors("university");

    //clear value
    setValue("formEdu", "");
    setValue("language", "");
    setValue("kvotaId", "");
    setLanguages([]);
    setSpecialities([]);
  };

  const handleChangeFormEdu = (val: string) => {
    // Languages
    GetLanguageSEConfig(val, watch("university")).then((res) => {
      setLanguages(makeLanguageItem(res.data));
    });

    //set value
    setValue("formEdu", val);
    clearErrors("formEdu");

    //clear value
    setValue("language", "");
    setValue("kvotaId", "");
    setSpecialities([]);
  };

  const handleChangeLanguage = (val: string) => {
    // Speciality
    GetSpecialitySEConfig(watch("formEdu"), watch("university"), val).then((res) => {
      setSpecialities(makeSpecialityAppItem(res.data));
    });

    //set value
    setValue("language", val);
    clearErrors("language");

    //clear value
    setValue("kvotaId", "");
  };

  //Result
  const [appID, setAppID] = useState("");
  const [visible, setVisible] = useState(false);
  const openModal = () => setVisible(true);
  const closeModal = () => {
    setVisible(false);
    navigate(`/service/admission/detail?id=${appID}&key=secondary_education_admission`);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='application-box'>
          <div className='application-box-title'>{t("second_edu.institution_name")}</div>
          <div className='application-box-form'>
            <Controller
              name='university'
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("second_edu.institution_name") || ""}
                  placeholder={t("second_edu.select_institution") || ""}
                  field={field}
                  error={errors.university && true}
                  options={universities}
                  className='full'
                  onChange={handleChangeUniversity}
                />
              )}
            />
            <Controller
              name='formEdu'
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("profile.edu.edu_form") || ""}
                  placeholder={t("second_edu.select_edu_form") || ""}
                  field={field}
                  options={eduForms}
                  error={errors.formEdu && true}
                  className='full'
                  onChange={handleChangeFormEdu}
                />
              )}
            />
            <Controller
              name='language'
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("profile.choices.edu_lang") || ""}
                  placeholder={t("second_edu.select_edu_lan") || ""}
                  field={field}
                  error={errors.language && true}
                  options={languages}
                  className='full'
                  onChange={handleChangeLanguage}
                />
              )}
            />
            <Controller
              name='kvotaId'
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("profile.edu.dir_name") || ""}
                  placeholder={t("second_edu.select_dir_name") || ""}
                  field={field}
                  error={errors.kvotaId && true}
                  options={specialities}
                  className='full'
                />
              )}
            />
          </div>
        </div>

        <div className='application-box-footer'>
          <Link to='/service/admission/secondary_education/step2'>
            <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
          </Link>
          <CustomButton {...forwardButtonProps}>{t("profile.common.continue") || ""}</CustomButton>
        </div>
      </form>
      {/* Finishing result */}
      <ResultCard visible={visible} closeModal={closeModal} id={appID} />
    </>
  );
}

export default SecondaryEducationAdmissionStep3;
