import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SECONDARY_ADMISSION_TOKEN, language } from "src/static/const/auth";
import { lyceumStepsData, secondaryEducationStepsData } from "src/static/data";
import CustomSteps from "src/components/common/steps";

function SecondaryEducationAdmissionService() {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const current = location.pathname.split("step")[1];

  // useEffect(() => {
  //   i18n.changeLanguage(language);
  //   if (
  //     location.pathname === "/service/admission/secondary_education" ||
  //     !localStorage.getItem(SECONDARY_ADMISSION_TOKEN)
  //   ) {
  //     navigate("/service");
  //   }
  // }, [location]);

  return (
    <div className='application container'>
      <div className='application-steps'>
        <CustomSteps items={secondaryEducationStepsData} current={+current} />
      </div>
      <div className='application-content'>{<Outlet />}</div>
    </div>
  );
}

export default SecondaryEducationAdmissionService;
