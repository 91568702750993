import { useSearchParams } from "react-router-dom";
import { CloseIconSVG, DoneIconSVG } from "src/assets/svg";
import CustomButton from "../common/button";
import { useTranslation } from "react-i18next";

function CustomTab({
  buttons = [
    {
      name: "a",
      key: "a",
    },
  ],
}: {
  buttons?: { name: string; key: string }[];
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const GiveActiveTag = (key: string) => {
    return searchParams.get("tag") === key && true;
  };
  const handleMakeParams = (key: any, value: any) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };
  const SetTab = (key: string) => {
    searchParams.get("tag") === key
      ? handleMakeParams("tag", "")
      : handleMakeParams("tag", key);
  };

  return (
    <div className="service__action">
      {buttons?.map((button) => (
        <CustomButton
          size="sm"
          left_icon
          key={button.key}
          onClick={() => SetTab(button.key)}
          color={GiveActiveTag(button.key) ? "primary" : "gray"}
          icon={GiveActiveTag(button.key) ? <CloseIconSVG /> : <DoneIconSVG />}
        >
          {t(button.name)}
        </CustomButton>
      ))}
    </div>
  );
}

export default CustomTab;
