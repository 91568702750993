import { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { ArrowBackSvg, EyePasswordSVG } from "src/assets/svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSigninTypes, LoginSigninProps } from "src/static/interface/login";
import { SignInConfig } from "src/service/api/auth";
import { login_signin_schema } from "src/static/const/schema";
import { handleLoginFunc } from "src/utils";

import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";
import CheckBoxItem from "src/components/form/checkbox";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

function LoginSignIn({ setStep, informs, closeModal }: LoginSigninProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { phone_number } = informs;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<LoginSigninTypes>({
    resolver: yupResolver(login_signin_schema),
  });
  useEffect(() => {
    phone_number && setValue("phone_number", phone_number);
  }, []);
  const onSubmit = (data: LoginSigninTypes) => {
    let { check, ...rest } = data;
    setLoading(true);
    SignInConfig(rest)
      .then((res) => {
        handleLoginFunc(res.data);
        closeModal();
        window.location.reload();
      })
      .catch((err) => {
        const { status } = err.response;
        // if (status === 400) {
        //   setStep("signup");
        //     toast.error(t("message.login_main_400"));
        //   } else if (status === 404) {
        //     toast.error(t("message.login_404"));
        //   } else if (status === 406) {
        //     toast.error(t("message.login_406"));
        // }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="login-signup">
      <Modal.Header className="login-header">{t("navbar.login")}</Modal.Header>
      <Modal.Body className="login-body">
        <div className="login-signup-header">
          <p></p>
        </div>
        <form className="custom-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("profile.common.phone") || ""}
                placeholder="+998 _ _ _  _ _  _ _"
                size="lg"
                message={t("profile.common.phone") || ""}
                disabled
                field={field}
                error={errors.phone_number ? true : false}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("registration.password") || ""}
                placeholder={t("registration.password_enter") || ""}
                size="lg"
                type="password"
                message={t("registration.password_enter") || ""}
                field={field}
                rightIcon={<EyePasswordSVG />}
                className="password-input"
                error={errors.password ? true : false}
              />
            )}
          />
          <div className="login-signin-reset">
            <Controller
              name="check"
              control={control}
              render={({ field }) => (
                <CheckBoxItem
                  field={field}
                  label={t("registration.save") || ""}
                />
              )}
            />
            <p onClick={() => setStep("reset")}>
              {t("registration.password_retrive")}
            </p>
          </div>
          <CustomButton
            size="xl"
            color="primary"
            type="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            {t("profile.common.continue")}
          </CustomButton>
          <CustomButton
            onClick={() => setStep("main")}
            size="xl"
            color="gray"
            style={{ width: "100%", marginTop: 12 }}
            icon={<ArrowBackSvg />}
            left_icon
          >
            {t("profile.common.back")}
          </CustomButton>
        </form>
      </Modal.Body>
    </div>
  );
}

export default LoginSignIn;
