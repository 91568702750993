import * as successJson from "./success.json";

export const successLottie = {
  loop: false,
  autoplay: true,
  animationData: successJson,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
