import { CreateFunc } from "src/service/config";
import {
  ConfirmSmsTypes,
  LoginMainTypes,
  SavePasswordTypes,
  SendSmsTypes,
} from "src/static/interface/login";

export const CheckPhoneConfig = (data: LoginMainTypes) => {
  return CreateFunc(`/check-phone-number/`, data);
};
export const SignInConfig = (data: LoginMainTypes) => {
  return CreateFunc(`/login/`, data);
};
export const SendSmsConfig = (data: SendSmsTypes) => {
  return CreateFunc(`/send-sms-code/`, data);
};
export const ConfirmSmsConfig = (data: ConfirmSmsTypes) => {
  return CreateFunc(`/confirm-sms-code/`, data);
};
export const SavePasswordConfig = (data: SavePasswordTypes) => {
  return CreateFunc(`/save-password/`, data);
};
