import { Label, FileInput } from "flowbite-react";
import { ChangeEvent } from "react";
import { HelpSvg } from "src/assets/svg";
import { InputItemProps } from "src/static/interface/common";

function InputUploadItem({
  label,
  placeholder,
  addon,
  disabled,
  size,
  error,
  message,
  field,
  type,
  accept,
  openDrower,
  onChange,
  className,
  ...rest
}: InputItemProps) {
  return (
    <div className={`custom-input ${className}`}>
      <div className="custom-input-top">
        <Label htmlFor={label} className="custom-input-label">
          {label}
        </Label>
        {openDrower && (
          <button
            className="custom-input-help"
            type="button"
            onClick={openDrower}
          >
            <HelpSvg />
          </button>
        )}
      </div>
      <FileInput
        {...field}
        id={label}
        color={error ? "failure" : "gray"}
        helperText={
          <li className="custom-form-error">{error ? message : ""}</li>
        }
        onChange={(ev: ChangeEvent<HTMLInputElement>) =>
          onChange?.(ev.target.files?.[0])
        }
        disabled={disabled}
        placeholder={placeholder}
        accept={accept}
        addon={addon}
        sizing={size}
        value={undefined}
        vocab="sa"
        {...rest}
      />
    </div>
  );
}

export default InputUploadItem;
