import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowBackSvg, NoApplicationsSVG } from "src/assets/svg";
import ApplicationCard from "src/components/cards/ApplicationCard";
import NoItemCard from "src/components/cards/NoItemCard";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import { GetApplications } from "./config";
import { useNavigate } from "react-router-dom";

export const ArchiveApplications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [apps, setApps] = useState([]);

  const GetApplicationsList = async () => {
    const { data } = await GetApplications(true);
    setApps(
      data.results.sort(
        (a: any, b: any) =>
          new Date(b?.last_updated_time).getTime() - new Date(a?.last_updated_time).getTime(),
      ),
    );
  };

  useEffect(() => {
    GetApplicationsList();
  }, []);

  return (
    <div className='archive'>
      <div className='container'>
        <CustomButton
          color='gray'
          leftIcon={<ArrowBackSvg />}
          style={{ marginBottom: 32 }}
          onClick={() => navigate("/profile/applications")}
        >
          Orqaga qaytish
        </CustomButton>
        <div className='profile__education_header' style={{ marginBottom: 32 }}>
          <h2>Arxiv arizalar</h2>
        </div>
        <div className='flex'>
          <div className='left'>
            {apps.length > 0 ? (
              apps.map((app: any) => <ApplicationCard data={app} key={crypto.randomUUID()} />)
            ) : (
              <NoItemCard
                link='/service?key=all'
                icon={<NoApplicationsSVG />}
                title={t("profile.applications.no_application")}
                info={"profile.applications.text1"}
              />
            )}
          </div>
          <div className='right'>
            <CustomAlert
              type='warning'
              title={`Arxiv arizalar`}
              info={`Bu yerda siz foydalanib bo‘lgan va 6 oy muddat o‘tgan arizalar arxivingiz tarix sifatida saqlanadi.`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
