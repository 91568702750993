import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { vocationStepsData } from "src/static/data";
import CustomSteps from "src/components/common/steps";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  VOCATION_ADMISSION_DATA,
  VOCATION_ADMISSION_TOKEN,
  language,
} from "src/static/const/auth";

function VocationAdmissionService() {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const current = location.pathname.split("step")[1];

  useEffect(() => {
    i18n.changeLanguage(language);
    if (
      location.pathname === "/service/admission/vocation" ||
      !localStorage.getItem(VOCATION_ADMISSION_TOKEN)
    ) {
      navigate("/service");
      localStorage.removeItem(VOCATION_ADMISSION_DATA);
      localStorage.removeItem("appID");
    }
  }, [location]);

  return (
    <div className="application container">
      <div className="application-steps">
        <CustomSteps items={vocationStepsData} current={+current} />
      </div>
      <div className="application-content">{<Outlet />}</div>
    </div>
  );
}

export default VocationAdmissionService;
