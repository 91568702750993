import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "src/components/common/button";
import SelectItem from "src/components/form/select";
import { MainContext } from "src/hooks/context";
import { LYCEUM_ADMISSION_DATA } from "src/static/const/auth";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { lyceum_step3_schema } from "src/static/const/schema";
import { BaseObjTypes } from "src/static/interface";
import { OptionTypes } from "src/static/interface/common";
import { makeOptionItem } from "src/utils";
import {
  GetLyceumDirectionConfig,
  GetLyceumLanguagesConfig,
  GetLyceumQuotaConfig,
} from "../config";
import { LyceumQuotaTypes, SpecialityTypes, Step3Types } from "../type";

function LyceumAdmissionStep3() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  //Get classifiers
  const { languages, getLanguages, regions, getRegions } = useContext(MainContext);

  const [hasForLan, setHasForLan] = useState(false);
  const [allDisabled, setAllDisabled] = useState(false);
  const [langs, setLangs] = useState<any[]>([]);
  const [eduLangs, setEduLangs] = useState<OptionTypes[]>();
  const [options, setOptions] = useState<OptionTypes[]>([]);
  const [options2, setOptions2] = useState<OptionTypes[]>([]);
  const [options3, setOptions3] = useState<OptionTypes[]>([]);
  const [options4, setOptions4] = useState<OptionTypes[]>([]);
  const [options5, setOptions5] = useState<OptionTypes[]>([]);
  const [specialities, setSpecialities] = useState<SpecialityTypes[]>([]);

  const getEduLangs = async () => {
    const { data } = await GetLyceumLanguagesConfig();
    const result = data?.results.reduce(
      (prev: BaseObjTypes[], next: BaseObjTypes) => [...prev, { ...next, value: next?.id }],
      [],
    );
    setEduLangs(result);
  };

  const getFunc = () => {
    GetLyceumDirectionConfig().then((res) => {
      setSpecialities(res.data?.results);
      setOptions(makeOptionItem(res.data?.results));
    });
    getLanguages?.();
    getEduLangs?.();
    getRegions?.();
  };

  useEffect(() => {
    languages && setLangs([...languages?.filter((lang) => lang.is_default)]);
  }, [languages]);

  useEffect(() => {
    getFunc();
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<Step3Types>({
    resolver: yupResolver(lyceum_step3_schema),
  });

  const onSubmit = async (data: Step3Types) => {
    if (localStorage.getItem(LYCEUM_ADMISSION_DATA)) {
      const local = await JSON.parse(localStorage.getItem(LYCEUM_ADMISSION_DATA) || "");
      localStorage.setItem(
        LYCEUM_ADMISSION_DATA,
        JSON.stringify({
          ...data,
          science_certificate: local?.science_certificate,
          foreign_language_certificate: local?.foreign_language_certificate,
          achievement_certificate: local?.achievement_certificate,
        }),
      );
    } else {
      localStorage.setItem(LYCEUM_ADMISSION_DATA, JSON.stringify(data));
    }
    navigate("/service/admission/lyceum/step4");
  };

  //Select change functions
  const handleChangeSpeciality = (val: string) => {
    let obj = specialities.find((el) => el.id === val);
    if (obj) {
      const { education_languages, foreign_language } = obj;
      let arr: OptionTypes[] = [];
      eduLangs?.forEach((item) => {
        if (education_languages.includes(item.value)) {
          arr.push(item);
        }
      });
      setOptions2([...arr]);

      if (foreign_language) {
        setValue("foreign_language", foreign_language.id);
        let arr =
          languages?.filter((lang) => lang.is_default && lang.value !== foreign_language.id) || [];
        if (arr.find((item) => item.value !== foreign_language.id)) {
          setLangs([...arr, { ...foreign_language, value: foreign_language.id }]);
        } else {
          setLangs([...arr]);
        }

        // setHasForLan(true);
      } else {
        // setHasForLan(false);
        setValue("foreign_language", "");
        let arr = languages?.filter((lang) => lang.is_default) || [];
        setLangs([...arr]);
      }

      //Clear
      setValue("education_language", "");
      setValue("lyceum1", "");
      setValue("lyceum2", "");
      setValue("lyceum3", "");
    }

    setValue("speciality", val);
    clearErrors("speciality");
  };

  const handleChangeEduLan = (val: string) => {
    let url = `?speciality=${watch("speciality")}&education_language=${val}&limit=1000`;
    GetLyceumQuotaConfig(url).then((res) => {
      let arr: BaseObjTypes[] = [];
      res.data?.results?.forEach((item: LyceumQuotaTypes) => {
        arr.push(item.lyceum);
      });
      setOptions3(makeOptionItem(arr));

      //clear
      setValue("lyceum1", "");
      setValue("lyceum2", "");
      setValue("lyceum3", "");
    });
    setValue("education_language", val);
    clearErrors("education_language");
  };

  const handleChangeLyceum1 = (val: string) => {
    //disable selected option
    const json = JSON.stringify(options3);
    const arr = JSON.parse(json);
    arr.forEach((item: OptionTypes) => {
      if (item.value === val) item.disabled = true;
      else item.disabled = false;
    });
    setOptions3([...arr]);
    setOptions4([...arr]);

    //clear
    setValue("lyceum2", "");
    setValue("lyceum3", "");

    //set value
    setValue("lyceum1", val);
    clearErrors("lyceum1");
  };

  const handleChangeLyceum2 = (val: string) => {
    //disable selected option
    const json = JSON.stringify(options4);
    const arr = JSON.parse(json);
    arr.forEach((item: OptionTypes) => {
      if (item.value === val || item.value === watch("lyceum1")) item.disabled = true;
      else item.disabled = false;
    });
    setOptions4([...arr]);
    setOptions5([...arr]);

    //clear
    setValue("lyceum3", "");

    //set value
    setValue("lyceum2", val);
    clearErrors("lyceum2");
  };

  const handleChangeLyceum3 = (val: string) => {
    //disable selected option
    const json = JSON.stringify(options5);
    const arr = JSON.parse(json);
    arr.forEach((item: OptionTypes) => {
      if (item.value === val || item.value === watch("lyceum1") || item.value === watch("lyceum2"))
        item.disabled = true;
      else item.disabled = false;
    });
    setOptions5([...arr]);

    //set value
    setValue("lyceum3", val);
    clearErrors("lyceum3");
  };

  const setLanguageDef = () => {
    if (localStorage.getItem(LYCEUM_ADMISSION_DATA)) {
      const data = JSON.parse(localStorage.getItem(LYCEUM_ADMISSION_DATA) || "");

      if (data) {
        let obj = specialities.find((el) => el.id === data.speciality);
        if (obj) {
          const { education_languages, foreign_language } = obj;
          let arr: OptionTypes[] = [];
          eduLangs?.forEach((item) => {
            if (education_languages.includes(item.value)) {
              arr.push(item);
            }
          });

          setOptions2([...arr]);
          if (foreign_language) {
            // setValue("foreign_language", foreign_language.id);
            let arr = languages?.filter((lang) => lang.is_default) || [];
            if (arr.find((item) => item.value !== foreign_language.id)) {
              setLangs([...arr, { ...foreign_language, value: foreign_language.id }]);
            } else {
              setLangs([...arr]);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    setLanguageDef();
  }, [specialities]);

  const backSetValues = async () => {
    setAllDisabled(true);
    if (localStorage.getItem(LYCEUM_ADMISSION_DATA)) {
      const data = await JSON.parse(localStorage.getItem(LYCEUM_ADMISSION_DATA) || "");

      if (data) {
        let url = `?speciality=${data.speciality}&education_language=${data.education_language}&limit=1000`;
        GetLyceumQuotaConfig(url).then((res) => {
          let arr: BaseObjTypes[] = [];
          res.data?.results.forEach((item: LyceumQuotaTypes) => {
            arr.push(item.lyceum);
          });

          //lyceum1 disable selected option
          const json1 = JSON.stringify(makeOptionItem(arr));
          const arr1 = JSON.parse(json1);
          arr1.forEach((item: OptionTypes) => {
            if (item.value === data.lyceum1) item.disabled = true;
            else item.disabled = false;
          });
          setOptions3([...arr1]);

          //lyceum2 disable selected option
          const json2 = JSON.stringify(arr1);
          const arr2 = JSON.parse(json2);
          arr2.forEach((item: OptionTypes) => {
            if (item.value === data.lyceum1 || item.value === data.lyceum2) item.disabled = true;
            else item.disabled = false;
          });
          setOptions4([...arr2]);

          //lyceum3 disable selected option
          const json3 = JSON.stringify(arr2);
          const arr3 = JSON.parse(json3);
          arr3.forEach((item: OptionTypes) => {
            if (
              item.value === data.lyceum1 ||
              item.value === data.lyceum2 ||
              item.value === data.lyceum3
            )
              item.disabled = true;
            else item.disabled = false;
          });
          setOptions5([...arr3]);
        });

        // Set specialty
        setValue("speciality", data.speciality);

        // setLanguage
        setValue("education_language", data.education_language);

        // set for lang
        setValue("foreign_language", data.foreign_language);
        if (langs.find((item) => item.value !== data.foreign_language)) {
          let forignLang = languages?.find((item) => item.value == data.foreign_language);
          setLangs([...langs, forignLang]);
        }

        // set region
        setValue("region", data.region);

        // Set LYCEUM 1
        setValue("lyceum1", data.lyceum1);

        // Set LYCEUM 2
        data.lyceum2 && setValue("lyceum2", data.lyceum2);

        // Set LYCEUM 3
        data.lyceum3 && setValue("lyceum3", data.lyceum3);
      }
    }

    // if (localStorage.getItem("lyceumAccepted") === "true") {
    //   setAllDisabled(true);
    // }
  };

  useEffect(() => {
    backSetValues();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='application-box'>
        <div className='application-box-title'>{t("profile.choices.select_edu")}</div>
        <div className='application-box-form'>
          <Controller
            name='speciality'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.choices.edu_direction") || ""}
                placeholder={t("second_edu.select_dir_name") || ""}
                field={field}
                error={errors.speciality && true}
                options={options}
                className='full'
                onChange={handleChangeSpeciality}
                disabled={allDisabled}
              />
            )}
          />
          <Controller
            name='education_language'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.choices.edu_lang") || ""}
                placeholder={t("second_edu.select_edu_lan") || ""}
                field={field}
                options={options2}
                disabled={options2.length === 0 || allDisabled}
                error={errors.education_language && true}
                className='half'
                onChange={handleChangeEduLan}
              />
            )}
          />
          <Controller
            name='foreign_language'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.awards.english") || ""}
                placeholder={t("profile.common.select_foreign_lang") || ""}
                field={field}
                error={errors.foreign_language && true}
                options={langs}
                disabled={hasForLan || allDisabled}
                className='half'
              />
            )}
          />
          <Controller
            name='region'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.choices.nearby_adress") || ""}
                placeholder={t("profile.common.select_region") || ""}
                field={field}
                error={errors.region && true}
                options={regions}
                className='full'
                disabled={allDisabled}
              />
            )}
          />

          <div className='application-line'></div>
          <div className='application-box-subtitle'>{t("profile.choices.order") || ""}</div>
          <Controller
            name='lyceum1'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.choices.lyceum1") || ""}
                placeholder={t("profile.choices.lyceum1") || ""}
                field={field}
                error={errors.lyceum1 && true}
                options={options3}
                disabled={(watch("education_language") ? false : true) || allDisabled}
                className='full'
                onChange={handleChangeLyceum1}
              />
            )}
          />
          <Controller
            name='lyceum2'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.choices.lyceum2") || ""}
                placeholder={t("profile.choices.lyceum2") || ""}
                field={field}
                error={errors.lyceum2 && true}
                options={options4}
                disabled={(watch("lyceum1") ? false : true) || allDisabled}
                className='full'
                onChange={handleChangeLyceum2}
              />
            )}
          />
          <Controller
            name='lyceum3'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.choices.lyceum3") || ""}
                placeholder={t("profile.choices.lyceum3") || ""}
                field={field}
                error={errors.lyceum3 && true}
                options={options5}
                disabled={(watch("lyceum2") ? false : true) || allDisabled}
                className='full'
                onChange={handleChangeLyceum3}
              />
            )}
          />
        </div>
      </div>

      <div className='application-box-footer'>
        <Link to='/service/admission/lyceum/step2'>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps}>{t("profile.common.continue") || ""}</CustomButton>
      </div>
    </form>
  );
}

export default LyceumAdmissionStep3;
