function DropDown({ mainButton, body, dropdownOpen, className }: any) {
  return (
    <div className={`customDropDown ${className}`}>
      {mainButton}

      <div
        className={`customDropDown__menu ${
          dropdownOpen ? "customDropDown__active" : ""
        }`}
      >
        {body}
      </div>
    </div>
  );
}

export default DropDown;
