import { useTranslation } from "react-i18next";
import { CustomStepsProps } from "src/static/interface/common";

function CustomSteps({ items, current }: CustomStepsProps) {
  const { t } = useTranslation();

  return (
    <ol className="items-center space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
      {items.map((item, index) => {
        return (
          <li
            key={item.title}
            className={`${
              current > index + 1
                ? "text-green-700"
                : current === index + 1
                ? "text-blue-700"
                : "text-gray-500"
            } flex items-center  space-x-2.5`}
          >
            <span
              className={`${
                current > index + 1
                  ? "border-green-700"
                  : current === index + 1
                  ? "border-blue-700"
                  : "border-gray-500"
              }  flex items-center justify-center w-8 h-8 border rounded-full shrink-0`}
            >
              {index + 1}
            </span>
            <span>
              <h3 className="font-medium leading-6">{t(item.title)}</h3>
              <p className="text-sm leading-6">{t(item.info)}</p>
            </span>
          </li>
        );
      })}
    </ol>
  );
}

export default CustomSteps;
