import { Link } from "react-router-dom";
import { RightSVG } from "src/assets/svg";
import { ServiceTypes } from "src/pages/services/type";
import { giveLan } from "src/utils";
import { StaticSites, giveStaticUrl } from "./const";

function HomeServiceCard({ data }: { data: ServiceTypes }) {
  return (
    <Link
      target={StaticSites.find((item) => item.slug === data.slug) ? "_blank" : ""}
      to={giveStaticUrl(data) || ""}
      className={`home__services-item ${data.is_active ? "home__services-active" : ""}`}
    >
      <img
        src={data.is_active ? data.svg_for_hot : data.svg_for_inactive}
        alt='icon'
        style={{ width: 64 }}
      />
      <div>
        <h3>{giveLan(data)}</h3>
        <p>{giveLan(data, "description")}</p>
      </div>
      <RightSVG />
    </Link>
  );
}

export default HomeServiceCard;
