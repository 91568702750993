import {
  HttpRequestHubLyceum,
  HttpRequestHubVocation,
  HttpRequestHubInterview,
  HttpRequestHubSecondEdu,
  HttpRequestHubMultipartSecondEdu,
} from "../hub";

// Lyceum---------------------------------------------------------
export const GetFuncLyceum = (url: string, data = "") => {
  const config = {
    method: "GET",
    url: `${url}`,
    data,
  };
  return HttpRequestHubLyceum(config);
};

export const DeleteFuncLyceum = (url: string, data?: any) => {
  const config = {
    method: "DELETE",
    url: `${url}`,
    data,
  };
  return HttpRequestHubLyceum(config);
};

export const CreateFuncLyceum = (url: string, data: any) => {
  const config = {
    method: "POST",
    url: `${url}`,
    data,
  };
  return HttpRequestHubLyceum(config);
};

export const EditFuncLyceum = (url: string, data: any, method = "PUT") => {
  const config = {
    method: method,
    url: `${url}`,
    data,
  };
  return HttpRequestHubLyceum(config);
};

// Vacation---------------------------------------------------------
export const GetFuncVocation = (url: string, data = "") => {
  const config = {
    method: "GET",
    url: `${url}`,
    data,
  };
  return HttpRequestHubVocation(config);
};

export const DeleteFuncVocation = (url: string, data?: any) => {
  const config = {
    method: "DELETE",
    url: `${url}`,
    data,
  };
  return HttpRequestHubVocation(config);
};

export const CreateFuncVocation = (url: string, data: any) => {
  const config = {
    method: "POST",
    url: `${url}`,
    data,
  };
  return HttpRequestHubVocation(config);
};

export const EditFuncVocation = (url: string, data: any, method = "PUT") => {
  const config = {
    method: method,
    url: `${url}`,
    data,
  };
  return HttpRequestHubVocation(config);
};

// Interview---------------------------------------------------------
export const GetFuncInterview = (url: string, data = "") => {
  const config = {
    method: "GET",
    url: `${url}`,
    data,
  };
  return HttpRequestHubInterview(config);
};

export const DeleteFuncInterview = (url: string, data?: any) => {
  const config = {
    method: "DELETE",
    url: `${url}`,
    data,
  };
  return HttpRequestHubInterview(config);
};

export const CreateFuncInterview = (url: string, data: any) => {
  const config = {
    method: "POST",
    url: `${url}`,
    data,
  };
  return HttpRequestHubInterview(config);
};

export const EditFuncInterview = (url: string, data: any, method = "PUT") => {
  const config = {
    method: method,
    url: `${url}`,
    data,
  };
  return HttpRequestHubInterview(config);
};

// Second Education---------------------------------------------------------
export const GetFuncSecondEdu = (url: string, data = "") => {
  const config = {
    method: "GET",
    url: `${url}`,
    data,
  };
  return HttpRequestHubSecondEdu(config);
};

export const DeleteFuncSecondEdu = (url: string, data?: any) => {
  const config = {
    method: "DELETE",
    url: `${url}`,
    data,
  };
  return HttpRequestHubSecondEdu(config);
};

export const CreateFuncSecondEdu = (url: string, data: any) => {
  const config = {
    method: "POST",
    url: `${url}`,
    data,
  };
  return HttpRequestHubSecondEdu(config);
};

export const EditFuncSecondEdu = (url: string, data: any, method = "PUT") => {
  const config = {
    method: method,
    url: `${url}`,
    data,
  };
  return HttpRequestHubSecondEdu(config);
};

export const CreateFuncMultipartSecondEdu = (url: string, data: any) => {
  const config = {
    method: "POST",
    url: `${url}`,
    data,
  };
  return HttpRequestHubMultipartSecondEdu(config);
};
