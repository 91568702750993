import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { MiniRightIconSVG } from "src/assets/svg";
import {
  ChangeUserPasswordConfig,
  CheckUserPasswordConfig,
} from "src/service/api/profile";
import {
  password_rule,
  profile_setting_passwrod_schema,
} from "src/static/const/schema";
import { UserPasswordTypes } from "src/static/interface/profile";
import { loginPasswordData } from "src/static/data";
import InputItem from "src/components/form/input";
import CustomButton from "src/components/common/button";
import { useTranslation } from "react-i18next";

function ChangePassword() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [one_time_p, set_one_time_p] = useState("");

  let password = "";
  const schema = yup
    .object({
      password: password_rule,
      confirm: yup.string().test("", "", (val) => val === password),
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<UserPasswordTypes>({
    resolver: yupResolver(
      one_time_p ? schema : profile_setting_passwrod_schema
    ),
  });
  password = watch("password");

  const onSubmit = (data: UserPasswordTypes) => {
    setLoading(true);
    if (one_time_p) {
      let obj = {
        password: data.password,
        one_time_password: one_time_p,
      };
      ChangeUserPasswordConfig(obj)
        .then((res) => {
          alert("Sizning parolingiz muvaffaqiyatli o'zgartirildi");
          reset();
          set_one_time_p("");
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    } else
      CheckUserPasswordConfig(data)
        .then((res) => {
          set_one_time_p(res.data.one_time_password);
          reset();
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
  };

  return (
    <>
      <h3 className="title">{t("profile.settings.change_password")}</h3>
      <div className="profile__settings_password_content">
        <form
          className="custom-form custom-form-sm profile__settings_form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {one_time_p ? (
            <>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <InputItem
                    label="Parol"
                    placeholder="Parolni yarating (eng kamida 6 belgi)"
                    size="lg"
                    // type="password"
                    message={
                      password
                        ? "Parol eng kamida 6 belgidan iborat bo'lishi kerak"
                        : "Parolni kiriting"
                    }
                    field={field}
                    error={errors.password ? true : false}
                  />
                )}
              />
              <Controller
                name="confirm"
                control={control}
                render={({ field }) => (
                  <InputItem
                    label="Parolni takrorlang"
                    placeholder="Parolni takror kiriting"
                    size="lg"
                    // type="password"
                    message={
                      watch("confirm")
                        ? "Parollar mos emas"
                        : "Parolni takror kiriting"
                    }
                    field={field}
                    error={errors.confirm ? true : false}
                  />
                )}
              />
            </>
          ) : (
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("profile.settings.enter_current_password") || ""}
                  field={field}
                  error={errors.password ? true : false}
                  message={
                    password
                      ? "Parol eng kamida 6 belgidan iborat bo'lishi kerak"
                      : t("profile.settings.enter_current_password") || ""
                  }
                  size="lg"
                />
              )}
            />
          )}

          <CustomButton
            type="submit"
            color={one_time_p ? "primary" : "gray"}
            icon={<MiniRightIconSVG />}
            loading={loading}
            style={{ marginTop: 8 }}
          >
            {t("profile.common.continue") || ""}
          </CustomButton>
        </form>

        {one_time_p && (
          <div className="login-password-rules">
            <h2>Parol talablariga e'tibor bering</h2>
            {loginPasswordData.map((item) => {
              return (
                <p className={`${item.active && "active"}`} key={item.text}>
                  - {item.text}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default ChangePassword;
