import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Footer from "src/components/layout/Footer";
import Navbar from "src/components/layout/navbar";
import StatusPage from "src/pages/Status";
import HomePage from "src/pages/home";
import ProfilePage from "src/pages/profile";
import ProfileApplications from "src/pages/profile/applications";
import { ArchiveApplications } from "src/pages/profile/applications/ArchiveApplications";
import ProfileDiplom from "src/pages/profile/diplom";
import ProfileEdu from "src/pages/profile/educations";
import ProfileInfo from "src/pages/profile/info";
import ProfileLang from "src/pages/profile/language";
import ProfilePortfoli from "src/pages/profile/portfolio";
import ProfileSettings from "src/pages/profile/settings";
import ProfileWork from "src/pages/profile/work";
import Services from "src/pages/services";
import AdmissionPage from "src/pages/services/admission";
import AdmissionDetail from "src/pages/services/admission/detail";
import InterviewAdmissionService from "src/pages/services/admission/interview_admission";
import InterviewAdmissionStep2 from "src/pages/services/admission/interview_admission/step2";
import InterviewAdmissionStep3 from "src/pages/services/admission/interview_admission/step3";
import InterviewAdmissionStep4 from "src/pages/services/admission/interview_admission/step4";
import LyceumAdmissionService from "src/pages/services/admission/lyceum";
import LyceumAdmissionStep1 from "src/pages/services/admission/lyceum/step1";
import LyceumAdmissionStep2 from "src/pages/services/admission/lyceum/step2";
import LyceumAdmissionStep3 from "src/pages/services/admission/lyceum/step3";
import LyceumAdmissionStep4 from "src/pages/services/admission/lyceum/step4";
import SecondaryEducationAdmissionService from "src/pages/services/admission/secondary_education";
import SecondaryEducationAdmissionStep2 from "src/pages/services/admission/secondary_education/step2";
import SecondaryEducationAdmissionStep3 from "src/pages/services/admission/secondary_education/step3";
import VocationAdmissionService from "src/pages/services/admission/vocation";
import VocationAdmissionStep3 from "src/pages/services/admission/vocation/step3";
import VocationAdmissionStep4 from "src/pages/services/admission/vocation/step4";
import LanguageService from "src/pages/services/language";
import QuolificationService from "src/pages/services/qualification_certificate";
import ServiceID from "src/pages/services/serviceId";
import StudyDocumentService from "src/pages/services/studyDocument";
import { InternalServerError, PhoneNumberBusy } from "src/pages/static/PhoneNumberBusy";
import { isUser } from "src/static/const/auth";

function RoutElements() {
  const location = useLocation();

  return (
    <>
      <div className='main-content'>
        <Navbar />
        <Routes>
          <Route index element={<HomePage />} />
          <Route path='/welcome' element={<StatusPage />} />
          <Route path='/phone-number-is-busy' element={<PhoneNumberBusy />} />
          <Route path='/internal-error' element={<InternalServerError />} />

          {/* Profile pages */}
          <Route path='/profile' element={isUser ? <ProfilePage /> : <Navigate to='/' />}>
            <Route index element={<ProfileInfo />} />
            <Route path='education' element={<ProfileEdu />} />
            <Route path='applications' element={<ProfileApplications />} />
            <Route path='diploma' element={<ProfileDiplom />} />
            <Route path='languages' element={<ProfileLang />} />
            <Route path='portfolio' element={<ProfilePortfoli />} />
            <Route path='work' element={<ProfileWork />} />
            <Route path='settings' element={<ProfileSettings />} />
          </Route>

          {/* Service pages */}
          <Route path='/service'>
            <Route index element={<Services />} />
            <Route path=':id' element={<ServiceID />} />

            {/* Add lang certificate service */}
            <Route
              path='language'
              element={isUser ? <LanguageService /> : <Navigate to='/service' />}
            />

            {/* Add qualification certificate service */}
            <Route
              path='qualification_certificate'
              element={isUser ? <QuolificationService /> : <Navigate to='/service' />}
            />

            {/* Add Univer study doc service */}
            <Route
              path='studyDocument'
              element={isUser ? <StudyDocumentService /> : <Navigate to='/service' />}
            />

            {/* Add Univer study doc service */}
            <Route
              path='studyProf'
              element={isUser ? <StudyDocumentService /> : <Navigate to='/service' />}
            />

            {/* Add Univer study doc service */}
            <Route
              path='phdProf'
              element={isUser ? <StudyDocumentService /> : <Navigate to='/service' />}
            />

            {/* Admissions services */}
            <Route
              path='admission'
              element={isUser ? <AdmissionPage /> : <Navigate to='/service' />}
            >
              {/* Lyceum admission service */}
              <Route path='lyceum' element={<LyceumAdmissionService />}>
                <Route path='step1' element={<LyceumAdmissionStep1 />} />
                <Route path='step2' element={<LyceumAdmissionStep2 />} />
                <Route path='step3' element={<LyceumAdmissionStep3 />} />
                <Route path='step4' element={<LyceumAdmissionStep4 />} />
              </Route>

              {/* Secondary education admission service */}
              <Route path='secondary_education' element={<SecondaryEducationAdmissionService />}>
                <Route
                  path='step1'
                  element={
                    <LyceumAdmissionStep1 nextLink='/service/admission/secondary_education/step2' />
                  }
                />
                <Route path='step2' element={<SecondaryEducationAdmissionStep2 />} />
                <Route path='step3' element={<SecondaryEducationAdmissionStep3 />} />
              </Route>

              {/* Vocation admission service */}
              <Route path='vocation' element={<VocationAdmissionService />}>
                <Route
                  path='step1'
                  element={<LyceumAdmissionStep1 nextLink='/service/admission/vocation/step2' />}
                />
                <Route
                  path='step2'
                  element={
                    <LyceumAdmissionStep2
                      nextLink='/service/admission/vocation/step3'
                      backLink='/service/admission/vocation/step1'
                    />
                  }
                />
                <Route path='step3' element={<VocationAdmissionStep3 />} />
                <Route path='step4' element={<VocationAdmissionStep4 />} />
              </Route>

              {/* Interview admission service */}
              <Route path='interview_admission' element={<InterviewAdmissionService />}>
                <Route
                  path='step1'
                  element={
                    <LyceumAdmissionStep1 nextLink='/service/admission/interview_admission/step2' />
                  }
                />

                <Route path='step2' element={<InterviewAdmissionStep2 />} />
                <Route path='step3' element={<InterviewAdmissionStep3 />} />
                <Route path='step4' element={<InterviewAdmissionStep4 />} />
              </Route>

              {/* Application detail pages */}
              <Route path='detail' element={<AdmissionDetail />} />
            </Route>
          </Route>

          <Route path='/archive' element={<ArchiveApplications />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default RoutElements;
