import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/common/button";
import SelectItem from "src/components/form/select";
import {
  GetVocationDirectionConfig,
  GetVocationEduTypesConfig,
  GetVocationLanguagesConfig,
  GetVocationRegionsConfig,
  GetVocationSchoolsConfig,
} from "../config";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { IVocationStep3 } from "../type";
import { vocation_step3_schema } from "src/static/const/schema";
import { VOCATION_ADMISSION_DATA } from "src/static/const/auth";

function VocationAdmissionStep3() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [regions, setRegions] = useState<any[]>();
  const [district, setDistrict] = useState<any[]>();
  const [schools, setSchools] = useState<any[]>();
  const [directions, setDirections] = useState<any[]>();
  const [languages, setLanguages] = useState<any[]>();
  const [eduforms, setEduforms] = useState<any[]>();
  const [alleduforms, setAllEduforms] = useState<any[]>();

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<IVocationStep3>({
    resolver: yupResolver(vocation_step3_schema),
  });

  const makeOptions = (list: any[]) => {
    return list?.reduce((all: any[], cur: any) => [...all, { ...cur, value: cur?.id }], []);
  };

  const getRegions = async () => {
    const { data } = await GetVocationRegionsConfig();
    setRegions(makeOptions(data));

    const eduTypes = await GetVocationEduTypesConfig();
    setAllEduforms(eduTypes.data);

    const langs = await GetVocationLanguagesConfig();
    setLanguages(makeOptions(langs.data));
  };
  const handleRegion = (val: string) => {
    let districts = regions?.find((region) => region.value === val)?.districts;
    setDistrict(makeOptions(districts));

    setValue("region", val);
    clearErrors("region");

    // Set values default
    setValue("district", "");
    setValue("vocational_school", "");
    setValue("education_form", "");
    setValue("education_language", "");
    setValue("speciality", "");
  };
  const handleDistrict = async (val: string) => {
    const { data } = await GetVocationSchoolsConfig(val);
    setSchools(makeOptions(data));

    // Set value
    setValue("district", val);
    clearErrors("district");

    // Clear other values
    setValue("vocational_school", "");
    setValue("education_form", "");
    setValue("education_language", "");
    setValue("speciality", "");
  };
  const handleSchool = async (val: string) => {
    // // Find this school
    // let schoolForms = schools?.find((item: any) => item?.id == val)?.education_forms;

    // // Filtering school edu types and set value
    // let result: any = [];
    // schoolForms?.forEach((val: any) => {
    //   alleduforms?.forEach((form) => {
    //     if (form?.id == val?.education_form) {
    //       result.push({ ...form, ...val, value: form?.id });
    //     }
    //   });
    // });
    // setEduforms(result);
    setEduforms(makeOptions(alleduforms || []));

    // Set value
    setValue("vocational_school", val);
    clearErrors("vocational_school");

    // Clear other values
    setValue("education_form", "");
    setValue("education_language", "");
    setValue("speciality", "");
  };
  const handleForm = (val: string) => {
    // Find this school langugaes
    // let languages = eduforms?.find((item: any) => item?.id == val)?.education_languages;

    // // Filtering school edu types and set value
    // let result: any = [];
    // eduLanguages?.forEach((val: any) => {
    //   [...new Set(languages)]?.forEach((lang: any) => {
    //     if (lang == val?.value) {
    //       result.push(val);
    //     }
    //   });
    // });
    // setLanguages([...new Set(result)]);
    // setLanguages(eduLanguages);

    setValue("education_form", val);
    clearErrors("education_form");

    // Clear other values
    setValue("education_language", "");
    setValue("speciality", "");
  };
  const handleLanguage = async (val: string) => {
    const { data } = await GetVocationDirectionConfig(
      watch("vocational_school"),
      watch("education_form"),
      val,
    );
    setDirections(
      data?.results?.reduce(
        (all: any[], cur: any) => [
          ...all,
          {
            ...cur,
            value: cur?.speciality?.id,
            name_uz: cur?.speciality?.name_uz,
            name_ru: cur?.speciality?.name_ru,
            name_en: cur?.speciality?.name_en,
          },
        ],
        [],
      ),
    );

    setValue("education_language", val);
    clearErrors("education_language");

    // Clear other values
    setValue("speciality", "");
  };
  const handleDirection = (val: string) => {
    // Set value
    setValue("speciality", val);
    clearErrors("speciality");
  };

  const onSubmit = async (data: any) => {
    localStorage.setItem(VOCATION_ADMISSION_DATA, JSON.stringify(data));
    navigate("/service/admission/vocation/step4");
  };
  const backSetValues = async () => {
    if (localStorage.getItem(VOCATION_ADMISSION_DATA)) {
      const data = await JSON.parse(localStorage.getItem(VOCATION_ADMISSION_DATA) || "");

      if (data) {
        let regionsList: any[] = [];
        let directionsList: any[] = [];

        // set region and get districts
        setValue("region", data?.region);
        GetVocationRegionsConfig().then((val) => {
          regionsList = val.data;

          let districts = regionsList?.find((region: any) => region.id === data?.region)?.districts;
          setRegions(makeOptions(regionsList));
          setDistrict(makeOptions(districts));
        });

        // set district and get schools
        setValue("district", data?.district);
        GetVocationSchoolsConfig(data?.district).then(async (res) => {
          setSchools(makeOptions(res.data));

          // Set edu form----------------------------------
          const eduTypes = await GetVocationEduTypesConfig();
          // Find this school
          // let schoolForms = res.data?.find(
          //   (item: any) => item?.id == data?.vocational_school,
          // )?.education_forms;

          // Filtering school edu types and set value
          // let result: any = [];
          // schoolForms?.forEach((val: any) => {
          //   eduTypes.data?.results?.forEach((form: any) => {
          //     if (form?.id == val?.education_form) {
          //       result.push({ ...form, ...val, value: form?.id });
          //     }
          //   });
          // });
          setEduforms(makeOptions(eduTypes.data));

          // Find this school langugaes----------------------------
          // let languages = result?.find(
          //   (item: any) => item?.id == data?.education_form,
          // )?.education_languages;

          // Filtering school language
          let resultLang: any = [];
          GetVocationLanguagesConfig().then((res) => {
            // makeOptionItem(res.data.results)?.forEach((val: any) => {
            //   [...new Set(languages)]?.forEach((lang: any) => {
            //     if (lang == val?.value) {
            //       resultLang.push(val);
            //     }
            //   });
            // });

            setLanguages(makeOptions(res.data));
          });
        });

        // set school and get directions
        setValue("vocational_school", data?.vocational_school);
        GetVocationDirectionConfig(
          data?.vocational_school,
          data?.education_form,
          data?.education_language,
        ).then((res) => {
          let arr = res.data?.results?.reduce(
            (all: any[], cur: any) => [
              ...all,
              {
                ...cur,
                value: cur?.speciality?.id,
                name_uz: cur?.speciality?.name_uz,
                name_ru: cur?.speciality?.name_ru,
                name_en: cur?.speciality?.name_en,
              },
            ],
            [],
          );
          setDirections(arr);
          directionsList = arr;
        });

        // set language
        setValue("education_form", data?.education_form);
        setValue("education_language", data?.education_language);
        setValue("speciality", data?.speciality);
      }
    }
  };

  useEffect(() => {
    getRegions();
    backSetValues();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='application-box'>
        <div className='application-box-title'>{t("profile.choices.select_edu")}</div>
        <div className='application-box-form'>
          <Controller
            name='region'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("vocation.vacation_region") || ""}
                placeholder={t("profile.common.select_region") || ""}
                field={field}
                className='half'
                options={regions}
                error={errors.region && true}
                onChange={handleRegion}
              />
            )}
          />
          <Controller
            name='district'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("vocation.vacation_district") || ""}
                placeholder={t("profile.common.select_district") || ""}
                field={field}
                error={errors.district && true}
                options={district}
                className='half'
                onChange={handleDistrict}
                disabled={!watch("region")}
              />
            )}
          />
          <Controller
            name='vocational_school'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("vocation.vacation_name") || ""}
                placeholder='Kasb-hunar maktabini tanlang'
                field={field}
                error={errors.vocational_school && true}
                options={schools}
                className='full'
                onChange={handleSchool}
                disabled={!watch("district") || schools?.length == 0}
              />
            )}
          />

          <Controller
            name='education_form'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.edu.edu_form") || ""}
                placeholder={t("profile.edu.edu_form") || ""}
                field={field}
                className='half'
                options={eduforms}
                error={errors.education_form && true}
                onChange={handleForm}
                disabled={!watch("vocational_school")}
              />
            )}
          />
          <Controller
            name='education_language'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.choices.edu_lang") || ""}
                placeholder={t("profile.choices.edu_lang") || ""}
                field={field}
                className='half'
                options={languages}
                error={errors.education_language && true}
                onChange={handleLanguage}
                disabled={!watch("education_form")}
              />
            )}
          />
          <Controller
            name='speciality'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.choices.edu_direction") || ""}
                placeholder={t("profile.choices.edu_direction") || ""}
                field={field}
                error={errors.speciality && true}
                options={directions}
                className='full'
                onChange={handleDirection}
                disabled={!watch("education_language") || directions?.length == 0}
              />
            )}
          />
        </div>
      </div>

      <div className='application-box-footer'>
        <Link to='/service/admission/vocation/step2'>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps}>{t("profile.common.continue") || ""}</CustomButton>
      </div>
    </form>
  );
}

export default VocationAdmissionStep3;
