import React from "react";

function CustomLoader({ visible }: { visible: boolean }) {
  return (
    <div className='custom-loader' style={!visible ? { display: "none" } : {}}>
      <div className='ring'>
        <div className='lds-dual-ring'></div>
      </div>
    </div>
  );
}

export default CustomLoader;
