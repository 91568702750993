import React from "react";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "src/assets/svg";

function SliderMulti(props: any) {
  const settings = {
    speed: 500,
    arrows: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    initialSlide: 0,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
    nextArrow: <NextArrow className="slider_next" />,
    prevArrow: <PrevArrow className="slider_prev" />,
  };

  return <Slider {...settings}>{props.children}</Slider>;
}

export default SliderMulti;
