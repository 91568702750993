import { useState } from "react";
import {
  LaptopIconSVG,
  LockIconSVG,
  PhoneIconSVG,
  RingIconSVG,
} from "src/assets/svg";
import ChangePassword from "./components/ChangePassword";
import ChangePhone from "./components/ChangePhone";
import Notifications from "./components/Notifications";
import Devices from "./components/Devices";
import { useTranslation } from "react-i18next";

function ProfileSettings() {
  const { t } = useTranslation();
  const [tab, setTab] = useState("password");

  return (
    <div className="profile__settings">
      <div className="profile__education_header">
        <h2>{t("profile.cabinet.settings")}</h2>
      </div>

      <div className="customTabs">
        <div
          className={`flex ${tab === "password" ? "active" : ""}`}
          onClick={() => setTab("password")}
        >
          <LockIconSVG />
          {t("profile.settings.change_password")}
        </div>
        <div
          className={`flex ${tab === "phone" ? "active" : ""}`}
          onClick={() => setTab("phone")}
        >
          <PhoneIconSVG />
          {t("profile.settings.change_phone")}
        </div>
        <div
          className={`flex ${tab === "ring" ? "active" : ""}`}
          onClick={() => setTab("ring")}
        >
          <RingIconSVG />
          {t("profile.settings.notification")}
        </div>
        <div
          className={`flex ${tab === "laptop" ? "active" : ""}`}
          onClick={() => setTab("laptop")}
        >
          <LaptopIconSVG />
          {t("profile.settings.devices")}
        </div>
      </div>

      <div className="profile__settings--body">
        {tab === "password" ? (
          <ChangePassword />
        ) : tab === "phone" ? (
          <ChangePhone />
        ) : tab === "ring" ? (
          <Notifications />
        ) : (
          <Devices />
        )}
      </div>
    </div>
  );
}

export default ProfileSettings;
