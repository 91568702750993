import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface INews {
  link?: string;
  img?: string;
  title?: string;
  info?: string;
}

function NewsCard({
  link,
  img,
  info,
  title = "O‘zbekiston oliy o‘quv yurtlarida shartnoma to’lovini Zoomrad ilovasida amalga oshiring – oddiy, tez va hech qanday komissiyalarsiz.",
}: INews) {
  const { t } = useTranslation();

  return (
    <Link to={link || ""} target="_blank" className="news">
      <div className="news__img">
        <img
          src={img || require("src/assets/images/news.png")}
          alt="News img"
        />
      </div>
      <h4>{title}</h4>
      <div className="flex">
        <span>{t("home.news.time")}</span>
        <b></b>
        <span>{t("home.news.time")}</span>
        <b></b>
        <div>{t("home.news.tag")}</div>
      </div>
    </Link>
  );
}

export default NewsCard;
