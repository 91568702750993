import {
  CertificateTypes,
  UserPasswordTypes,
  UserPersonalTypes,
  UserReloadTypes,
} from "src/static/interface/profile";
import { CreateFunc, GetFunc } from "../../config";
import { LoginMainTypes } from "src/static/interface/login";

////////// User //////////
export const GetUserMeConfig = () => {
  return GetFunc(`/users/me`);
};
export const EditUserConfig = (data: UserPersonalTypes) => {
  return CreateFunc(`/users/edit/`, data);
};
export const ReloadUserConfig = (data: UserReloadTypes) => {
  return CreateFunc(`/users/reload/`, data);
};

//Change password
export const CheckUserPasswordConfig = (data: UserPasswordTypes) => {
  return CreateFunc(`/users/check_password/`, data);
};
export const ChangeUserPasswordConfig = (data: UserPasswordTypes) => {
  return CreateFunc(`/users/change_password/`, data);
};

//Change password
export const SendSmsForChangePhoneConfig = (data: LoginMainTypes) => {
  return CreateFunc(`/users/send_sms_code_on_change_phone_number/`, data);
};
export const ConfirmSmsForChangePhoneConfig = (data: { code: string }) => {
  return CreateFunc(`/users/confirm_sms_code_on_change_phone_number/`, data);
};

//Devices
export const GetSessionsConfig = () => {
  return GetFunc(`/users/sessions/`);
};
export const TerminateSessionsConfig = () => {
  return CreateFunc(`/users/terminate_all_another_sessions/`, "");
};

//Science certificate
export const GetScienceCertificatesConfig = () => {
  return GetFunc(`/portfolios/foreign-language-certificates/`);
};
export const ReloadScienceCertificatesConfig = (data: CertificateTypes[]) => {
  return CreateFunc(`/portfolios/foreign-language-certificates/reload/`, data);
};
