import React from "react";

function DefaultBtn({
  title = "Bildirishnomalar",
  className,
  onClick,
}: {
  title?: string;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <button className={`customBtn ${className}`} onClick={onClick}>
      {title}
    </button>
  );
}

export default DefaultBtn;
