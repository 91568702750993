import React from "react";
import CustomAlert from "src/components/common/alert";

export function PhoneNumberBusy() {
  return (
    <div
      style={{
        height: 320,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "90%",
        margin: "auto",
      }}
    >
      <CustomAlert
        closeIcon={false}
        type='warning'
        info="Siz ushbu telefon raqamingiz bilan avval ro'yxatdan o'tgansiz. Ro'yxatdan o'tish uchun ONE ID
      tizimidagi telefon raqamingizni o'zgartiring."
      />
    </div>
  );
}

export function InternalServerError() {
  return (
    <div
      style={{
        height: 320,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "90%",
        margin: "auto",
      }}
    >
      <CustomAlert
        closeIcon={false}
        type='warning'
        info="Tizim xatoligi birozdan so'ng qayta urinib ko'ring !"
      />
    </div>
  );
}
