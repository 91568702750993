import {
  CreateFuncMultipartSecondEdu,
  CreateFuncSecondEdu,
  EditFuncSecondEdu,
  GetFuncSecondEdu,
} from "src/service/config/others";
import { IDiplomFile, IDiplomSE } from "./type";

//App configs
export const GetAppDetailSEConfig = (token: string) => {
  return GetFuncSecondEdu(`/diplomaAndApp/applicationDetails?token=${token}`);
};
export const GetUniversitiesSEConfig = () => {
  return GetFuncSecondEdu(`/public/allAppUnivesity`);
};
export const GetEduFormSEConfig = (otm: string) => {
  return GetFuncSecondEdu(`/public/getEduFormApp/${otm}`);
};
export const GetLanguageSEConfig = (edu: string, otm: string) => {
  return GetFuncSecondEdu(`/public/getLanguageApp/${edu}/${otm}`);
};
export const GetSpecialitySEConfig = (edu: string, otm: string, lan: string) => {
  return GetFuncSecondEdu(`/public/getSpecialityApp/${edu}/${otm}/${lan}`);
};
export const AddAppSEConfig = (token: string, kvotaId: string) => {
  return CreateFuncSecondEdu(
    `/diplomaAndApp/createApplication?token=${token}&kvotaId=${kvotaId}`,
    "",
  );
};
export const EditAppSEConfig = (token: string, kvotaId: string) => {
  return EditFuncSecondEdu(
    `/diplomaAndApp/updateApplication?token=${token}&kvotaId=${kvotaId}`,
    "",
  );
};

//Dimlom configs
export const GetDiplomSEConfig = (token: string) => {
  return GetFuncSecondEdu(`/diplomaAndApp/getDiplomaApi?token=${token}`);
};
export const GetAllEduFormSEConfig = () => {
  return GetFuncSecondEdu(`/public/getAllEduFormDiploma`);
};
export const GetDownloadFileSEConfig = (file: string) => {
  return GetFuncSecondEdu(`/public/download/${file}`);
};
export const GetContriesSEConfig = () => {
  return GetFuncSecondEdu(`public/countries`);
};
export const GetSpecialitiesSEConfig = (id: string) => {
  return GetFuncSecondEdu(`/public/allSpecialitiesInstitution/${id}`);
};
export const GetDurationSEConfig = () => {
  return GetFuncSecondEdu(`/public/allDuration`);
};
export const GetDiplomSerialsSEConfig = () => {
  return GetFuncSecondEdu(`/public/allDiplomaSerials`);
};
export const GetDiplomInstitutionsSEConfig = () => {
  return GetFuncSecondEdu(`/public/allDiplomaInstitutions`);
};
export const AddDiplomSEConfig = (data: Partial<IDiplomSE>, token: string) => {
  return CreateFuncSecondEdu(`/diplomaAndApp/createDiploma?token=${token}`, data);
};
export const EditDiplomSEConfig = (data: Partial<IDiplomSE>, id: number, token: string) => {
  return EditFuncSecondEdu(`/diplomaAndApp/updateDiploma?token=${token}&id=${id}`, data);
};
export const EditDiplomFilesSEConfig = (data: IDiplomFile, token: string) => {
  return EditFuncSecondEdu(`/diplomaAndApp/addDiplomaAndIlova?token=${token}`, data);
};
export const ChangeDiplomActiveSEConfig = (id: number, token: string) => {
  return EditFuncSecondEdu(`/diplomaAndApp/diplomaIsActive/${id}?token=${token}`, "", "PATCH");
};

export const UploadFileSEConfig = (data: any) => {
  return CreateFuncMultipartSecondEdu("/public/uploadFile", data);
};
