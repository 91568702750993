import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StaticSites, giveStaticUrl } from "src/pages/home/components/services/components/const";
import { ServiceTypes } from "src/pages/services/type";
import { giveLan } from "src/utils";

function ServiceCard({ data }: { data: ServiceTypes }) {
  const { t } = useTranslation();

  return (
    <Link
      target={StaticSites.find((item) => item.slug === data.slug) ? "_blank" : ""}
      to={giveStaticUrl(data) || ""}
      className='service__item'
    >
      <div>
        <h2>{giveLan(data)}</h2>
        <p>{giveLan(data, "description")}</p>
        <h4 className={`service__item_${data.level}`}>#{t(`service.tab.${data.level}`)}</h4>
      </div>
      <img src={data.svg} alt='icon' style={{ width: 64 }} />

      {!data.is_active ? (
        <div className='wrapper'>
          <h2>{t("service.soon")}</h2>
        </div>
      ) : (
        ""
      )}
    </Link>
  );
}

export default ServiceCard;
