import {
  AllServicesSVG,
  BakalavrSVG,
  BoshqaSVG,
  EduSVg,
  KasbSVG,
  KollejSVG,
  LitseySVG,
  QabulSVG,
  TexnikumSVG,
} from "src/assets/svg";
import {
  INTERVIEW_ADMISSION_TOKEN,
  LANGUAGE_DATA,
  LYCEUM_ADMISSION_DATA,
  LYCEUM_ADMISSION_TOKEN,
  SECONDARY_ADMISSION_TOKEN,
  VOCATION_ADMISSION_DATA,
  VOCATION_ADMISSION_TOKEN,
} from "src/static/const/auth";
import { GetApplicationIDConfig } from "./admission/detail/config";
import { GetLyceumAppIDConfig } from "./admission/lyceum/config";
import { generateServiceToken } from "./config";
import { SeriviceSlugTypeAttribute } from "./type";

export const serviceSidebarData = [
  {
    key: "all",
    title: "profile.common.all_services",
    icon: <AllServicesSVG />,
    divider: true,
  },
  {
    key: "admissions",
    title: "home.actions.admission",
    icon: <QabulSVG color='#6B7280' />,
    divider: true,
  },
  {
    key: "bachelor",
    title: "home.actions.bachelor",
    icon: <BakalavrSVG color='#6B7280' />,
  },
  {
    key: "master",
    title: "home.actions.magistr",
    icon: <EduSVg />,
  },
  {
    key: "college",
    title: "home.actions.college",
    icon: <KollejSVG color='#6B7280' />,
  },
  {
    key: "lyceum",
    title: "home.actions.lyceum",
    icon: <LitseySVG color='#6B7280' />,
  },
  {
    key: "technical_school",
    title: "home.actions.technikum",
    icon: <TexnikumSVG color='#6B7280' />,
  },
  {
    key: "profession_school",
    title: "home.actions.kasb",
    icon: <KasbSVG color='#6B7280' />,
  },
  {
    key: "other",
    title: "home.actions.others",
    icon: <BoshqaSVG color='#6B7280' />,
  },
];

export const serviceTabButtons = [
  {
    name: "service.tab.higher_education",
    key: "higher_education",
  },
  {
    name: "service.tab.professional_education",
    key: "professional_education",
  },
  {
    name: "service.tab.secondary_special_education",
    key: "secondary_special_education",
  },
  {
    name: "service.tab.other",
    key: "other",
  },
];

export const GenServiceToken = async (name: string, id: string, slug?: string) => {
  const { data } = await generateServiceToken(id);

  if (slug === "secondary_education_admission") {
    localStorage.setItem(name, encodeURIComponent(data?.token.trim()));
  } else localStorage.setItem(name, data?.token);
};

export const editApplication = async (slug: string, data?: any) => {
  if (slug === "lyceum_admission") {
    const res = await GetLyceumAppIDConfig(data?.external_id);
    let app = {
      education_language: res?.data?.education_language?.id,
      foreign_language: res?.data?.foreign_language?.id,
      lyceum1: res?.data?.lyceum_selections?.[0]?.lyceum?.id,
      lyceum2: res?.data?.lyceum_selections?.[1]?.lyceum?.id,
      lyceum3: res?.data?.lyceum_selections?.[2]?.lyceum?.id,
      region: res?.data?.region?.id,
      speciality: res?.data?.speciality?.id,
      science_certificate: data?.science_certificate?.serial_number,
      foreign_language_certificate: data?.foreign_language_certificate?.serial_number,
      achievement_certificate: data?.achievement_certificate?.serial_number,
    };
    await GenServiceToken(LYCEUM_ADMISSION_TOKEN, data?.service?.id);
    localStorage.setItem(LYCEUM_ADMISSION_DATA, JSON.stringify(app));
    localStorage.setItem("appID", data?.external_id);

    // check if weather one lyceum is accepted or not
    if (
      [
        data?.data?.lyceum_selections?.[0]?.status,
        data?.data?.lyceum_selections?.[1]?.status,
        data?.data?.lyceum_selections?.[2]?.status,
      ].includes("6")
    ) {
      localStorage.setItem("lyceumAccepted", "true");
    }
    window.location.href = `/service/admission/lyceum/step1`;
  }
  if (slug === "vocational_school_admission") {
    const res = await GetApplicationIDConfig(data?.external_id);

    let app = {
      district: res.data?.data?.vocational_school?.district?.id,
      education_form: data?.education_form?.id,
      education_language: data?.education_language?.id,
      region: res.data?.data?.vocational_school?.district?.region?.id,
      speciality: res.data?.data?.speciality?.id,
      vocational_school: res.data?.data?.vocational_school?.id,
    };
    await GenServiceToken(VOCATION_ADMISSION_TOKEN, res.data?.service?.id);
    localStorage.setItem(VOCATION_ADMISSION_DATA, JSON.stringify(app));
    localStorage.setItem("appID", res.data?.external_id);
    window.location.href = `/service/admission/vocation/step1`;
  }
  if (slug === "foreign_language_certificate") {
    localStorage.setItem(LANGUAGE_DATA, JSON.stringify(data));
    window.location.href = `/service/language`;
  }
  if (slug === "interview_admission") {
    const res = await generateServiceToken(data?.service?.id || "");
    localStorage.setItem(findAdmissionToken(slug), res.data?.token);
    localStorage.setItem("interviewEdit", "true");
    window.location.href = `/service/admission/${findAdmissionUrl(slug)}/step4`;
  }
  if (slug === "secondary_education_admission") {
    generateServiceToken(data?.service?.id).then((res) => {
      localStorage.setItem(SECONDARY_ADMISSION_TOKEN, encodeURIComponent(res.data?.token.trim()));
      setTimeout(() => {
        window.location.href = `/service/admission/secondary_education/step1`;
      }, 100);
    });
  }
};

export const checkEditApplication = (slug: string, data?: any) => {
  if (slug === "lyceum_admission") {
    let openEdit = true;

    data?.data?.lyceum_selections?.forEach((lyceum: any) => {
      if (lyceum?.status == "6") {
        openEdit = openEdit ? (openEdit = true) : (openEdit = false);
      } else {
        openEdit = false;
      }
    });

    return !openEdit;
  } else if (slug === "secondary_education_admission") {
    return data?.status === "Ariza tasdiqlandi" ? false : true;
    // const step = findStatusSE(data?.status);
    // if (step === 1 || step === 2) return true;
    // else return false;
    // return data?.status === "Ariza tasdiqlandi" ? false : true;
    // return false;
  } else if (slug === "interview_admission") {
    return !data?.is_grouped;
  } else {
    return ["new", "1", "2"].includes(data?.status) ? true : false;
  }
};

export const findAdmissionUrl = (slug: SeriviceSlugTypeAttribute) => {
  if (slug === "lyceum_admission") return "lyceum";
  else if (slug === "vocational_school_admission") return "vocation";
  else if (slug === "secondary_education_admission") return "secondary_education";
  else if (slug === "interview_admission") return "interview_admission";
};

export const findAdmissionToken = (slug: SeriviceSlugTypeAttribute) => {
  if (slug === "lyceum_admission") return LYCEUM_ADMISSION_TOKEN;
  else if (slug === "vocational_school_admission") return VOCATION_ADMISSION_TOKEN;
  else if (slug === "secondary_education_admission") return SECONDARY_ADMISSION_TOKEN;
  else if (slug === "interview_admission") return INTERVIEW_ADMISSION_TOKEN;
  else return "";
};
