import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";
import SelectItem from "src/components/form/select";
import { MainContext } from "src/hooks/context";
import { GetSchoolInfo, UpdateSchoolCer } from "src/pages/profile/diplom/config";
import { SchoolCerTypes } from "src/pages/profile/diplom/type";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { giveLan } from "src/utils";
import * as yup from "yup";

function LyceumAdmissionStep2({ nextLink, backLink }: { nextLink?: string; backLink?: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [cerInfo, setCerInfo] = useState<SchoolCerTypes>();

  //Classifiers section
  const { languages, getLanguages, eduLanguages, getEduLanguages } = useContext(MainContext);

  const schema = yup
    .object({
      country: yup.string().required(),
      number: yup.string().required(),
      region: yup.string().required(),
      school: yup.string().required(),
      serial: yup.string().required(),
      graduate_year: yup.string().required(),
      district: yup.string().required(),
      education_language: yup.string().required(),
      foreign_language: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<SchoolCerTypes>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: SchoolCerTypes) => {
    cerInfo?.id &&
      (await UpdateSchoolCer(cerInfo?.id, {
        education_language: data.education_language,
        foreign_language: data.foreign_language,
      }));
    navigate(nextLink ? nextLink : "/service/admission/lyceum/step3");
    // console.log(data);
  };

  const getInfo = async () => {
    !languages && getLanguages?.();
    !eduLanguages && getEduLanguages?.();
    const { data } = await GetSchoolInfo();
    const list = data?.results || data;
    setCerInfo(list[0]);

    setValue("country", giveLan(list[0]?.country) ?? "");
    setValue("region", list[0]?.region ?? "");
    setValue("district", list[0]?.district ?? "");
    setValue("school", list[0]?.school ?? "");
    setValue("graduate_year", list[0]?.graduate_year ?? "");
    setValue("serial", list[0]?.serial ?? "");
    setValue("number", list[0]?.number ?? "");
    setValue("education_language", list[0]?.education_language.id);
    setValue("foreign_language", list[0]?.foreign_language.id);
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='application-box'>
        <div className='application-box-title'>{t("profile.choices.detail_title2")}</div>
        <div className='application-box-form'>
          <Controller
            name='country'
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("profile.edu.end_school_country") || ""}
                error={errors.country ? true : false}
                field={field}
                className='full'
                disabled
              />
            )}
          />
          <Controller
            name='region'
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("profile.edu.end_school_region") || ""}
                field={field}
                disabled
                className='half'
                error={errors.region ? true : false}
              />
            )}
          />
          <Controller
            name='district'
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("profile.edu.end_school_district") || ""}
                field={field}
                disabled
                className='half'
                error={errors.district ? true : false}
              />
            )}
          />
          <Controller
            name='school'
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("profile.edu.end_school_name") || ""}
                field={field}
                disabled
                className='half'
                error={errors.school ? true : false}
              />
            )}
          />
          <Controller
            name='graduate_year'
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("profile.edu.end_school_year") || ""}
                field={field}
                disabled
                className='half'
                error={errors.graduate_year ? true : false}
              />
            )}
          />
          <Controller
            name='education_language'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.edu.end_school_lang") || ""}
                field={field}
                options={eduLanguages}
                className='half'
                error={errors.education_language ? true : false}
              />
            )}
          />
          <Controller
            name='foreign_language'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("profile.edu.end_school_ForLang") || ""}
                field={field}
                options={languages}
                className='half'
                error={errors.foreign_language ? true : false}
              />
            )}
          />
          <div className='application-line'></div>
          <div className='application-box-subtitle'>
            {t("profile.edu.end_school_diplomInfo") || ""}
          </div>
          <div className='application-box-tiny half'>
            <Controller
              name='serial'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("profile.edu.series") || ""}
                  placeholder='_ _ _ _ '
                  field={field}
                  className='first'
                  disabled
                  error={errors.serial ? true : false}
                />
              )}
            />

            <Controller
              name='number'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("profile.edu.number") || ""}
                  placeholder=' _ _ _ _ _ _ _ _ _ _ _'
                  field={field}
                  className='second'
                  disabled
                  error={errors.number ? true : false}
                />
              )}
            />
          </div>

          {!cerInfo?.number && (
            <CustomAlert
              closeIcon={false}
              type={"warning"}
              info={`Agada sizning Shahodatnoma Seriyasi va raqami ko'rinmasa, keyingi qadamga o'tib ariza
            yuboravering, shahodatnoma maktabingiz tomonidan shakllangandan so'ng integratsiya
            orqali tizimda avtomatik tarzda ko'rinadi.`}
            />
          )}
        </div>
      </div>

      <div className='application-box-footer'>
        <Link to={backLink ? backLink : "/service/admission/lyceum/step1"}>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps}>{t("profile.common.continue") || ""}</CustomButton>
      </div>
    </form>
  );
}

export default LyceumAdmissionStep2;
