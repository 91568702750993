import { Modal } from "flowbite-react";
import { ProfileInfoReloadProps } from "../types";
import { UserReloadTypes } from "src/static/interface/profile";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { profile_info_reload_schema } from "src/static/const/schema";
import { useState } from "react";
import { ArrowBackSvg } from "src/assets/svg";
import { ReloadUserConfig } from "src/service/api/profile";
import CustomButton from "src/components/common/button";
import CustomDrower from "src/components/common/drower";
import { useTranslation } from "react-i18next";
import MaskedInputItem from "src/components/form/input/masked";
import InputItem from "src/components/form/input";

function ProfileInfoReload({ visible, setVisible }: ProfileInfoReloadProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  //Drower section
  const [drower, setDrower] = useState(false);
  // const openDrower = () => setDrower(true);
  const closeDrower = () => setDrower(false);

  //Form section
  const {
    handleSubmit,
    control,
    reset,
    // setValue,
    formState: { errors },
  } = useForm<UserReloadTypes>({
    resolver: yupResolver(profile_info_reload_schema),
  });

  const closeModal = () => {
    setVisible(false);
    reset({
      number: "",
      serial: "",
    });
  };

  const onSubmit = (data: UserReloadTypes) => {
    setLoading(true);
    ReloadUserConfig(data)
      .then((res) => {
        closeModal();
      })

      .finally(() => setLoading(false));
  };

  return (
    <Modal show={visible} onClose={closeModal} size='md' className='login'>
      <Modal.Header className='login-header'>{t("profile.cabinet.refresh_info")}</Modal.Header>
      <Modal.Body className='login-body'>
        <form className='custom-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='login-signup-inputs'>
            <Controller
              name='serial'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={"Passport seriya"}
                  placeholder='_ _'
                  size='lg'
                  message={"Passport seriya"}
                  field={field}
                  error={errors.serial ? true : false}
                />
              )}
            />
            <Controller
              name='number'
              control={control}
              render={({ field }) => (
                <MaskedInputItem
                  label={"Passport raqami"}
                  placeholder='_ _ _ _ _ _ _'
                  // mask={docum_number_mask}
                  size='lg'
                  message={"Passport raqami"}
                  field={field}
                  error={errors.number ? true : false}
                />
              )}
            />
          </div>
          <CustomButton
            size='xl'
            color='primary'
            style={{ width: "100%" }}
            type='submit'
            loading={loading}
          >
            {t("profile.common.continue")}
          </CustomButton>
          <CustomButton
            onClick={closeModal}
            size='xl'
            color='gray'
            style={{ width: "100%", marginTop: 12 }}
            icon={<ArrowBackSvg />}
            left_icon
          >
            {t("profile.common.back")}
          </CustomButton>
        </form>
      </Modal.Body>
      <CustomDrower
        title='Pasport berilgan sana'
        okText='Yopish'
        visible={drower}
        onCancel={closeDrower}
        onOk={closeDrower}
      >
        <div className='login-signup-drower'>
          <p>
            JSHSHIR – jismoniy shaxsning shaxsiy identifikasion raqami bo‘lib, u har bir fuqaroning
            pasportida yozilgan bo‘ladi. Uni aniqlash juda oson. Quyida ko‘rsatilgan surat orqali
            pasportingizdan 14 ta raqamni toping, ana shu Sizning JSHSHIRingiz bo‘ladi.
          </p>
          <img src={require("src/assets/images/birthDateHelp.png")} alt='error birth date help' />
        </div>
      </CustomDrower>
    </Modal>
  );
}

export default ProfileInfoReload;
