import * as yup from "yup";
import { phone_regex, pinfl_regex, sms_code_regex } from "..";

const string_rule = yup.string().required();
const file_rule = yup.mixed().required();
const sms_code_rule = yup.string().required().matches(sms_code_regex);
const phone_rule = yup.string().required().matches(phone_regex);
const pinfl_rule = yup.string().required().matches(pinfl_regex);
// const docum_number_rule = yup.string().required().matches(docum_number_regex);
const docum_number_rule = yup.string().test("", "", (val) => {
  if (!val) return false;
  return val.length > 3 && val.length < 9;
});
const docum_serial_rule = yup.string().test("", "", (val) => {
  if (!val) return false;
  return val.length >= 1 && val.length < 7;
});
const passport_number_rule = yup.string().test("", "", (val) => {
  if (!val) return false;
  return val.length === 7;
});
const passport_serial_rule = yup.string().test("", "", (val) => {
  if (!val) return false;
  return val.length === 2;
});
export const password_rule = yup.string().test("", "", (val) => {
  if (!val) return false;
  return val.length > 5;
});
const date_rule = yup.object({
  startDate: string_rule,
  endDate: yup.string(),
});

export const login_main_schema = yup
  .object({
    phone_number: phone_rule,
  })
  .required();

export const login_signin_schema = yup
  .object({
    password: password_rule,
  })
  .required();

export const login_signup_schema = yup
  .object({
    pinfl: pinfl_rule,
    number: passport_number_rule,
    serial: passport_serial_rule,
  })
  .required();

export const login_signup_schema2 = yup
  .object({
    number: docum_number_rule,
    serial: docum_serial_rule,
    birth_date: date_rule,
  })
  .required();

export const login_forget_schema = yup
  .object({
    pinfl: pinfl_rule,
    number: passport_number_rule,
    serial: passport_serial_rule,
    phone_number: phone_rule,
  })
  .required();

export const login_forget_schema2 = yup
  .object({
    serial: docum_serial_rule,
    number: docum_number_rule,
    birth_date: date_rule,
    phone_number: phone_rule,
  })
  .required();

export const login_check_schema = yup
  .object({
    code: sms_code_rule,
  })
  .required();

export const profile_info_reload_schema = yup
  .object({
    number: passport_number_rule,
    serial: passport_serial_rule,
  })
  .required();

export const profile_info_edit_schema = yup
  .object({
    district: string_rule,
    address: string_rule,
  })
  .required();

export const add_award_schema = yup
  .object({
    name: string_rule,
    type: string_rule,
    given_date: date_rule,
    serial_number: string_rule,
  })
  .shape({
    file: file_rule,
  })
  .required();

export const profile_info_edit_schema2 = yup
  .object({
    citizenship: string_rule,
    nationality: string_rule,
    district: string_rule,
    address: string_rule,
  })
  .required();

export const profile_setting_passwrod_schema = yup
  .object({
    password: password_rule,
  })
  .required();

export const language_service_schema = yup
  .object({
    foreign_language: string_rule,
    type: string_rule,
    degree: string_rule,
    serial_number: string_rule,
    given_date: date_rule,
    expire_date: date_rule,
  })
  .shape({
    file: file_rule,
  })
  .required();

export const studyForm_service_schema = yup
  .object({
    education_type_name: string_rule,
    education_form_name: string_rule,
    payment_type_name: string_rule,
    education_year: string_rule,
    university_name: string_rule,
    faculty_name: string_rule,
    speciality_name: string_rule,
    course: string_rule,
  })
  .shape({
    file: file_rule,
  })
  .required();

export const lyceum_step3_schema = yup
  .object({
    speciality: string_rule,
    education_language: string_rule,
    foreign_language: string_rule,
    region: string_rule,
    lyceum1: string_rule,
  })
  .required();

export const vocation_step3_schema = yup
  .object({
    region: string_rule,
    district: string_rule,
    vocational_school: string_rule,
    speciality: string_rule,
    education_language: string_rule,
    education_form: string_rule,
  })
  .required();

export const second_edu_step2_schema_uzb = yup
  .object({
    country_id: string_rule,
    edu_form_id: string_rule,
    institution_id: string_rule,
    edu_starting_date: date_rule,
    edu_finishing_date: date_rule,
    speciality_id: string_rule,
    diploma_given_date: date_rule,
    edu_duration_id: string_rule,
    diploma_serial_id: string_rule,
    diploma_number: string_rule,
    diploma_url: string_rule,
    ilova_url: string_rule,
  })
  .required();

export const second_edu_step2_schema_other = yup
  .object({
    country_id: string_rule,
    edu_starting_date: date_rule,
    edu_finishing_date: date_rule,
    diploma_given_date: date_rule,
    edu_duration_id: string_rule,
    diploma_serial: string_rule,
    diploma_number: string_rule,
    diploma_url: string_rule,
    ilova_url: string_rule,

    edu_form_name: string_rule,
    speciality_custom_name: string_rule,
    foreign_otm_name: string_rule,
  })
  .required();

export const second_edu_step3_schema = yup
  .object({
    university: string_rule,
    formEdu: string_rule,
    language: string_rule,
    kvotaId: string_rule,
  })
  .required();
