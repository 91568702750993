import { Label, Select } from "flowbite-react";
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SelectItemProps } from "src/static/interface/common";
import { giveLan } from "src/utils";

function SelectItem({
  label,
  placeholder,
  size,
  error,
  message,
  field,
  className,
  options,
  onChange,
  ...rest
}: SelectItemProps) {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang") ?? "uz");
  }, []);

  const props = onChange
    ? {
        onChange: (ev: ChangeEvent<HTMLSelectElement>) => {
          onChange?.(ev.target.value);
        },
      }
    : {};

  return (
    <div className={`custom-input ${className}`}>
      <div className="custom-input-top">
        <Label htmlFor={label} className="custom-input-label">
          {label}
        </Label>
      </div>
      <Select
        {...field}
        id={label}
        color={error ? "failure" : "gray"}
        helperText={
          <li className="custom-form-error">
            {error ? placeholder || message : ""}
          </li>
        }
        sizing={size}
        className="custom-select"
        {...props}
        {...rest}
      >
        <option hidden>{placeholder}</option>
        {options &&
          options.map((item) => {
            return (
              <option
                value={item.value}
                key={item.value}
                selected={item.selected}
                disabled={item.disabled}
                style={item.disabled ? { backgroundColor: "#DADCE0" } : {}}
              >
                {item.name_ru ? giveLan(item) : giveLan(item, "static")}
              </option>
            );
          })}
      </Select>
    </div>
  );
}

export default SelectItem;
