import React from "react";
import { giveLan } from "src/utils";

function MessageItem({ list, item, univer }: { list: any; item: any; univer?: string }) {
  let lyceum = list?.find((listItem: any) => listItem?.lyceum?.id == item?.lyceum);

  return (
    <div
      className={`messageItem ${
        Number(item?.status) == 1
          ? ""
          : Number(item?.status) > 1 && Number(item?.status) < 6
          ? "messageItem__error"
          : "messageItem__success"
      }`}
    >
      <div className='messageItem__header'>
        {lyceum?.lyceum ? <h2>{giveLan(lyceum?.lyceum)}</h2> : <h2>{univer}</h2>}
        <h2>{item?.added_time ? new Date(item?.added_time).toLocaleString() : ""}</h2>
      </div>
      <p>{item?.status === "1" ? `Arizangiz yuborildi` : item?.text}</p>
    </div>
  );
}

export default MessageItem;
