import { handleLogoutFunc } from "src/utils";
import axios from "axios";
import {
  INTERVIEW_ADMISSION_TOKEN,
  LYCEUM_ADMISSION_TOKEN,
  SECONDARY_ADMISSION_TOKEN,
  VOCATION_ADMISSION_TOKEN,
  language,
  token,
} from "src/static/const/auth";
import toast from "react-hot-toast";

export let host = process.env.REACT_APP_BASE_URL;
export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const giveError = (detail: string) => {
  if (detail === `Telefon raqami noto'g'ri formatda kiritilgan.`) {
    return language === "ru" ? `Номер телефона введен в неверном формате` : detail;
  }

  // else if (detail === `Ushbu raqam tizimda ro'yxatga olinmagan.`) {
  //   return language === "ru"
  //     ? `Данный номер в системе не зарегистрирован`
  //     : detail;
  // }
  else if (detail === `Ro'yxatdan o'tish ma'lumotlari topilmadi.`) {
    return language === "ru" ? `Регистрационные данные не найдены` : detail;
  } else if (detail === `Sizda ushbu chet tili sertifikati allaqachon mavjud.`) {
    return language === "ru"
      ? `У вас уже есть этот серийный цифровой сертификат иностранного языка. Выберите доступный сертификат с помощью кнопки "просмотреть все"!`
      : `Sizda ushbu seriya raqamli chet tili sertifikati allaqachon mavjud. Hammasini ko'rish tugmasi orqali mavjud sertifikatni tanlang !`;
  } else if (detail === `Siz kiritgan ma'lumotlar FHDYO bazasidan topilmadi.`) {
    return language === "ru"
      ? `В ваших данных ошибка, проверьте и повторите попытку или обратитесь в ЗАГС !`
      : `Sizning ma'lumotlaringizda xatolik mavjud, iltimos tekshirib qaytadan urinib ko'ring yoki FHDYO ga murojaat qiling!`;
  } else if (detail === `Siz kiritgan ma'lumotlar personallashtirish markazi bazasida topilmadi.`) {
    return language === "ru"
      ? `В ваших данных ошибка, проверьте и повторите попытку или обратитесь в центр персонализации!`
      : `Sizning ma'lumotlaringizda xatolik mavjud, iltimos tekshirib qaytadan urinib ko'ring yoki personallashtirish markaziga murojaat qiling!`;
  } else if (detail === `Muvaffaqiyatli yangilandi !`) {
    return language === "ru" ? `Успешно обновлено !` : detail;
  } else if (detail === `SMS kodni tasdiqlash vaqti tugagan, iltimos qayta urinib ko'ring.`) {
    return language === "ru"
      ? `Время подтверждения SNS-кода истекло. Пожалуйста, попробуйте еще раз.`
      : detail;
  } else if (detail === `SMS kod noto'g'ri kiritilgan.`) {
    return language === "ru" ? `SMS-код введен неверно` : detail;
  } else if (detail === `Sizda arxiv arizalar mavjud emas !`) {
    return language === "ru" ? `Архивные заявления у Вас отсутствуют !` : detail;
  } else if (detail === `SMS kod ketma ket 3 marotaba noto'g'ri kiritildi.`) {
    return language === "ru" ? `SMS-код три раза введен неверно` : detail;
  } else if (detail === `Telefon raqami noto'g'ri formatda kiritilgan.`) {
    return language === "ru" ? `Номер телефона введен в неверном формате` : detail;
  } else if (detail === `Kiritilgan telefon raqami ro'yxatga olinmagan.`) {
    return language === "ru" ? `Введенный номер телефона не зарегистрирован` : detail;
  } else if (detail === `Telefon raqami yoki parol noto'g'ri kiritilgan.`) {
    return language === "ru" ? `Неправильно введен номер телефона или пароль` : detail;
  } else if (detail === `Token topilmadi.`) {
    return language === "ru" ? `Token не найден` : detail;
  } else if (detail === `Yangilash kaliti yaroqlilik muddati tugagan.`) {
    return language === "ru" ? `Истёк срок действия ключа обновления` : detail;
  } else if (detail === `Parol kerakli formatda kiritilmagan.`) {
    return language === "ru" ? `Пароль не введён в нужном формате` : detail;
  } else if (detail === `Ma'lumotlar topilmadi.`) {
    return language === "ru" ? `Сведения не найдены` : detail;
  } else if (detail === `Telefon raqami noto'g'ri formatda kiritilgan.`) {
    return language === "ru" ? `Номер телефона введен в неверном формате` : detail;
  } else if (detail === `Ushbu telefon raqamiga sms yuborishni iloji bo'lmadi.`) {
    return language === "ru"
      ? `Не было возможности отправить sms-сообщение на данный номер телефона`
      : detail;
  } else if (
    detail === `Siz kiritgan ma'lumotlar noto'g'ri iltimos tekshirib qayta urinib ko'ring.`
  ) {
    return language === "ru"
      ? `Введенные Вами данные неверные. Пожалуйста проверьте и попытайтесь вновь`
      : detail;
  } else if (detail === `Kerakli ma'lumotlar taqdim etilmagan.`) {
    return language === "ru" ? `Нужные сведения не предоставлены` : detail;
  } else if (
    detail === `Siz ushbu holatdagi chet tili sertifikati ma'lumotlarini o'zgartirolmaysiz.`
  ) {
    return language === "ru"
      ? `Вы не сможете изменить данные сертификата по иностранному языку в данном случае`
      : detail;
  } else if (detail === `Sizda ushbu chet tili sertifikati allaqachon mavjud.`) {
    return language === "ru" ? `Этот сертификат по иностранному языку у Вас уже имеется` : detail;
  } else if (detail === `Ushbu xizmat uchun kerakli ma'lumotlar tayyor emas.`) {
    return language === "ru" ? `Сведения необходимые для этой услуги не готовы.` : detail;
  } else if (detail === `Parol kerakli formatda kiritilmagan.`) {
    return language === "ru" ? `Пароль не введён в нужном формате` : detail;
  } else if (detail === `Siz ushbu amalni bajarolmaysiz.`) {
    return language === "ru" ? `Вы не можете выполнить эту операцию` : detail;
  } else if (
    detail ===
    `Sizda tasdiqlangan fotosurat mavjud, yangi fotosuratni o'rnatish uchun ma'lumotlaringizni yangilang.`
  ) {
    return language === "ru"
      ? `У Вас есть подтверждённая фотография, для установки новой фотографии обновите свои данные.`
      : detail;
  } else if (detail === `Parol kerakli formatda kiritilmagan.`) {
    return language === "ru" ? `Пароль не введён в нужном формате` : detail;
  } else if (detail === `Parol noto'g'ri kiritildi.`) {
    return language === "ru" ? `Введён неверный пароль` : detail;
  } else if (detail === `SMS kodni tasdiqlash vaqti tugagan, iltimos qayta urinib ko'ring.`) {
    return language === "ru"
      ? `Время подтверждения SNS-кода истекло. Пожалуйста, попробуйте еще раз.`
      : detail;
  } else if (detail === `SMS kod noto'g'ri kiritilgan.`) {
    return language === "ru" ? `SMS-код введен неверно` : detail;
  } else if (detail === `SMS kod ketma ket 3 marotaba noto'g'ri kiritildi.`) {
    return language === "ru" ? `SMS-код три раза введен неверно` : detail;
  } else if (detail === `Tasdiqlangan ma'lumotlarni o'zgartirish mumkin emas.`) {
    return language === "ru" ? `Невозможно изменить подтверждённые сведения` : detail;
  } else if (detail === `Tashqi manbada sizga tegishli ma'lumotlar topilmadi.`) {
    return language === "ru" ? `Информация о Вас во внешнем источнике не найдена.` : detail;
  } else if (detail === `Telefon raqami noto'g'ri formatda kiritilgan.`) {
    return language === "ru" ? `Номер телефона введен в неверном формате` : detail;
  } else if (
    detail === `Ushbu telefon raqam bilan allaqachon boshqa foydalanuvchi ro'xatdan o'tgan.`
  ) {
    return language === "ru"
      ? `Данный номер телефона уже зарегистрирован на другого пользователя.`
      : detail;
  } else if (detail === `Siz ushbu amalni bajarolmaysiz.`) {
    return language === "ru" ? `Вы не можете выполнить эту операцию` : detail;
  } else if (detail === `Tizimga kirganingizga 1 kun bo'lmagan, iltimos keyinroq urinib ko'ring.`) {
    return language === "ru"
      ? `С момента Вашего входа в систему не прошло 1-го дня, пожалуйста попробуйте позже.`
      : detail;
  } else if (detail === `Noto'g'ri autentifikatsiya ma'lumotlari.`) {
    return language === "ru"
      ? `Вы не зарегистрированы в системе !`
      : `Siz tizimdan ro'yhatdan o'tmagansiz !`;
  } else if (detail === `Siz ushbu xizmatdan foydalanolmaysiz.`) {
    return language === "ru" ? `Вы не можете использовать эту услугу.` : detail;
  } else if (
    detail ===
    `Joriy yildagi 9-sinf shahodatnoma ma'lumotlari tahsil olgan maktabingiz tomonidan to'liq shakllantirilmagan. Maktabga murojaat qiling.`
  ) {
    return language === "ru"
      ? `Сведения аттестата об окончании  9 класса за текущий год не подтверждены со стороны школы, в которой Вы обучались. Обратитесь в школу.`
      : detail;
  } else if (detail === `Ensure this field has at least 6 characters.`) {
    return language === "ru"
      ? `Убедитесь, что это поле содержит не менее 6 символов.`
      : `Kamida 6ta belgi borligiga ishonch hosil qiling`;
  } else {
    return detail;
  }
};

// -------------------------------
export let headers = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json; charset=utf-8",
  Authorization: token ? `Token ${token}` : "",
};

export let headersMultipart = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "multipart/form-data",
  Authorization: token ? `Token ${token}` : "",
};

export let headersLyceum = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json; charset=utf-8",
  Authorization: localStorage.getItem(LYCEUM_ADMISSION_TOKEN)
    ? `Token ${localStorage.getItem(LYCEUM_ADMISSION_TOKEN)}`
    : "",
};

export let headersVocation = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json; charset=utf-8",
  Authorization: localStorage.getItem(VOCATION_ADMISSION_TOKEN)
    ? `Token ${localStorage.getItem(VOCATION_ADMISSION_TOKEN)}`
    : "",
};

export let headersInterview = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json; charset=utf-8",
  Authorization: localStorage.getItem(INTERVIEW_ADMISSION_TOKEN)
    ? `Token ${localStorage.getItem(INTERVIEW_ADMISSION_TOKEN)}`
    : "",
};

export let headersSecondEdu = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json; charset=utf-8",
  Authorization: localStorage.getItem(SECONDARY_ADMISSION_TOKEN)
    ? `Token ${localStorage.getItem(SECONDARY_ADMISSION_TOKEN)}`
    : "",
};

export let headersMultipartSecondEdu = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "multipart/form-data",
  Authorization: localStorage.getItem(SECONDARY_ADMISSION_TOKEN)
    ? `Token ${localStorage.getItem(SECONDARY_ADMISSION_TOKEN)}`
    : "",
};

// --------------------------------------
export let axiosInstance = axios.create({
  baseURL: `${host}api`,
  headers,
  timeout: 100000,
});

export let axiosInstanceMultipart = axios.create({
  baseURL: `${host}api`,
  headers: headersMultipart,
  timeout: 100000,
});

export let axiosInstanceLyceum = axios.create({
  baseURL: `https://litsey.edu.uz/api`,
  headers: headersLyceum,
  timeout: 100000,
});

export let axiosInstanceVocation = axios.create({
  baseURL: `https://kasb.edu.uz/api`,
  headers: headersVocation,
  timeout: 100000,
});

export let axiosInstanceInterview = axios.create({
  baseURL: `https://backoffice.suhbat.edu.uz/api`,
  headers: headersInterview,
  timeout: 100000,
});
export let axiosInstanceSecondEdu = axios.create({
  baseURL: `https://admin.ikkinchitalim.edu.uz/api`,
  headers: headersSecondEdu,
  timeout: 100000,
});
export let axiosInstanceMultipartSecondEdu = axios.create({
  baseURL: `https://admin.ikkinchitalim.edu.uz/api`,
  headers: headersMultipartSecondEdu,
  timeout: 100000,
});

// --------------------------------------------
axiosInstance.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    showError(error);
    throw error;
  },
);
axiosInstanceMultipart.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    showError(error);
    throw error;
  },
);
axiosInstanceLyceum.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    showError(error);
    throw error;
  },
);
axiosInstanceVocation.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    showError(error);
    throw error;
  },
);
axiosInstanceInterview.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    showError(error);
    throw error;
  },
);
axiosInstanceSecondEdu.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    showError(error);
    throw error;
  },
);
axiosInstanceMultipartSecondEdu.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    showError(error);
    throw error;
  },
);

//Helper functions
const showError = (error: any) => {
  if (error.response) {
    let detail = error.response.data?.detail;
    let message = error.response.data?.message;

    let { status } = error.response;
    if (status === 401) {
      handleLogoutFunc();
    } else {
      if (
        Array.isArray(error?.response?.data?.password) &&
        error?.response?.data?.password?.[0] === "Ensure this field has at least 6 characters."
      ) {
        toast.error(giveError("Ensure this field has at least 6 characters."));
      } else {
        if (
          detail !== `Ushbu raqam tizimda ro'yxatga olinmagan.` &&
          detail !== `Noto'g'ri autentifikatsiya ma'lumotlari.`
        ) {
          detail
            ? toast.error(giveError(detail))
            : message !== "Expirete Token"
            ? toast.error(message)
            : console.log(`Ma'lumotlar topilmadi.`);
        }
      }
    }
  }
};
