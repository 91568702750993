import { Modal } from "flowbite-react";
import { ArrowBackSvg } from "src/assets/svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmSmsTypes, LoginCheckProps } from "src/static/interface/login";
import { sms_code_mask } from "src/static/const";
import { login_check_schema } from "src/static/const/schema";
import { clearMask } from "src/utils";
import { useState } from "react";
import { ConfirmSmsConfig } from "src/service/api/auth";

import CustomButton from "src/components/common/button";
import MaskedInputItem from "src/components/form/input/masked";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

function LoginCheck({ setStep, informs, changeOneTimePass }: LoginCheckProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const { credential_id } = informs;

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ConfirmSmsTypes>({
    resolver: yupResolver(login_check_schema),
  });
  const errorCode = clearMask(watch("code")).length !== 6;
  const onSubmit = (data: ConfirmSmsTypes) => {
    if (credential_id) {
      let obj = {
        credential_id,
        phone_number: informs.phone_number,
        code: clearMask(data.code),
      };
      setLoading(true);
      ConfirmSmsConfig(obj)
        .then((res) => {
          changeOneTimePass(res.data.one_time_password);
          setStep("password");
        })
        .catch((err) => {
          const { status } = err.response;
          if (status === 404) {
            setStep("signup");
            //   toast.error(t("message.login_check_404"));
            // } else if (status === 406) {
            //   toast.error(t("message.login_check_406"));
            // } else if (status === 409) {
            //   toast.error(t("message.login_check_409"));
            // } else if (status === 510) {
            //   toast.error(t("message.login_check_510"));
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className='login-check'>
      <Modal.Header className='login-header'>{t("registration.confirm_sms")}</Modal.Header>
      <Modal.Body className='login-body'>
        <p className='login-check-info'>
          {t("registration.sendSMS1")} <span>{informs.phone_number}</span>{" "}
          {t("registration.sendSMS2")}
        </p>
        <form className='custom-form' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='code'
            control={control}
            render={({ field }) => (
              <MaskedInputItem
                label={t("registration.sms") || ""}
                placeholder={t("registration.sms_enter") || ""}
                size='lg'
                message={t("registration.sms_enter") || ""}
                mask={sms_code_mask}
                field={field}
                error={errorCode && validated}
              />
            )}
          />
          {/* <a className='login-check-resend'> {t("registration.resetSMS")}</a> */}
          <CustomButton
            size='xl'
            color='primary'
            style={{ width: "100%" }}
            type='submit'
            loading={loading}
            onClick={() => setValidated(true)}
          >
            {t("registration.confirm")}
          </CustomButton>
          <CustomButton
            onClick={() => setStep(informs.step || "main")}
            size='xl'
            color='gray'
            style={{ width: "100%", marginTop: 12 }}
            icon={<ArrowBackSvg />}
            left_icon
          >
            {t("profile.common.back")}
          </CustomButton>
        </form>
      </Modal.Body>
    </div>
  );
}

export default LoginCheck;
