import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MiniRightIconSVG } from "src/assets/svg";
import { login_check_schema, login_main_schema } from "src/static/const/schema";
import { phone_mask, sms_code_mask } from "src/static/const";
import { clearMask } from "src/utils";
import {
  ConfirmSmsForChangePhoneConfig,
  SendSmsForChangePhoneConfig,
} from "src/service/api/profile";
import { useContext, useState } from "react";
import { UserChangePhoneTypes } from "src/static/interface/profile";
import MaskedInputItem from "src/components/form/input/masked";
import CustomButton from "src/components/common/button";
import { MainContext } from "src/hooks/context";
import { USER_DATA } from "src/static/const/auth";
import { useTranslation } from "react-i18next";

function ChangePhone() {
  const { t } = useTranslation();
  const { userData, setUserData } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [phone, setPhone] = useState("");

  //Send sms section
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<UserChangePhoneTypes>({
    resolver: yupResolver(phone ? login_check_schema : login_main_schema),
  });

  const onSubmit = (data: UserChangePhoneTypes) => {
    setLoading(true);
    if (phone) {
      const code = clearMask(data.code);
      ConfirmSmsForChangePhoneConfig({ code: code })
        .then((res) => {
          let obj = userData;
          if (obj) {
            obj.phone_number = phone;
            setUserData?.(obj);
            localStorage.setItem(USER_DATA, JSON.stringify(obj));
          }
          alert("Telefon raqamingiz muvaffaqiyatli o'zgartirildi");
          setPhone("");
          setValidated(false);
          reset();
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      const phone_number = "+998" + clearMask(data.phone_number);
      SendSmsForChangePhoneConfig({ phone_number: phone_number })
        .then((res) => {
          if (res.data.ok) {
            setPhone(phone_number);
            setValidated(false);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const errorCode = clearMask(watch("code")).length !== 6;
  const errorPhone = clearMask(watch("phone_number")).length !== 9;

  return (
    <div>
      <h3 className="title">
        {t("profile.settings.change_phone")} {userData?.phone_number}
      </h3>
      <form
        className="custom-form custom-form-sm profile__settings_form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {phone ? (
          <>
            <p className="login-check-info">
              SMS kodni <span>{phone}</span> raqamiga yubordik. Telefon
              raqamingizni tasdiqlash uchun quyidagi maydonga kodni kiriting.
            </p>
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <MaskedInputItem
                  label="SMS kod"
                  placeholder="SMS kodni kiriting 6 ta belgi"
                  size="lg"
                  message="SMS kodni to'liq kiriting"
                  mask={sms_code_mask}
                  field={field}
                  error={errorCode && validated}
                />
              )}
            />
            <a className="login-check-resend">SMS kodni qayta yuborish</a>
          </>
        ) : (
          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <MaskedInputItem
                label={t("profile.settings.add_new_phone") || ""}
                addon="+998"
                placeholder="(__) ___ __ __"
                mask={phone_mask}
                size="lg"
                message="Telefon raqamni to'liq kiriting"
                field={field}
                error={errorPhone && validated}
              />
            )}
          />
        )}

        <CustomButton
          type="submit"
          color={phone ? "primary" : "gray"}
          icon={<MiniRightIconSVG />}
          loading={loading}
          style={{ marginTop: 8 }}
          onClick={() => setValidated(true)}
        >
          {t("profile.common.continue") || ""}
        </CustomButton>
      </form>
    </div>
  );
}

export default ChangePhone;
