import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Modal } from "flowbite-react";
import { OneIdHoverSvg, OneIdSvg, RightNarrowSvg } from "src/assets/svg";
import { LoginMainTypes, LoginMainProps } from "src/static/interface/login";
import { clearMask } from "src/utils";
import { login_main_schema } from "src/static/const/schema";
import { phone_mask } from "src/static/const";
import { CheckPhoneConfig } from "src/service/api/auth";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import CustomButton from "src/components/common/button";
import MaskedInputItem from "src/components/form/input/masked";

function LoginMain({ setStep, changePhone }: LoginMainProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [hovered, setHovered] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<LoginMainTypes>({
    resolver: yupResolver(login_main_schema),
  });

  const errorPhone = clearMask(watch("phone_number")).length !== 9;

  const onSubmit = (data: LoginMainTypes) => {
    const phone_number = "+998" + clearMask(data.phone_number);
    setLoading(true);
    CheckPhoneConfig({ phone_number: phone_number })
      .then((res) => {
        if (res.data.ok) setStep("signin");
      })
      .catch((err) => {
        const { status } = err.response;
        if (status === 404) {
          setStep("signup");
          //   toast.error(t("message.login_main_404"));
          // } else if (status === 400) {
          //   toast.error(t("message.login_main_400"));
        }
      })
      .finally(() => {
        setLoading(false);
        changePhone(phone_number);
      });
  };

  return (
    <div className="login-main">
      <Modal.Header className="login-header">
        {t("registration.loginorreg")}
      </Modal.Header>
      <Modal.Body className="login-body">
        <form className="custom-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <MaskedInputItem
                label={t("profile.common.phone") || ""}
                addon="+998"
                placeholder="(__) ___ __ __"
                mask={phone_mask}
                size="lg"
                message={t("profile.common.phone") || ""}
                field={field}
                error={errorPhone && validated}
              />
            )}
          />

          <p onClick={() => setStep("forget")} className="login-main-forget">
            {t("registration.phone_remind") || ""}
          </p>
          <CustomButton
            size="xl"
            color="primary"
            icon={<RightNarrowSvg />}
            style={{ width: "100%" }}
            type="submit"
            loading={loading}
            onClick={() => setValidated(true)}
          >
            {t("profile.common.continue") || ""}
          </CustomButton>
          <div className="login-main-other">
            <p>
              <span>{t("registration.other_way") || ""}</span>
            </p>
          </div>
          <CustomButton
            size="xl"
            color="oneid"
            onClick={() =>
              (window.location.href = "https://my.edu.uz/auth/one-id-callback")
            }
            style={{ width: "100%" }}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            icon={hovered ? <OneIdHoverSvg /> : <OneIdSvg />}
          />
        </form>
      </Modal.Body>
    </div>
  );
}

export default LoginMain;
