import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRightSvg } from "src/assets/svg";
import InfoCard from "src/components/cards/InfoCard";
import CustomButton from "src/components/common/button";
import CustomDrower from "src/components/common/drower";
import DatepickerItem from "src/components/form/datepicker";
import InputItem from "src/components/form/input";
import InputUploadItem from "src/components/form/input/upload";
import SelectItem from "src/components/form/select";
import { pdfType } from "src/static/const";
import { SECONDARY_ADMISSION_TOKEN } from "src/static/const/auth";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import {
  second_edu_step2_schema_other,
  second_edu_step2_schema_uzb,
} from "src/static/const/schema";
import { OptionTypes } from "src/static/interface/common";
import {
  AddDiplomSEConfig,
  ChangeDiplomActiveSEConfig,
  EditDiplomFilesSEConfig,
  EditDiplomSEConfig,
  GetAllEduFormSEConfig,
  GetContriesSEConfig,
  GetDiplomInstitutionsSEConfig,
  GetDiplomSEConfig,
  GetDiplomSerialsSEConfig,
  GetDurationSEConfig,
  GetSpecialitiesSEConfig,
  UploadFileSEConfig,
} from "../config";
import {
  makeCountriesItem,
  makeDateValue,
  makeDurationItem,
  makeEduFormItem,
  makeInstitutionItem,
  makeSerialItem,
  makeSpecialityItem,
} from "../const";
import { IDiplomResSE, IDiplomSE, Step2Types } from "../type";

function SecondaryEducationAdmissionStep2() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem(SECONDARY_ADMISSION_TOKEN);
  const [country_id, setCountryId] = useState("1");

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<Step2Types>({
    resolver: yupResolver(
      country_id === "1" ? second_edu_step2_schema_uzb : second_edu_step2_schema_other,
    ),
  });

  //Submit functions
  const [loading, setLoading] = useState(false);
  const onSubmit = (data: Step2Types) => {
    if (!watch("diploma_url")) return toast.error(t("profile.diplom.copy"));
    if (!watch("ilova_url")) return toast.error(t("profile.diplom.file"));
    if (token && !disabled) {
      let {
        edu_starting_date,
        edu_finishing_date,
        diploma_given_date,
        degreeName,
        country_id,
        diploma_number,
        speciality_id,
        edu_form_id,
        institution_id,
        edu_duration_id,
        diploma_serial_id,
        speciality_custom_name,
        edu_form_name,
        foreign_otm_name,
        diploma_serial,
        ...rest
      } = data;
      let obj: Partial<IDiplomSE> = {};
      if (country_id === "1") {
        obj = {
          edu_starting_date: edu_starting_date.startDate,
          edu_finishing_date: edu_finishing_date.startDate,
          diploma_given_date: diploma_given_date.startDate,
          country_id: Number(country_id),
          diploma_number: diploma_number,

          edu_form_id: Number(edu_form_id),
          institution_id: Number(institution_id),
          edu_duration_id: Number(edu_duration_id),
          diploma_serial_id: Number(diploma_serial_id),
          ...rest,
        };
        if (speciality_id === "other") obj["speciality_custom_name"] = speciality_custom_name;
        else obj["speciality_id"] = Number(speciality_id);
      } else {
        obj = {
          edu_starting_date: edu_starting_date.startDate,
          edu_finishing_date: edu_finishing_date.startDate,
          diploma_given_date: diploma_given_date.startDate,
          country_id: Number(country_id),
          diploma_number: diploma_number,
          edu_duration_id: Number(edu_duration_id),

          edu_form_name: edu_form_name,
          foreign_otm_name: foreign_otm_name,
          speciality_custom_name: speciality_custom_name,
          diploma_serial: diploma_serial,
          ...rest,
        };
      }

      setLoading(true);

      if (diplomId) {
        EditDiplomSEConfig(obj, diplomId, token)
          .then(async (res) => {
            await changeActiveDiplom();
            navigate("/service/admission/secondary_education/step3");
          })
          .finally(() => setLoading(false));
      } else
        AddDiplomSEConfig(obj, decodeURI(token.trim()))
          .then(async (res) => {
            await ChangeDiplomActiveSEConfig(res.data?.object, token);
            navigate("/service/admission/secondary_education/step3");
          })
          .finally(() => setLoading(false));
    }
  };

  const handleNavigate = () => {
    if (disabled && token && diplomId) {
      if (!watch("diploma_url")) return toast.error(t("profile.diplom.copy"));
      if (!watch("ilova_url")) return toast.error(t("profile.diplom.file"));

      EditDiplomFilesSEConfig(
        { id: diplomId, ilova: watch("ilova_url"), diploma: watch("diploma_url") },
        token,
      ).then(() => {
        navigate("/service/admission/secondary_education/step3");
      });
    }
  };

  //Get classificators
  const [activated, setActivated] = useState(false);
  const [diplomId, setDiplomId] = useState<number>();
  const [diploms, setDiploms] = useState<IDiplomResSE[]>([]);
  const [contries, setCountries] = useState<OptionTypes[]>([]);
  const [eduForms, setEduForms] = useState<OptionTypes[]>([]);
  const [institutions, setInstitutions] = useState<OptionTypes[]>([]);
  const [specialities, setSpecialities] = useState<OptionTypes[]>([]);
  const [durations, setDurations] = useState<OptionTypes[]>([]);
  const [serials, setSerials] = useState<OptionTypes[]>([]);
  const [disabled, setDisabled] = useState(false);
  const getFunc = () => {
    if (token) {
      //Diplom
      GetDiplomSEConfig(token)
        .then((res) => {
          let arr = res.data.object as IDiplomResSE[];
          if (arr) {
            if (arr.length > 0) {
              let activeDiplom = arr.find((el) => el.isActive);
              if (activeDiplom) {
                setActivated(true);
                setDiploms(arr);
                handleSet(activeDiplom);
                setCountryId(String(activeDiplom.countryId));
              } else if (arr.length === 1) {
                ChangeDiplomActiveSEConfig(arr[0].id, token).then((res) => {
                  arr[0].isActive = true;
                  setDiploms(arr);
                  handleSet(arr[0]);
                  setCountryId(String(arr[0].countryId));
                });
              } else {
                openDrower();
                toast.success(t("second_edu.select_diplom"));
                setDiploms(arr);
              }
            }
          }
        })
        .catch((err) => {
          if (err.response.data?.message === "Expirete Token") {
            navigate("/service");
            localStorage.removeItem(SECONDARY_ADMISSION_TOKEN);
            toast.error(t("message.expire_warning"));
          }
        });
      //Countries
      GetContriesSEConfig().then((res) => {
        setCountries(makeCountriesItem(res.data));
      });
      //EduForms
      GetAllEduFormSEConfig().then((res) => {
        setEduForms(makeEduFormItem(res.data));
      });
      //Institutions
      GetDiplomInstitutionsSEConfig().then((res) => {
        setInstitutions(makeInstitutionItem(res.data));
      });
      //Duration
      GetDurationSEConfig().then((res) => {
        setDurations(makeDurationItem(res.data));
      });
      //Serials
      GetDiplomSerialsSEConfig().then((res) => {
        setSerials(makeSerialItem(res.data));
      });

      //Degree
      setValue("degreeName", t("home.actions.bachelor"));
    } else navigate("/service");
  };

  useEffect(() => {
    getFunc();
  }, []);

  const handleSet = (diplom: IDiplomResSE, rendered = false) => {
    let {
      countryId,
      eduFormId,
      institutionId,
      specialityId,
      eduDurationId,
      diplomaSerialId,
      diplomaNumber,
      eduFinishingDate,
      eduStartingDate,
      diplomaGivenDate,
      diplomaUrl,
      ilovaUrl,
      id,
      specialityCustomName,
      statusName,
    } = diplom;

    statusName === "Tasdiqlangan" && setDisabled(true);
    id && setDiplomId(id);
    countryId && setValue("country_id", String(countryId));

    eduDurationId && setValue("edu_duration_id", String(eduDurationId));
    diplomaNumber && setValue("diploma_number", String(diplomaNumber));
    diplomaUrl && setValue("diploma_url", String(diplomaUrl));
    ilovaUrl && setValue("ilova_url", String(ilovaUrl));
    eduFinishingDate && setValue("edu_finishing_date", makeDateValue(eduFinishingDate));
    eduStartingDate && setValue("edu_starting_date", makeDateValue(eduStartingDate));
    diplomaGivenDate && setValue("diploma_given_date", makeDateValue(diplomaGivenDate));
    if (countryId == 1) {
      eduFormId && setValue("edu_form_id", String(eduFormId));
      specialityId && setValue("speciality_id", String(specialityId));
      diplomaSerialId && setValue("diploma_serial_id", String(diplomaSerialId));
      if (institutionId) {
        setValue("institution_id", String(institutionId));
        !rendered && handleChangeUniversity(String(institutionId));
      }
    }
    if (specialityCustomName) {
      setValue("speciality_custom_name", specialityCustomName);
      setValue("speciality_id", "other");
    }
  };

  //Form item functions

  //University
  const handleChangeCountry = (val: string) => {
    setCountryId(val);
    //set value
    setValue("country_id", val);
    clearErrors("country_id");

    //clear value
    if (val !== "1") {
      setValue("edu_form_id", "");
      setValue("institution_id", "");
      setValue("speciality_id", "");
      setValue("speciality_custom_name", "");
      setValue("edu_duration_id", "");
      setValue("diploma_number", "");
      setValue("diploma_url", "");
      setValue("ilova_url", "");
      setValue("diploma_serial_id", "");
    }
  };
  useEffect(() => {
    if (country_id) {
      let activDiplom = diploms.find((el) => el.isActive) as IDiplomResSE;
      if (activDiplom) {
        setTimeout(() => {
          if (country_id === "1") {
            handleSet(activDiplom, true);
          } else {
            setValue("edu_form_name", activDiplom.eduFormName);
            setValue("foreign_otm_name", activDiplom.institutionName);
            setValue("speciality_custom_name", activDiplom.specialityCustomName);
            setValue("diploma_serial", activDiplom.diplomaSerial);
          }
        }, 100);
      }
    }
  }, [country_id]);
  //University

  const handleChangeUniversity = (val: string) => {
    // Speciality
    GetSpecialitiesSEConfig(val).then((res) => {
      setSpecialities([
        {
          value: "other",
          name_uz: "Boshqa",
          name_ru: "Boshqa",
          name_en: "Boshqa",
        },
        ...makeSpecialityItem(res.data),
      ]);
    });
    //set value
    setValue("institution_id", val);
    clearErrors("institution_id");
  };

  const handleChangeFile = (val: any, name: "ilova_url" | "diploma_url") => {
    const formData = new FormData();
    formData.append("file", val);
    formData.append("key", name);
    UploadFileSEConfig(formData).then((res) => {
      setValue(name, res.data);
    });
  };

  //Drower settings
  const [drower, setDrower] = useState(false);
  const openDrower = () => setDrower(true);
  const closeDrower = () => {
    // if (activated) setDrower(false);
    setDrower(false);
  };

  const changeActiveDiplom = () => {
    if (diplomId && token) {
      ChangeDiplomActiveSEConfig(diplomId, token).then((res) => {
        let activDiplom = diploms.find((el) => el.id === diplomId);
        if (activDiplom) handleSet(activDiplom, true);
        closeDrower();
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='application-box'>
        <div className='application-box-title'>
          {t("second_edu.finished_edu_info")}
          <CustomButton onClick={openDrower} color='primary' outline icon={<ArrowRightSvg />}>
            {t("profile.common.seeAll")}
          </CustomButton>
        </div>
        <div className='application-box-form'>
          <Controller
            name='country_id'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("second_edu.institution_country") || ""}
                placeholder={t("second_edu.select_country") || ""}
                field={field}
                error={errors.country_id && true}
                options={contries}
                className='half'
                onChange={handleChangeCountry}
                disabled={disabled}
              />
            )}
          />
          <Controller
            name={country_id === "1" ? "edu_form_id" : "edu_form_name"}
            control={control}
            render={({ field }) =>
              country_id === "1" ? (
                <SelectItem
                  label={t("profile.edu.edu_form") || ""}
                  placeholder={t("second_edu.select_edu_form") || ""}
                  field={field}
                  options={eduForms}
                  error={errors.edu_form_id && true}
                  className='half'
                  disabled={disabled}
                />
              ) : (
                <InputItem
                  label={t("profile.edu.edu_form") || ""}
                  placeholder={t("second_edu.select_edu_form") || ""}
                  field={field}
                  error={errors.edu_form_name && true}
                  className='half'
                  disabled={disabled}
                />
              )
            }
          />
          <Controller
            name={country_id === "1" ? "institution_id" : "foreign_otm_name"}
            control={control}
            render={({ field }) =>
              country_id === "1" ? (
                <SelectItem
                  label={t("second_edu.institution_name") || ""}
                  placeholder={t("second_edu.select_institution") || ""}
                  field={field}
                  error={errors.institution_id && true}
                  options={institutions}
                  className='full'
                  onChange={handleChangeUniversity}
                  disabled={disabled}
                />
              ) : (
                <InputItem
                  label={t("second_edu.institution_name") || ""}
                  placeholder={t("second_edu.select_institution") || ""}
                  field={field}
                  error={errors.foreign_otm_name && true}
                  className='full'
                  disabled={disabled}
                />
              )
            }
          />
          <Controller
            name='edu_starting_date'
            control={control}
            render={({ field }) => (
              <DatepickerItem
                label={t("second_edu.edu_starting_date") || ""}
                field={field}
                error={errors.edu_starting_date ? true : false}
                message={t("profile.awards.docstart_date") || ""}
                className='half'
                disabled={disabled}
                onChange={(val) => {
                  setValue("edu_starting_date", val);
                  clearErrors("edu_starting_date");
                }}
              />
            )}
          />
          <Controller
            name='edu_finishing_date'
            control={control}
            render={({ field }) => (
              <DatepickerItem
                label={t("second_edu.edu_finishing_date") || ""}
                field={field}
                error={errors.edu_finishing_date ? true : false}
                message={t("profile.awards.docstart_date") || ""}
                className='half'
                disabled={disabled}
                onChange={(val) => {
                  setValue("edu_finishing_date", val);
                  clearErrors("edu_finishing_date");
                }}
              />
            )}
          />
          <Controller
            name={country_id === "1" ? "speciality_id" : "speciality_custom_name"}
            control={control}
            render={({ field }) =>
              country_id === "1" ? (
                <SelectItem
                  label={t("profile.edu.dir_name") || ""}
                  placeholder={t("second_edu.select_dir_name") || ""}
                  field={field}
                  error={errors.speciality_id && true}
                  options={specialities}
                  className='full'
                  disabled={disabled}
                />
              ) : (
                <InputItem
                  label={t("profile.edu.dir_name") || ""}
                  placeholder={t("second_edu.select_dir_name") || ""}
                  field={field}
                  error={errors.speciality_custom_name && true}
                  className='full'
                  disabled={disabled}
                />
              )
            }
          />
          {watch("speciality_id") === "other" && (
            <Controller
              name={"speciality_custom_name"}
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("profile.edu.dir_name") || ""}
                  placeholder={t("second_edu.enter_dir_name") || ""}
                  field={field}
                  error={errors.speciality_custom_name && true}
                  className='full'
                  disabled={disabled}
                />
              )}
            />
          )}

          <Controller
            name='diploma_given_date'
            control={control}
            render={({ field }) => (
              <DatepickerItem
                label={t("interview.diplom_dates") || ""}
                field={field}
                error={errors.diploma_given_date ? true : false}
                message={t("profile.awards.docstart_date") || ""}
                className='half'
                onChange={(val) => {
                  setValue("diploma_given_date", val);
                  clearErrors("diploma_given_date");
                }}
                disabled={disabled}
              />
            )}
          />
          <Controller
            name='edu_duration_id'
            control={control}
            render={({ field }) => (
              <SelectItem
                label={t("second_edu.edu_duration") || ""}
                placeholder={t("second_edu.select_edu_duration") || ""}
                field={field}
                error={errors.edu_duration_id && true}
                options={durations}
                className='half'
                disabled={disabled}
              />
            )}
          />
          <div className='application-box-tiny half'>
            <Controller
              name={country_id === "1" ? "diploma_serial_id" : "diploma_serial"}
              control={control}
              render={({ field }) =>
                country_id === "1" ? (
                  <SelectItem
                    label={t("profile.edu.series") || ""}
                    placeholder='_ _ _ _ '
                    field={field}
                    error={errors.diploma_serial && true}
                    options={serials}
                    className='first'
                    disabled={disabled}
                  />
                ) : (
                  <InputItem
                    label={t("profile.edu.series") || ""}
                    placeholder='_ _ _ _ '
                    field={field}
                    error={errors.diploma_serial ? true : false}
                    className='first'
                    disabled={disabled}
                  />
                )
              }
            />

            <Controller
              name='diploma_number'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("profile.edu.number") || ""}
                  placeholder=' _ _ _ _ _ _ _ _ _ _ _'
                  field={field}
                  error={errors.diploma_number ? true : false}
                  className='second'
                  disabled={disabled}
                />
              )}
            />
          </div>
          <Controller
            name='degreeName'
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("second_edu.edu_degree") || ""}
                field={field}
                disabled
                className='half'
              />
            )}
          />
          <div className='half'>
            <Controller
              name='diploma_url'
              control={control}
              render={({ field }) => (
                <InputUploadItem
                  className='full custom-input-upload'
                  label={t("profile.diplom.copy") || ""}
                  message={t("profile.diplom.copy") || ""}
                  accept={pdfType}
                  field={field}
                  error={errors.diploma_url && true}
                  onChange={(file) => {
                    handleChangeFile(file, "diploma_url");
                    clearErrors("diploma_url");
                  }}
                />
              )}
            />
            {watch("diploma_url") && (
              <a
                download
                href={watch("diploma_url")}
                target='_blank'
                style={{ color: "#1A56DB", textDecoration: "underLine" }}
              >
                {t("service.serviceID.download")}
              </a>
            )}
          </div>
          <div className='half'>
            <Controller
              name='ilova_url'
              control={control}
              render={({ field }) => (
                <InputUploadItem
                  className='full custom-input-upload'
                  label={t("profile.diplom.file") || ""}
                  message={t("profile.diplom.file") || ""}
                  accept={pdfType}
                  field={field}
                  error={errors.ilova_url && true}
                  onChange={(file) => {
                    handleChangeFile(file, "ilova_url");
                    clearErrors("ilova_url");
                  }}
                />
              )}
            />
            {watch("ilova_url") && (
              <a
                download
                href={watch("ilova_url")}
                target='_blank'
                style={{ color: "#1A56DB", textDecoration: "underLine" }}
              >
                {t("service.serviceID.download")}
              </a>
            )}
          </div>
        </div>
      </div>

      <div className='application-box-footer'>
        <Link to='/service/admission/secondary_education/step1'>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps} loading={loading} onClick={handleNavigate}>
          {t("profile.common.continue") || ""}
        </CustomButton>
      </div>

      <CustomDrower
        title={t("second_edu.finished_edu_info") || ""}
        okText={t("photo.save") || ""}
        cancelText={t("photo.cancel") || ""}
        width={840}
        visible={drower}
        onCancel={closeDrower}
        onOk={changeActiveDiplom}
        blured
      >
        <>
          {diploms.map((item) => {
            return (
              <div
                key={item?.id}
                className={`application-certificate ${
                  item.id === diplomId && "application-certificate-active"
                }`}
              >
                <div className='application-certificate-header'>
                  {item.degreeName} {t("second_edu.diplom")}
                  <div
                    className='application-certificate-button'
                    onClick={() => setDiplomId(item.id)}
                  >
                    <div className='radio' /> {t("profile.awards.select")}
                  </div>
                </div>
                <InfoCard title={t("second_edu.country")} info={item.countryName} />
                <InfoCard title={t("profile.edu.edu_name")} info={item.institutionOldName} />
                <InfoCard title={t("profile.edu.edu_type")} info={item.eduFormName} />
                <InfoCard title={t("profile.common.choices")} info={item.specialityName} />
              </div>
            );
          })}
        </>
      </CustomDrower>
    </form>
  );
}

export default SecondaryEducationAdmissionStep2;
