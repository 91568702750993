import { GetFuncLyceum, EditFuncLyceum, CreateFuncLyceum } from "src/service/config/others";

// --------------------------------------------------------------------
export const CreateLyceumAppConfig = (data: any) => {
  return CreateFuncLyceum(`/applications/`, data);
};

export const UpdateLyceumAppConfig = (id: string, data: any) => {
  return EditFuncLyceum(`/applications/${id}/`, data, "PATCH");
};

export const GetLyceumQuotaConfig = (param: string) => {
  return GetFuncLyceum(`/quotas/${param}`);
};

export const GetLyceumDirectionConfig = () => {
  return GetFuncLyceum(`/classifiers/specialities/?limit=1000`);
};
export const GetLyceumAppIDConfig = (id: string) => {
  return GetFuncLyceum(`/applications/${id}/`);
};
export const AppealLyceumApplication = (id: string, data: any) => {
  return CreateFuncLyceum(`/applications/${id}/appeal/`, data);
};
export const GetLyceumLanguagesConfig = () => {
  return GetFuncLyceum(`/classifiers/education-languages/`);
};
