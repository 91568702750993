import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CustomBroudcamp({
  nav = [
    {
      name: "nav.service",
      link: "/service?key=all",
    },
  ],
}: any) {
  const { t } = useTranslation();

  return (
    <nav
      className="inline-flex mb-7 px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700 customBroudCamp"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center">
          <Link
            to={"/"}
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
            {t("nav.home")}
          </Link>
        </li>
        {nav?.map((link: any) => (
          <li key={crypto.randomUUID()}>
            <div className="flex items-center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.50156 13.2292L10.7349 9.99583L7.50156 6.7625C7.17656 6.4375 7.17656 5.9125 7.50156 5.5875C7.82656 5.2625 8.35156 5.2625 8.67656 5.5875L12.5016 9.4125C12.8266 9.7375 12.8266 10.2625 12.5016 10.5875L8.67656 14.4125C8.35156 14.7375 7.82656 14.7375 7.50156 14.4125C7.1849 14.0875 7.17656 13.5542 7.50156 13.2292Z"
                  fill="#9CA3AF"
                />
              </svg>
              <Link
                to={`${link.link}`}
                className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                {t(link.name)}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default CustomBroudcamp;
