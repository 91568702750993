import { LoadingIcon } from "src/components/icon";
import { CustomButtonProps } from "src/static/interface/common";

function CustomButton({
  children,
  size,
  color,
  outline,
  loading,
  disabled,
  leftIcon,
  left_icon,
  right_icon,
  icon,
  onClick,
  ...rest
}: CustomButtonProps) {
  return (
    <div
      className={`custom-button ${outline && "outline"} ${(disabled || loading) && "disabled"} `}
      onClick={onClick}
    >
      <button type='button' className={`${size} ${color}`} {...rest}>
        {leftIcon}
        {left_icon && icon && (loading ? <LoadingIcon /> : icon)}
        {children}
        {(right_icon || !left_icon) && icon && !loading && icon}
        {(right_icon || !left_icon) && loading && <LoadingIcon />}
      </button>
    </div>
  );
}

export default CustomButton;
