import { CreateFunc, EditFunc, GetFunc } from "src/service/config";

export const GetSchoolInfo = () => {
  return GetFunc(`/portfolios/school-certificates/`);
};

export const GetDiplomsInfo = () => {
  return GetFunc(`/portfolios/diplomas/`);
};

export const ReloadDiploms = () => {
  return CreateFunc("/portfolios/diplomas/reload/", {});
};
export const ReloadSchoolCert = () => {
  return CreateFunc("/portfolios/school-certificates/reload/", {});
};

export const UpdateSchoolCer = (id: string, data: any) => {
  return EditFunc(`/portfolios/school-certificates/${id}/`, data);
};
