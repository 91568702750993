import { InfoCardType, StepItemTypes } from "src/static/interface/common";

function InfoCard({ title, info }: StepItemTypes) {
  return (
    <div className="infocard">
      <div className="infocard-left">
        <h2>{title}:</h2>
        <p></p>
      </div>
      <h3>{info}</h3>
    </div>
  );
}

export default InfoCard;
