import { yupResolver } from "@hookform/resolvers/yup";
import { ToggleSwitch } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  ArrowRightSvg,
  LongArrowSVG,
  NoInformationSVG,
  NoLanguageSVG,
  ShortNarrowSvg,
} from "src/assets/svg";
import InfoCard from "src/components/cards/InfoCard";
import NoItemCard from "src/components/cards/NoItemCard";
import ResultCard from "src/components/cards/ResultCard";
import CustomButton from "src/components/common/button";
import CustomDrower from "src/components/common/drower";
import DatepickerItem from "src/components/form/datepicker";
import InputItem from "src/components/form/input";
import InputUploadItem from "src/components/form/input/upload";
import SelectItem from "src/components/form/select";
import { MainContext } from "src/hooks/context";
import { GetSchoolInfo } from "src/pages/profile/diplom/config";
import { SchoolCerTypes } from "src/pages/profile/diplom/type";
import {
  GetCefrCerConfig,
  GetLanCertificatesConfig,
  GetSubjectCerConfig,
} from "src/pages/profile/language/config";
import { CreateAchievmentConfig } from "src/pages/profile/portfolio/components/config";
import LanguageForm from "src/pages/services/language/components/LanguageForm";
import { giveError } from "src/service/host";
import { pdfType } from "src/static/const";
import {
  LYCEUM_ADMISSION_DATA,
  LYCEUM_ADMISSION_TOKEN,
  applicationID,
  language,
} from "src/static/const/auth";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { add_award_schema } from "src/static/const/schema";
import { AwardTypes } from "src/static/interface/profile";
import { giveLan } from "src/utils";
import { CreateLyceumAppConfig, UpdateLyceumAppConfig } from "../config";

function LyceumAdmissionStep4() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { achievements, getAchievements } = useContext(MainContext);

  //Drower section
  const [tab, setTab] = useState("1");
  const [tabDrawer, setTabDrower] = useState("1");
  const [langID, setLangID] = useState();
  const [appID, setAppID] = useState("");
  const [submit, setSubmit] = useState(1);
  const [drower, setDrower] = useState(false);
  const [loading, setLoading] = useState(false);
  const openDrower = () => {
    setDrower(true);
    getCertificates();
  };
  const closeDrower = () => setDrower(false);

  // ------------------------------------------------
  const [checked, setChecked] = useState(false);
  const [selectedForeignCert, setSelectedForeignCert] = useState<any>();
  const [selectedSubjectCert, setSelectedSubjectCert] = useState<any>();
  const [selectedCefrCert, setSelectedCefrCert] = useState<any>();
  const [visible, setVisible] = useState(false);
  const openModal = () => setVisible(true);
  const closeModal = () => {
    setVisible(false);
    navigate(`/service/admission/detail?id=${appID}&key=lyceum_admission`);
    localStorage.removeItem(LYCEUM_ADMISSION_DATA);
    // localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
  };

  // certidactes list
  const [cefrCer, setCefrCer] = useState<any[]>();
  const [foreginCer, setForeginCer] = useState<any[]>();
  const [subjectCer, setSubjectCer] = useState<any[]>();
  const [schoolInfo, setSchoolInfo] = useState<SchoolCerTypes>();

  const {
    trigger: trigger2,
    getValues: getValues2,
    handleSubmit: handleSubmit2,
    control: control2,
    clearErrors: clearErrors2,
    setValue: setValue2,
    formState: { errors },
  } = useForm<AwardTypes>({
    resolver: yupResolver(add_award_schema),
  });

  const getCertificates = async () => {
    if (!foreginCer || !subjectCer || !cefrCer) {
      const foregn = await GetLanCertificatesConfig();
      setForeginCer(foregn?.data?.results);

      const subjects = await GetSubjectCerConfig();
      setSubjectCer(subjects?.data?.results);
      const cefrs = await GetCefrCerConfig();
      setCefrCer(cefrs?.data?.results);

      return { foregn, subjects, cefrs };
    } else {
      return { foreginCer, subjectCer, cefrCer };
    }
  };

  // Submit with new cer
  const onSubmit = async () => {
    // __________________________________________________________ //
    const info = JSON.parse(localStorage.getItem(LYCEUM_ADMISSION_DATA) || "");

    // Lyceum selections
    const lyceum_selections = [];
    info?.lyceum1 &&
      lyceum_selections.push({
        lyceum: info?.lyceum1,
        sequence: 1,
      });
    info?.lyceum2 &&
      lyceum_selections.push({
        lyceum: info?.lyceum2,
        sequence: 2,
      });
    info?.lyceum3 &&
      lyceum_selections.push({
        lyceum: info?.lyceum3,
        sequence: 3,
      });
    // __________________________________________________________ //

    // Create with chet tili
    if (checked && tab === "1" && langID) {
      const result = {
        school_certificate: {
          ...schoolInfo,
          country: schoolInfo?.country.id,
          education_language: schoolInfo?.education_language.id,
          foreign_language: schoolInfo?.foreign_language.id,
        },
        speciality: info?.speciality,
        education_language: info?.education_language,
        foreign_language: info?.foreign_language,
        region: info?.region,
        foreign_language_certificate: langID,
        science_certificate: null,
        achievement_certificate: null,
        lyceum_selections,
      };

      if (applicationID) {
        const { data } = await UpdateLyceumAppConfig(applicationID, result);
        toast.success("Ariza tahrirlandi !");
        navigate(`/service/admission/detail?id=${data?.id}&key=lyceum_admission`);
        localStorage.removeItem(LYCEUM_ADMISSION_DATA);
        localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
        localStorage.removeItem("appID");
      } else {
        const { data } = await CreateLyceumAppConfig(result);

        // Give error message
        if (data?.detail) {
          toast.error(giveError(data?.detail));
          localStorage.removeItem(LYCEUM_ADMISSION_DATA);
          localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
        }
        if (data?.id) {
          setAppID(data?.id);
          openModal();
        }
      }
    }
    if (checked && tab === "2") {
      trigger2(); //triggers validation for whole form
      const formValue = getValues2();
      const isValid = add_award_schema.isValidSync(formValue);
      if (isValid) {
        const result = new FormData();
        result.append("name", formValue.name);
        result.append("type", formValue.type);
        result.append("serial_number", formValue.serial_number);
        result.append("given_date", formValue.given_date.startDate);
        result.append("file", formValue.file);

        // Create yutuq certitifacte
        const cerResponse = await CreateAchievmentConfig(result);

        // Create Application with yutuq certitifcate
        const resultAward = {
          school_certificate: {
            ...schoolInfo,
            country: schoolInfo?.country.id,
            education_language: schoolInfo?.education_language.id,
            foreign_language: schoolInfo?.foreign_language.id,
          },
          speciality: info?.speciality,
          education_language: info?.education_language,
          foreign_language: info?.foreign_language,
          region: info?.region,
          foreign_language_certificate: null,
          science_certificate: null,
          achievement_certificate: cerResponse.data,
          lyceum_selections,
        };

        if (applicationID) {
          const { data } = await UpdateLyceumAppConfig(applicationID, resultAward);
          toast.success("Ariza tahrirlandi !");
          navigate(`/service/admission/detail?id=${data?.id}&key=lyceum_admission`);
          localStorage.removeItem(LYCEUM_ADMISSION_DATA);
          localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
          localStorage.removeItem("appID");
        } else {
          const { data } = await CreateLyceumAppConfig(resultAward);

          // Give error message
          if (data?.detail) {
            toast.error(giveError(data?.detail));
            localStorage.removeItem(LYCEUM_ADMISSION_DATA);
            localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
          }
          if (data?.id) {
            setAppID(data?.id);
            openModal();
          }
        }
      }
    }

    setLoading(false);
  };

  // Submit with exact cer
  const createWithExactCertificate = async () => {
    if (selectedSubjectCert?.id || selectedForeignCert?.id || selectedCefrCert?.id) {
      const info = JSON.parse(localStorage.getItem(LYCEUM_ADMISSION_DATA) || "");

      // Lyceum selections
      const lyceum_selections = [];
      info?.lyceum1 &&
        lyceum_selections.push({
          lyceum: info?.lyceum1,
          sequence: 1,
        });
      info?.lyceum2 &&
        lyceum_selections.push({
          lyceum: info?.lyceum2,
          sequence: 2,
        });
      info?.lyceum3 &&
        lyceum_selections.push({
          lyceum: info?.lyceum3,
          sequence: 3,
        });

      const result = {
        school_certificate: {
          ...schoolInfo,
          country: schoolInfo?.country.id,
          education_language: schoolInfo?.education_language.id,
          foreign_language: schoolInfo?.foreign_language.id,
        },
        speciality: info?.speciality,
        education_language: info?.education_language,
        foreign_language: info?.foreign_language,
        region: info?.region,
        foreign_language_certificate: selectedForeignCert
          ? {
              ...selectedForeignCert,
              type: selectedForeignCert?.type?.id,
              degree: selectedForeignCert?.degree?.id,
              file: selectedForeignCert?.file || selectedForeignCert?.url,
              foreign_language: selectedForeignCert?.foreign_language?.id,
            }
          : null,
        science_certificate: selectedSubjectCert
          ? { ...selectedSubjectCert, science: selectedSubjectCert?.science?.id }
          : null,
        achievement_certificate: selectedCefrCert
          ? {
              ...selectedCefrCert,
              type: selectedCefrCert?.type?.id,
            }
          : null,
        lyceum_selections,
      };

      if (applicationID) {
        const { data } = await UpdateLyceumAppConfig(applicationID, result);
        toast.success("Ariza tahrirlandi !");
        navigate(`/service/admission/detail?id=${data?.id}&key=lyceum_admission`);
        localStorage.removeItem(LYCEUM_ADMISSION_DATA);
        localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
        localStorage.removeItem("appID");
        document.body.style.overflow = "auto";
      } else {
        const { data } = await CreateLyceumAppConfig(result);

        // Give error message
        if (data?.detail) {
          toast.error(giveError(data?.detail));
          navigate("/profile/applications");
          localStorage.removeItem(LYCEUM_ADMISSION_DATA);
          localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
        }
        if (data?.id) {
          setDrower(false);
          setAppID(data?.id);
          openModal();
        }
      }
    } else {
      toast.error("Sertifikatni tanlang !");
    }
  };

  // Submit with no certificate
  const createWithNOCertificate = async () => {
    const info = JSON.parse(localStorage.getItem(LYCEUM_ADMISSION_DATA) || "");

    // Lyceum selections
    const lyceum_selections = [];
    info?.lyceum1 &&
      lyceum_selections.push({
        lyceum: info?.lyceum1,
        sequence: 1,
      });
    info?.lyceum2 &&
      lyceum_selections.push({
        lyceum: info?.lyceum2,
        sequence: 2,
      });
    info?.lyceum3 &&
      lyceum_selections.push({
        lyceum: info?.lyceum3,
        sequence: 3,
      });

    const result = {
      school_certificate: {
        ...schoolInfo,
        country: schoolInfo?.country.id,
        education_language: schoolInfo?.education_language.id,
        foreign_language: schoolInfo?.foreign_language.id,
      },
      speciality: info?.speciality,
      education_language: info?.education_language,
      foreign_language: info?.foreign_language,
      region: info?.region,
      foreign_language_certificate: null,
      science_certificate: null,
      achievement_certificate: null,
      lyceum_selections,
    };

    if (applicationID) {
      const { data } = await UpdateLyceumAppConfig(applicationID, result);
      toast.success("Ariza tahrirlandi !");
      navigate(`/service/admission/detail?id=${data?.id}&key=lyceum_admission`);
      localStorage.removeItem(LYCEUM_ADMISSION_DATA);
      localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
      localStorage.removeItem("appID");
      document.body.style.overflow = "auto";
    } else {
      const { data } = await CreateLyceumAppConfig(result);

      // Give error message
      if (data?.detail) {
        toast.error(giveError(data?.detail));
        navigate("/profile/applications");
        localStorage.removeItem(LYCEUM_ADMISSION_DATA);
        localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
      }
      if (data?.id) {
        setAppID(data?.id);
        openModal();
      }
    }
  };

  const changeSwitch = () => {
    setChecked(!checked);
    // i18n.changeLanguage(language);
  };

  const handleChangeFile = (file: any) => {
    setValue2("file", file);
    clearErrors2("file");
  };

  const getFunc = async () => {
    if (checked) {
      !achievements && getAchievements?.();
    }
    if (localStorage.getItem(LYCEUM_ADMISSION_DATA)) {
      const { cefrs, foregn, subjects } = await getCertificates();

      const data = await JSON.parse(localStorage.getItem(LYCEUM_ADMISSION_DATA) || "");

      setSelectedCefrCert({
        id: cefrs?.data?.results?.find(
          (item: any) => item?.serial_number == data?.achievement_certificate,
        )?.id,
      });

      setSelectedForeignCert({
        id: foregn?.data?.results?.find(
          (item: any) => item?.serial_number == data?.foreign_language_certificate,
        )?.id,
      });

      setSelectedSubjectCert({
        id: subjects?.data?.results?.find(
          (item: any) => item?.serial_number == data?.science_certificate,
        )?.id,
      });
    }
  };

  const getSchoolInfo = async () => {
    const { data } = await GetSchoolInfo();
    const list = data?.results || data;
    setSchoolInfo(list[0]);
  };

  useEffect(() => {
    onSubmit();
  }, [langID]);

  useEffect(() => {
    getFunc();
    getSchoolInfo();
    i18n.changeLanguage(language);
  }, [checked]);

  return (
    <>
      <div className='application-box'>
        <div className='application-box-title'>
          {t("profile.common.awards")}
          <div className='right'>
            <CustomButton color='primary' outline icon={<ArrowRightSvg />} onClick={openDrower}>
              {t("profile.common.seeAll")}
            </CustomButton>
            <ToggleSwitch
              label={t("profile.common.awards")}
              checked={checked}
              onChange={changeSwitch}
              className={`${checked && "active"}`}
            />
          </div>
        </div>
        {checked ? (
          <>
            {/* TABS */}
            <div className='customTabs' style={{ marginBottom: 24 }}>
              <div className={`flex ${tab === "1" ? "active" : ""}`} onClick={() => setTab("1")}>
                {t("language.title")}
              </div>
              <div className={`flex ${tab === "2" ? "active" : ""}`} onClick={() => setTab("2")}>
                {t("profile.awards.prizes") || ""}
              </div>
            </div>

            {tab === "1" ? (
              <LanguageForm submitForm={submit} setLangID={setLangID} isStep />
            ) : (
              <>
                <form className='custom-form custom-form-sm'>
                  <div className='application-box-form' style={{ marginBottom: 8 }}>
                    <div className='application-box-subtitle application-accordion'>
                      {t("profile.awards.prizes") || ""}
                    </div>
                    <Controller
                      name='name'
                      control={control2}
                      render={({ field }) => (
                        <InputItem
                          label={t("profile.awards.prizes_name") || ""}
                          field={field}
                          error={errors.name ? true : false}
                          message={t("profile.awards.prizes_name") || ""}
                          placeholder={t("profile.awards.prizes_name_type") || ""}
                          className='half'
                        />
                      )}
                    />

                    <Controller
                      name='type'
                      control={control2}
                      render={({ field }) => (
                        <SelectItem
                          className='half'
                          label={t("profile.awards.prizes_type") || ""}
                          placeholder={t("profile.awards.prizes_type") || ""}
                          message={t("profile.awards.prizes_type") || ""}
                          field={field}
                          options={achievements}
                          error={errors.type ? true : false}
                        />
                      )}
                    />

                    <Controller
                      name='given_date'
                      control={control2}
                      render={({ field }) => (
                        <DatepickerItem
                          label={t("profile.awards.docstart_date") || ""}
                          field={field}
                          error={errors.given_date ? true : false}
                          message={t("profile.awards.docstart_date") || ""}
                          className='half'
                          onChange={(val) => setValue2("given_date", val)}
                        />
                      )}
                    />
                    <Controller
                      name='serial_number'
                      control={control2}
                      render={({ field }) => (
                        <InputItem
                          label={t("profile.awards.series") || ""}
                          placeholder='-----------------------'
                          error={errors.serial_number ? true : false}
                          field={field}
                          className='half'
                        />
                      )}
                    />

                    <Controller
                      name='file'
                      control={control2}
                      render={({ field }) => (
                        <InputUploadItem
                          className='half custom-input-upload'
                          label={t("profile.awards.file") || ""}
                          placeholder='Hech qanday fayl tanlanmagan'
                          message={"Faylni yuklang !"}
                          accept={pdfType}
                          field={field}
                          error={errors.file ? true : false}
                          onChange={handleChangeFile}
                        />
                      )}
                    />
                  </div>
                </form>
              </>
            )}
          </>
        ) : (
          <div className='application-unchecked'>
            <div className='application-unchecked-left'>
              <h3>{t("language.info_title")}:</h3>
              <ul>
                <li>{t("language.cer1")}</li>
                <li>{t("language.cer2")}</li>
                <li>{t("language.cer3")}</li>
                <li>{t("language.cer4")}</li>
              </ul>
              <p dangerouslySetInnerHTML={{ __html: t("language.info2") }} />
              <h2>{t("language.info3")}</h2>
              <p>
                {t("language.info4")} <span>{t("nav.apply")}</span> {t("language.info5")}
              </p>
            </div>
            <div className='application-unchecked-right'>
              <LongArrowSVG />
              <ShortNarrowSvg />
            </div>
          </div>
        )}
      </div>

      <div className='application-box-footer'>
        <Link to='/service/admission/lyceum/step3'>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton
          loading={loading}
          {...forwardButtonProps}
          onClick={() => {
            if (checked && tab === "1") {
              setSubmit(submit + 1);
            } else if (checked && tab === "2") {
              onSubmit();
            } else {
              createWithNOCertificate();
            }
          }}
        >
          {t("nav.apply") || ""}
        </CustomButton>
      </div>

      <CustomDrower
        title={t("profile.common.awards") || ""}
        okText={t("nav.apply") || ""}
        cancelText={t("photo.cancel") || ""}
        width={840}
        visible={drower}
        onCancel={closeDrower}
        onOk={() => createWithExactCertificate()}
        blured
      >
        <>
          <div className='customTabs'>
            <div
              className={`flex ${tabDrawer === "1" ? "active" : ""}`}
              onClick={() => setTabDrower("1")}
            >
              {t("language.title")}
            </div>
            <div
              className={`flex ${tabDrawer === "3" ? "active" : ""}`}
              onClick={() => setTabDrower("3")}
            >
              {t("language.science")}
            </div>
            <div
              className={`flex ${tabDrawer === "2" ? "active" : ""}`}
              onClick={() => setTabDrower("2")}
            >
              {t("profile.awards.prizes")}
            </div>
          </div>

          {tabDrawer === "1" ? (
            <>
              {foreginCer && foreginCer.length > 0 ? (
                foreginCer?.map((item) => (
                  <div
                    key={item?.id}
                    className={`application-certificate ${
                      selectedForeignCert?.id === item?.id && "application-certificate-active"
                    }`}
                  >
                    <div className='application-certificate-header'>
                      {t("language.title")}
                      <div
                        className='application-certificate-button'
                        onClick={() => setSelectedForeignCert(item)}
                      >
                        <div className='radio' /> {t("profile.awards.select")}
                      </div>
                    </div>
                    <InfoCard
                      title={t("profile.awards.english")}
                      info={giveLan(item?.foreign_language)}
                    />
                    <InfoCard title={t("profile.awards.type_cer")} info={giveLan(item?.type)} />
                    <InfoCard title={t("profile.awards.level")} info={giveLan(item?.degree)} />
                    <InfoCard title={t("profile.awards.series")} info={item?.serial_number} />
                    <InfoCard
                      title={t("profile.awards.docend_date")}
                      info={`${item?.given_date} dan  ${item?.expire_date} gacha`}
                    />
                  </div>
                ))
              ) : (
                <NoItemCard icon={<NoLanguageSVG />} title={t("language.noLang")} />
              )}
            </>
          ) : tabDrawer === "3" ? (
            <>
              {subjectCer && subjectCer?.length > 0 ? (
                subjectCer?.map((item) => (
                  <div
                    key={item?.id}
                    className={`application-certificate ${
                      selectedSubjectCert?.id === item?.id && "application-certificate-active"
                    }`}
                  >
                    <div className='application-certificate-header'>
                      {t("language.science")}
                      <div
                        className='application-certificate-button'
                        onClick={() => setSelectedSubjectCert(item)}
                      >
                        <div className='radio' /> {t("profile.awards.select")}
                      </div>
                    </div>
                    <InfoCard title='Fan nomi' info={giveLan(item?.science)} />
                    <InfoCard title='Ball' info={item?.ball} />
                    <InfoCard title={t("profile.awards.series")} info={item?.serial_number} />
                    <InfoCard
                      title={t("profile.awards.docend_date")}
                      info={`${item?.given_date} dan ${item?.expire_date}`}
                    />
                  </div>
                ))
              ) : (
                <NoItemCard icon={<NoInformationSVG />} title={t("language.noSubject")} />
              )}
            </>
          ) : tabDrawer === "2" ? (
            <>
              {cefrCer && cefrCer?.length > 0 ? (
                cefrCer?.map((item) => (
                  <div
                    key={item?.id}
                    className={`application-certificate ${
                      selectedCefrCert?.id === item?.id && "application-certificate-active"
                    }`}
                  >
                    <div className='application-certificate-header'>
                      {t("profile.awards.prizes")}
                      <div
                        className='application-certificate-button'
                        onClick={() => setSelectedCefrCert(item)}
                      >
                        <div className='radio' /> {t("profile.awards.select")}
                      </div>
                    </div>
                    <InfoCard title={t("profile.awards.prizes_name")} info={item?.name} />
                    <InfoCard title={t("profile.awards.type_cer")} info={giveLan(item?.type)} />
                    <InfoCard title={t("profile.awards.series")} info={item?.serial_number} />
                    <InfoCard
                      title={t("profile.awards.docend_date")}
                      info={`${item?.given_date}`}
                    />
                  </div>
                ))
              ) : (
                <NoItemCard icon={<NoInformationSVG />} title={t("language.noAward")} />
              )}
            </>
          ) : (
            <></>
          )}
        </>
      </CustomDrower>

      <ResultCard visible={visible} closeModal={closeModal} id={appID} />
    </>
  );
}

export default LyceumAdmissionStep4;
