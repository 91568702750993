import { Template, generate } from "@pdfme/generator";
import dayjs from "dayjs";
import { newFont, normal } from "src/assets/fonts";
import {
  INTERVIEW_ADMISSION_DATA,
  INTERVIEW_ADMISSION_TOKEN,
  LANGUAGE,
  LYCEUM_ADMISSION_DATA,
  LYCEUM_ADMISSION_TOKEN,
  ROLE,
  ROLE_U,
  SECONDARY_ADMISSION_DATA,
  SECONDARY_ADMISSION_TOKEN,
  SESSION_DATA,
  TOKEN,
  USERNAME,
  USER_DATA,
  USER_ID,
  VOCATION_ADMISSION_DATA,
  VOCATION_ADMISSION_TOKEN,
} from "src/static/const/auth";
import {
  BaseBlankPdf,
  BaseProfStudyPDF,
  BaseStudyDocumentPDF,
  MinistryLogoBase64,
  PhdBasePDF,
} from "src/static/data";
import { BaseObjTypes } from "src/static/interface";
import { OptionTypes } from "src/static/interface/common";
import { GiveLanNameTypeAttribute } from "./type";

export const clearMask = (text: string | number) => {
  return text
    ? text.toString().replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("_", "")
    : "";
};

export const getDateFormat = (value: string) => {
  let date = new Date(value);
  let arr = date.toLocaleDateString().split("/");
  let formated = arr[2] + "-" + arr[0] + "-" + arr[1];
  return formated;
};

export const findAge = (birth_date: string | undefined) => {
  if (birth_date) {
    let date = new Date();
    let current_year = date.getFullYear();
    let birth_year = +birth_date.slice(0, 4);
    return current_year - birth_year;
  }
  return "";
};

//Auth actions
export const handleLogoutFunc = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(ROLE);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(USERNAME);
  localStorage.removeItem(USER_DATA);
  localStorage.removeItem(SESSION_DATA);
  localStorage.removeItem(LYCEUM_ADMISSION_DATA);
  localStorage.removeItem(LYCEUM_ADMISSION_TOKEN);
  localStorage.removeItem(VOCATION_ADMISSION_DATA);
  localStorage.removeItem(VOCATION_ADMISSION_TOKEN);
  localStorage.removeItem(INTERVIEW_ADMISSION_DATA);
  localStorage.removeItem(INTERVIEW_ADMISSION_TOKEN);
  localStorage.removeItem(SECONDARY_ADMISSION_DATA);
  localStorage.removeItem(SECONDARY_ADMISSION_TOKEN);
  localStorage.removeItem("appID");
  localStorage.removeItem("lyceumAccepted");
  localStorage.removeItem("interviewEdit");
  window.location.href = "/?modal=open&key=main";
};

export const handleLoginFunc = (data: any) => {
  localStorage.setItem(TOKEN, data.access_key);
  localStorage.setItem(ROLE, ROLE_U);
  localStorage.setItem(USER_DATA, JSON.stringify(data.user));

  const date = new Date();
  let obj = {
    agent: data.agent,
    authorization_type: data.authorization_type,
    ip: data.ip,
    added_time: date.toLocaleString(),
  };
  localStorage.setItem(SESSION_DATA, JSON.stringify(obj));
};

// Get cookie info
export function getCookie(name: string) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

//Make data
export const makeOptionItem = (list: BaseObjTypes[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item: BaseObjTypes) => {
    arr.push({
      value: item.id,
      code: item.code,
      name_uz: item.name_uz,
      name_ru: item.name_ru,
      name_en: item.name_en,
    });
  });
  return arr;
};

//Lamguage fundtions
export const giveLan = (data: any, name?: GiveLanNameTypeAttribute) => {
  if (data) {
    const language = localStorage.getItem(LANGUAGE) || "uz";
    if (name === "title") {
      return language === "uz" ? data.title_uz : language === "ru" ? data.title_ru : data.title_en;
    } else if (name === "description") {
      return language === "uz"
        ? data.description_uz
        : language === "ru"
        ? data.description_ru
        : data.description_en;
    } else if (name === "users") {
      return language === "uz" ? data.users_uz : language === "ru" ? data.users_ru : data.users_en;
    } else if (name === "legal_basis") {
      return language === "uz"
        ? data.legal_basis_uz
        : language === "ru"
        ? data.legal_basis_ru
        : data.legal_basis_en;
    } else if (name === "caption") {
      return language === "uz"
        ? data.caption_uz
        : language === "ru"
        ? data.caption_ru
        : data.caption_en;
    } else if (name === "necessary_documents") {
      return language === "uz"
        ? data.necessary_documents_uz
        : language === "ru"
        ? data.necessary_documents_ru
        : data.necessary_documents_en;
    } else if (name === "video_url") {
      return language === "uz"
        ? data.video_url_uz
        : language === "ru"
        ? data.video_url_ru
        : data.video_url_en;
    } else if (name === "static") {
      return data.name_uz;
    } else {
      return language === "uz" ? data.name_uz : language === "ru" ? data.name_ru : data.name_en;
    }
  }
};

// Krilldan lotinga otkazish
export const transliterate = (text: string) => {
  text = text
    .replace(/\u0401/g, "YO")
    .replace(/\u0419/g, "I")
    .replace(/\u0426/g, "TS")
    .replace(/\u0423/g, "U")
    .replace(/\u041A/g, "K")
    .replace(/\u0415/g, "E")
    .replace(/\u041D/g, "N")
    .replace(/\u0413/g, "G")
    .replace(/\u0428/g, "SH")
    .replace(/\u0429/g, "SCH")
    .replace(/\u0417/g, "Z")
    .replace(/\u0425/g, "X")
    .replace(/\u042A/g, "")
    .replace(/\u0451/g, "yo")
    .replace(/\u0439/g, "i")
    .replace(/\u0446/g, "ts")
    .replace(/\u0443/g, "u")
    .replace(/\u043A/g, "k")
    .replace(/\u0435/g, "e")
    .replace(/\u043D/g, "n")
    .replace(/\u0433/g, "g")
    .replace(/\u0448/g, "sh")
    .replace(/\u0449/g, "sch")
    .replace(/\u0437/g, "z")
    .replace(/\u0445/g, "x")
    .replace(/\u044A/g, "'")
    .replace(/\u0424/g, "F")
    .replace(/\u042B/g, "I")
    .replace(/\u0412/g, "V")
    .replace(/\u0410/g, "a")
    .replace(/\u041F/g, "P")
    .replace(/\u0420/g, "R")
    .replace(/\u041E/g, "O")
    .replace(/\u041B/g, "L")
    .replace(/\u0414/g, "D")
    .replace(/\u0416/g, "ZH")
    .replace(/\u042D/g, "E")
    .replace(/\u0444/g, "f")
    .replace(/\u044B/g, "i")
    .replace(/\u0432/g, "v")
    .replace(/\u0430/g, "a")
    .replace(/\u043F/g, "p")
    .replace(/\u0440/g, "r")
    .replace(/\u043E/g, "o")
    .replace(/\u043B/g, "l")
    .replace(/\u0434/g, "d")
    .replace(/\u0436/g, "zh")
    .replace(/\u044D/g, "e")
    .replace(/\u042F/g, "Ya")
    .replace(/\u0427/g, "CH")
    .replace(/\u0421/g, "S")
    .replace(/\u041C/g, "M")
    .replace(/\u0418/g, "I")
    .replace(/\u0422/g, "T")
    .replace(/\u042C/g, "'")
    .replace(/\u0411/g, "B")
    .replace(/\u042E/g, "YU")
    .replace(/\u044F/g, "ya")
    .replace(/\u0447/g, "ch")
    .replace(/\u0441/g, "s")
    .replace(/\u043C/g, "m")
    .replace(/\u0438/g, "i")
    .replace(/\u0442/g, "t")
    .replace(/\u044C/g, "'")
    .replace(/\u0431/g, "b")
    .replace(/\u044E/g, "yu");

  return text.charAt(0).toUpperCase() + text.slice(1);
};

// Oqishni bitirgan sertifikat, Malak oshirish
export const BimmPDF = async (data?: any, appID?: number) => {
  const font = {
    normal: {
      data: await fetch(normal).then((res) => res.arrayBuffer()),
      fallback: true,
    },
    // bold: {
    //   data: await fetch(bold).then((res) => res.arrayBuffer()),
    // },
  };
  const template: Template = {
    schemas: [
      {
        field1: {
          type: "image",
          position: { x: 20, y: 15.35 },
          width: 27.8,
          height: 27.8,
        },
        title: {
          type: "text",
          position: { x: 51.14, y: 29.13 },
          width: 113.58,
          height: 14.14,
          alignment: "center",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        field3: {
          type: "text",
          position: { x: 20, y: 55.04 },
          width: 35,
          height: 3.82,
          alignment: "left",
          fontSize: 9,
          characterSpacing: 0,
          lineHeight: 1,
        },
        sana: {
          type: "text",
          position: { x: 57.78, y: 55.04 },
          width: 35,
          height: 3.82,
          alignment: "left",
          fontSize: 9,
          characterSpacing: 0,
          lineHeight: 1,

          fontColor: "#ff0505",
        },
        "field3 copy": {
          type: "text",
          position: { x: 20, y: 60.66 },
          width: 19.92,
          height: 4.09,
          alignment: "left",
          fontSize: 9,
          characterSpacing: 0,
          lineHeight: 1,
        },
        id: {
          type: "text",
          position: { x: 41.78, y: 60.66 },
          width: 104.85,
          height: 3.82,
          alignment: "left",
          fontSize: 9,
          characterSpacing: 0,
          lineHeight: 1,

          fontColor: "#ff0505",
        },
        "title copy": {
          type: "text",
          position: { x: 51.14, y: 72.7 },
          width: 113.58,
          height: 11.23,
          alignment: "center",
          fontSize: 13,
          characterSpacing: 0.5,
          lineHeight: 1.2,
        },
        field8: {
          type: "text",
          position: { x: 20, y: 98.42 },
          width: 41.6,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        fullname: {
          type: "text",
          position: { x: 63.45, y: 98.42 },
          width: 128.67,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 17": {
          type: "text",
          position: { x: 20, y: 106.42 },
          width: 17,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        pinfl: {
          type: "text",
          position: { x: 40.44, y: 106.42 },
          width: 151.68,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 19": {
          type: "text",
          position: { x: 20, y: 114.42 },
          width: 28.11,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        cert_type: {
          type: "text",
          position: { x: 51.29, y: 114.42 },
          width: 140.83,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 21": {
          type: "text",
          position: { x: 20, y: 122.42 },
          width: 26.79,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        cert_number: {
          type: "text",
          position: { x: 50.3, y: 122.42 },
          width: 140.82,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 23": {
          type: "text",
          position: { x: 20, y: 130.42 },
          width: 25.73,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        cert_date: {
          type: "text",
          position: { x: 48.55, y: 130.42 },
          width: 140.57,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 25": {
          type: "text",
          position: { x: 20, y: 138.42 },
          width: 173.89,
          height: 11.48,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1.3,
        },
        "field8 copy 27": {
          type: "text",
          position: { x: 20, y: 152.42 },
          width: 174.17,
          height: 11.22,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1.3,
        },
        "field8 copy 29": {
          type: "text",
          position: { x: 20, y: 166.42 },
          width: 11.18,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        duration: {
          type: "text",
          position: { x: 33.56, y: 166.42 },
          width: 158.56,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 31": {
          type: "text",
          position: { x: 20, y: 174.42 },
          width: 173.9,
          height: 11.22,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1.3,
        },
        "field8 copy 33": {
          type: "text",
          position: { x: 20, y: 188.42 },
          width: 43.46,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        date_of_training: {
          type: "text",
          position: { x: 65.84, y: 188.42 },
          width: 126.28,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 35": {
          type: "text",
          position: { x: 20, y: 196.42 },
          width: 25.2,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        result: {
          type: "text",
          position: { x: 47.71, y: 196.42 },
          width: 144,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 37": {
          type: "text",
          position: { x: 20, y: 204.42 },
          width: 56.95,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        ded_line: {
          type: "text",
          position: { x: 79.48, y: 204.42 },
          width: 27.85,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
        },
        "field8 copy 39": {
          type: "text",
          position: { x: 110.91, y: 204.63 },
          width: 82.09,
          height: 4.88,
          alignment: "left",
          fontSize: 12,
          characterSpacing: 0,
          lineHeight: 1,
          fontColor: "#ff3838",
        },
        field33: {
          type: "qrcode",
          position: { x: 166.75, y: 221.43 },
          width: 26.27,
          height: 26.27,
        },
      },
    ],
    basePdf: BaseBlankPdf,
  };
  const inputs = [
    {
      field1: MinistryLogoBase64,
      title: "O‘zbekiston Respublikasi Oliy ta’lim, fan va innovatsiyalar vazirligi",
      field3: "Hujjat yaratilingan sana:",
      sana: new Date().toLocaleDateString(),
      "field3 copy": "Ariza raqami:",
      id: String(appID),
      "title copy": "MALAKA OSHIRGANLIGI HAQIDA\nMA’LUMOT",
      field8: "Familiya, Ismi, Sharifi:",
      fullname: data?.fullname,
      "field8 copy 17": "JShShIR:",
      pinfl: data?.pinfl,
      "field8 copy 19": "Hujjat seriyasi:",
      cert_type: data?.cert_type,
      "field8 copy 21": "Hujjat raqami:",
      cert_number: data?.cert_number,
      "field8 copy 23": "Hujjat sanasi:",
      cert_date: data?.cert_date,
      "field8 copy 25": "Malaka oshirish muassasasi: " + data?.org,
      "field8 copy 27": "Yo‘nalish: " + data?.direction,
      "field8 copy 29": "Soati:",
      duration: String(data?.duration),
      "field8 copy 31": "Mavzusi: " + transliterate(data?.theme),
      "field8 copy 33": "Malaka oshirgan vaqti:",
      date_of_training: data?.date_of_training,
      "field8 copy 35": "Kurs natijasi:",
      result: data?.result,
      "field8 copy 37": "Hujjatning haqiqiylik muddati:",
      ded_line: data?.ded_line,
      "field8 copy 39": "(malaka oshirgandan boshlab + 3 yil - 1 kun)",
      field33: "https://qr.edu.uz/bimm/qc/" + data?.hash_string,
    },
  ];

  const pdf = await generate({ template, inputs, options: { font } });

  // Browser
  const blob = new Blob([pdf.buffer], { type: "application/pdf" });

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = URL.createObjectURL(blob);
  link.download = "Ma'lumotnoma.pdf";

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);

  // window.open(URL.createObjectURL(blob));
};

// Oqishni bitirgan sertifikat, Universitet
export const StudyDocumentPDF = async (data?: any, appID?: number, date = new Date()) => {
  const font = {
    normal: {
      data: await fetch(newFont).then((res) => res.arrayBuffer()),
      fallback: true,
    },
  };
  const template: Template = {
    schemas: [
      {
        fullname: {
          type: "text",
          position: { x: 103.05, y: 102.47 },
          width: 99.2,
          height: 6.55,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        pinfl: {
          type: "text",
          position: { x: 103.05, y: 111.15 },
          width: 99.2,
          height: 6.55,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        birthday: {
          type: "text",
          position: { x: 103.05, y: 119.83 },
          width: 99.2,
          height: 6.55,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        citizenship_name: {
          type: "text",
          position: { x: 103.05, y: 127.18 },
          width: 99.2,
          height: 7.87,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        education_type_name: {
          type: "text",
          position: { x: 103.05, y: 136.66 },
          width: 99.2,
          height: 7.08,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        education_form_name: {
          type: "text",
          position: { x: 103.05, y: 145.34 },
          width: 99.2,
          height: 7.08,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        payment_type_name: {
          type: "text",
          position: { x: 103.05, y: 154.01 },
          width: 99.2,
          height: 6.55,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        education_year: {
          type: "text",
          position: { x: 103.05, y: 162.42 },
          width: 99.2,
          height: 7.34,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        speciality_name: {
          type: "text",
          position: { x: 103.05, y: 187.18 },
          width: 99.2,
          height: 12.1,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        university_name: {
          type: "text",
          position: { x: 103.05, y: 171.51 },
          width: 99.2,
          height: 13.69,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        course: {
          type: "text",
          position: { x: 103, y: 200.89 },
          width: 99.2,
          height: 6.55,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        hujjat_id: {
          type: "text",
          position: { x: 15, y: 62 },
          width: 61.92,
          height: 12.09,
          rotate: 0,
          alignment: "left",

          fontSize: 8,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        hujjat_pinfl: {
          type: "text",
          position: { x: 118.59, y: 62 },
          width: 83.34,
          height: 12.09,
          rotate: 0,
          alignment: "right",

          fontSize: 8,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        qr: {
          type: "qrcode",
          position: { x: 171.2, y: 223.39 },
          width: 22.85,
          height: 22.85,
          rotate: 0,
        },
      },
    ],
    basePdf: BaseStudyDocumentPDF,
  };
  const inputs = [
    {
      ...data,
      birthday: String(data?.birthday)?.slice(0, 10),
      fullname: `${data?.lastname} ${data?.firstname} ${data?.fathername}`,
      hujjat_pinfl: `Hujjat berilgan: ${data?.lastname} ${data?.firstname} ${data?.fathername}\nJShShIR: ${data?.pinfl}`,
      hujjat_id: `Hujjat yaratilingan sana: ${dayjs(date).format(
        `YYYY-MM-DD, HH:mm:ss`,
      )}\nAriza raqami: ${appID}`,
      qr: "https://qr.edu.uz/myeduuz/sc/" + data?.hash_string,
    },
  ];

  const pdf = await generate({ template, inputs, options: { font } });

  // Browser
  const blob = new Blob([pdf.buffer], { type: "application/pdf" });

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = URL.createObjectURL(blob);
  link.download = "Ma'lumotnoma.pdf";

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);

  // window.open(URL.createObjectURL(blob));
};

// Oqishni bitirgan sertifikat, Akademik litsey
export const ProfStudyDocumentPDF = async (data?: any, appID?: number, date = new Date()) => {
  const font = {
    normal: {
      data: await fetch(newFont).then((res) => res.arrayBuffer()),
      fallback: true,
    },
  };
  const template: Template = {
    schemas: [
      {
        citizen_full_name: {
          type: "text",
          position: { x: 103.44, y: 102.15 },
          width: 98.93,
          height: 6.55,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        citizen_birthday: {
          type: "text",
          position: { x: 103.44, y: 111.51 },
          width: 98.93,
          height: 6.55,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        citizen_citizenship: {
          type: "text",
          position: { x: 103.44, y: 119.39 },
          width: 98.93,
          height: 7.87,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        edu_type: {
          type: "text",
          position: { x: 103.44, y: 128.81 },
          width: 98.93,
          height: 7.08,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        edu_form: {
          type: "text",
          position: { x: 103.44, y: 136.55 },
          width: 98.93,
          height: 7.08,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        edu_payment_type: {
          type: "text",
          position: { x: 103.44, y: 145.69 },
          width: 98.93,
          height: 7.08,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        edu_starting_year: {
          type: "text",
          position: { x: 103.44, y: 154.1 },
          width: 98.93,
          height: 7.34,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        edu_speciality: {
          type: "text",
          position: { x: 103.44, y: 178.33 },
          width: 98.93,
          height: 12.63,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.4,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        edu_institution_name: {
          type: "text",
          position: { x: 103.44, y: 163.19 },
          width: 98.93,
          height: 13.69,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.4,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        edu_study_level: {
          type: "text",
          position: { x: 103.39, y: 192.51 },
          width: 98.93,
          height: 6.55,
          rotate: 0,
          alignment: "left",
          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        hujjat_id: {
          type: "text",
          position: { x: 15, y: 62 },
          width: 61.92,
          height: 12.09,
          rotate: 0,
          alignment: "left",
          fontSize: 8,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        hujjat_pinfl: {
          type: "text",
          position: { x: 118.59, y: 62 },
          width: 83.34,
          height: 12.09,
          rotate: 0,
          alignment: "right",
          fontSize: 8,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        qr: {
          type: "qrcode",
          position: { x: 171.2, y: 223.39 },
          width: 22.85,
          height: 22.85,
          rotate: 0,
        },
      },
    ],
    basePdf: BaseProfStudyPDF,
  };
  const inputs = [
    {
      ...data,
      edu_study_level: String(data?.edu_study_level),
      edu_starting_year: String(data?.edu_starting_year),
      hujjat_pinfl: `Hujjat berilgan: ${data?.citizen_full_name}`,
      hujjat_id: `Hujjat yaratilingan sana: ${dayjs(date).format(
        `YYYY-MM-DD, HH:mm:ss`,
      )}\nAriza raqami: ${appID}`,
      qr: "https://qr.edu.uz/myeduuz/psc/" + data?.hash_string,
    },
  ];

  const pdf = await generate({ template, inputs, options: { font } });

  // Browser
  const blob = new Blob([pdf.buffer], { type: "application/pdf" });

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = URL.createObjectURL(blob);
  link.download = "Ma'lumotnoma.pdf";

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);

  // window.open(URL.createObjectURL(blob));
};

// Oqishni bitirgan sertifikat, Universitet
export const PhdDocumentPDF = async (data?: any, appID?: number, date = new Date()) => {
  const font = {
    normal: {
      data: await fetch(newFont).then((res) => res.arrayBuffer()),
      fallback: true,
    },
  };
  const template: Template = {
    schemas: [
      {
        fullname: {
          type: "text",
          position: { x: 104, y: 108.5 },
          width: 98,
          height: 6.55,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        birthday: {
          type: "text",
          position: { x: 104, y: 125.33 },
          width: 98,
          height: 7.61,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        work_place: {
          type: "text",
          position: { x: 104, y: 134.21 },
          width: 98,
          height: 13.16,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.4,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        hujjat_id: {
          type: "text",
          position: { x: 15, y: 62 },
          width: 61.92,
          height: 12.09,
          rotate: 0,
          alignment: "left",

          fontSize: 8,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        hujjat_pinfl: {
          type: "text",
          position: { x: 118.59, y: 62 },
          width: 83.34,
          height: 12.09,
          rotate: 0,
          alignment: "right",

          fontSize: 8,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        qr: {
          type: "qrcode",
          position: { x: 171.2, y: 223.39 },
          width: 22.85,
          height: 22.85,
          rotate: 0,
        },
        pinfl: {
          type: "text",
          position: { x: 104, y: 117.07 },
          width: 98,
          height: 6.81,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.3,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        position: {
          type: "text",
          position: { x: 104, y: 148.81 },
          width: 98,
          height: 7.32,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.4,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        code_and_name: {
          type: "text",
          position: { x: 104, y: 157.18 },
          width: 98,
          height: 13.42,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.4,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        science_sector: {
          type: "text",
          position: { x: 104, y: 172.2 },
          width: 98,
          height: 12.88,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.4,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        title: {
          type: "text",
          position: { x: 104, y: 186.7 },
          width: 98,
          height: 7.32,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.4,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
        confirmed_date: {
          type: "text",
          position: { x: 104, y: 195.7 },
          width: 98,
          height: 7.32,
          rotate: 0,
          alignment: "left",

          fontSize: 10,
          lineHeight: 1.4,
          characterSpacing: 0,
          fontColor: "#000000",
          backgroundColor: "",
        },
      },
    ],
    basePdf: PhdBasePDF,
  };
  const inputs = [
    {
      work_place: data?.title_details?.work_place,
      code_and_name: data?.title_details?.code_and_name,
      science_sector: data?.title_details?.science_sector,
      title: data?.title_details?.title,
      position: data?.title_details?.position,
      confirmed_date: data?.title_details?.confirmed_date,
      pinfl: data?.pin,
      birthday: String(data?.birth_date)?.slice(0, 10),
      fullname: `${data?.s_name} ${data?.f_name} ${data?.m_name}`,
      hujjat_pinfl: `Hujjat berilgan: ${data?.s_name} ${data?.f_name} ${data?.m_name}\nJShShIR: ${data?.pin}`,
      hujjat_id: `Hujjat yaratilingan sana: ${dayjs(date).format(
        `YYYY-MM-DD, HH:mm:ss`,
      )}\nAriza raqami: ${appID}`,
      qr: "https://qr.edu.uz/myeduuz/adc/" + data?.hash_string,
    },
  ];

  const pdf = await generate({ template, inputs, options: { font } });

  // Browser
  const blob = new Blob([pdf.buffer], { type: "application/pdf" });

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = URL.createObjectURL(blob);
  link.download = "Ma'lumotnoma.pdf";

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);

  // window.open(URL.createObjectURL(blob));
};
