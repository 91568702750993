import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/common/button";
import SelectItem from "src/components/form/select";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InterviewTechnikumForm } from "../type";
import {
  EditAppConfig,
  GetDiplomConfig,
  GetDirectionConfig,
  GetInterviewApplicationConfig,
  GetUniverConfig,
  SubmitConfig,
} from "../config";
import toast from "react-hot-toast";
import ResultCard from "src/components/cards/ResultCard";
import { giveError } from "src/service/host";
import CustomAlert from "src/components/common/alert";

function InterviewAdmissionStep3() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [appID, setAppID] = useState("");
  const [diplomInfo, setDiplomInfo] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [allUnivers, setAllUnivers] = useState<any[]>();
  const [allEdus, setAllEdus] = useState<any[]>();
  const [univers, setUnivers] = useState<any[]>();
  const [directions, setDirections] = useState<any[]>();
  const [languages, setLanguages] = useState<any[]>();
  const [types, setTypes] = useState<any[]>();

  const schema = yup
    .object({
      university: yup.string().required(),
      university_direction: yup.string().required(),
      education_language: yup.string().required(),
      education_type: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<InterviewTechnikumForm>({
    resolver: yupResolver(schema),
  });

  const GetDiplom = async () => {
    const { data } = await GetDiplomConfig();
    setDiplomInfo(data);
    if (!data?.can_apply) {
      navigate("/service?key=all");
    }
  };
  const makeOptions = (list: any[]) => {
    return list?.reduce(
      (all: any[], cur: any) => [...all, { value: String(cur?.id), name_uz: cur?.name }],
      [],
    );
  };
  const getUnivers = async () => {
    if (localStorage.getItem("interviewEdit")) {
      backValuesSet();
    } else {
      const { data } = await GetUniverConfig();
      setAllUnivers(data);
      setUnivers(makeOptions(data));
    }
  };
  const openModal = () => setVisible(true);
  const closeModal = () => {
    setVisible(false);
    navigate(`/service/admission/detail?id=${appID}&key=interview_admission`);
    localStorage.removeItem("interviewEdit");
    document.body.style.overflow = "auto";
  };

  // --------------
  const handleUniver = (val: string) => {
    setDirections(
      makeOptions(allUnivers?.find((univer: any) => univer?.id === Number(val))?.directions),
    );
    setValue("university", val);
    clearErrors("university");

    // ------------------------------------
    setValue("university_direction", "");
    setValue("education_language", "");
    setValue("education_type", "");
  };
  const handleDirection = async (val: string) => {
    const { data } = await GetDirectionConfig(val);
    setAllEdus(data);
    setLanguages(makeOptions(data));

    setValue("university_direction", val);
    clearErrors("university_direction");

    // ------------------------------------
    setValue("education_language", "");
    setValue("education_type", "");
  };
  const handleLanguage = (val: string) => {
    setTypes(makeOptions(allEdus?.find((edu: any) => edu?.id === Number(val))?.types));
    setValue("education_language", val);
    clearErrors("education_language");

    // ------------------------------------
    setValue("education_type", "");
  };

  // ---------------------------
  const onSubmit = async (data: any) => {
    if (localStorage.getItem("interviewEdit")) {
      const res = await EditAppConfig(data);
      toast.success("Ariza tahrirlandi !");
      navigate(`/service/admission/detail?id=${res?.data?.id}&key=interview_admission`);
    } else {
      const res = await SubmitConfig(data);

      // clear form
      setValue("education_language", "");
      setValue("education_type", "");

      // Give error message
      if (res.data?.detail) {
        toast.error(giveError(res.data?.detail));
      }
      if (res.data?.id) {
        setAppID(res.data?.id);
        openModal();
      }
    }

    localStorage.removeItem("appID");
    localStorage.removeItem("interviewEdit");
  };
  const backValuesSet = async () => {
    // Parse data from localstorage
    const { data } = await GetInterviewApplicationConfig();

    // Get universities
    GetUniverConfig().then((res) => {
      setAllUnivers(res.data);
      setUnivers(makeOptions(res.data));

      // Set directions
      setDirections(
        makeOptions(
          res.data?.find((univer: any) => univer?.id === Number(data?.university))?.directions,
        ),
      );

      // Get langauges and set
      GetDirectionConfig(data?.university_direction).then((res) => {
        setLanguages(makeOptions(res.data));
        setTypes(
          makeOptions(
            res.data?.find((edu: any) => edu?.id === Number(data?.education_language))?.types,
          ),
        );
      });
    });

    // ------------------------------------
    setValue("university", data?.university);
    setValue("university_direction", data?.university_direction);
    setValue("education_language", data?.education_language);
    setValue("education_type", data?.education_type);
  };

  useEffect(() => {
    GetDiplom();
    getUnivers();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='application-box'>
        <div className='application-box-title'>{t("profile.common.choices")}</div>
        {allUnivers && allUnivers?.length > 0 ? (
          <div className='application-box-form'>
            <Controller
              name='university'
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("interview.otm_name") || ""}
                  placeholder={t("interview.otm_name") || ""}
                  field={field}
                  error={errors.university && true}
                  options={univers}
                  className='full'
                  onChange={handleUniver}
                />
              )}
            />
            <Controller
              name='university_direction'
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("profile.edu.dir_name") || ""}
                  placeholder={t("profile.edu.dir_name") || ""}
                  field={field}
                  error={errors.university_direction && true}
                  options={directions}
                  className='full'
                  disabled={!watch("university") || directions?.length == 0}
                  onChange={handleDirection}
                />
              )}
            />

            <Controller
              name='education_language'
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("profile.choices.edu_lang") || ""}
                  placeholder={t("profile.choices.edu_lang") || ""}
                  field={field}
                  className='half'
                  options={languages}
                  error={errors.education_language && true}
                  disabled={!watch("university_direction") || languages?.length == 0}
                  onChange={handleLanguage}
                />
              )}
            />
            <Controller
              name='education_type'
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("profile.edu.edu_form") || ""}
                  placeholder={t("profile.edu.edu_form") || ""}
                  field={field}
                  className='half'
                  options={types}
                  error={errors.education_type && true}
                  disabled={!watch("education_language") || types?.length == 0}
                />
              )}
            />
          </div>
        ) : (
          <CustomAlert
            type='warning'
            info='Texnikum mutaxassisligiga mos bakalavr yo’nalishi tasdiqlanmagan.'
          />
        )}
      </div>

      <div className='application-box-footer'>
        <Link to='/service/admission/interview_admission/step3'>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps} disabled={!diplomInfo?.can_apply}>
          {t("profile.common.continue") || ""}
        </CustomButton>
      </div>

      <ResultCard visible={visible} closeModal={closeModal} id={appID} />
    </form>
  );
}

export default InterviewAdmissionStep3;
