import { ArrowBackSvg, ArrowForwardSvg } from "src/assets/svg";
import { CustomButtonProps } from "src/static/interface/common";

export const backButtonProps: CustomButtonProps = {
  size: "xl",
  color: "gray",
  icon: <ArrowBackSvg />,
  left_icon: true,
};

export const forwardButtonProps: CustomButtonProps = {
  size: "xl",
  color: "primary",
  icon: <ArrowForwardSvg />,
  type: "submit",
};
