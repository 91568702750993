import React from "react";
import { useTranslation } from "react-i18next";
import { EducationTypes } from "src/static/interface/profile";
import { giveLan } from "src/utils";

function EducationCard({ data }: { data: EducationTypes }) {
  const { t } = useTranslation();

  return (
    <div className="diplomCard">
      <div className="diplomCard__header">
        <h2>{t("profile.edu.title")}</h2>
      </div>
      <div className="diplomCard__body">
        <div className="flex">
          <h2>{t("profile.edu.edu_name")} :</h2>
          <p></p>
          <h1>{giveLan(data.institution)}</h1>
        </div>
        {/* <div className="flex">
          <h2>{t("profile.edu.edu_type")} :</h2>
          <p></p>
          <h1>Madaniyat vazirligi ixtisoslashtirilgan maktab-internati</h1>
        </div> */}
        <div className="flex">
          <h2>{t("profile.edu.dir_name")} :</h2>
          <p></p>
          <h1>{giveLan(data.speciality)}</h1>
        </div>
        <div className="flex">
          <h2>{t("profile.edu.edu_form")} :</h2>
          <p></p>
          <h1>{giveLan(data.form)}</h1>
        </div>
        <div className="flex">
          <h2>{t("profile.choices.edu_lang")} :</h2>
          <p></p>
          <h1>{giveLan(data.language)}</h1>
        </div>
        <div className="flex">
          <h2>{t("profile.edu.edu_step")} :</h2>
          <p></p>
          <h1>{giveLan(data.course)}</h1>
        </div>
      </div>
    </div>
  );
}

export default EducationCard;
