import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDrower from "src/components/common/drower";
import SelectItem from "src/components/form/select";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "src/hooks/context";
import { EditUserConfig } from "src/service/api/profile";
import { UserPersonalTypes } from "src/static/interface/profile";
import {
  profile_info_edit_schema,
  profile_info_edit_schema2,
} from "src/static/const/schema";
import { ProfileInfoEditProps } from "../types";
import InputItem from "src/components/form/input";
import { USER_DATA } from "src/static/const/auth";
import { useTranslation } from "react-i18next";

function ProfileInfoEdit({ drower, setDrower }: ProfileInfoEditProps) {
  const {
    userData,
    regions,
    districts,
    citizenships,
    nationalities,
    setUserData,
    getRegions,
    getDistricts,
    getCitizenships,
    getNationalities,
  } = useContext(MainContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<UserPersonalTypes>({
    resolver: yupResolver(
      userData?.passport ? profile_info_edit_schema : profile_info_edit_schema2
    ),
  });

  const onSubmit = (data: UserPersonalTypes) => {
    const { region, ...rest } = data;
    setLoading(true);
    EditUserConfig(rest)
      .then((res) => {
        setUserData?.(res.data);
        localStorage.setItem(USER_DATA, JSON.stringify(res.data));
        setDrower(false);
        //Ma'lumotlar muvaffaqiyatli tahrirlandi
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const onCancel = () => {
    setDrower(false);
  };

  const getFunc = () => {
    if (drower) {
      !citizenships && getCitizenships?.();
      !nationalities && getNationalities?.();
      !regions && getRegions?.();
      userData?.district?.region &&
        getDistricts?.(userData?.district?.region.id);
    }
  };

  useEffect(() => {
    getFunc();
  }, [drower]);

  const setDefaults = () => {
    if (userData) {
      const { citizenship, nationality, district, address, passport } =
        userData;
      if (!passport) {
        citizenship && setValue("citizenship", citizenship.id);
        nationality && setValue("nationality", nationality.id);
      }
      district && setValue("district", district.id);
      district && setValue("region", district.region.id);
      address && setValue("address", address);
    }
  };

  useEffect(() => {
    setDefaults();
  }, [userData]);

  //Select change functions
  const handleChangeRegion = (val: string) => {
    getDistricts?.(val);
    setValue("region", val);
    clearErrors("region");
  };

  return (
    <CustomDrower
      blured
      visible={drower}
      okBtnLoading={loading}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t("profile.cabinet.edit_info") || ""}
      okText={t("photo.save") || ""}
      cancelText={t("photo.cancel") || ""}
    >
      <form
        className="custom-form custom-form-sm"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!userData?.passport && (
          <>
            <Controller
              name="citizenship"
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("profile.common.citizenship") || ""}
                  field={field}
                  options={citizenships}
                  error={errors.citizenship ? true : false}
                  message={t("profile.common.citizenship") || ""}
                  placeholder={t("profile.common.citizenship") || ""}
                  size="lg"
                />
              )}
            />
            <Controller
              name="nationality"
              control={control}
              render={({ field }) => (
                <SelectItem
                  label={t("profile.common.nationality") || ""}
                  field={field}
                  options={nationalities}
                  error={errors.nationality ? true : false}
                  message={t("profile.common.nationality") || ""}
                  placeholder={t("profile.common.nationality") || ""}
                  size="lg"
                />
              )}
            />
          </>
        )}

        <Controller
          name="region"
          control={control}
          render={({ field }) => (
            <SelectItem
              label={t("profile.common.adress_region") || ""}
              field={field}
              options={regions}
              error={errors.region ? true : false}
              message={t("profile.common.select_region") || ""}
              placeholder={t("profile.common.select_region") || ""}
              size="lg"
              onChange={handleChangeRegion}
            />
          )}
        />

        <Controller
          name="district"
          control={control}
          render={({ field }) => (
            <SelectItem
              label={t("profile.common.adress_district") || ""}
              field={field}
              options={districts}
              error={errors.district ? true : false}
              message={t("profile.common.select_district") || ""}
              placeholder={t("profile.common.select_district") || ""}
              size="lg"
            />
          )}
        />

        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <InputItem
              label={t("profile.common.adress") || ""}
              field={field}
              error={errors.address ? true : false}
              message={t("profile.common.adress") || ""}
              placeholder={t("profile.common.adress") || ""}
              size="lg"
            />
          )}
        />
      </form>
    </CustomDrower>
  );
}

export default ProfileInfoEdit;
