import { Modal } from "flowbite-react";
import { ArrowBackSvg } from "src/assets/svg";
import { Controller, useForm } from "react-hook-form";
import { LoginMainTypes, LoginResetProps } from "src/static/interface/login";
import { useEffect, useState } from "react";
import { SendSmsConfig } from "src/service/api/auth";

import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

function LoginReset({ setStep, informs, changeCredential }: LoginResetProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { phone_number } = informs;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<LoginMainTypes>();

  useEffect(() => {
    phone_number && setValue("phone_number", phone_number);
  }, []);

  const onSubmit = () => {
    if (phone_number) {
      setLoading(true);
      SendSmsConfig({ phone_number })
        .then((res) => {
          setStep("check");
          changeCredential(res.data.credential_id, "reset");
        })
        .catch((err) => {
          const { status } = err.response;
          if (status === 404) {
            setStep("signup");
            //   toast.error(t("message.login_main_400"));
            // } else if (status === 406) {
            //   toast.error(t("message.login_send_code_406"));
            // } else if (status === 409) {
            //   toast.error(t("message.login_send_code_409"));
            // } else if (status === 411) {
            //   toast.error(t("message.login_send_code_411"));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="login-signin">
      <Modal.Header className="login-header">Parolni tiklash</Modal.Header>
      <Modal.Body className="login-body">
        <form className="custom-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <InputItem
                label="Telefon raqami"
                size="lg"
                field={field}
                disabled
              />
            )}
          />
          <p className="login-check-info">
            SMS kodni <span>{informs.phone_number}</span> raqamiga yuboramiz.
            Telefon raqamingizni tasdiqlasangiz davom etirish tugmasini bosing.
          </p>
          <CustomButton
            size="xl"
            color="primary"
            style={{ width: "100%" }}
            type="submit"
            loading={loading}
          >
            Davom ettirish
          </CustomButton>
          <CustomButton
            onClick={() => setStep("signin")}
            size="xl"
            color="gray"
            style={{ width: "100%", marginTop: 12 }}
            icon={<ArrowBackSvg />}
            left_icon
          >
            Orqaga qaytish
          </CustomButton>
        </form>
      </Modal.Body>
    </div>
  );
}

export default LoginReset;
