import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LanguageTypeTypes } from "src/pages/services/language/type";
import {
  GetAchievmentsConfig,
  GetCitizenshipsConfig,
  GetDistrictsConfig,
  GetEducationLanguagesConfig,
  GetLanguageTypesConfig,
  GetLanguagesConfig,
  GetNationalitiesConfig,
  GetRegionsConfig,
} from "src/service/api/classifiers";
import { BaseObjTypes, ContextTypes } from "src/static/interface";
import { OptionTypes } from "src/static/interface/common";
import { UserDataTypes } from "src/static/interface/profile";
import { makeOptionItem } from "src/utils";

export const MainContext = React.createContext<ContextTypes>({});

function MainContextProvider({ children }: { children?: React.ReactNode }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  //Regions
  const [regions, setRegions] = useState<OptionTypes[]>();
  const getRegions = () => {
    GetRegionsConfig().then((res) => {
      setRegions(makeOptionItem(res.data.results || res.data));
    });
  };

  //Districs
  const [districts, setDistricts] = useState<OptionTypes[]>();
  const getDistricts = (id: string) => {
    GetDistrictsConfig(id).then((res) => {
      setDistricts(makeOptionItem(res.data.results || res.data));
    });
  };

  //Citizenships
  const [citizenships, setCitizenships] = useState<OptionTypes[]>();
  const getCitizenships = () => {
    GetCitizenshipsConfig().then((res) => {
      setCitizenships(makeOptionItem(res.data.results || res.data));
    });
  };

  //Nationalities
  const [nationalities, setNationalities] = useState<OptionTypes[]>();
  const getNationalities = () => {
    GetNationalitiesConfig().then((res) => {
      setNationalities(makeOptionItem(res.data.results || res.data));
    });
  };

  //Languages
  const [languages, setLanugages] = useState<OptionTypes[]>();
  const getLanguages = () => {
    GetLanguagesConfig().then((res) => {
      let list = res.data?.results || res?.data;
      setLanugages(
        list.reduce(
          (all: any, cur: any) => [
            ...all,
            {
              ...cur,
              value: cur?.id,
            },
          ],
          [],
        ),
      );
    });
  };

  //Languages EDU
  const [eduLanguages, setEduLanguages] = useState<OptionTypes[]>();
  const getEduLanguages = () => {
    GetEducationLanguagesConfig().then((res) => {
      setEduLanguages(makeOptionItem(res.data.results || res.data));
    });
  };

  //Achievments list
  const [achievements, setAchievements] = useState<OptionTypes[]>();
  const getAchievements = () => {
    GetAchievmentsConfig().then((res) => {
      setAchievements(makeOptionItem(res.data.results || res.data));
    });
  };

  //Language certificate types
  const [languageTypes, setLanguageTypes] = useState<OptionTypes[]>();
  const [allCertificateTypes, setAllCertificateTypes] = useState<LanguageTypeTypes[]>();
  const getLanguageTypes = (id: string) => {
    GetLanguageTypesConfig(id).then((res) => {
      setLanguageTypes(makeOptionItem(res.data.results || res.data));
      setAllCertificateTypes(res.data.results || res.data);
    });
  };

  //Language certificat degrees
  const [languageDegrees, setLanguageDegrees] = useState<OptionTypes[]>();
  const getLanguageDegrees = (id: string) => {
    let obj = allCertificateTypes?.find((el) => el.id === id);
    if (obj) setLanguageDegrees(makeOptionItem(obj?.degrees));
  };

  const [userData, setUserData] = useState<UserDataTypes>();
  return (
    <MainContext.Provider
      value={{
        handleMakeParams,
        userData,
        setUserData,
        regions,
        getRegions,
        districts,
        getDistricts,
        achievements,
        getAchievements,
        citizenships,
        getCitizenships,
        nationalities,
        getNationalities,
        languages,
        getLanguages,
        eduLanguages,
        getEduLanguages,
        languageTypes,
        getLanguageTypes,
        languageDegrees,
        getLanguageDegrees,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}

export default MainContextProvider;
