import * as yup from "yup";
import { Modal } from "flowbite-react";
import { ArrowBackSvg, EyePasswordSVG } from "src/assets/svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  LoginPasswordProps,
  LoginPasswordTypes,
} from "src/static/interface/login";
import { password_rule } from "src/static/const/schema";
import { useState } from "react";
import { SavePasswordConfig } from "src/service/api/auth";
import { handleLoginFunc } from "src/utils";
import { loginPasswordData } from "src/static/data";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";

function LoginPassword({ setStep, informs }: LoginPasswordProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  let password = "";
  const schema = yup
    .object({
      password: password_rule,
      confirm: yup.string().test("", "", (val) => val === password),
    })
    .required();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<LoginPasswordTypes>({
    resolver: yupResolver(schema),
  });
  password = watch("password");

  const onSubmit = (data: LoginPasswordTypes) => {
    let { credential_id, one_time_password } = informs;
    if (credential_id && one_time_password) {
      let obj = {
        credential_id,
        one_time_password,
        password: data.password,
      };
      setLoading(true);
      SavePasswordConfig(obj)
        .then((res) => {
          handleLoginFunc(res.data);
          const { step } = informs;
          if (step === "signup") setStep("finished");
          else if (step === "reset") {
            setStep("signin");
            alert("Parolingiz muvaffaqiyatli o'zgartirildi");
          } else if (step === "forget") {
            setStep("main");
            alert("Yangi telefon raqam va parolingiz muvaffaqiyatli saqlandi");
          }
        })
        .catch((err) => {
          const { status } = err.response;
          if (status === 400) {
            setStep("signup");
            //   toast.error(t("message.login_password_400"));
            // } else if (status === 404) {
            //   toast.error(t("message.login_password_404"));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="login-password">
      <Modal.Header className="login-header">
        {t("registration.password_new")}
      </Modal.Header>
      <Modal.Body className="login-body">
        <form className="custom-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("registration.password") || ""}
                placeholder={t("registration.password_place") || ""}
                size="lg"
                type="password"
                // message={
                //   password
                //     ? "Parol eng kamida 6 belgidan iborat bo'lishi kerak"
                //     : "Parolni kiriting"
                // }
                message={t("registration.password_place") || ""}
                field={field}
                rightIcon={<EyePasswordSVG />}
                className="password-input"
                error={errors.password ? true : false}
              />
            )}
          />
          <Controller
            name="confirm"
            control={control}
            render={({ field }) => (
              <InputItem
                label={t("registration.password2") || ""}
                placeholder={t("registration.password2_place") || ""}
                size="lg"
                type="password"
                message={
                  watch("confirm")
                    ? t("registration.password_check") || ""
                    : t("registration.password2_place") || ""
                }
                field={field}
                rightIcon={<EyePasswordSVG />}
                className="password-input"
                error={errors.confirm ? true : false}
              />
            )}
          />
          <div className="login-password-rules">
            <h2>{t("registration.password_info")}</h2>
            {loginPasswordData.map((item) => {
              return (
                <p className={`${item.active && "active"}`} key={item.text}>
                  - {t(item.text)}
                </p>
              );
            })}
          </div>
          <CustomButton
            size="xl"
            color="primary"
            type="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            {t("profile.common.continue")}
          </CustomButton>
          <CustomButton
            onClick={() => setStep(informs.step || "main")}
            size="xl"
            color="gray"
            style={{ width: "100%", marginTop: 12 }}
            icon={<ArrowBackSvg />}
            left_icon
          >
            {t("profile.common.back")}
          </CustomButton>
        </form>
      </Modal.Body>
    </div>
  );
}

export default LoginPassword;
