import React, { CSSProperties, useState } from "react";
import { CheckIconSVG, CloseIconSVG, WarningIconSVG } from "src/assets/svg";

function CustomAlert({
  type = "default",
  title = "Ma’lumot",
  info = "Ushbu bo‘limda foydalanuvchiga tegishli tugallangan taʼlim muassasalari ro‘yxati ko‘rsatiladi.",
  footer,
  style,
  closeIcon = true,
}: {
  type?: "warning" | "default" | "error" | "success";
  info?: string;
  title?: string;
  footer?: JSX.Element;
  style?: CSSProperties;
  closeIcon?: boolean;
}) {
  const [visible, setVisible] = useState(true);

  return (
    <div
      style={!visible ? { display: "none", ...style } : { ...style }}
      className={`customAlert ${
        type === "warning"
          ? "customAlert__yellow"
          : type === "error"
          ? "customAlert__red"
          : type === "success"
          ? "customAlert__green"
          : ""
      } ${!visible ? "customAlert__hidden" : ""}`}
    >
      <div className='customAlert__header'>
        <div className='customAlert__left'>
          {type === "warning" ? <WarningIconSVG /> : <CheckIconSVG />}
          <span>{title}</span>
        </div>
        {closeIcon && <CloseIconSVG onClick={() => setVisible(false)} />}
      </div>
      {info && <p className='customAlert__body' dangerouslySetInnerHTML={{ __html: info }} />}
      {footer}
    </div>
  );
}

export default CustomAlert;
