import React from "react";
import RightMiniArrowBtn from "../buttons/RightMiniArrowBtn";
import { LongArrowSVG, MiniRightIconSVG } from "src/assets/svg";
import CustomButton from "../common/button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NoItemCard({
  icon,
  title,
  info,
  link,
  buttonText,
  longArrow = false,
}: {
  icon?: React.ReactNode;
  title: string;
  info?: string;
  link?: string;
  buttonText?: string;
  longArrow?: Boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className="home__notifications_body">
      {icon}
      <h3 className="home__notifications_body_title">{title}</h3>
      {info && (
        <p
          className="home__notifications_body_info"
          dangerouslySetInnerHTML={{ __html: t(info) }}
        />
      )}
      {link ? (
        <Link to={link || ""}>
          <CustomButton
            outline
            size="sm"
            color="primary"
            icon={<MiniRightIconSVG />}
          >
            {t("profile.common.all_services")}
          </CustomButton>
        </Link>
      ) : (
        ""
      )}
      {longArrow ? (
        <div className="rightLongArrow">
          <LongArrowSVG />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default NoItemCard;
