import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/common/button";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import CustomAlert from "src/components/common/alert";
import { CheckOutlineIconSVG } from "src/assets/svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CreateVocationApplication,
  GetVocationPrivilageConfig,
  UpdateVocationApplication,
} from "../config";
import {
  VOCATION_ADMISSION_DATA,
  VOCATION_ADMISSION_TOKEN,
  applicationID,
} from "src/static/const/auth";
import { GetSchoolInfo } from "src/pages/profile/diplom/config";
import toast from "react-hot-toast";
import ResultCard from "src/components/cards/ResultCard";
import { giveError } from "src/service/host";

function VocationAdmissionStep4() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [appID, setAppID] = useState("");
  const [cert1, setCert1] = useState(true);
  const [cert2, setCert2] = useState(false);
  const [visible, setVisible] = useState(false);

  const openModal = () => setVisible(true);
  const closeModal = () => {
    setVisible(false);
    navigate(`/service/admission/detail?id=${appID}&key=vocational_school_admission`);
    // localStorage.removeItem(VOCATION_ADMISSION_TOKEN);
    localStorage.removeItem(VOCATION_ADMISSION_DATA);
    document.body.style.overflow = "auto";
  };

  const getCerts = async () => {
    const { data } = await GetVocationPrivilageConfig();
    setCert1(data?.has_in_iron_copybook);
    setCert2(data?.has_in_social_security_register);
  };

  const onSubmit = async () => {
    const data = JSON.parse(localStorage.getItem(VOCATION_ADMISSION_DATA) || "");
    const schoolInfo = await GetSchoolInfo();
    const list = schoolInfo?.data?.results || schoolInfo?.data;

    if (applicationID) {
      await UpdateVocationApplication(applicationID, {
        school_certificate: {
          ...list[0],
          country: list[0]?.country.id,
          education_language: list[0]?.education_language.id,
          foreign_language: list[0]?.foreign_language.id,
        },
        ...data,
      });
      toast.success("Ariza tahrirlandi !");
      navigate(`/service/admission/detail?id=${applicationID}&key=vocational_school_admission`);
      localStorage.removeItem(VOCATION_ADMISSION_DATA);
      // localStorage.removeItem(VOCATION_ADMISSION_TOKEN);
      localStorage.removeItem("appID");
    } else {
      const res = await CreateVocationApplication({
        school_certificate: {
          ...list[0],
          country: list[0]?.country.id,
          education_language: list[0]?.education_language.id,
          foreign_language: list[0]?.foreign_language.id,
        },
        ...data,
      });
      // Give error message
      if (res.data?.detail) {
        toast.error(giveError(res.data?.detail));
        localStorage.removeItem(VOCATION_ADMISSION_DATA);
        // localStorage.removeItem(VOCATION_ADMISSION_TOKEN);
      }
      if (res.data?.id) {
        setAppID(res.data?.id);
        openModal();
      }
    }
  };

  useEffect(() => {
    getCerts();
  }, []);

  return (
    <>
      <div className='application-box'>
        <div className='application-box-title'>Ijtimoiy himoya</div>
        <div className='application-box-form'>
          <CustomAlert
            title='Tushuncha'
            info="Bu bosqich ariza topshiruvchi uchun majburiy emas. Agar sizning ma'lumotlaringiz <b>Ijtimoiy himoya</b> to'g'risidagi ro'yxatlarda mavjud bo'lsa ariza topshirishda inobatga olinadi. Aks holda shunchaki davom ettirish tugmasini bosing!"
          />

          <div className={`notebook_cert ${cert1 ? "notebook_cert-active" : ""}`}>
            <CheckOutlineIconSVG />
            Siz ma'lumotlaringiz "Ijtimoiy himoya yagona reestri" da mavjud
            {!cert1 ? " emas" : ""}
          </div>
          <div className={`notebook_cert ${cert2 ? "notebook_cert-active" : ""}`}>
            <CheckOutlineIconSVG />
            Siz ma’lumotlaringiz "Temir daftar" da mavjud{!cert2 ? " emas" : ""}
          </div>
        </div>
      </div>

      <div className='application-box-footer'>
        <Link to={`/service/admission/vocation/step3`}>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps} onClick={onSubmit}>
          {t("profile.common.continue") || ""}
        </CustomButton>
      </div>

      <ResultCard visible={visible} closeModal={closeModal} id={appID} />
    </>
  );
}

export default VocationAdmissionStep4;
