import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { LoaderSVG, NoWorkSVG } from "src/assets/svg";
import NoItemCard from "src/components/cards/NoItemCard";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import { language } from "src/static/const/auth";
import { GetWorkConfig, LoadWorkConfig } from "./config";
import DiplomCard from "src/components/cards/DiplomCard";

function ProfileWork() {
  const { t, i18n } = useTranslation();
  const [works, setWorks] = useState<any[]>();
  const [loadingCheck, setLaodingCheck] = useState(false);

  const reload = async () => {
    setLaodingCheck(true);
    try {
      const { data } = await LoadWorkConfig();
      getAchievments();
    } catch {}
    setLaodingCheck(false);
  };

  const getAchievments = async () => {
    const { data } = await GetWorkConfig();
    setWorks(data.results);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    getAchievments();
  }, []);

  return (
    <div className="profile__education">
      <CustomAlert
        title={t("profile.cabinet.info") || ""}
        info={t("profile.applications.infoWork") || ""}
      />

      <div className="profile__education_header">
        <h2>{t("profile.cabinet.work")}</h2>
        <CustomButton
          icon={<LoaderSVG />}
          left_icon
          color="green"
          outline
          onClick={reload}
        >
          {t("profile.cabinet.refresh_info")}
        </CustomButton>
      </div>

      <div className="profile__education_table">
        {works && works?.length > 0 ? (
          works?.map((work) => <DiplomCard data={work} type="work" />)
        ) : (
          <NoItemCard
            longArrow
            icon={<NoWorkSVG />}
            title={t("profile.applications.no_application")}
            info={"profile.portfolio.noWork"}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileWork;
