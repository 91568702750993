import {
  axiosInstanceLyceum,
  axiosInstanceVocation,
  axiosInstanceInterview,
  axiosInstanceSecondEdu,
  axiosInstanceMultipartSecondEdu,
} from "../host";

export const HttpRequestHubLyceum = (config: any) => {
  return axiosInstanceLyceum(config);
};

export const HttpRequestHubVocation = (config: any) => {
  return axiosInstanceVocation(config);
};

export const HttpRequestHubInterview = (config: any) => {
  return axiosInstanceInterview(config);
};

export const HttpRequestHubSecondEdu = (config: any) => {
  return axiosInstanceSecondEdu(config);
};

export const HttpRequestHubMultipartSecondEdu = (config: any) => {
  return axiosInstanceMultipartSecondEdu(config);
};
