import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "flowbite-react";
import { ArrowBackSvg, OneIdHoverSvg, OneIdSvg } from "src/assets/svg";
import { LoginSignupTypes, LoginSignUpProps, SendSmsTypes } from "src/static/interface/login";
import { loginSignInData } from "src/static/data";
import { useEffect, useState } from "react";
import { clearMask } from "src/utils";
import { pinfl_mask } from "src/static/const";
import { login_signup_schema, login_signup_schema2 } from "src/static/const/schema";
import { SendSmsConfig } from "src/service/api/auth";
import { useTranslation } from "react-i18next";

import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";
import RadioItem from "src/components/form/radio";
import CustomDrower from "src/components/common/drower";
import MaskedInputItem from "src/components/form/input/masked";
import DatepickerItem from "src/components/form/datepicker";

function LoginSignUp({ setStep, informs, changeCredential }: LoginSignUpProps) {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { phone_number } = informs;
  let type = "passport";

  //Drower section
  const [drower, setDrower] = useState(false);
  const [drower2, setDrower2] = useState(false);
  const openDrower = () => setDrower(true);
  // const openDrower2 = () => setDrower2(true);
  const closeDrower = () => setDrower(false);
  const closeDrower2 = () => setDrower2(false);

  //Form section
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<LoginSignupTypes>({
    resolver: yupResolver(login_signup_schema),
  });

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    reset: reset2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm<LoginSignupTypes>({
    resolver: yupResolver(login_signup_schema2),
  });

  type = watch("type") || "passport";

  const errorPinfl = clearMask(watch("pinfl")).length !== 14;
  const errorNumber = clearMask(watch2("number")).length < 7;

  const onSubmit = (data: LoginSignupTypes) => {
    if (type === "passport" && phone_number) {
      let obj = {
        phone_number,
        passport: {
          pinfl: clearMask(data.pinfl),
          serial: data.serial.toUpperCase(),
          number: clearMask(data.number),
        },
      };

      sendSmsFunc(obj);
    } else if (phone_number) {
      let obj = {
        phone_number,
        birth_certificate: {
          serial: data.serial.toUpperCase(),
          number: clearMask(data.number),
          birth_date: data.birth_date.startDate,
        },
      };
      sendSmsFunc(obj);
    }
  };

  const sendSmsFunc = (obj: SendSmsTypes) => {
    setLoading(true);
    SendSmsConfig(obj)
      .then((res) => {
        changeCredential(res.data.credential_id, "signup");
        setStep("check");
      })
      .catch((err) => {
        const { status } = err.response;
        if (status === 404) {
          setStep("signup");
          // toast.error(t("message.login_main_400"));
          // } else if (status === 406) {
          //   toast.error(t("message.login_send_code_406"));
          // } else if (status === 409) {
          //   toast.error(t("message.login_send_code_409"));
          // } else if (status === 411) {
          //   toast.error(t("message.login_send_code_411"));
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClear = () => {
    setValidated(false);
    reset();
    reset2();
    setValue("type", type);
  };

  useEffect(() => {
    handleClear();
  }, [type]);

  const actionButtons = (
    <>
      <CustomButton
        size='xl'
        color='primary'
        style={{ width: "100%" }}
        type='submit'
        loading={loading}
        onClick={() => setValidated(true)}
      >
        {t("profile.common.continue")}
      </CustomButton>
      <CustomButton
        onClick={() => setStep("main")}
        size='xl'
        color='gray'
        style={{ width: "100%", marginTop: 12 }}
        icon={<ArrowBackSvg />}
        left_icon
      >
        {t("profile.common.back")}
      </CustomButton>
      <div className='login-main-other'>
        <p>
          <span> {t("registration.other_way")}</span>
        </p>
      </div>
      <CustomButton
        size='xl'
        color='oneid'
        onClick={() => (window.location.href = "https://my.edu.uz/auth/one-id-callback")}
        style={{ width: "100%" }}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        icon={hovered ? <OneIdHoverSvg /> : <OneIdSvg />}
      />
    </>
  );

  return (
    <div className='login-signup'>
      <Modal.Header className='login-header'>{t("registration.registr")}</Modal.Header>
      <Modal.Body className='login-body'>
        <form className='custom-form' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='type'
            control={control}
            render={({ field }) => (
              <RadioItem
                items={loginSignInData}
                label={t("registration.select_doc_type")}
                active={type || "passport"}
                defaultChecked='passport'
                field={field}
              />
            )}
          />
          <div className='custom-form-line'></div>
          {type === "passport" && (
            <>
              <Controller
                name='pinfl'
                control={control}
                render={({ field }) => (
                  <MaskedInputItem
                    label={t("profile.common.pinfl") || ""}
                    placeholder='_ _ _ _ _ _ _ _ _ _ _ _ _ _'
                    mask={pinfl_mask}
                    size='lg'
                    message={t("profile.common.pinfl") || ""}
                    openDrower={openDrower}
                    field={field}
                    error={errorPinfl && validated}
                  />
                )}
              />
              {/* <Controller
                name="given_date"
                control={control}
                render={({ field }) => (
                  <DatepickerItem
                    label={t("registration.doc_date") || ""}
                    size="lg"
                    field={field}
                    onChange={(val) => setValue("given_date", val)}
                    error={errors.given_date && validated}
                    message={t("registration.doc_date") || ""}
                    openDrower={openDrower2}
                  />
                )}
              /> */}
              <div className='login-signup-inputs'>
                <Controller
                  name='serial'
                  control={control}
                  render={({ field }) => (
                    <InputItem
                      style={{ textTransform: "uppercase" }}
                      label={"Passport seriya"}
                      placeholder='_ _'
                      size='lg'
                      message={"Passport seriya"}
                      field={field}
                      error={errors.serial && validated ? true : false}
                    />
                  )}
                />
                <Controller
                  name='number'
                  control={control}
                  render={({ field }) => (
                    <MaskedInputItem
                      label={"Passport raqami"}
                      placeholder='_ _ _ _ _ _ _'
                      // mask={docum_number_mask}
                      size='lg'
                      message={"Passport raqami"}
                      field={field}
                      error={errors.number && validated ? true : false}
                    />
                  )}
                />
              </div>
              {actionButtons}
            </>
          )}
        </form>

        {type === "certificate" && (
          <form className='custom-form' onSubmit={handleSubmit2(onSubmit)}>
            <div className='login-signup-inputs'>
              <Controller
                name='serial'
                control={control2}
                render={({ field }) => (
                  <InputItem
                    style={{ textTransform: "uppercase" }}
                    label={t("registration.series_guvohnoma") || ""}
                    placeholder='_ _ _'
                    size='lg'
                    message={t("registration.series_guvohnoma") || ""}
                    field={field}
                    error={errors2.serial && validated ? true : false}
                  />
                )}
              />
              <Controller
                name='number'
                control={control2}
                render={({ field }) => (
                  <MaskedInputItem
                    label={t("registration.number_guvohnoma") || ""}
                    placeholder='_ _ _ _ _ _ _ _'
                    // mask={docum_number_mask}
                    size='lg'
                    message={t("registration.number_guvohnoma") || ""}
                    field={field}
                    error={errors2.number && validated ? true : false}
                  />
                )}
              />
            </div>
            <Controller
              name='birth_date'
              control={control2}
              render={({ field }) => (
                <DatepickerItem
                  label={t("profile.common.birth_date") || ""}
                  size='lg'
                  field={field}
                  onChange={(val) => setValue2("birth_date", val)}
                  error={errors2.birth_date && validated ? true : false}
                  message={t("profile.common.birth_date") || ""}
                />
              )}
            />
            {actionButtons}
          </form>
        )}
        <div className='login-signup-footer'>
          {/* “Davom etish” tugmasini bosish bilan orqali siz{" "}
          <a href="#">Foydalanish shartlari</a> va{" "}
          <a href="#">Maxfiylik siyosatiga</a> rozilik bildirasiz */}
          {t("registration.privacy")}
        </div>
      </Modal.Body>

      <CustomDrower
        title={t("registration.about_jshshr") || ""}
        okText={t("registration.close") || ""}
        visible={drower}
        onCancel={closeDrower}
        onOk={closeDrower}
      >
        <div className='login-signup-drower'>
          <p>{t("registration.info_jshshr") || ""}</p>
          <img src={require("src/assets/images/pinflHelp.png")} alt='error pinfl help' />
        </div>
      </CustomDrower>

      <CustomDrower
        title={t("profile.common.passport_given") || ""}
        okText={t("registration.close") || ""}
        visible={drower2}
        onCancel={closeDrower2}
        onOk={closeDrower2}
      >
        <div className='login-signup-drower'>
          <p>{t("registration.info_jshshr") || ""}</p>
          <img src={require("src/assets/images/birthDateHelp.png")} alt='error birth date help' />
        </div>
      </CustomDrower>
    </div>
  );
}

export default LoginSignUp;
