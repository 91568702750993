import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BottomIconSVG } from "src/assets/svg";
import CardStatus from "src/pages/services/admission/detail/components/cardStatus";
import { findStatusSE } from "src/pages/services/admission/secondary_education/const";
import { generateServiceToken } from "src/pages/services/config";
import { GenServiceToken, findAdmissionToken } from "src/pages/services/const";
import { giveLan } from "src/utils";
import CustomButton from "../common/button";

function ApplicationCard({ data, getHistory }: { data: any; getHistory?: () => void }) {
  const parsed = typeof data?.data === "string" ? JSON.parse(data?.data) : data?.data;
  const slug = data?.service?.slug;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const aboutApplication = async () => {
    if (["interview_admission", "lyceum_admission", "vocational_school_admission"].includes(slug)) {
      const res = await generateServiceToken(data?.service?.id || "");
      localStorage.setItem(findAdmissionToken(slug), res.data?.token);
      window.location.href = `/service/admission/detail?id=${data?.external_id}&key=${data?.service?.slug}`;
    } else if (slug == "secondary_education_admission") {
      GenServiceToken(
        findAdmissionToken("secondary_education_admission"),
        data?.service?.id || "",
        "secondary_education_admission",
      );
      window.location.href = `/service/admission/detail?id=${data?.external_id}&key=${data?.service?.slug}`;
    } else {
      navigate(`/service/admission/detail?id=${data?.external_id}&key=${data?.service?.slug}`);
    }
  };

  return (
    <div className='applicationCard'>
      <div className='applicationCard__header'>
        <h3>{giveLan(data?.service)}</h3>
        <div className='flex'>
          <h4>
            {t("profile.current_edu.app_id")}: <b>{data?.id}</b>
          </h4>
          <h4>
            {t("profile.current_edu.app_sendTime")}:{" "}
            <b>{new Date(data?.added_time).toLocaleString()}</b>
          </h4>
        </div>
      </div>
      <div className='applicationCard__body'>
        <div className='left'>
          {slug === "foreign_language_certificate" ? (
            <h2>{giveLan(parsed?.foreign_language)}</h2>
          ) : (
            <></>
          )}
          <p>{giveLan(data?.service, "description")}</p>
        </div>
        <div className='applicationCard__status'>
          {["lyceum_admission", "vocational_school_admission"].includes(slug) ? (
            <CardStatus
              status={
                ["3", "4", "5", "6", "7"].includes(data?.status) ? 6 : data?.status == "1" ? 1 : 2
              }
            />
          ) : (
            <CardStatus
              status={
                slug === "secondary_education_admission" ? findStatusSE(data?.status) : data?.status
              }
              slug={slug}
            />
          )}
        </div>
      </div>
      <div className='applicationCard__footer'>
        <CustomButton
          icon={<BottomIconSVG />}
          right_icon
          size='xs'
          color='primary'
          outline
          onClick={getHistory}
        >
          {t("profile.current_edu.app_history")}
        </CustomButton>

        <div className='flex gap-3'>
          {data?.service?.slug === "lyceum_admission" &&
            data?.status === "4" &&
            data?.data?.dtm_exam_paper_url && (
              <a href={data?.data?.dtm_exam_paper_url} target='_blank'>
                <CustomButton size='xs' color='gray'>
                  Ruxsatnomani yuklab olish
                </CustomButton>
              </a>
            )}
          {/* {checkEditApplication(data?.service?.slug, data) && (
            <CustomButton
              size='xs'
              color='gray'
              onClick={() => {
                if (data?.service?.slug === "secondary_education_admission") {
                  editApplication(data?.service?.slug, data);
                } else
                  GetApplicationIDConfig(data?.external_id).then((res) =>
                    editApplication(data?.service?.slug, res.data),
                  );
              }}
            >
              {t("profile.cabinet.edit")}
            </CustomButton>
          )} */}

          <CustomButton color='primary' size='xs' onClick={aboutApplication}>
            {t("profile.common.more")}
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default ApplicationCard;
