import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import { LoginFinishedProps } from "src/static/interface/login";
import CustomButton from "src/components/common/button";
import LottieSuccess from "src/components/lottie/success";
import { useTranslation } from "react-i18next";

function LoginFinished({ closeModal }: LoginFinishedProps) {
  const { t } = useTranslation();

  return (
    <Modal.Body className="login-body">
      <div className="login-finished">
        <div className="login-finished-lottie">
          <LottieSuccess />
        </div>
        <h2>{t("registration.congratulation")}!</h2>
        <p>{t("registration.reg_succes")}.</p>
        <div className="login-finished-buttons">
          <CustomButton color="gray" onClick={closeModal}>
            {t("registration.main_page")}
          </CustomButton>
          <Link
            to="/profile"
            onClick={() => {
              window.location.href = "/profile";
            }}
          >
            <CustomButton color="primary" onClick={closeModal}>
              {t("registration.profile")}
            </CustomButton>
          </Link>
        </div>
      </div>
    </Modal.Body>
  );
}

export default LoginFinished;
