import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "flowbite-react";
import { ArrowBackSvg } from "src/assets/svg";
import { LoginSignupTypes, LoginForgetProps, SendSmsTypes } from "src/static/interface/login";
import { loginSignInData } from "src/static/data";
import { useEffect, useState } from "react";
import { clearMask } from "src/utils";
import { phone_mask, pinfl_mask } from "src/static/const";
import { login_forget_schema, login_forget_schema2 } from "src/static/const/schema";

import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";
import RadioItem from "src/components/form/radio";
import CustomDrower from "src/components/common/drower";
import MaskedInputItem from "src/components/form/input/masked";
import DatepickerItem from "src/components/form/datepicker";
import { SendSmsConfig } from "src/service/api/auth";
import { useTranslation } from "react-i18next";

function LoginForget({ setStep, changeCredential }: LoginForgetProps) {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  let type = "passport";

  //Drower section
  const [drower, setDrower] = useState(false);
  const [drower2, setDrower2] = useState(false);
  const openDrower = () => setDrower(true);
  // const openDrower2 = () => setDrower2(true);
  const closeDrower = () => setDrower(false);
  const closeDrower2 = () => setDrower2(false);

  //Form section
  const {
    handleSubmit,
    control,
    watch,
    // setValue,
    formState: { errors },
  } = useForm<LoginSignupTypes>({
    resolver: yupResolver(login_forget_schema),
  });

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm<LoginSignupTypes>({
    resolver: yupResolver(login_forget_schema2),
  });
  type = watch("type") || "passport";

  const errorPinfl = clearMask(watch("pinfl")).length !== 14;
  // const errorNumber = clearMask(watch2("number")).length !== 7;
  const errorPhone = clearMask(watch("phone_number")).length !== 9;
  const errorPhone2 = clearMask(watch2("phone_number")).length !== 9;

  const onSubmit = (data: LoginSignupTypes) => {
    const phone_number = "+998" + clearMask(data.phone_number);
    if (type === "passport") {
      let obj = {
        phone_number,
        passport: {
          pinfl: clearMask(data.pinfl),
          serial: data.serial,
          number: clearMask(data.number),
        },
      };
      sendSmsFunc(obj);
    } else {
      let obj = {
        phone_number,
        birth_certificate: {
          serial: data.serial,
          number: clearMask(data.number),
          birth_date: data.birth_date.startDate,
        },
      };
      sendSmsFunc(obj);
    }
  };

  const sendSmsFunc = (obj: SendSmsTypes) => {
    setLoading(true);
    SendSmsConfig(obj)
      .then((res) => {
        changeCredential(res.data.credential_id, "forget");
        setStep("check");
      })
      .catch((err) => {
        const { status } = err.response;
        if (status === 404) {
          setStep("signup");
          //   toast.error(t("message.login_main_400"));
          // } else if (status === 406) {
          //   toast.error(t("message.login_send_code_406"));
          // } else if (status === 409) {
          //   toast.error(t("message.login_send_code_409"));
          // } else if (status === 411) {
          //   toast.error(t("message.login_send_code_411"));
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setValidated(false);
  }, [type]);

  const actionButtons = (
    <>
      <CustomButton
        size='xl'
        color='primary'
        style={{ width: "100%" }}
        type='submit'
        loading={loading}
        onClick={() => setValidated(true)}
      >
        {t("profile.common.continue")}
      </CustomButton>
      <CustomButton
        onClick={() => setStep("main")}
        size='xl'
        color='gray'
        style={{ width: "100%", marginTop: 12 }}
        icon={<ArrowBackSvg />}
        left_icon
      >
        {t("profile.common.back")}
      </CustomButton>
    </>
  );

  return (
    <div className='login-signup'>
      <Modal.Header className='login-header'>{t("registration.new_password")}</Modal.Header>
      <Modal.Body className='login-body'>
        <form className='custom-form' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='type'
            control={control}
            render={({ field }) => (
              <RadioItem
                items={loginSignInData}
                label={t("registration.select_doc_type")}
                active={type || "passport"}
                defaultChecked='passport'
                field={field}
              />
            )}
          />
          <div className='custom-form-line'></div>
          {type === "passport" && (
            <>
              <Controller
                name='pinfl'
                control={control}
                render={({ field }) => (
                  <MaskedInputItem
                    label={t("profile.common.pinfl") || ""}
                    placeholder='_ _ _ _ _ _ _ _ _ _ _ _ _ _'
                    mask={pinfl_mask}
                    size='lg'
                    message={t("profile.common.pinfl") || ""}
                    openDrower={openDrower}
                    field={field}
                    error={errorPinfl && validated}
                  />
                )}
              />
              <div className='login-signup-inputs'>
                <Controller
                  name='serial'
                  control={control}
                  render={({ field }) => (
                    <InputItem
                      label={"Passport seriya"}
                      placeholder='_ _'
                      size='lg'
                      message={"Passport seriya"}
                      field={field}
                      error={errors.serial && validated ? true : false}
                    />
                  )}
                />
                <Controller
                  name='number'
                  control={control}
                  render={({ field }) => (
                    <MaskedInputItem
                      label={"Passport raqami"}
                      placeholder='_ _ _ _ _ _ _'
                      // mask={docum_number_mask}
                      size='lg'
                      message={"Passport raqami"}
                      field={field}
                      error={errors.number && validated ? true : false}
                    />
                  )}
                />
              </div>
              <Controller
                name='phone_number'
                control={control}
                render={({ field }) => (
                  <MaskedInputItem
                    label={t("profile.settings.add_new_phone_title") || ""}
                    addon='+998'
                    placeholder='(__) ___ __ __'
                    mask={phone_mask}
                    size='lg'
                    message={t("profile.settings.add_new_phone") || ""}
                    field={field}
                    error={errorPhone && validated}
                  />
                )}
              />
              {actionButtons}
            </>
          )}
        </form>

        {type === "certificate" && (
          <form className='custom-form' onSubmit={handleSubmit2(onSubmit)}>
            <div className='login-signup-inputs'>
              <Controller
                name='serial'
                control={control2}
                render={({ field }) => (
                  <InputItem
                    label={t("registration.series_guvohnoma") || ""}
                    placeholder='_ _ _'
                    size='lg'
                    // message={
                    //   watch2("serial") ? "Minimal 3 belgi" : "Seriyani kiriting"
                    // }
                    message={t("registration.series_guvohnoma") || ""}
                    field={field}
                    error={errors2.serial && validated ? true : false}
                  />
                )}
              />
              <Controller
                name='number'
                control={control2}
                render={({ field }) => (
                  <MaskedInputItem
                    label={t("registration.number_guvohnoma") || ""}
                    placeholder='_ _ _ _ _ _ _ _'
                    // mask={docum_number_mask}
                    size='lg'
                    message={t("registration.number_guvohnoma") || ""}
                    field={field}
                    error={errors2.number && validated ? true : false}
                  />
                )}
              />
            </div>
            <Controller
              name='birth_date'
              control={control2}
              render={({ field }) => (
                <DatepickerItem
                  label={t("profile.common.birth_date") || ""}
                  size='lg'
                  field={field}
                  onChange={(val) => setValue2("birth_date", val)}
                  error={errors2.birth_date && validated ? true : false}
                  message={t("profile.common.birth_date") || ""}
                />
              )}
            />
            <Controller
              name='phone_number'
              control={control2}
              render={({ field }) => (
                <MaskedInputItem
                  label={t("profile.settings.add_new_phone_title") || ""}
                  addon='+998'
                  placeholder='(__) ___ __ __'
                  mask={phone_mask}
                  size='lg'
                  message={t("profile.settings.add_new_phone") || ""}
                  field={field}
                  error={errorPhone2 && validated}
                />
              )}
            />
            {actionButtons}
          </form>
        )}
      </Modal.Body>

      <CustomDrower
        title={t("registration.about_jshshr") || ""}
        okText={t("registration.close") || ""}
        visible={drower}
        onCancel={closeDrower}
        onOk={closeDrower}
      >
        <div className='login-signup-drower'>
          <p>{t("registration.info_jshshr") || ""}</p>
          <img src={require("src/assets/images/pinflHelp.png")} alt='error pinfl help' />
        </div>
      </CustomDrower>

      <CustomDrower
        title={t("profile.common.passport_given") || ""}
        okText={t("registration.close") || ""}
        visible={drower2}
        onCancel={closeDrower2}
        onOk={closeDrower2}
      >
        <div className='login-signup-drower'>
          <p>{t("registration.info_jshshr") || ""}</p>
          <img src={require("src/assets/images/birthDateHelp.png")} alt='error birth date help' />
        </div>
      </CustomDrower>
    </div>
  );
}

export default LoginForget;
