import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ArizalarSVg,
  DiplomaSVG,
  EduSVg,
  LangSVG,
  LogoutSVG,
  PersonSVG,
  PortfolioSVG,
  SettingsSVG,
  WorkSVG,
} from "src/assets/svg";
import SelectItem from "src/components/form/select";
import { MainContext } from "src/hooks/context";
import { language } from "src/static/const/auth";

function ProfilePage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { userData } = useContext(MainContext);
  const GiveActiveTab = (key: string) => {
    return location.pathname.includes(key) && "sidebarBtn__active";
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <>
      <div className="profile">
        <div className="container">
          <h1>{t("profile.cabinet.title")}</h1>
          <div className="flex">
            <div className="profile__sidebar">
              <div className="profile__name">
                <div>
                  {userData?.first_name[0]}
                  {userData?.last_name[0]}
                </div>
                <div>
                  <h2>
                    {userData?.first_name} {userData?.last_name}
                  </h2>
                  <h3>ID:{userData?.id}</h3>
                </div>
              </div>
              <Link to={"/profile"}>
                <div className={`sidebarBtn`}>
                  <PersonSVG />
                  {t("profile.common.personal_info")}
                </div>
              </Link>

              <Link to={"/profile/applications"}>
                <div className={`sidebarBtn ${GiveActiveTab("applications")}`}>
                  <ArizalarSVg /> {t("profile.applications.title")}
                </div>
              </Link>
              <Link to={"/profile/education"}>
                <div className={`sidebarBtn ${GiveActiveTab("education")}`}>
                  <EduSVg /> {t("profile.cabinet.current_edu")}
                </div>
              </Link>
              <Link to={"/profile/diploma"}>
                <div className={`sidebarBtn ${GiveActiveTab("diploma")}`}>
                  <DiplomaSVG /> {t("profile.cabinet.diplom_info")}
                </div>
              </Link>
              <Link to={"/profile/languages"}>
                <div className={`sidebarBtn ${GiveActiveTab("languages")}`}>
                  <LangSVG /> {t("profile.cabinet.forign_lang")}
                </div>
              </Link>
              <Link to={"/profile/portfolio"}>
                <div className={`sidebarBtn ${GiveActiveTab("portfolio")}`}>
                  <PortfolioSVG /> {t("profile.cabinet.portfolio")}
                </div>
              </Link>
              <Link to={"/profile/work"}>
                <div className={`sidebarBtn ${GiveActiveTab("work")}`}>
                  <WorkSVG /> {t("profile.cabinet.work")}
                </div>
              </Link>

              <div className="divider" />

              <Link to={"/profile/settings"}>
                <div className={`sidebarBtn ${GiveActiveTab("settings")}`}>
                  <SettingsSVG /> {t("profile.cabinet.settings")}
                </div>
              </Link>

              {/* <div className={`sidebarBtn`}>
                <LogoutSVG /> {t("profile.cabinet.logout")}
              </div> */}
            </div>
            <div className="profile__body">
              <div className="profile__navmobile">
                <SelectItem
                  label="Menyu"
                  options={[
                    {
                      value: "/profile",
                      name_uz: t("profile.common.personal_info"),
                      selected: location.pathname === "/profile",
                    },
                    {
                      value: "/profile/applications",
                      name_uz: t("profile.applications.title"),
                      selected: location.pathname === "/profile/applications",
                    },
                    {
                      value: "/profile/education",
                      name_uz: t("profile.cabinet.current_edu"),
                      selected: location.pathname === "/profile/education",
                    },
                    {
                      value: "/profile/diploma",
                      name_uz: t("profile.cabinet.diplom_info"),
                      selected: location.pathname === "/profile/diploma",
                    },
                    {
                      value: "/profile/languages",
                      name_uz: t("profile.cabinet.forign_lang"),
                      selected: location.pathname === "/profile/languages",
                    },
                    {
                      value: "/profile/portfolio",
                      name_uz: t("profile.cabinet.portfolio"),
                      selected: location.pathname === "/profile/portfolio",
                    },
                    {
                      value: "/profile/work",
                      name_uz: t("profile.cabinet.work"),
                      selected: location.pathname === "/profile/work",
                    },
                    {
                      value: "/profile/settings",
                      name_uz: t("profile.cabinet.settings"),
                      selected: location.pathname === "/profile/settings",
                    },
                  ]}
                  size="lg"
                  onChange={navigate}
                />
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
