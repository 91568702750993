import { LoaderSVG, NoInformationSVG } from "src/assets/svg";
import { useTranslation } from "react-i18next";
import { GetEducationsConfig, ReloadEducationsConfig } from "./config";
import { useEffect, useState } from "react";
import CustomButton from "src/components/common/button";
import CustomAlert from "src/components/common/alert";
import EducationCard from "src/components/cards/EducationCard";
import { EducationTypes } from "src/static/interface/profile";
import toast from "react-hot-toast";
import NoItemCard from "src/components/cards/NoItemCard";
import { giveError } from "src/service/host";

function ProfileEdu() {
  const { t } = useTranslation();
  const [data, setData] = useState<EducationTypes>();

  const getFunc = async () => {
    const { data } = await GetEducationsConfig();
    setData(data?.results[0]);
  };
  useEffect(() => {
    getFunc();
  }, []);

  //Reload
  const [loadingReload, setLaodingReload] = useState(false);
  const reloadFunc = () => {
    setLaodingReload(true);
    ReloadEducationsConfig()
      .then((res) => {
        toast.success(giveError("Muvaffaqiyatli yangilandi !"));
      })
      .catch((err) => console.log(err))
      .finally(() => setLaodingReload(false));
  };
  return (
    <div className="profile__education">
      <CustomAlert
        title={t("profile.cabinet.info") || ""}
        info={t("profile.applications.infoEdu") || ""}
      />

      <div className="profile__education_header">
        <h2>{t("profile.cabinet.current_edu")}</h2>
        <CustomButton
          onClick={reloadFunc}
          icon={<LoaderSVG />}
          left_icon
          color="green"
          outline
          loading={loadingReload}
        >
          {t("profile.cabinet.refresh_info")}
        </CustomButton>
      </div>

      <div className="profile__education_table">
        {data ? (
          <EducationCard data={data} />
        ) : (
          <NoItemCard
            icon={<NoInformationSVG />}
            title={t("profile.edu.noEdu")}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileEdu;
