import { useEffect, useState } from "react";
import CustomButton from "src/components/common/button";
import CustomAlert from "src/components/common/alert";
import NoItemCard from "src/components/cards/NoItemCard";
import { LoaderSVG, PlusIconSVG, NoLanguageSVG } from "src/assets/svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  GetLanCertificatesConfig,
  ReloadLanCertificatesConfig,
} from "./config";
import DiplomCard from "src/components/cards/DiplomCard";
import { CheckServiceConfig } from "src/pages/services/language/config";

function ProfileLang() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [certificates, setCertifcates] = useState<any[]>();
  const [loadingCheck, setLaodingCheck] = useState(false);
  const handleCheck = () => {
    setLaodingCheck(true);
    CheckServiceConfig("bdd2ed46-1ce4-4641-92f8-9746d8034c17")
      .then((res) => {
        if (res.data.ok) navigate("/service/language");
      })
      .catch((err) => console.log(err))
      .finally(() => setLaodingCheck(false));
  };

  //Reload
  const [loadingReload, setLaodingReload] = useState(false);
  const handleReload = () => {
    setLaodingReload(true);
    ReloadLanCertificatesConfig()
      .then((res) => {
        getCertificates();
      })
      .finally(() => setLaodingReload(false));
  };

  const getCertificates = async () => {
    const { data } = await GetLanCertificatesConfig();
    setCertifcates(data?.results);
  };

  useEffect(() => {
    getCertificates();
  }, []);

  return (
    <div className="profile__education">
      <CustomAlert
        title={t("profile.cabinet.info") || ""}
        info={t("profile.applications.infoLang") || ""}
      />

      <div className="profile__education_header">
        <h2>{t("profile.cabinet.forign_lang")}</h2>

        <div className="flex gap-4">
          <CustomButton
            icon={<LoaderSVG />}
            left_icon
            color="green"
            outline
            loading={loadingReload}
            onClick={handleReload}
          >
            {t("profile.awards.cefr_name")}
          </CustomButton>
          <CustomButton
            icon={<PlusIconSVG />}
            left_icon
            color="primary"
            loading={loadingCheck}
            onClick={handleCheck}
          >
            {t("profile.awards.add_lang")}
          </CustomButton>
        </div>
      </div>

      <div className="profile__education_table">
        {certificates && certificates?.length > 0 ? (
          certificates?.map((item) => (
            <DiplomCard type="chettili" data={item} key={crypto.randomUUID()} />
          ))
        ) : (
          <NoItemCard
            longArrow
            icon={<NoLanguageSVG />}
            title={t("profile.awards.no_award")}
            info={"profile.edu.noLang"}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileLang;
