import CustomBroudcamp from "src/components/layout/CustomBroudcamp";
import CustomSteps from "src/components/common/steps";
import LyceumAdmissionStep1 from "../admission/lyceum/step1";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "src/components/common/button";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { QuolificationCertConfig } from "./config";
import InputItem from "src/components/form/input";
import { BimmPDF } from "src/utils";
import CustomAlert from "src/components/common/alert";

function QuolificationService() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [appID, setAppID] = useState<number>();
  const [searchParam] = useSearchParams();
  const currentStep = searchParam.get("step") || 1;
  const [step, setStep] = useState(+currentStep);

  useEffect(() => {
    setStep(+currentStep);
  }, [searchParam]);

  useEffect(() => {
    QuolificationCertConfig().then((res) => {
      setData(res.data?.data);
      setAppID(res.data?.application_id);
    });
  }, []);

  return (
    <div className='service service-language'>
      <div className='container'>
        <CustomBroudcamp
          nav={[
            { name: "nav.service", link: "/service?key=all" },
            {
              name: "Qayta tayyorlash malaka sertifikati",
              link: "",
            },
          ]}
        />

        <div className='application-steps'>
          <CustomSteps
            items={[
              {
                title: "profile.common.personal_info",
                info: "profile.common.passport_info",
              },
              {
                title: "Qayta tayyorlash malaka sertifikati",
                info: "Qayta tayyorlash malaka sertifikati",
              },
            ]}
            current={step}
          />
        </div>
        <div className='application-content'>
          {step === 1 ? (
            <LyceumAdmissionStep1 nextLink='/service/qualification_certificate?step=2' />
          ) : (
            <>
              {appID ? (
                <div className='application-box'>
                  <div className='application-box-form'>
                    <InputItem
                      className='half'
                      label={"Ism familyasi" || ""}
                      size='lg'
                      disabled={true}
                      placeholder={data?.fullname}
                    />
                    <InputItem
                      className='half'
                      label={t("profile.common.pinfl") || ""}
                      size='lg'
                      disabled={true}
                      placeholder={data?.pinfl}
                    />
                    <InputItem
                      className='full'
                      label={"Yo'nalish"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.direction}
                    />
                    <InputItem
                      className='full'
                      label={"Ta'lim muassasasi"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.org}
                    />
                    <InputItem
                      className='full'
                      label={"Mavzu"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.theme}
                    />
                    <InputItem
                      className='half'
                      label={"Dars vaqti"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.date_of_training}
                    />
                    <InputItem
                      className='half'
                      label={"Oxirgi muddat"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.ded_line}
                    />
                    <InputItem
                      className='half'
                      label={"Sertifikat seriyasi"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.cert_type}
                    />
                    <InputItem
                      className='half'
                      label={"Sertifikat raqami"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.cert_number}
                    />
                    <InputItem
                      className='half'
                      label={"Sertifikat berilgan sana"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.cert_date}
                    />
                    <InputItem
                      className='half'
                      label={"O'qish vaqti (soat)"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.duration}
                    />
                    <InputItem
                      className='half'
                      label={"To'plagan bali"}
                      size='lg'
                      disabled={true}
                      placeholder={data?.result}
                    />
                  </div>
                </div>
              ) : (
                <CustomAlert
                  type='error'
                  info="Sizning ma'lumotlaringiz topilmadi !"
                  style={{ marginBottom: 100 }}
                />
              )}

              <div className='application-box-footer'>
                <Link to='/service/qualification_certificate?step=1'>
                  <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
                </Link>
                <CustomButton
                  {...forwardButtonProps}
                  onClick={() => BimmPDF(data, appID)}
                  disabled={!appID}
                >
                  Sertifikatni yuklash
                </CustomButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuolificationService;
