import { useContext, useEffect, useState } from "react";
import { MainContext } from "src/hooks/context";
import { findAge, giveLan } from "src/utils";
import { EditIconSVG, LoaderSVG } from "src/assets/svg";
import { useTranslation } from "react-i18next";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import ProfileInfoReload from "./components/ProfileInfoReload";
import ProfileInfoEdit from "./components/ProfileInfoEdit";
import UploadItem from "src/components/form/upload";
import { language } from "src/static/const/auth";

function ProfileInfo() {
  const { t, i18n } = useTranslation();
  const { userData } = useContext(MainContext);
  const [visible, setVisible] = useState(false);
  const [drower, setDrower] = useState(false);
  const openModal = () => setVisible(true);
  const openDrower = () => setDrower(true);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <div>
      <CustomAlert
        title={t("profile.cabinet.info") || ""}
        info={t("profile.cabinet.big_info") || ""}
      />
      <div
        className="profile__education_header"
        style={{
          paddingBottom: 24,
          marginBottom: 24,
          borderBottom: "1px solid #F3F4F6",
        }}
      >
        <h2>{t("profile.common.personal_info")}</h2>
        <div className="profile__education_header_right">
          <CustomButton
            icon={<LoaderSVG />}
            left_icon
            color="green"
            outline
            onClick={openModal}
          >
            {t("profile.cabinet.refresh_info")}
          </CustomButton>
          <CustomButton
            icon={<EditIconSVG />}
            left_icon
            color="primary"
            onClick={openDrower}
          >
            {t("profile.cabinet.edit")}
          </CustomButton>
        </div>
      </div>
      <div className="profile__info">
        <UploadItem />
        <div className="profile__info-body">
          <h2>
            {userData?.first_name} {userData?.last_name} {userData?.middle_name}
          </h2>
          <div className="flex">
            <span>{t("profile.cabinet.gender_info")} :</span>
            <b>
              {userData?.gender === "male"
                ? t("profile.common.male")
                : t("profile.common.female")}
              , {findAge(userData?.birth_date)} {t("profile.common.age")} (
              {userData?.birth_date.replaceAll("-", ".")})
            </b>
          </div>
          <div className="flex">
            <span>{t("profile.common.citizenship")} :</span>
            <b>{giveLan(userData?.citizenship) || "-"}</b>
          </div>
          <div className="flex">
            <span>{t("profile.common.nationality")} :</span>
            <b>{giveLan(userData?.nationality) || "-"}</b>
          </div>
          <div className="flex">
            <span>{t("profile.common.adress_region")} :</span>
            <b>
              {giveLan(userData?.district?.region) || "-"},{" "}
              {giveLan(userData?.district) || "-"}
            </b>
          </div>
          <div className="flex">
            <span>{t("profile.common.adress")} :</span>
            <b>{userData?.address || "-"}</b>
          </div>
          <div className="flex">
            <span>{t("profile.common.pinfl")} :</span>
            <b>{userData?.pinfl || "-"}</b>
          </div>
          <div className="flex">
            <span>{t("profile.common.phone")} :</span>
            <b>{userData?.phone_number || "-"}</b>
          </div>
        </div>
      </div>
      <ProfileInfoReload visible={visible} setVisible={setVisible} />
      <ProfileInfoEdit drower={drower} setDrower={setDrower} />
    </div>
  );
}

export default ProfileInfo;
