import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FacebookSVG, InstagramSvg, TelegramSVG, YoutubeSVG } from "src/assets/svg";
import { isUser } from "src/static/const/auth";

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='footer' id='footer'>
      <div className='container'>
        <div className='footer__nav'>
          <div className='footer__info'>
            <div className='flex'>
              <a href='https://t.me/my_edu_uz' target={"_blank"} className='social'>
                <TelegramSVG />
              </a>
              <a href='https://www.instagram.com/edu.uz/' target={"_blank"} className='social'>
                <InstagramSvg />
              </a>
              <a href='https://www.facebook.com/eduuzofficial' target={"_blank"} className='social'>
                <FacebookSVG />
              </a>
              <a href='http://youtube.com/c/eduuz' target={"_blank"} className='social'>
                <YoutubeSVG />
              </a>
            </div>
          </div>

          <div className='footer__contact'>
            <div
              style={{ cursor: "pointer" }}
              className='contact'
              onClick={() =>
                isUser ? navigate("/profile") : (window.location.href = "/?modal=open&key=main")
              }
            >
              <span>{t("home.footer.cabinet")}</span>
            </div>
            <a target='_blank' href='tel:+998712306464' className='contact'>
              <span>{t("home.footer.phone")}: +998712306464</span>
            </a>

            <a href={"https://t.me/my_edu_yordam_bot"} target='_blank' className='contact'>
              <span>{t("home.footer.bot")}</span>
            </a>
          </div>

          <div className='footer__contact'>
            <a href='#integrations' className='contact' onClick={() => navigate("/")}>
              <span>{t("home.footer.integration")}</span>
            </a>
            <a href='#partners' className='contact' onClick={() => navigate("/")}>
              <span>{t("home.footer.partners")}</span>
            </a>
            {/* <a href='#blog' className='contact' onClick={() => navigate("/")}>
              <span>{t("home.footer.blog")}</span>
            </a> */}
          </div>

          <div className='footer__contact'>
            <a href={"https://t.me/my_edu_yordam_bot"} target='_blank' className='contact'>
              <span>{t("home.footer.help")}</span>
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.tmetjem.hemis&hl=ru&gl=US'
              target='_blank'
              className='contact'
            >
              <span>{t("home.footer.app")}</span>
            </a>
          </div>
        </div>
        <p>
          <a
            href='https://t.me/elektron_talim_markazi'
            target={"_blank"}
            style={{ color: "white" }}
          >
            {t("home.footer.madein")} <span>{t("home.footer.name")}</span>
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
