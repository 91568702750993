import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CloseIconSVG, DocSVG, MiniRightIconSVG, PdfSVG, ShareSVG } from "src/assets/svg";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import CustomBroudcamp from "src/components/layout/CustomBroudcamp";
import { GetApplications } from "src/pages/profile/applications/config";
import { GetServiceByIdConfig } from "src/service/api/services/inde";
import { language } from "src/static/const/auth";
import { giveLan } from "src/utils";
import { GenServiceToken, findAdmissionToken, findAdmissionUrl } from "../const";
import { CheckServiceConfig } from "../language/config";
import { SeriviceSlugTypeAttribute, ServiceIdTypes } from "../type";

function ServiceId() {
  const params = useParams();
  const serviceId = params.id;
  const { i18n, t } = useTranslation();

  const [data, setData] = useState<ServiceIdTypes>();
  const getFunc = () => {
    if (serviceId) {
      GetServiceByIdConfig(serviceId).then((res) => {
        setData(res.data);
      });
    }
  };

  //Check service funcions
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [loading, setLaoding] = useState(false);
  const slug = searchParams.get("slug") as SeriviceSlugTypeAttribute;

  const handleCheck = async () => {
    setLaoding(true);
    try {
      const { data } = await CheckServiceConfig(serviceId || "");
      let slug_arr = slug.split("_");
      if (data?.ok && slug === "foreign_language_certificate") {
        navigate("/service/language");
      } else if (data?.ok && slug === "qualification_certificate") {
        navigate("/service/qualification_certificate");
      } else if (data?.ok && slug === "getting_certificate_about_studying") {
        navigate("/service/studyDocument");
      } else if (data?.ok && slug === "getting_certificate_about_prof_studying") {
        navigate("/service/studyProf");
      } else if (data?.ok && slug === "getting_certificate_about_academic_degree") {
        navigate("/service/phdProf");
      } else if (data?.ok && slug_arr[slug_arr.length - 1] === "admission") {
        handleCheckAdmission();
      }
    } catch {}
    setLaoding(false);
  };

  const handleCheckAdmission = async () => {
    const response = await GetApplications();
    let appID = response.data.results.find((el: any) => el?.service?.id === serviceId);

    GenServiceToken(findAdmissionToken(slug), serviceId || "", slug).then((res) => {
      if (appID) {
        window.location.href = `/service/admission/detail?id=${appID?.external_id}&key=${slug}`;
      } else {
        window.location.href = `/service/admission/${findAdmissionUrl(slug)}/step1`;
      }
    });
  };

  const giveServiceDayCount = (end_date: string, start_date: string) => {
    const now = new Date().getTime();
    const endDate = new Date(end_date).getTime();
    const startDate = new Date(start_date).getTime();

    if (now < startDate) {
      const day = Math.floor(Math.abs(startDate - now) / 1000 / (60 * 60 * 24));
      return language === "uz"
        ? `Qabul boshlanishiga ${day < 1 ? "1" : day} kun qoldi`
        : `До приема заявок остается ${day < 1 ? "1" : day} день`;
    } else if (now > startDate && now < endDate) {
      const day = Math.floor(Math.abs(endDate - now) / 1000 / (60 * 60 * 24));
      return language === "uz"
        ? `Qabul yakunlanishiga ${day < 1 ? "1" : day} kun qoldi`
        : `До окончания приема заявок остается ${day < 1 ? "1" : day} день`;
    } else {
      return language === "uz" ? `Qabul yakunlangan !` : `Прием заявок завершен`;
    }
  };

  useEffect(() => {
    getFunc();
    i18n.changeLanguage(language);
  }, []);

  return (
    <div className='service seriveID'>
      <div className='container'>
        <CustomBroudcamp
          nav={[
            { name: "nav.service", link: "/service?key=all" },
            {
              name: giveLan(data),
              link: "",
            },
          ]}
        />

        <div className='serviceID__navbar'>
          {data?.information ? (
            <h1>{giveLan(data)}</h1>
          ) : (
            <ContentLoader
              className='loader-component'
              speed={2}
              width={500}
              height={48}
              viewBox='0 0 500 48'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
            >
              <rect x='5' y='10' rx='3' ry='3' width='500' height='32' />
            </ContentLoader>
          )}

          {data?.information ? (
            <a
              href={`https://t.me/share/url?url=${window.location.href}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <CustomButton icon={<ShareSVG />} left_icon>
                {t("service.share")}
              </CustomButton>
            </a>
          ) : (
            <ContentLoader
              className='loader-component'
              speed={2}
              width={200}
              height={48}
              viewBox='0 0 200 48'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
            >
              <rect x='5' y='10' rx='3' ry='3' width='200' height='32' />
            </ContentLoader>
          )}
        </div>

        <div className='serviceID__body'>
          <div className='serviceID__main'>
            {/* INformarmation section */}
            <section className='serviceID__information'>
              <h2>{t("service.serviceID.title")}</h2>
              <div className='flex'>
                <div>
                  <h3>{t("service.serviceID.users")}</h3>
                  {data?.information ? (
                    <p>{giveLan(data?.information, "users")}</p>
                  ) : (
                    <ContentLoader
                      className='loader-component'
                      speed={2}
                      width={300}
                      height={48}
                      viewBox='0 0 300 48'
                      backgroundColor='#f3f3f3'
                      foregroundColor='#ecebeb'
                    >
                      <rect x='5' y='10' rx='3' ry='3' width='300' height='32' />
                    </ContentLoader>
                  )}
                </div>
                <div>
                  <h3>{t("service.serviceID.legacy")}</h3>
                  {data?.information ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: giveLan(data?.information, "legal_basis"),
                      }}
                    />
                  ) : (
                    <ContentLoader
                      className='loader-component'
                      speed={2}
                      width={300}
                      height={48}
                      viewBox='0 0 300 48'
                      backgroundColor='#f3f3f3'
                      foregroundColor='#ecebeb'
                    >
                      <rect x='5' y='10' rx='3' ry='3' width='300' height='32' />
                    </ContentLoader>
                  )}
                </div>
              </div>
              <div className='serviceID__information-detail'>
                <h3>{t("service.serviceID.doc")}</h3>
                {data?.information ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: giveLan(data?.information, "necessary_documents"),
                    }}
                  />
                ) : (
                  <>
                    <ContentLoader
                      className='loader-component'
                      speed={2}
                      width={500}
                      height={48}
                      viewBox='0 0 500 48'
                      backgroundColor='#f3f3f3'
                      foregroundColor='#ecebeb'
                    >
                      <rect x='5' y='10' rx='3' ry='3' width='500' height='32' />
                    </ContentLoader>
                    <ContentLoader
                      className='loader-component'
                      speed={2}
                      width={500}
                      height={48}
                      viewBox='0 0 500 48'
                      backgroundColor='#f3f3f3'
                      foregroundColor='#ecebeb'
                    >
                      <rect x='5' y='10' rx='3' ry='3' width='500' height='32' />
                    </ContentLoader>
                  </>
                )}
              </div>
            </section>

            {/* Video section */}
            {data?.video_guide ? (
              <section className='serviceID__video'>
                <h2>{t("service.serviceID.video")}</h2>
                <CustomAlert
                  title={t("service.usefull") || ""}
                  info={giveLan(data?.video_guide, "caption")}
                />
                {/* <div
                  className="serviceID__video-video"
                  onClick={() => setShow(true)}
                >
                  <PlayIconSVG />
                </div> */}
                <iframe
                  className='serviceID__iframe'
                  src={data?.video_guide?.video_url_uz}
                  title={giveLan(data?.video_guide, "caption")}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                />
              </section>
            ) : (
              <></>
            )}

            {/* Apply steps */}
            {data?.application_steps ? (
              <section className='serviceID__steps'>
                <h2>{t("service.serviceID.steps_title")}</h2>
                <div className='flex'>
                  {data.application_steps.map((item, index) => (
                    <div className='step' key={crypto.randomUUID()}>
                      <span>{index + 1}</span>
                      <div>
                        <h3>{giveLan(item, "title")}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: giveLan(item, "description"),
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            ) : (
              <></>
            )}

            {/* Documents */}
            {data?.documents.length ? (
              <section className='serviceID__documents'>
                <h2>{t("service.serviceID.docs_link")}</h2>

                <div className='serviceID__documents-list'>
                  {data?.documents.map((item) => (
                    <div className='serviceID__document' key={crypto.randomUUID()}>
                      <div className='flex'>
                        {item.type === "pdf" ? <PdfSVG /> : item.type === "doc" ? <DocSVG /> : ""}
                        <div>
                          <h3>{giveLan(item)}</h3>
                          <p>{item.size} Мб</p>
                        </div>
                      </div>
                      <div className='flex'>
                        <a href={item.file} target='_blank' rel='noreferrer'>
                          <CustomButton color='dark' outline>
                            {t("service.serviceID.view")}
                          </CustomButton>
                        </a>
                        <a href={item.file} target='_blank' rel='noreferrer'>
                          <CustomButton>{t("service.serviceID.download")}</CustomButton>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            ) : (
              <></>
            )}

            {/* <section className="serviceID__statistics">
              <h2>Akademik litseylar arizalar statistikasi</h2>

              <CustomAlert
                title="Foydali resurs"
                info="Pastdagi XLS file orqali siz filtrda bergan qidiruvingizga mos XLS file natijalarini yuklab olishingiz mumkin. "
              />

              <div className="serviceID__documents-list">
                <div className="serviceID__document">
                  <div className="flex">
                    <XlsSVG />
                    <div>
                      <h3>Qabul parametrlari</h3>
                      <p>1.3 Мб</p>
                    </div>
                  </div>
                  <div className="flex">
                    <CustomButton color="dark" outline>
                      Onlayn ko‘rish
                    </CustomButton>
                    <CustomButton>Yuklab olish</CustomButton>
                  </div>
                </div>
              </div>
            </section> */}

            <div className='serviceID__mobile'>
              {data?.availability_end_time && (
                <CustomAlert
                  type='warning'
                  title={giveServiceDayCount(
                    data?.availability_end_time,
                    data?.availability_start_time || "",
                  )}
                  info={""}
                />
              )}
            </div>
            {data?.availability_end_time &&
            [`Qabul yakunlangan !`, `Прием заявок завершен`].includes(
              giveServiceDayCount(data?.availability_end_time, data?.availability_start_time || ""),
            ) ? (
              <></>
            ) : (
              <div className='serviceID__status'>
                <div className='flex'>
                  <p>{t("service.serviceID.app_day")}</p>
                  <h3>{data?.today_applications_count}</h3>
                </div>
                <div className='flex'>
                  <p>{t("service.serviceID.app_all")}</p>
                  <h3>{data?.total_applications_count}</h3>
                </div>

                <CustomButton
                  color='primary'
                  size='l'
                  icon={<MiniRightIconSVG />}
                  onClick={handleCheck}
                  loading={loading}
                  disabled={!data?.information}
                >
                  {t("service.serviceID.app_usage")}
                </CustomButton>
              </div>
            )}
          </div>

          {/* Right sidebar notifications */}
          <div className='serviceID__right'>
            {data?.availability_end_time && (
              <CustomAlert
                type='warning'
                title={giveServiceDayCount(
                  data?.availability_end_time,
                  data?.availability_start_time || "",
                )}
                info={""}
              />
            )}

            {data?.availability_end_time &&
            [`Qabul yakunlangan !`, `Прием заявок завершен`].includes(
              giveServiceDayCount(data?.availability_end_time, data?.availability_start_time || ""),
            ) ? (
              <></>
            ) : (
              <div className='serviceID__status'>
                <div className='flex'>
                  <p>{t("service.serviceID.app_day")}</p>
                  <h3>{data?.today_applications_count}</h3>
                </div>
                <div className='flex'>
                  <p>{t("service.serviceID.app_all")}</p>
                  <h3>{data?.total_applications_count}</h3>
                </div>

                <CustomButton
                  color='primary'
                  size='l'
                  icon={<MiniRightIconSVG />}
                  onClick={handleCheck}
                  loading={loading}
                  disabled={!data?.information}
                >
                  {t("service.serviceID.app_usage")}
                </CustomButton>
              </div>
            )}
          </div>

          <Modal show={show} className='youtubePLayer__modal'>
            <div className='youtubePLayer'>
              {data?.video_guide && (
                <iframe
                  src={giveLan(data?.video_guide)}
                  title={giveLan(data?.video_guide, "caption")}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                />
              )}

              <CustomButton
                size='sm'
                color='gray'
                icon={<CloseIconSVG />}
                onClick={() => setShow(false)}
              />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ServiceId;
