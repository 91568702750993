import { CloseSvg } from "src/assets/svg";
import { CustomDrowerProps } from "src/static/interface/common";
import CustomButton from "../button";
import { useEffect } from "react";

function CustomDrower({
  title,
  visible,
  blured,
  okText,
  cancelText,
  onOk,
  onCancel,
  okBtnLoading,
  cancelBtnLoading,
  width = 662,
  placement = "right",
  children,
}: CustomDrowerProps) {
  useEffect(() => {
    if (visible) document.body.classList.add("scrollbar-hidden");
    else document.body.classList.remove("scrollbar-hidden");
  }, [visible]);
  return (
    <div
      className={`custom-drower ${
        visible && (blured ? "custom-drower-blured" : "custom-drower-visible")
      } ${visible}`}
    >
      <div
        className={`custom-drower-card-${placement} bg-white ${
          !visible && `custom-drower-card-${placement}-${width}`
        }`}
        style={{ width: width }}
      >
        <div className="custom-drower-header">
          <h5 id={title}>{title}</h5>
          <button
            onClick={onCancel}
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <CloseSvg />
            <span className="sr-only">Close menu</span>
          </button>
        </div>

        <div className="custom-drower-content">{children}</div>
        <div className="custom-drower-footer">
          {cancelText && (
            <CustomButton
              type="button"
              color="gray"
              size="xl"
              onClick={onCancel}
              loading={cancelBtnLoading}
            >
              {cancelText}
            </CustomButton>
          )}
          {okText && (
            <CustomButton
              type="button"
              color="primary"
              size="xl"
              onClick={onOk}
              loading={okBtnLoading}
            >
              {okText}
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomDrower;
