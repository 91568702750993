import { useCallback, useContext, useRef, useState } from "react";
import { Modal, RangeSlider } from "flowbite-react";
import {
  ArrowForwardSvg,
  CorrectSvg,
  EditIconSVG,
  InCorrectSvg,
  MinusSvg,
  PhotoSvg,
  PlusSvg,
  ReloadSvg,
} from "src/assets/svg";
import CustomButton from "src/components/common/button";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { ChangeUserPhotoConfig } from "src/service/api/multipart";
import { useTranslation } from "react-i18next";
import { USER_DATA } from "src/static/const/auth";
import { MainContext } from "src/hooks/context";

function UploadItem() {
  const { t } = useTranslation();
  const { userData, setUserData } = useContext(MainContext);

  //Modal section
  const [visible, setVisible] = useState(false);
  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  //Upload section
  const [imageSrc, setImageSrc] = useState<any>();
  const fileInput = useRef<HTMLInputElement>(null);
  const handleUpload = () => {
    fileInput.current?.click();
  };
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    let imageDataUrl = await readFile(file);
    imageDataUrl && setImageSrc(imageDataUrl);
  };
  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  //Crop section
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      let blob = await fetch(croppedImage)
        .then((r) => r.blob())
        .then((blobFile) => new File([blobFile], "ghhhj.png"));

      const formData = new FormData();
      formData.append("file", blob);
      ChangeUserPhotoConfig(formData).then(({ data }) => {
        localStorage.setItem(USER_DATA, JSON.stringify(data));
        setUserData?.(data);
      });

      setVisible(false);
    } catch (e) {}
  }, [croppedAreaPixels]);

  return (
    <div className="custom-upload">
      {userData?.photo ? (
        <div className="custom-upload-croppedImg">
          <div className="custom-upload-croppedImg-wrap">
            <div onClick={openModal}>
              <EditIconSVG />
            </div>
          </div>
          <img
            src={
              userData?.photo.file.includes("http://my.edu.uz/")
                ? userData.photo.file
                : `https://my.edu.uz/${userData?.photo.file}`
            }
            alt="cropped img errror"
          />
        </div>
      ) : (
        <div className="custom-upload-card" onClick={openModal}>
          <PhotoSvg />
          <h3>{t("profile.common.photo_upload")}</h3>
          <p>{t("profile.common.photo")}</p>
        </div>
      )}

      <Modal
        show={visible}
        onClose={closeModal}
        size="lg"
        className="login custom-upload-modal"
      >
        {imageSrc ? (
          <>
            <Modal.Header className="custom-upload-modal-header">
              {t("photo.title4")}
            </Modal.Header>
            <Modal.Body className="custom-upload-modal-body">
              <div className="custom-upload-crop">
                <div className="custom-upload-crop-content">
                  <div className="custom-upload-crop-content-left">
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      aspect={0.78}
                      classes={{
                        containerClassName: "custom-upload-crop-container",
                        cropAreaClassName: "custom-upload-crop-area",
                      }}
                    />
                  </div>
                  <div className="custom-upload-crop-content-right">
                    <p>{t("photo.title4_info")}</p>
                    <p>{t("photo.title4_info2")}</p>
                  </div>
                </div>
                <div className="custom-upload-crop-zoom">
                  <div
                    className="button"
                    onClick={() => {
                      if (zoom !== 1) setZoom(zoom - 0.1);
                    }}
                  >
                    <MinusSvg />
                  </div>
                  <RangeSlider
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    aria-labelledby="Zoom"
                    onChange={(e) => setZoom(+e.target.value)}
                    className="custom-upload-crop-zoom-range"
                  />
                  <div
                    className="button"
                    onClick={() => {
                      if (zoom.toString().slice(0, 1) !== "3")
                        setZoom(zoom + 0.1);
                    }}
                  >
                    <PlusSvg />
                  </div>
                  <div className="button" onClick={() => setZoom(1)}>
                    <ReloadSvg />
                  </div>
                </div>
                <div className="custom-upload-modal-footer">
                  <CustomButton color="primary" outline onClick={handleUpload}>
                    {t("photo.select_anather")}
                  </CustomButton>
                  <CustomButton color="primary" onClick={showCroppedImage}>
                    {t("photo.save")}
                  </CustomButton>
                </div>
              </div>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header className="custom-upload-modal-header">
              {t("photo.title")}
            </Modal.Header>
            <Modal.Body className="custom-upload-modal-body">
              <div className="custom-upload-info">
                <div className="custom-upload-info-left"></div>
                <div>
                  <h2>{t("photo.title1")}</h2>
                  <p>{t("photo.title1_info")}</p>
                </div>
              </div>

              <div className="custom-upload-line" />
              <h2>{t("photo.title2")}</h2>
              <p>{t("photo.title2_info")}</p>

              <div className="custom-upload-images">
                <div>
                  <img
                    src={require("src/assets/images/upload1.png")}
                    alt="upload1"
                  />
                  <p>
                    <CorrectSvg /> {t("photo.image1")}
                  </p>
                </div>
                <div>
                  <img
                    src={require("src/assets/images/upload2.png")}
                    alt="upload1"
                  />
                  <p>
                    <InCorrectSvg /> {t("photo.image2")}
                  </p>
                </div>
                <div>
                  <img
                    src={require("src/assets/images/upload3.png")}
                    alt="upload1"
                  />
                  <p>
                    <InCorrectSvg /> {t("photo.image3")}
                  </p>
                </div>
              </div>

              <div className="custom-upload-line" />
              <h2 style={{ marginBottom: 8 }}>{t("photo.title3")}</h2>
              <p style={{ marginBottom: 4 }}>{t("photo.title3_info")}</p>
              <p style={{ marginBottom: 4 }}>{t("photo.title3_info2")}</p>
              {/* <p>erkin yuqori chegara 5(±1) mm</p> */}
              {/* <div className="custom-upload-line" /> */}

              {/* <div className="custom-upload-info">
                <div
                  className="custom-upload-info-left"
                  style={{ height: 48 }}
                ></div>
                <p>
                  To'liq yuz, neytral ifoda, yopiq og'iz va ochiq ko'zlar.
                  To'liq yuz - bu kameraga qaragan fotosurat. Siz
                  to'g'ridan-to'g'ri linzaga qarashingiz kerak. Hech narsa yuzni
                  qoplamasligi kerak. Surat yoshga mos keladi
                </p>
              </div> */}

              <div className="custom-upload-modal-footer">
                <CustomButton
                  color="primary"
                  icon={<ArrowForwardSvg />}
                  onClick={handleUpload}
                >
                  {t("profile.common.continue")}
                </CustomButton>
              </div>

              <input
                type="file"
                accept="image/*"
                ref={fileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
}

export default UploadItem;
