import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "flowbite-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ActiveStar, PassiveStar } from "src/assets/svg";
import { rateData } from "src/static/data";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import CustomButton from "src/components/common/button";
import { giveRangingConfig } from "src/pages/services/config";
import TextareaItem from "../form/textarea";

type CardTypes = {
  comment: string;
};

interface CardProps {
  id: string;
  visible: boolean;
  closeModal: () => void;
}

function ResultCard({ visible = false, closeModal, id }: CardProps) {
  const { t } = useTranslation();
  const [rate, setRate] = useState(1);

  const schema = yup.object({
    text: yup.string().required(),
  });
  const {
    handleSubmit: submit,
    control,
    formState: { errors },
  } = useForm<CardTypes>({
    resolver: yupResolver(schema),
  });
  const addRanking = async (data: CardTypes) => {
    await giveRangingConfig(id, {
      ...data,
      grade: rate,
    });
    closeModal();
  };
  return (
    <Modal show={visible} onClose={closeModal} size='lg' className='login resultcard'>
      <Modal.Header className='login-header'>
        <p>{t("registration.congratulation")}!</p>
        <p>Sizning arizangiz muvaffaqiyatli yuborildi.</p>
      </Modal.Header>
      <Modal.Body className='login-body'>
        <form className='resultcard-content' onSubmit={submit(addRanking)}>
          <div className='resultcard-content-title'>Xizmatni baholash</div>
          <div className='resultcard-content-rate'>
            {rateData.map((item) => {
              return (
                <div onClick={() => setRate(item.rate)} key={item.rate}>
                  {item.rate <= rate ? <ActiveStar /> : <PassiveStar />}
                </div>
              );
            })}
          </div>
          <div className='resultcard-content-rate-title'>
            {rateData.find((el) => el.rate === rate)?.value}
          </div>

          <Controller
            name='comment'
            control={control}
            render={({ field }) => (
              <TextareaItem
                label='Fikr bildirish'
                field={field}
                className='full'
                placeholder='Iltimos fikringizni yozib qoldiring. Sizning fikringiz ushbu portalni yanada qulay qilish uchun juda muhim'
                rows={4}
              />
            )}
          />

          <CustomButton
            color='primary'
            size='xl'
            style={{ width: "100%", marginTop: 12 }}
            type='submit'
            // disabled={watch("text") || rate ? false : true}
          >
            Baholash
          </CustomButton>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ResultCard;
