import { Modal } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { LoginSvg } from "src/assets/svg";
import { LoginInformsTypes } from "src/static/interface/login";
import { useTranslation } from "react-i18next";

import LoginSignUp from "./singup";
import LoginForget from "./forget";
import LoginMain from "./main";
import LoginSignIn from "./signin";
import LoginReset from "./reset";
import LoginCheck from "./check";
import LoginPassword from "./password";
import LoginFinished from "./finished";
import CustomButton from "src/components/common/button";
import { MainContext } from "src/hooks/context";

function Login({
  visible,
  setVisible,
  defaultPage,
  setDefaultPage,
}: {
  visible: boolean;
  defaultPage?: string;
  setVisible: (val: boolean) => void;
  setDefaultPage: (val: string) => void;
}) {
  const { handleMakeParams } = useContext(MainContext);
  const { t } = useTranslation();

  const [informs, setInforms] = useState<LoginInformsTypes>({});

  const openModal = () => setVisible(true);
  const closeModal = () => {
    setVisible(false);
    setDefaultPage("main");
    handleMakeParams?.("modal", "");
    handleMakeParams?.("key", "");

    if (defaultPage === ("finished" || "signin")) window.location.href = "/";
  };
  const changePhone = (val: string) => {
    informs.phone_number = val;
    setInforms(informs);
  };
  const changeCredential = (val: string, val2: string) => {
    informs.credential_id = val;
    informs.step = val2;
    setInforms(informs);
  };
  const changeOneTimePass = (val: string) => {
    informs.one_time_password = val;
    setInforms(informs);
  };

  return (
    <div className="loginBtn">
      <CustomButton
        onClick={openModal}
        size="l"
        color="glass"
        icon={<LoginSvg />}
      >
        {t("navbar.login")}
      </CustomButton>
      <Modal show={visible} onClose={closeModal} size="md" className="login">
        {defaultPage === "main" ? (
          <LoginMain setStep={setDefaultPage} changePhone={changePhone} />
        ) : defaultPage === "signup" ? (
          <LoginSignUp
            setStep={setDefaultPage}
            informs={informs}
            changeCredential={changeCredential}
          />
        ) : defaultPage === "forget" ? (
          <LoginForget
            setStep={setDefaultPage}
            changeCredential={changeCredential}
          />
        ) : defaultPage === "signin" ? (
          <LoginSignIn
            setStep={setDefaultPage}
            informs={informs}
            closeModal={closeModal}
          />
        ) : defaultPage === "reset" ? (
          <LoginReset
            setStep={setDefaultPage}
            changeCredential={changeCredential}
            informs={informs}
          />
        ) : defaultPage === "check" ? (
          <LoginCheck
            setStep={setDefaultPage}
            informs={informs}
            changeOneTimePass={changeOneTimePass}
          />
        ) : defaultPage === "password" ? (
          <LoginPassword setStep={setDefaultPage} informs={informs} />
        ) : defaultPage === "finished" ? (
          <LoginFinished closeModal={closeModal} />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}

export default Login;
