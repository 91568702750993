import { CheckNarrowIconSVG, CloseIconSVG } from "src/assets/svg";
import { SeriviceSlugTypeAttribute } from "src/pages/services/type";

function GiveSteps({ status = "1", slug }: { status: string; slug?: SeriviceSlugTypeAttribute }) {
  if (
    ["foreign_language_certificate", "vocational_school_admission", "interview_admission"].includes(
      slug || "",
    )
  ) {
    return (
      <>
        <div className={`detailPage__step detailPage__step-done`}>
          <div className='flex'>
            <div className='detailPage__step-number'>{true ? <CheckNarrowIconSVG /> : "1"}</div>
            <h3>Ariza yuborildi</h3>
          </div>
        </div>
        <div
          className={`detailPage__step ${
            status == "2"
              ? "detailPage__step-error"
              : [1, "1", "CREATED"].includes(status)
              ? "detailPage__step-current"
              : "detailPage__step-done"
          }`}
        >
          <div className='flex'>
            <div className='detailPage__step-number'>
              {status == "2" ? (
                <CloseIconSVG />
              ) : [1, "1", "CREATED"].includes(status) ? (
                "2"
              ) : (
                <CheckNarrowIconSVG />
              )}
            </div>
            <h3>
              {status == "2"
                ? `Ariza rad etildi`
                : +status >= 3
                ? `Ariza qabul qilindi`
                : `Ariza ko'rib chiqilmoqda`}
            </h3>
          </div>
        </div>
        <div
          className={`detailPage__step  ${+status > 4 ? `detailPage__step-done` : ""}
          `}
        >
          <div className='flex'>
            <div className='detailPage__step-number'>
              {status > "4" ? <CheckNarrowIconSVG /> : "3"}
            </div>
            <h3>{status > "4" ? `Tasdiqlandi` : `Tasdiqlandi / Tasdiqlanmadi`}</h3>
          </div>
        </div>
      </>
    );
  } else if (slug == "secondary_education_admission") {
    return (
      <>
        <div
          className={`detailPage__step ${
            status == "Diplom Haqiqiyligi tekshirilmoqda"
              ? "detailPage__step-current"
              : status == "Diplom Rad etildi"
              ? "detailPage__step-error"
              : "detailPage__step-done"
          }`}
        >
          <div className='flex'>
            <div className='detailPage__step-number'>
              {status == "Diplom Haqiqiyligi tekshirilmoqda" ? (
                "1"
              ) : status == "Diplom Rad etildi" ? (
                <CloseIconSVG />
              ) : (
                <CheckNarrowIconSVG />
              )}
            </div>
            <h3>
              {status == "Diplom Haqiqiyligi tekshirilmoqda"
                ? "Diplom haqiqiyligi tekshirilmoqda"
                : status == "Diplom Rad etildi"
                ? "Diplom rad etildi"
                : "Diplom tasdiqlangan"}
            </h3>
          </div>
        </div>

        <div
          className={`detailPage__step ${
            ["Ariza tasdiqlandi", "Ariza rad etildi"].includes(status)
              ? "detailPage__step-done"
              : status == "Diplom Tasdiqlangan"
              ? "detailPage__step-current"
              : ""
          }`}
        >
          <div className='flex'>
            <div className='detailPage__step-number'>
              {["Ariza tasdiqlandi", "Ariza rad etildi"].includes(status) ? (
                <CheckNarrowIconSVG />
              ) : (
                "2"
              )}
            </div>
            <h3>Ariza ko‘rib chiqilmoqda</h3>
          </div>
        </div>

        <div
          className={`detailPage__step ${
            status == "Ariza tasdiqlandi"
              ? "detailPage__step-done"
              : status == "Ariza rad etildi"
              ? "detailPage__step-error"
              : ""
          }`}
        >
          <div className='flex'>
            <div className='detailPage__step-number'>
              {status == "Ariza tasdiqlandi" ? (
                <CheckNarrowIconSVG />
              ) : status == "Ariza rad etildi" ? (
                <CloseIconSVG />
              ) : (
                "3"
              )}
            </div>
            <h3>
              {status == "Ariza tasdiqlandi"
                ? "Ariza tasdiqlandi"
                : status == "Ariza rad etildi"
                ? "Ariza rad etildi"
                : "Ariza tasdiqlandi / Ariza rad etildi"}
            </h3>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={`detailPage__step detailPage__step-done`}>
          <div className='flex'>
            <div className='detailPage__step-number'>{true ? <CheckNarrowIconSVG /> : "1"}</div>
            <h3>Ariza yuborildi</h3>
          </div>
        </div>
        <div
          className={`detailPage__step ${
            status == "2"
              ? "detailPage__step-error"
              : status == "1"
              ? "detailPage__step-current"
              : "detailPage__step-done"
          }`}
        >
          <div className='flex'>
            <div className='detailPage__step-number'>
              {status == "2" ? <CloseIconSVG /> : status == "1" ? "2" : <CheckNarrowIconSVG />}
            </div>
            <h3>
              {status == "2"
                ? `Ariza rad etildi`
                : +status >= 3
                ? `Ariza qabul qilindi`
                : `Ariza ko'rib chiqilmoqda`}
            </h3>
          </div>
        </div>
        <div
          className={`detailPage__step ${
            status == "3" ? `detailPage__step-current` : +status >= 4 ? `detailPage__step-done` : ``
          }`}
        >
          <div className='flex'>
            <div className='detailPage__step-number'>
              {+status > 3 ? <CheckNarrowIconSVG /> : "3"}
            </div>
            <h3>{+status > 3 ? `Test kuni aniqlandi` : `Test kuni aniqlanmoqda`}</h3>
          </div>
        </div>
        <div
          className={`detailPage__step ${
            status == "5"
              ? `detailPage__step-error`
              : status == "7"
              ? `detailPage__step-done`
              : status == "4"
              ? `detailPage__step-current`
              : ``
          }`}
        >
          <div className='flex'>
            <div className='detailPage__step-number'>
              {status == "5" ? <CloseIconSVG /> : status == "7" ? <CheckNarrowIconSVG /> : "4"}
            </div>
            <h3>
              {status == "7"
                ? `Test natijasi e'lon qilindi`
                : status == "5"
                ? `Tavsiya etilmadi (o’qishga kirmadingiz)`
                : `Test natijasi`}
            </h3>
          </div>
        </div>
      </>
    );
  }
}

export default GiveSteps;
