import { BrowserRouter as Router } from "react-router-dom";
import MainContextProvider from "./hooks/context";
import RoutElements from "./router/routes";

import i18n from "i18next";
import { Toaster } from "react-hot-toast";
import { initReactI18next } from "react-i18next";
import { language } from "./static/const/auth";
import languageRu from "./static/language/ru.json";
import languageUz from "./static/language/uz.json";

function App() {
  i18n.use(initReactI18next).init({
    resources: {
      uz: { translation: languageUz },
      ru: { translation: languageRu },
    },
    fallbackLng: language || "uz",
    interpolation: { escapeValue: false },
  });

  return (
    <Router>
      <Toaster />
      <MainContextProvider>
        <RoutElements />
      </MainContextProvider>
    </Router>
  );
}

export default App;
