import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NavigateSVG } from "src/assets/svg";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import CustomDrower from "src/components/common/drower";
import TextareaItem from "src/components/form/textarea";
import CustomBroudcamp from "src/components/layout/CustomBroudcamp";
import CustomLoader from "src/components/layout/CustomLoader";
import { MainContext } from "src/hooks/context";
import { SECONDARY_ADMISSION_TOKEN } from "src/static/const/auth";
import {
  PhdDocumentPDF,
  ProfStudyDocumentPDF,
  StudyDocumentPDF,
  findAge,
  giveLan,
} from "src/utils";
import * as yup from "yup";
import {
  GenServiceToken,
  checkEditApplication,
  editApplication,
  findAdmissionToken,
} from "../../const";
import { SeriviceSlugTypeAttribute } from "../../type";
import {
  AppealConfig,
  GetDiplomConfig,
  GetInterviewApplicationConfig,
  GetUserConfig,
} from "../interview_admission/config";
import { subject } from "../interview_admission/type";
import { AppealLyceumApplication } from "../lyceum/config";
import { GetAppDetailSEConfig } from "../secondary_education/config";
import { IAppDetailSE } from "../secondary_education/type";
import { GETVocationApplication } from "../vocation/config";
import CardStatus from "./components/cardStatus";
import GiveSteps from "./components/giveSteps";
import MessageItem from "./components/messageItem";
import { GetApplicationIDConfig } from "./config";

function AdmissionDetail() {
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>({});
  const [exInfo, setExInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { userData } = useContext(MainContext);
  const [drower, setDrower] = useState(false);
  const [comments, setComments] = useState<any>([]);
  const [grades, setGrades] = useState<any>([]);
  const [detailSE, setDetailSE] = useState<IAppDetailSE>();
  const userRealdata = [`vocational_school_admission`].includes(searchParam.get("key") || "")
    ? info?.user
    : userData;

  const token = localStorage.getItem(SECONDARY_ADMISSION_TOKEN);

  const getInfo = async () => {
    setLoading(true);

    let currentData;
    if (searchParam.get("key") == "interview_admission") {
      const { data } = await GetInterviewApplicationConfig();
      const profile = await GetUserConfig();
      await GetDiplomConfig().then((res) => {
        setExInfo({ ...data, diplom: res?.data, info: profile?.data });
        currentData = { ...data, diplom: res?.data, info: profile?.data };
        AllGrades(res?.data?.grades);
      });
    } else if (searchParam.get("key") === "secondary_education_admission" && token) {
      GetAppDetailSEConfig(token)
        .then((res) => {
          setDetailSE(res.data.object);
          currentData = res.data.object;
        })
        .catch((err) => {
          if (err.response.data?.message === "Expirete Token") {
            navigate("/service");
            localStorage.removeItem(SECONDARY_ADMISSION_TOKEN);
            toast.error(t("message.expire_warning"));
          }
        });
    } else if (searchParam.get("key") === "lyceum_admission") {
      // const { data } = await GetLyceumAppIDConfig(searchParam.get("id") || "");
      // currentData = {
      //   ...data,
      //   user: { ...data?.user, photo: { file: data?.user?.photos[0]?.file } },
      // };
    } else if (searchParam.get("key") === "vocational_school_admission") {
      const { data } = await GETVocationApplication(searchParam.get("id") || "");
      currentData = {
        ...data,
        user: { ...data?.user, photo: { file: data?.user?.photos[0]?.file } },
      };
    } else {
    }
    const { data } = await GetApplicationIDConfig(searchParam.get("id") || "");
    setInfo({ ...data, ...currentData });
    setLoading(false);
  };

  useEffect(() => {
    if (searchParam.get("key") == "secondary_education_admission" && info?.service?.id && !token) {
      GenServiceToken(
        findAdmissionToken("secondary_education_admission"),
        info?.service?.id || "",
        "secondary_education_admission",
      ).then(() => {
        window.location.reload();
      });
    }
  }, [info]);

  const giveComments = (slug: string, val?: string) => {
    setDrower(true);
    if (slug === "lyceum_admission") {
      if (val === "reject") {
        setComments(info?.lyceum_selection_histories?.filter((item: any) => item?.status == "2"));
      } else {
        setComments(info?.lyceum_selection_histories);
      }
    }
    if (slug === "vocational_school_admission") {
      setComments(info?.data?.histories);
    }
    if (slug === "secondary_education_admission") {
      let arr = [];
      if (detailSE?.applicationMessage) {
        if ([`Ariza rad etildi`, `Rad etildi`].includes(detailSE.status)) {
          arr.push({
            added_time: "",
            initiator: "",
            univer: detailSE.kvota.universityName,
            status: "2",
            text: detailSE?.applicationMessage,
          });
        } else {
          arr.push({
            added_time: "",
            initiator: "",
            univer: detailSE.kvota.universityName,
            status: "6",
            text: detailSE.applicationMessage,
          });
        }
      }
      if (detailSE?.diplomaMessage) {
        if ([`Ariza rad etildi`, `Rad etildi`].includes(detailSE.status)) {
          arr.push({
            added_time: "",
            univer: detailSE.diplomaResponse.institutionName,
            status: "2",
            text: detailSE.diplomaMessage,
          });
        } else {
          arr.push({
            added_time: "",
            univer: detailSE.diplomaResponse.institutionName,
            status: "6",
            text: detailSE.diplomaMessage,
          });
        }
      }
      setComments(arr);
    }
  };
  const AllGrades = (grades: any) => {
    const FullSemesters: subject[] = [];
    const BadSemesters: subject[] = [];
    grades.forEach((semestr: any) => {
      for (let item in semestr) {
        FullSemesters.push(semestr[item]);

        // Get all 3 baholilar
        semestr[item].map((sub: subject) => {
          if (sub.ball == 3) {
            BadSemesters.push(sub);
          }
        });
      }
    });
    setGrades(FullSemesters);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<{ reason: string }>({
    resolver: yupResolver(
      yup
        .object({
          reason: yup.string().required(),
        })
        .required(),
    ),
  });
  const closeModal = () => {
    setVisible(false);
  };
  const onSubmit = async (data: any) => {
    searchParam.get("key") === "lyceum_admission"
      ? await AppealLyceumApplication(searchParam.get("id") || "", data)
      : await AppealConfig({ content: data?.reason });
    await getInfo();
    toast.success(`Appelatsiya yuborildi !`);
    setValue("reason", "");
    setVisible(false);
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div className='detailPage'>
      <div className='container'>
        <CustomBroudcamp
          nav={[
            { name: "nav.service", link: "/service?key=all" },
            { name: "nav.app_detail", link: "" },
          ]}
        />

        {/* Service ID title -----------------------------------------------*/}
        <h1 className='detailPage__title'>{giveLan(info?.service)}</h1>

        {/* Serive ID body ------------------------------------------------- */}
        <div className='flex'>
          <div className='detailPage__main'>
            {/* Profile and applications info all services same------------------------------- */}
            <div className='applicationCard__header'>
              <h3>{t("profile.common.personal_info")}</h3>
              <div className='flex'>
                <h4>
                  {t("profile.current_edu.app_id")}: <b>{info?.id}</b>
                </h4>
                <h4>
                  {t("profile.current_edu.app_sendTime")}:{" "}
                  <b>{new Date(info?.added_time).toLocaleString()}</b>
                </h4>
              </div>
            </div>
            <div className='profile__info'>
              <img
                src={
                  userRealdata?.photo?.file?.includes("http://my.edu.uz/media/user-photo-files/")
                    ? userRealdata?.photo?.file
                    : `https://my.edu.uz/${userRealdata?.photo.file}`
                }
                alt='user image error'
              />
              <div>
                <h2>
                  {userRealdata?.first_name} {userRealdata?.last_name} {userRealdata?.middle_name}
                </h2>
                <div className='flex'>
                  <span>{t("profile.cabinet.gender_info")} :</span>
                  <b>
                    {userRealdata?.gender === "male"
                      ? t("profile.common.male")
                      : t("profile.common.female")}
                    , {findAge(userRealdata?.birth_date)} {t("profile.common.age")} (
                    {userRealdata?.birth_date.replaceAll("-", ".")})
                  </b>
                </div>
                <div className='flex'>
                  <span>{t("profile.common.citizenship")} :</span>
                  <b>{giveLan(userRealdata?.citizenship) || "-"}</b>
                </div>
                <div className='flex'>
                  <span>{t("profile.common.nationality")} :</span>
                  <b>{giveLan(userRealdata?.nationality) || "-"}</b>
                </div>
                <div className='flex'>
                  <span>{t("profile.common.adress_region")} :</span>
                  <b>
                    {giveLan(userRealdata?.district?.region) || "-"},{" "}
                    {giveLan(userRealdata?.district) || "-"}
                  </b>
                </div>
                <div className='flex'>
                  <span>{t("profile.common.adress")} :</span>
                  <b>{userRealdata?.address || "-"}</b>
                </div>
                <div className='flex'>
                  <span>{t("profile.common.pinfl")} :</span>
                  <b>{userRealdata?.pinfl || "-"}</b>
                </div>
                <div className='flex'>
                  <span>{t("profile.common.phone")} :</span>
                  <b>{userRealdata?.phone_number || "-"}</b>
                </div>
              </div>
            </div>
            {/* ------------------------------------------------------------------------------ */}
            {searchParam.get("key") === "lyceum_admission" && (
              <>
                {info?.data?.appeal && (
                  <section>
                    <h2>Appelatsiya</h2>
                    <div className='list'>
                      <div className='flex'>
                        <h3>Appelatsiya matni:</h3>
                        <p>{info?.data?.appeal?.reason}</p>
                      </div>
                      <div className='flex'>
                        <h3>Appelatsiyaga berilgan javob:</h3>
                        {info?.data?.appeal?.comment ? (
                          <p>{info?.data?.appeal?.comment}</p>
                        ) : (
                          <span>
                            <CustomButton size='xs' color='red'>
                              Ko'rib chiqilmoqda
                            </CustomButton>
                          </span>
                        )}
                      </div>
                      {
                        info?.data?.appeal?.start_time &&
                      <div className='flex'>
                        <CustomAlert
                        type='success'
                        info={`Akademik litseylar test sinoviga Appeliyatsiya shikoyatingizni ko'rib chiqish uchun ${new Date(info?.data?.appeal?.start_time).toLocaleDateString()} sanasi soat ${new Date(info?.data?.appeal?.start_time).toLocaleTimeString().slice(0,5)} da Bilim va malakalarni baholash agentligiga shaxsni tasdiqlovchi hujjatingiz bilan tashrif buyurishingizni so'raymiz.`}
                        closeIcon={false}
                      />
                      </div>
                      }
                    </div>
                  </section>
                )}

                {info?.data?.status === "7" && info?.data?.result == null ? (
                  <section>
                    <h2>Test natijasi</h2>

                    <CustomAlert
                      type='warning'
                      closeIcon={false}
                      info='Siz test imtihonida qatnashmagansiz !'
                    />
                  </section>
                ) : info?.data?.status === "7" ? (
                  <section>
                    <div className='resultTitle'>
                      <h2>Test natijasi</h2>
                      <a href={info?.data?.result?.url || ""} target='_blank'>
                        Yuklab olish
                      </a>
                    </div>

                    {/* {info?.status === "5" && (
                      <CustomAlert
                        type='error'
                        info={`Tavsiya etilmadi (o’qishga kirmadingiz) !`}
                        closeIcon={false}
                      />
                    )}
                    {info?.status === "6" && (
                      <CustomAlert
                        info={`Tavsiya etildi (o’qishga kirdingiz) !`}
                        closeIcon={false}
                      />
                    )} */}
                    {/* <div className='list'>
                      {info?.status === "6" && (
                        <div className='flex'>
                          <h3>Akademik litsey nomi:</h3>
                          <p>{info?.result?.lyceum_name}</p>
                        </div>
                      )}
                      <div className='flex'>
                        <h3>ID Raqami:</h3>
                        <p>{info?.result?.dtm_exam_number}</p>
                      </div>
                      <div className='flex'>
                        <h3>Umumiy Ball:</h3>
                        <p>{info?.result?.total_ball}</p>
                      </div>
                      <div className='flex'>
                        <h3>1-fan:</h3>
                        <p>{info?.result?.first_subject_ball}</p>
                      </div>
                      <div className='flex'>
                        <h3>2-fan:</h3>
                        <p>{info?.result?.second_subject_ball}</p>
                      </div>
                      <div className='flex'>
                        <h3>3-fan:</h3>
                        <p>{info?.result?.third_subject_ball}</p>
                      </div>
                      {info?.result?.science_certificate_ball > 0 && (
                        <div className='flex'>
                          <h3>Milliy test/fan olimpiyada bali:</h3>
                          <p>{info?.result?.science_certificate_ball}</p>
                        </div>
                      )}
                      {info?.result?.foreign_language_certificate_ball > 0 && (
                        <div className='flex'>
                          <h3>Chet tili sertifikati bali:</h3>
                          <p>{info?.result?.foreign_language_certificate_ball}</p>
                        </div>
                      )}
                      {info?.result?.privilege_certificate_ball > 0 && (
                        <div className='flex'>
                          <h3>Imtiyoz bali:</h3>
                          <p>{info?.result?.privilege_certificate_ball}</p>
                        </div>
                      )}
                      {info?.result?.creative_certificate_ball > 0 && (
                        <div className='flex'>
                          <h3>Ijodiy imtihon bali:</h3>
                          <p>{info?.result?.creative_certificate_ball}</p>
                        </div>
                      )}
                    </div> */}
                    <div className='customResultIframe'>
                      {/* <iframe
                        className='mobile'
                        src={`https://docs.google.com/gview?url=${info?.data?.result?.url}&embedded=true`}
                      />
                      <iframe className='desktop' src={info?.data?.result?.url} allow='web-share' /> */}
                      <iframe src={info?.data?.result?.url} allow='web-share' />
                    </div>
                  </section>
                ) : (
                  <></>
                )}

                <section>
                  <h2>{t("profile.choices.detail_title")}</h2>
                  <div className='list'>
                    {/* Lyceum 1 */}
                    <div className='flex'>
                      <h3>{t("profile.choices.lyceum1")}:</h3>
                      <p>{giveLan(info?.data?.lyceum_selections?.[0]?.lyceum)}</p>
                      <CardStatus
                        onClick={(val: any) => giveComments("lyceum_admission", val)}
                        status={Number(info?.data?.lyceum_selections?.[0]?.status || 1)}
                      />
                    </div>

                    {/* Lyceum 2 */}
                    <div className='flex'>
                      <h3>{t("profile.choices.lyceum2")}:</h3>
                      <p>
                        {info?.data?.lyceum_selections?.[1]
                          ? giveLan(info?.data?.lyceum_selections?.[1]?.lyceum)
                          : "Tanlanmagan"}
                      </p>

                      {info?.data?.lyceum_selections?.[1] && (
                        <CardStatus
                          onClick={(val: any) => giveComments("lyceum_admission", val)}
                          status={Number(info?.data?.lyceum_selections?.[1]?.status || 1)}
                        />
                      )}
                    </div>

                    {/* Lyceum 3 */}
                    <div className='flex'>
                      <h3>{t("profile.choices.lyceum3")}:</h3>
                      <p>
                        {" "}
                        {info?.data?.lyceum_selections?.[2]
                          ? giveLan(info?.data?.lyceum_selections?.[2]?.lyceum)
                          : "Tanlanmagan"}
                      </p>

                      {info?.data?.lyceum_selections?.[2] && (
                        <CardStatus
                          onClick={(val: any) => giveComments("lyceum_admission", val)}
                          status={Number(info?.data?.lyceum_selections?.[2]?.status || 1)}
                        />
                      )}
                    </div>
                    {info?.data?.speciality && (
                      <div className='flex'>
                        <h3>{t("profile.edu.dir_name")}:</h3>
                        <p>
                          {info?.data?.speciality?.code} - {giveLan(info?.data?.speciality)}
                        </p>
                      </div>
                    )}
                    {info?.data?.education_language && (
                      <div className='flex'>
                        <h3>{t("profile.choices.edu_lang")}:</h3>
                        <p>{giveLan(info?.data?.education_language)}</p>
                      </div>
                    )}
                    {info?.data?.foreign_language && (
                      <div className='flex'>
                        <h3>{t("profile.awards.english")}:</h3>
                        <p>{giveLan(info?.data?.foreign_language)}</p>
                      </div>
                    )}
                    {info?.data?.region && (
                      <div className='flex'>
                        <h3>{t("profile.choices.nearby_adressTitle")}:</h3>
                        <p>{giveLan(info?.data?.region)}</p>
                      </div>
                    )}
                  </div>
                </section>

                {info?.school_certificate && (
                  <section>
                    <h2>{t("profile.choices.detail_title2")}</h2>

                    <div className='list'>
                      <div className='flex'>
                        <h3>{t("profile.edu.end_school_country")}:</h3>
                        <p>{giveLan(info?.school_certificate?.country)}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.edu.end_school_region")}:</h3>
                        <p>{info?.school_certificate?.region}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.edu.end_school_district")}:</h3>
                        <p>{info?.school_certificate?.district}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.edu.end_school_name")}:</h3>
                        <p>{info?.school_certificate?.school}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.edu.end_school_year")}:</h3>
                        <p>{info?.school_certificate?.graduate_year}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.edu.end_school_lang")}:</h3>
                        <p>{giveLan(info?.school_certificate?.education_language)}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.edu.end_school_ForLang")}:</h3>
                        <p>{giveLan(info?.school_certificate?.foreign_language)}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.edu.end_school_diplomInfo")}:</h3>
                        <p>
                          {info?.school_certificate?.serial} - {info?.school_certificate?.number}
                        </p>
                      </div>
                    </div>
                  </section>
                )}

                {info?.foreign_language_certificate && (
                  <section>
                    <h2>{t("profile.common.certificates")}</h2>

                    <div className='list'>
                      <div className='flex'>
                        <h3>{t("profile.awards.lang_name")}:</h3>
                        <p>{giveLan(info?.foreign_language_certificate?.foreign_language)}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.type_cer")}:</h3>
                        <p>{giveLan(info?.foreign_language_certificate?.type)}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.level")}:</h3>
                        <p>{giveLan(info?.foreign_language_certificate?.degree)}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.series")}:</h3>
                        <p>{info?.foreign_language_certificate?.serial_number}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.docstart_date")}:</h3>
                        <p>{info?.foreign_language_certificate?.given_date}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.docend_date")}:</h3>
                        <p>{info?.foreign_language_certificate?.expire_date}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.file")}:</h3>
                        <a download href={info?.foreign_language_certificate?.file} target='_blank'>
                          {t("service.serviceID.download")}
                        </a>
                      </div>
                    </div>
                  </section>
                )}

                {info?.achievement_certificate && (
                  <section>
                    <h2>{t("profile.awards.prizes")}</h2>

                    <div className='list'>
                      <div className='flex'>
                        <h3>{t("profile.awards.prizes_name")}:</h3>
                        <p>{info?.achievement_certificate?.name}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.prizes_type")}:</h3>
                        <p>{giveLan(info?.achievement_certificate?.type)}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.series")}:</h3>
                        <p>{info?.achievement_certificate?.serial_number}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.docstart_date")}:</h3>
                        <p>{info?.achievement_certificate?.given_date}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.file")}:</h3>
                        <a download href={info?.achievement_certificate?.file} target='_blank'>
                          {t("service.serviceID.download")}
                        </a>
                      </div>
                    </div>
                  </section>
                )}

                {info?.science_certificate && (
                  <section>
                    <h2>{t("language.science")}</h2>

                    <div className='list'>
                      <div className='flex'>
                        <h3>{t("language.science")}:</h3>
                        <p>{giveLan(info?.science_certificate?.science)}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.series")}:</h3>
                        <p>{info?.science_certificate?.serial_number}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.docstart_date")}:</h3>
                        <p>{info?.science_certificate?.given_date}</p>
                      </div>
                      <div className='flex'>
                        <h3>{t("profile.awards.file")}:</h3>
                        <a download href={info?.science_certificate?.file} target='_blank'>
                          {t("service.serviceID.download")}
                        </a>
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
            {/* --------------------------------------------------- */}
            {searchParam.get("key") === "vocational_school_admission" && (
              <>
                <section>
                  <h2>{t("profile.choices.detail_title")}</h2>
                  <div className='list'>
                    <div className='flex'>
                      <h3>{t("vocation.vacation_name")}:</h3>
                      <p>{giveLan(info?.vocational_school)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("vocation.vacation_region")}:</h3>
                      <p>{giveLan(info?.vocational_school?.district?.region)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("vocation.vacation_district")}:</h3>
                      <p>{giveLan(info?.vocational_school?.district)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.dir_name")}:</h3>
                      <p>{giveLan(info?.speciality)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.choices.edu_lang")}:</h3>
                      <p>{giveLan(info?.education_language)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.edu_type")}:</h3>
                      <p>{giveLan(info?.education_form)}</p>
                    </div>
                  </div>
                </section>

                <section>
                  <h2>{t("profile.choices.detail_title2")}</h2>

                  <div className='list'>
                    <div className='flex'>
                      <h3>{t("profile.edu.end_school_country")}:</h3>
                      <p>{giveLan(info?.school_certificate?.country)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.end_school_region")}:</h3>
                      <p>{info?.school_certificate?.region}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.end_school_district")}:</h3>
                      <p>{info?.school_certificate?.district}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.end_school_name")}:</h3>
                      <p>{info?.school_certificate?.school}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.end_school_year")}:</h3>
                      <p>{info?.school_certificate?.graduate_year}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.end_school_lang")}:</h3>
                      <p>{giveLan(info?.school_certificate?.education_language)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.end_school_ForLang")}:</h3>
                      <p>{giveLan(info?.school_certificate?.foreign_language)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.end_school_diplomInfo")}:</h3>
                      <p>
                        {info?.school_certificate?.serial} - {info?.school_certificate?.number}
                      </p>
                    </div>
                  </div>
                </section>
              </>
            )}
            {searchParam.get("key") === "interview_admission" && (
              <>
                {exInfo?.info?.appeal_data && (
                  <section>
                    <h2>Appelatsiya</h2>
                    <div className='list'>
                      <div className='flex'>
                        <h3>Appelatsiya matni:</h3>
                        <p>{exInfo?.info?.appeal_data?.content}</p>
                      </div>
                      <div className='flex'>
                        <h3>Appelatsiyaga berilgan javob:</h3>
                        {exInfo?.info?.appeal_data?.response_content ? (
                          <p>{exInfo?.info?.appeal_data?.response_content}</p>
                        ) : (
                          <span>
                            <CustomButton size='xs' color='red'>
                              Ko'rib chiqilmoqda
                            </CustomButton>
                          </span>
                        )}
                      </div>
                    </div>
                  </section>
                )}

                {exInfo?.info?.result_data && (
                  <section>
                    <h2>Suhbat natijasi</h2>
                    <div className='list'>
                      <div className='flex'>
                        <h3>O'rtacha bali:</h3>
                        <p>{exInfo?.info?.result_data?.average_ball}</p>
                      </div>
                      <div className='flex'>
                        <h3>Qo'shimcha bali:</h3>
                        <p>{exInfo?.info?.result_data?.additional_ball}</p>
                      </div>
                      <div className='flex'>
                        <h3>Komissiya a'zolari soni:</h3>
                        <p>{exInfo?.info?.result_data?.number_of_commission_members}</p>
                      </div>
                      <div className='flex'>
                        <h3>Umumiy ball:</h3>
                        <p>{exInfo?.info?.result_data?.total_ball}</p>
                      </div>
                    </div>
                  </section>
                )}

                {exInfo?.info?.chat_datetime && (
                  <section>
                    <h2>Suhbat sanasi va manzili</h2>
                    <div className='list'>
                      <div className='flex'>
                        <h3>Guruh nomi:</h3>
                        <p>{exInfo?.info?.chat_datetime?.name}</p>
                      </div>
                      <div className='flex'>
                        <h3>Suhbat sanasi:</h3>
                        <p>{exInfo?.info?.chat_datetime?.chat_started_date}</p>
                      </div>
                      <div className='flex'>
                        <h3>Suhbat vaqti:</h3>
                        <p>{exInfo?.info?.chat_datetime?.chat_started_time}</p>
                      </div>
                      <div className='flex'>
                        <h3>Ma'sul shaxs telefon raqami:</h3>
                        <a
                          href={`tel:${exInfo?.info?.chat_datetime?.phone_number_full?.number}`}
                          target='_blank'
                        >
                          {exInfo?.info?.chat_datetime?.phone_number_full?.number}
                        </a>
                      </div>

                      <div className='flex'>
                        <h3>Manzil:</h3>
                        <p>{exInfo?.info?.chat_datetime?.address_full?.name}</p>
                      </div>
                      <div className='flex'>
                        <h3>Blok:</h3>
                        <p>{exInfo?.info?.chat_datetime?.address_full?.block_name}</p>
                      </div>
                      <div className='flex'>
                        <h3>Qavati:</h3>
                        <p>{exInfo?.info?.chat_datetime?.address_full?.floor_number}</p>
                      </div>
                      <div className='flex'>
                        <h3>Xona raqami:</h3>
                        <p>{exInfo?.info?.chat_datetime?.address_full?.room_number}</p>
                      </div>
                      <div className='flex'>
                        <h3>Google orqali topish:</h3>
                        <a
                          href={exInfo?.info?.chat_datetime?.address_full?.location_link || ""}
                          target='_blank'
                        >
                          Manzil
                        </a>
                      </div>
                    </div>
                  </section>
                )}

                <section>
                  <h2>{t("profile.choices.detail_title")}</h2>
                  <div className='list'>
                    <div className='flex'>
                      <h3>{t("interview.otm_name")}:</h3>
                      <p>{exInfo?.university_name}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.dir_name")}:</h3>
                      <p>{exInfo?.university_direction_name}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.choices.edu_lang")}:</h3>
                      <p>{exInfo?.education_language_name}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.edu_type")}:</h3>
                      <p>{exInfo?.education_type_name}</p>
                    </div>
                  </div>
                </section>

                <section>
                  <h2>{t("profile.cabinet.diplom_info")}</h2>
                  <div className='list'>
                    <div className='flex'>
                      <h3>{t("interview.technikum_name")}:</h3>
                      <p>{exInfo?.diplom?.technical_college_name}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.dir_name")}:</h3>
                      <p>{exInfo?.diplom?.direction_name}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("interview.direction_name")}:</h3>
                      <p>{exInfo?.diplom?.profession_name}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.choices.edu_lang")}:</h3>
                      <p>{exInfo?.diplom?.education_language}</p>
                    </div>{" "}
                    <div className='flex'>
                      <h3>{t("interview.diplom_series")}:</h3>
                      <p>
                        {exInfo?.diplom?.diploma_series} {exInfo?.diplom?.diploma_number}
                      </p>
                    </div>{" "}
                    <div className='flex'>
                      <h3>{t("interview.diplom_dates")}:</h3>
                      <p>{exInfo?.diplom?.diploma_given_year}</p>
                    </div>
                  </div>
                </section>

                <section>
                  <h2>{t("interview.semestr_grade")}</h2>

                  <CustomAlert
                    info={t("interview.alert_info1") || ""}
                    footer={
                      <div className='interview__percent'>
                        <p
                          className='customAlert__body'
                          dangerouslySetInnerHTML={{ __html: t("interview.alert_info2") }}
                        />
                        <div className='interview__percent-box'>
                          <div>
                            <p style={{ width: `${exInfo?.diplom?.percent_of_five}%` }}></p>
                          </div>
                          <span>{exInfo?.diplom?.percent_of_five}%</span>
                        </div>
                      </div>
                    }
                  />
                  <div className='full'>
                    {grades.map((semestr: subject[], index: number) => (
                      <div className='interview__semestr' key={index}>
                        <div className='interview__semestr-title'>{index + 1} - semestr</div>
                        <div>
                          {semestr.map((sub: subject) => (
                            <div
                              key={sub.subject}
                              className={sub.ball == 3 ? "error flex" : "flex"}
                            >
                              <h3>{sub.subject}</h3>
                              <h4>
                                {sub.ball}{" "}
                                {sub.ball == 3
                                  ? "(qoniqarli)"
                                  : sub.ball == 4
                                  ? "(yaxshi)"
                                  : "(a'lo)"}
                              </h4>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              </>
            )}
            {searchParam.get("key") === "secondary_education_admission" && (
              <>
                <section>
                  <h2>{t("profile.choices.detail_title")}</h2>
                  <div className='list'>
                    <div className='flex'>
                      <h3>{t("interview.otm_name")}:</h3>
                      <p>{detailSE?.kvota?.universityName}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.edu_form")}:</h3>
                      <p>{detailSE?.kvota?.eduFormName}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.choices.edu_lang")}:</h3>
                      <p>{detailSE?.kvota?.languageName}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.dir_name")}:</h3>
                      <p>{detailSE?.kvota?.specialityName}</p>
                    </div>
                  </div>
                </section>

                <section>
                  <h2>{t("second_edu.finished_edu_info")}</h2>
                  <div className='list'>
                    <div className='flex'>
                      <h3>{t("second_edu.institution_country")}:</h3>
                      <p>{detailSE?.diplomaResponse.countryName}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("second_edu.institution_name")}:</h3>
                      <p>
                        {detailSE?.diplomaResponse.institutionOldName ||
                          detailSE?.diplomaResponse.institutionName}
                      </p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.dir_name")}:</h3>
                      <p>
                        {detailSE?.diplomaResponse.specialityCustomName ||
                          detailSE?.diplomaResponse.specialityName}
                      </p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.edu.edu_form")}:</h3>
                      <p>{detailSE?.diplomaResponse.eduFormName}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("second_edu.edu_starting_date")}:</h3>
                      <p>{detailSE?.diplomaResponse.eduStartingDate}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("second_edu.edu_finishing_date")}:</h3>
                      <p>{detailSE?.diplomaResponse.eduFinishingDate}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("interview.diplom_dates")}:</h3>
                      <p>{detailSE?.diplomaResponse.diplomaGivenDate}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("second_edu.edu_duration")}:</h3>
                      <p>{detailSE?.diplomaResponse.eduDurationName}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("interview.diplom_series")}:</h3>
                      <p>
                        {detailSE?.diplomaResponse.diplomaSerial}
                        {detailSE?.diplomaResponse.diplomaNumber}
                      </p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.diplom.copy")}:</h3>
                      {detailSE?.diplomaResponse.diplomaUrl && (
                        <a download href={detailSE?.diplomaResponse.diplomaUrl} target='_blank'>
                          {t("service.serviceID.download")}
                        </a>
                      )}
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.diplom.file")}:</h3>
                      {detailSE?.diplomaResponse.ilovaUrl && (
                        <a download href={detailSE?.diplomaResponse.ilovaUrl} target='_blank'>
                          {t("service.serviceID.download")}
                        </a>
                      )}
                    </div>
                  </div>
                </section>
              </>
            )}
            {searchParam.get("key") === "foreign_language_certificate" &&
              info?.data?.foreign_language && (
                <section>
                  <h2>{t("profile.common.certificates")}</h2>

                  <div className='list'>
                    <div className='flex'>
                      <h3>{t("profile.awards.lang_name")}:</h3>
                      <p>{giveLan(info?.data?.foreign_language)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.awards.type_cer")}:</h3>
                      <p>{giveLan(info?.data?.type)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.awards.level")}:</h3>
                      <p>{giveLan(info?.data?.degree)}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.awards.series")}:</h3>
                      <p>{info?.data?.serial_number}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.awards.docstart_date")}:</h3>
                      <p>{info?.data?.given_date}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.awards.docend_date")}:</h3>
                      <p>{info?.data?.expire_date}</p>
                    </div>
                    <div className='flex'>
                      <h3>{t("profile.awards.file")}:</h3>
                      <a
                        download
                        href={info?.data?.file || info?.data?.url}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {t("service.serviceID.download")}
                      </a>
                    </div>
                  </div>
                </section>
              )}
            {searchParam.get("key") === "getting_certificate_about_studying" && (
              <section>
                <h2>OʼQISH JOYIDАN MАʼLUMOTNOMА</h2>

                <div className='list'>
                  <div className='flex'>
                    <h3>Ta'lim turi:</h3>
                    <p>{info?.data?.education_type_name}</p>
                  </div>
                  <div className='flex'>
                    <h3>Ta'lim shakli:</h3>
                    <p>{info?.data?.education_form_name}</p>
                  </div>
                  <div className='flex'>
                    <h3>Qabul turi:</h3>
                    <p>{info?.data?.payment_type_name}</p>
                  </div>
                  <div className='flex'>
                    <h3>O‘qishga kirgan yili:</h3>
                    <p>{info?.data?.education_year}</p>
                  </div>
                  <div className='flex'>
                    <h3>Oliy ta'lim muassasasi:</h3>
                    <p>{info?.data?.university_name}</p>
                  </div>
                  <div className='flex'>
                    <h3>Fakultet:</h3>
                    <p>{info?.data?.faculty_name}</p>
                  </div>
                  <div className='flex'>
                    <h3>Yo‘nalish:</h3>
                    <p>{info?.data?.speciality_name}</p>
                  </div>
                  <div className='flex'>
                    <h3>O‘quv kurs:</h3>
                    <p>{info?.data?.course}</p>
                  </div>
                  <div className='flex'>
                    <h3>Fayl:</h3>
                    <a
                      target='_blank'
                      style={{ cursor: "pointer" }}
                      onClick={() => StudyDocumentPDF(info?.data, info?.id, info?.added_time)}
                    >
                      {t("service.serviceID.download")}
                    </a>
                  </div>
                </div>
              </section>
            )}
            {searchParam.get("key") === "getting_certificate_about_prof_studying" && (
              <section>
                <h2>OʼQISH JOYIDАN MАʼLUMOTNOMА</h2>

                <div className='list'>
                  <div className='flex'>
                    <h3>Ta'lim turi:</h3>
                    <p>{info?.data?.edu_type}</p>
                  </div>
                  <div className='flex'>
                    <h3>Ta'lim shakli:</h3>
                    <p>{info?.data?.edu_form}</p>
                  </div>
                  <div className='flex'>
                    <h3>Qabul turi:</h3>
                    <p>{info?.data?.edu_payment_type}</p>
                  </div>
                  <div className='flex'>
                    <h3>O‘qishga kirgan yili:</h3>
                    <p>{info?.data?.edu_starting_year}</p>
                  </div>
                  <div className='flex'>
                    <h3>Oliy ta'lim muassasasi:</h3>
                    <p>{info?.data?.edu_institution_name}</p>
                  </div>

                  <div className='flex'>
                    <h3>Yo‘nalish:</h3>
                    <p>{info?.data?.edu_speciality}</p>
                  </div>
                  <div className='flex'>
                    <h3>O‘quv kurs:</h3>
                    <p>{info?.data?.edu_study_level}</p>
                  </div>
                  <div className='flex'>
                    <h3>Fayl:</h3>
                    <a
                      target='_blank'
                      style={{ cursor: "pointer" }}
                      onClick={() => ProfStudyDocumentPDF(info?.data, info?.id, info?.added_time)}
                    >
                      {t("service.serviceID.download")}
                    </a>
                  </div>
                </div>
              </section>
            )}{" "}
            {searchParam.get("key") === "getting_certificate_about_academic_degree" && (
              <section>
                <h2>Ilmiy daraja va ilmiy unvon to'g'risidagi ma'lumotnoma</h2>

                <div className='list'>
                  <div className='flex'>
                    <h3>Oliy ta'lim muassasasi:</h3>
                    <p>{info?.data?.title_details?.work_place}</p>
                  </div>
                  <div className='flex'>
                    <h3>Lavozim:</h3>
                    <p>{info?.data?.title_details?.position}</p>
                  </div>
                  <div className='flex'>
                    <h3>Kafedra:</h3>
                    <p>{info?.data?.title_details?.code_and_name}</p>
                  </div>
                  <div className='flex'>
                    <h3>Fan sohasi:</h3>
                    <p>{info?.data?.title_details?.science_sector}</p>
                  </div>
                  <div className='flex'>
                    <h3>Daraja:</h3>
                    <p>{info?.data?.title_details?.title}</p>
                  </div>
                  <div className='flex'>
                    <h3>Tasdiqlangan sanasi:</h3>
                    <p>{info?.data?.title_details?.confirmed_date}</p>
                  </div>
                  <div className='flex'>
                    <h3>Fayl:</h3>
                    <a
                      target='_blank'
                      style={{ cursor: "pointer" }}
                      onClick={() => PhdDocumentPDF(info?.data, info?.id, info?.added_time)}
                    >
                      {t("service.serviceID.download")}
                    </a>
                  </div>
                </div>
              </section>
            )}
          </div>

          {/* Service ID sidebar --------------------------------------------- */}
          <div className='detailPage__sidebar'>
            <div className='detailPage__stepsbox'>
              {searchParam.get("key") === `lyceum_admission` && <GiveSteps status={info?.status} />}
              {[
                `foreign_language_certificate`,
                `vocational_school_admission`,
                `getting_certificate_about_studying`,
                `getting_certificate_about_prof_studying`,
                `getting_certificate_about_academic_degree`,
              ].includes(searchParam.get("key") || "") && (
                <GiveSteps
                  status={info?.status === "done" ? 5 : info?.status}
                  slug={"foreign_language_certificate"}
                />
              )}
              {searchParam.get("key") === "secondary_education_admission" && detailSE && (
                <GiveSteps
                  status={info?.status}
                  slug={searchParam.get("key") as SeriviceSlugTypeAttribute}
                />
              )}
              {searchParam.get("key") === "interview_admission" && (
                <GiveSteps
                  status={info?.is_grouped ? "5" : "1"}
                  slug={searchParam.get("key") as SeriviceSlugTypeAttribute}
                />
              )}
            </div>

            <div className='flex'>
              {searchParam.get("key") === "lyceum_admission" &&
                info?.status === "4" &&
                info?.data?.dtm_exam_paper_url && (
                  <a href={info?.data?.dtm_exam_paper_url} target='_blank'>
                    <CustomButton color='primary' icon={<NavigateSVG />}>
                      Ruxsatnomani yuklab olish
                    </CustomButton>
                  </a>
                )}
              {[`secondary_education_admission`, `lyceum_admission`].includes(
                searchParam.get("key") || "",
              ) && (
                <CustomButton
                  icon={<NavigateSVG />}
                  color='primary'
                  outline
                  onClick={() => giveComments(searchParam.get("key") || "")}
                >
                  Barcha izohlarni ko'rish
                </CustomButton>
              )}
              {checkEditApplication(searchParam.get("key") || "", info) && (
                <CustomButton
                  color='primary'
                  icon={<NavigateSVG />}
                  onClick={() => editApplication(searchParam.get("key") || "", info)}
                >
                  Tahrirlash
                </CustomButton>
              )}
              {((searchParam.get("key") === "lyceum_admission" && !info?.appeal) ||
                (searchParam.get("key") === "interview_admission" &&
                  info?.info?.can_send_appeal)) && (
                <CustomButton
                  color='green'
                  outline
                  icon={<NavigateSVG />}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  Appelatsiya yuborish
                </CustomButton>
              )}
              {searchParam.get("key") === "lyceum_admission" && info?.appeal && (
                <CustomAlert
                  info='Appelatsiya muvofaqqiyatli yuborildi !'
                  title="So'rov bajarildi"
                  closeIcon={false}
                  type='success'
                />
              )}
              {/* {searchParam.get("key") === "secondary_education_admission" && (
                <CustomAlert
                  info="Ikkinchi oliy ta'limga qabul bo'yicha arizalarni tahrirlash 2023 yil 16 avgust kuni soat 15:00 da to'xtatiladi."
                  closeIcon={false}
                  type='warning'
                />
              )} */}
            </div>
          </div>
        </div>
      </div>

      <Modal show={visible} onClose={closeModal} size='md'>
        <Modal.Header>
          <p style={{ fontSize: 18 }}>Siz haqiqatdan ham appelatsiya topshirmoqchimisiz ?</p>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='reason'
              control={control}
              render={({ field }) => (
                <TextareaItem
                  field={field}
                  label={"Appelatsiya matni"}
                  error={errors.reason ? true : false}
                />
              )}
            />
            <div className='flex' style={{ marginTop: 16, justifyContent: "center", gap: 16 }}>
              <CustomButton
                color='red'
                size='sm'
                style={{ width: 150 }}
                onClick={() => setVisible(false)}
              >
                Bekor qilish
              </CustomButton>
              <CustomButton color='primary' size='sm' style={{ width: 150 }} type='submit'>
                Yuborish
              </CustomButton>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <CustomDrower
        title={`Izohlar`}
        okText={`Yopish`}
        width={840}
        visible={drower}
        onOk={() => setDrower(false)}
        onCancel={() => setDrower(false)}
        blured
      >
        <>
          {comments.map((item: any) => (
            <MessageItem
              list={info?.data?.lyceum_selections}
              item={item}
              key={item?.added_time || item?.univer}
              univer={item.univer}
            />
          ))}
        </>
      </CustomDrower>

      <CustomLoader visible={loading} />
    </div>
  );
}

export default AdmissionDetail;
