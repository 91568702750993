import { TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AppStoreSVG,
  BakalavrSVG,
  BoshqaSVG,
  GoogleSVG,
  KambagalSVG,
  KasbSVG,
  KollejSVG,
  LitseySVG,
  MagistrSVG,
  MaktabgachaSVG,
  MyGovSVG,
  NoApplicationsSVG,
  NoNotificationsSVG,
  QabulSVG,
  RightLinkSVG,
  SearchSVG,
  Slider1SVG,
  Slider2SVG,
  Slider3SVG,
  Slider4SVG,
  Slider5SVG,
  SoliqSVG,
  TarnsportSVG,
  TexnikumSVG,
  YoshlarSVG,
} from "src/assets/svg";
import CategoryButton from "src/components/buttons/CategoryButton";
import DefaultBtn from "src/components/buttons/DefaultBtn";
import ApplicationCard from "src/components/cards/ApplicationCard";
import CaraouselCard from "src/components/cards/CaraouselCard";
import NewsCard from "src/components/cards/NewsCard";
import NoItemCard from "src/components/cards/NoItemCard";
import CustomAlert from "src/components/common/alert";
import CustomButton from "src/components/common/button";
import Inputs from "src/components/inputs";
import SliderMulti from "src/components/slider";
import { isUser, language } from "src/static/const/auth";
import { GetApplications } from "../profile/applications/config";
import HomeServices from "./components/services";

function HomePage() {
  const { t, i18n } = useTranslation();
  const [isApplications, setIsApplications] = useState(true);

  const [apps, setApps] = useState([]);

  const GetApplicationsList = async () => {
    const { data } = await GetApplications();
    setApps(data.results);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    isUser && GetApplicationsList();
  }, []);
  return (
    <div className='home'>
      {/* Top page */}
      <div className='home-top min-h-[660px]'>
        <div className='container'>
          <h1 className='mt-12'>{t("home.top.title")}</h1>
          <p className='text-lg text-white text-center mt-2 mb-8 m-auto home-top-info'>
            {t("home.top.info")}
          </p>
          <TextInput
            icon={SearchSVG}
            placeholder={t("home.top.search") || ""}
            className='home__search'
          />

          <div className='flex align-center justify-center gap-2 mt-4 home-top__searchs'>
            {[
              t("home.keywords.key1"),
              t("home.keywords.key2"),
              t("home.keywords.key3"),
              t("home.keywords.key4"),
              t("home.keywords.key5"),
            ].map((title: string) => (
              <Inputs text={title} key={title} />
            ))}
          </div>

          <div className='flex home-top__category'>
            <CategoryButton
              icon={<QabulSVG />}
              title={t("home.actions.admission")}
              link='/service?key=admissions'
              active
            />
            <div className='home-top__category__divider'></div>
            <CategoryButton
              icon={<BakalavrSVG />}
              title={t("home.actions.bachelor")}
              link='/service?key=bachelor'
              active
            />
            <CategoryButton
              icon={<MagistrSVG />}
              title={t("home.actions.magistr")}
              link='/service?key=master'
              active
            />
            <CategoryButton
              active
              icon={<LitseySVG />}
              title={t("home.actions.lyceum")}
              link='/service?key=lyceum'
            />
            <CategoryButton
              icon={<KollejSVG />}
              title={t("home.actions.college")}
              link='/service?key=college'
              active
            />
            <CategoryButton
              icon={<TexnikumSVG />}
              title={t("home.actions.technikum")}
              link='/service?key=technical_school'
              active
            />
            <CategoryButton
              icon={<KasbSVG />}
              title={t("home.actions.kasb")}
              link='/service?key=profession_school'
              active
            />
            <CategoryButton
              icon={<BoshqaSVG />}
              title={t("home.actions.others")}
              link='/service?key=other'
              active
            />
          </div>
        </div>
      </div>

      <div className='container'>
        {/* Applications and notification after login */}
        {isUser && (
          <section className='home__notifications'>
            <div className='flex home__notifications_tab'>
              <div className='flex'>
                <DefaultBtn
                  title={t("profile.settings.notification") || ""}
                  onClick={() => setIsApplications(false)}
                  className={!isApplications ? `customBtn-active` : ""}
                />
                <DefaultBtn
                  title={t("profile.applications.title") || ""}
                  onClick={() => setIsApplications(true)}
                  className={isApplications ? `customBtn-active` : ""}
                />
              </div>
              <Link to={"/profile/applications"}>
                <CustomButton icon={<RightLinkSVG />} color='menu'>
                  {t("home.news.link") || ""}
                </CustomButton>
              </Link>
            </div>
            {isApplications ? (
              <>
                {apps.length > 0 ? (
                  <div className='home__applications'>
                    <div className='home__applications-list'>
                      {apps.slice(0, 4).map((app: any) => (
                        <ApplicationCard data={app} key={crypto.randomUUID()} />
                      ))}

                      <Link to={"/profile/applications"}>
                        <CustomButton
                          icon={<RightLinkSVG />}
                          color='menu'
                          className='mobileMoreBtn'
                        >
                          {t("home.news.link") || ""}
                        </CustomButton>
                      </Link>
                    </div>

                    <CustomAlert
                      title={t("profile.cabinet.info") || ""}
                      info={t("profile.applications.notification_info") || ""}
                    />
                  </div>
                ) : (
                  <NoItemCard
                    link='/service?key=all'
                    icon={<NoApplicationsSVG />}
                    title={t("profile.applications.no_application")}
                    info={"profile.applications.text1"}
                  />
                )}
              </>
            ) : (
              <NoItemCard
                icon={<NoNotificationsSVG />}
                title={t("profile.applications.noNotitification")}
              />
            )}
          </section>
        )}

        <HomeServices />

        {/* Home slider */}
        <div className='home__slider'>
          <SliderMulti>
            <CaraouselCard
              className='blue'
              icon={<Slider3SVG />}
              website='my.gov.uz'
              link='https://my.gov.uz/oz/service/642'
              title={t("home.caraousel.car1.title")}
              info={t("home.caraousel.car1.info")}
            />
            <CaraouselCard
              className='pink'
              website='my.gov.uz'
              icon={<Slider2SVG />}
              link='https://my.gov.uz/oz/service/413'
              title={t("home.caraousel.car2.title")}
              info={t("home.caraousel.car2.info")}
            />
            <CaraouselCard
              className='yellow'
              icon={<Slider1SVG />}
              link='https://talim-krediti.mf.uz/'
              website='talim-krediti.mf.uz'
              title={t("home.caraousel.car3.title")}
              info={t("home.caraousel.car3.info")}
            />
            <CaraouselCard
              website='unilibrary.uz'
              className='sky'
              icon={<Slider4SVG />}
              link='https://unilibrary.uz/'
              title={t("home.caraousel.car4.title")}
              info={t("home.caraousel.car4.info")}
            />{" "}
            <CaraouselCard
              website='ijara.soliq.uz'
              className='green'
              icon={<Slider5SVG />}
              link='https://ijara.soliq.uz/'
              title={t("home.caraousel.car5.title")}
              info={t("home.caraousel.car5.info")}
            />
          </SliderMulti>
        </div>

        {/* Home banner */}
        <div className='home__banner' id='partners'>
          <div>
            <h2>{t("home.banner.title")}</h2>
            <div className='flex'>
              <img
                src={require("src/assets/images/qr2.png")}
                alt='Qr code'
                width={140}
                height={140}
                style={{ borderRadius: 8 }}
              />
              <div>
                <p>{t("home.banner.info")}</p>
                <div className='flex gap-4'>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.tmetjem.hemis&hl=ru&gl=US'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <GoogleSVG />
                  </a>
                  <a
                    href='https://apps.apple.com/ru/app/hemis/id1626119615?platform=iphone'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <AppStoreSVG />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <img src={require("src/assets/images/phone2.png")} alt='' className='home__banner-img' />
        </div>

        {/* Home Integratsiyalar */}
        <section className='home__integrations' id='integrations'>
          <div className='flex '>
            <h2>{t("home.footer.integrations")}</h2>
          </div>

          <div className='flex home__integrations-list'>
            <div>
              <a href='https://yoshlar.gov.uz/uz/' target='_blank'>
                <YoshlarSVG />
              </a>
            </div>
            <div>
              <a href='https://mehnat.uz/oz' target='_blank'>
                <KambagalSVG />
              </a>
            </div>
            <div>
              <a href='https://uzedu.uz/uz' target='_blank'>
                <MaktabgachaSVG />
              </a>
            </div>
            <div>
              <a href='https://mintrans.uz/' target='_blank'>
                <TarnsportSVG />
              </a>
            </div>
            <div>
              <a href='https://my.gov.uz/uz/' target='_blank'>
                <MyGovSVG />
              </a>
            </div>
            <div>
              <a href='https://www.imv.uz/' target='_blank'>
                <img
                  alt='Iqtisodiyot va moliya vazirligi'
                  src={require("src/assets/images/imv.png")}
                />
              </a>
            </div>
            <div>
              <a href='https://soliq.uz/' target='_blank'>
                <SoliqSVG />
              </a>
            </div>
            <div>
              <a href='https://adliya.uz/' target='_blank'>
                <img alt='Soliq logo' src={require("src/assets/images/adliya.png")} />
              </a>
            </div>
          </div>
        </section>

        {/* Home links */}
        <section className='home__links' id='blog'>
          <div className='flex'>
            <h2>{t("home.news.title")}</h2>
            <CustomButton icon={<RightLinkSVG />} color='menu' className='moreBtn'>
              {t("home.news.link") || ""}
            </CustomButton>
          </div>

          <div className='flex home__links-list'>
            <NewsCard
              link='https://taklif.edu.uz/'
              img={require("src/assets/images/news1.png")}
              title={t("home.news.news1") || ""}
            />
            <NewsCard
              link='https://edu.uz/uz'
              img={require("src/assets/images/news2.jpg")}
              title={t("home.news.news2") || ""}
            />
            <NewsCard link='https://edu.uz/uz/news/view/4963' title={t("home.news.news3") || ""} />
          </div>
          <CustomButton icon={<RightLinkSVG />} color='menu' className='mobileMoreBtn'>
            {t("home.news.link") || ""}
          </CustomButton>
        </section>
      </div>
    </div>
  );
}

export default HomePage;
