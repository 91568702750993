import { ToggleSwitch } from "flowbite-react";
import { useTranslation } from "react-i18next";

function Notifications() {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="title">{t("profile.settings.notification") || ""}</h3>
      <div className="notificationToggle">
        <div>
          <h2>{t("profile.settings.bot") || ""}</h2>
          <ToggleSwitch checked={true} label="" onChange={() => {}} />
        </div>
        <p>{t("profile.settings.bot_info") || ""}</p>
      </div>
    </div>
  );
}

export default Notifications;
