import toast from "react-hot-toast";
import { getCookie, handleLogoutFunc } from "src/utils";
import React, { useEffect } from "react";
import { ROLE, ROLE_U, TOKEN } from "src/static/const/auth";

const StatusPage: React.FC = () => {
  const token = getCookie("access_key");

  const NavigateHome = async () => {
    await new Promise((res) => setTimeout(res, 2000));
    if (token) {
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(ROLE, ROLE_U);
      window.location.href = "/?modal=open&key=finished";
    } else {
      toast.error("Nimadir xato ketti, qayta urinib ko'ring");
      await new Promise((res) => setTimeout(res, 2000));
      handleLogoutFunc();
    }
  };

  useEffect(() => {
    NavigateHome();
  }, []);

  return (
    <>
      <div className="ring">
        <div className="lds-dual-ring"></div>
      </div>
    </>
  );
};

export default StatusPage;
