import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forwardButtonProps } from "src/static/const/props";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "src/hooks/context";
import { language_service_schema } from "src/static/const/schema";
import { useNavigate } from "react-router-dom";
import {
  AddLanguageApplicationConfig,
  UpdateLanguageApplicationConfig,
} from "src/service/api/multipart";
import { pdfType } from "src/static/const";
import { LANGUAGE_DATA, language } from "src/static/const/auth";
import { useTranslation } from "react-i18next";
import SelectItem from "src/components/form/select";
import InputItem from "src/components/form/input";
import DatepickerItem from "src/components/form/datepicker";
import CustomButton from "src/components/common/button";
import InputUploadItem from "src/components/form/input/upload";
import toast from "react-hot-toast";
import { LanguageServiceTypes } from "../type";
import { OptionTypes } from "src/static/interface/common";
import { GetLanguageTypesConfig } from "src/service/api/classifiers";
import { makeOptionItem } from "src/utils";

function LanguageForm({
  isStep = false,
  submitForm,
  setLangID,
}: {
  isStep?: boolean;
  submitForm?: number;
  setLangID?: (val: any) => void;
}) {
  const { t, i18n } = useTranslation();
  const [degrees, setDegree] = useState<OptionTypes[]>();

  //Form section
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    clearErrors,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<LanguageServiceTypes>({
    resolver: yupResolver(language_service_schema),
  });

  const onSubmit = async (data: LanguageServiceTypes) => {
    const formData = new FormData();
    formData.append("foreign_language", data.foreign_language);
    formData.append("type", data.type);
    formData.append("degree", data.degree);
    formData.append("serial_number", data.serial_number);
    formData.append("given_date", data.given_date.startDate);
    formData.append("expire_date", data.expire_date.startDate);
    formData.append("file", data.file);

    if (localStorage.getItem(LANGUAGE_DATA)) {
      const data = await JSON.parse(localStorage.getItem(LANGUAGE_DATA) || "");

      UpdateLanguageApplicationConfig(data?.external_id, formData).then(() => {
        reset();
        localStorage.removeItem(LANGUAGE_DATA);
        toast.success("Ariza muvaffaqiyatli yangilandi !");
        navigate("/profile/applications");
      });
    } else {
      AddLanguageApplicationConfig(formData).then((res) => {
        reset();
        if (isStep) {
          setLangID?.({
            ...res?.data?.data,
            degree: res?.data?.data?.degree?.id,
            file: res?.data?.data?.file,
            foreign_language: res?.data?.data?.foreign_language?.id,
            type: res?.data?.data?.type?.id,
          });
        } else {
          toast.success("Ariza muvaffaqiyatli yuborildi");
          navigate("/profile/applications");
        }
      });
    }
  };

  const handleOnSave = async () => {
    trigger(); //triggers validation for whole form
    const formValue = getValues();
    const isValid = language_service_schema.isValidSync(formValue);
    if (isValid) {
      onSubmit(formValue);
    } else {
      setValidated(true);
    }
  };

  useEffect(() => {
    if (submitForm && submitForm > 1) {
      handleOnSave();
    }
  }, [submitForm]);

  //Classifiers section
  const {
    languages,
    getLanguages,
    languageTypes,
    getLanguageTypes,
    languageDegrees,
    getLanguageDegrees,
  } = useContext(MainContext);
  const getFunc = () => {
    getLanguages?.();
  };

  useEffect(() => {
    getFunc();
    i18n.changeLanguage(language);
  }, []);

  const handleChange = (val: string) => {
    getLanguageTypes?.(val);
    setValue("foreign_language", val);
    clearErrors("foreign_language");
  };
  const handleChangeTypes = (val: string) => {
    getLanguageDegrees?.(val);
    setValue("type", val);
    clearErrors("type");
  };
  const handleChangeFile = (file: any) => {
    setValue("file", file);
    clearErrors("file");
  };

  const setDefaultValues = async () => {
    if (localStorage.getItem(LANGUAGE_DATA)) {
      const data = await JSON.parse(localStorage.getItem(LANGUAGE_DATA) || "");

      if (data) {
        getLanguageTypes?.(data?.data?.foreign_language?.id);

        GetLanguageTypesConfig(data?.data?.foreign_language?.id).then((res) => {
          const typeObject = res?.data?.results?.find(
            (value: any) => value?.id == data?.data?.type?.id
          );
          setDegree(makeOptionItem(typeObject?.degrees));
        });

        setValue("foreign_language", data?.data?.foreign_language?.id);
        setValue("type", data?.data?.type?.id);
        setValue("degree", data?.data?.degree?.id);
        setValue("serial_number", data?.data?.serial_number);
      }
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, []);

  return (
    <>
      <div className="application-box-subtitle">{t("language.title")}</div>
      <form className="application-box-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="foreign_language"
          control={control}
          render={({ field }) => (
            <SelectItem
              className="half"
              label={t("profile.awards.lang_name") || ""}
              placeholder={t("language.select_lang") || ""}
              message={t("language.select_lang") || ""}
              field={field}
              options={languages}
              error={errors.foreign_language && validated}
              onChange={handleChange}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <SelectItem
              className="half"
              label={t("profile.awards.type_cer") || ""}
              placeholder={t("language.select_cer_type") || ""}
              message={t("language.select_cer_type") || ""}
              field={field}
              options={languageTypes}
              error={errors.type && validated}
              disabled={languageTypes ? false : true}
              onChange={handleChangeTypes}
            />
          )}
        />
        <Controller
          name="degree"
          control={control}
          render={({ field }) => (
            <SelectItem
              className="half"
              label={t("profile.awards.level") || ""}
              placeholder={t("language.selectlangLevel") || ""}
              message={t("language.selectlangLevel") || ""}
              field={field}
              options={languageDegrees || degrees}
              error={errors.degree && validated}
              disabled={languageDegrees || degrees ? false : true}
            />
          )}
        />
        <Controller
          name="serial_number"
          control={control}
          render={({ field }) => (
            <InputItem
              className="half"
              label={t("language.seriesandNumber") || ""}
              placeholder="-----------------------"
              message={t("language.seriesandNumber") || ""}
              field={field}
              error={errors.serial_number && validated}
            />
          )}
        />
        <Controller
          name="given_date"
          control={control}
          render={({ field }) => (
            <DatepickerItem
              className="half"
              label={t("profile.awards.docstart_date") || ""}
              message={t("profile.awards.docstart_date") || ""}
              field={field}
              onChange={(val) => setValue("given_date", val)}
              error={errors.given_date && validated}
            />
          )}
        />
        <Controller
          name="expire_date"
          control={control}
          render={({ field }) => (
            <DatepickerItem
              className="half"
              label={t("profile.awards.docend_date") || ""}
              message={t("profile.awards.docend_date") || ""}
              field={field}
              onChange={(val) => setValue("expire_date", val)}
              error={errors.expire_date && validated}
            />
          )}
        />
        <Controller
          name="file"
          control={control}
          render={({ field }) => (
            <InputUploadItem
              className="half custom-input-upload"
              label={t("profile.awards.file") || ""}
              placeholder="Hech qanday fayl tanlanmagan"
              message={t("profile.awards.file") || ""}
              accept={pdfType}
              field={field}
              error={errors.file && validated}
              onChange={handleChangeFile}
            />
          )}
        />
        {!isStep ? (
          <div className="application-box-footer full">
            <div></div>
            <CustomButton
              {...forwardButtonProps}
              onClick={() => setValidated(true)}
            >
              {t("nav.apply")}
            </CustomButton>
          </div>
        ) : (
          <></>
        )}
      </form>
    </>
  );
}

export default LanguageForm;
