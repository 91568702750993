import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDrower from "src/components/common/drower";
import SelectItem from "src/components/form/select";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "src/hooks/context";
import { AwardTypes } from "src/static/interface/profile";
import { add_award_schema } from "src/static/const/schema";
import InputItem from "src/components/form/input";
import { ProfileInfoEditProps } from "../../info/types";
import DatepickerItem from "src/components/form/datepicker";
import InputUploadItem from "src/components/form/input/upload";
import { useTranslation } from "react-i18next";
import { pdfType } from "src/static/const";
import { CreateAchievmentConfig } from "./config";

function ProfileAwardsAdd({
  drower,
  setDrower,
  getAchievments,
}: ProfileInfoEditProps) {
  const { t } = useTranslation();
  const { achievements, getAchievements } = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<AwardTypes>({
    resolver: yupResolver(add_award_schema),
  });

  const onSubmit = async (val: AwardTypes) => {
    setLoading(true);
    const result = new FormData();
    result.append("name", val.name);
    result.append("type", val.type);
    result.append("serial_number", val.serial_number);
    result.append("given_date", val.given_date.startDate);
    result.append("file", val.file);

    await CreateAchievmentConfig(result);
    setLoading(false);
    getAchievments?.();
    onCancel();
  };

  const onCancel = () => {
    setDrower(false);
  };

  const getFunc = () => {
    if (drower) {
      !achievements && getAchievements?.();
    }
  };

  useEffect(() => {
    getFunc();
  }, [drower]);

  const handleChangeFile = (file: any) => {
    setValue("file", file);
    clearErrors("file");
  };

  return (
    <CustomDrower
      blured
      visible={drower}
      okBtnLoading={loading}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t("profile.awards.prizes") || ""}
      okText={t("photo.save") || ""}
      cancelText={t("photo.cancel") || ""}
    >
      <form
        className="custom-form custom-form-sm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputItem
              label={t("profile.awards.prizes_name") || ""}
              field={field}
              error={errors.name ? true : false}
              message={t("profile.awards.prizes_name_type") || ""}
              placeholder={t("profile.awards.prizes_name_type") || ""}
              size="lg"
            />
          )}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <SelectItem
              label={t("profile.awards.prizes_type") || ""}
              field={field}
              options={achievements}
              error={errors.type ? true : false}
              message={t("profile.awards.prizes_type") || ""}
              placeholder={t("profile.awards.prizes_type") || ""}
              size="lg"
            />
          )}
        />

        <Controller
          name="given_date"
          control={control}
          render={({ field }) => (
            <DatepickerItem
              label={t("profile.awards.docstart_date") || ""}
              size="lg"
              field={field}
              onChange={(val) => setValue("given_date", val)}
              error={errors.given_date ? true : false}
              message="YYYY-MM-DD"
            />
          )}
        />

        <Controller
          name="serial_number"
          control={control}
          render={({ field }) => (
            <InputItem
              label={t("profile.awards.series") || ""}
              field={field}
              error={errors.serial_number ? true : false}
              message={t("profile.awards.series") || ""}
              placeholder={t("profile.awards.series") || ""}
              size="lg"
            />
          )}
        />

        <Controller
          name="file"
          control={control}
          render={({ field }) => (
            <InputUploadItem
              className="half custom-input-upload"
              label={t("profile.awards.file") || ""}
              placeholder="Hech qanday fayl tanlanmagan"
              message={"Faylni yuklang !"}
              accept={pdfType}
              field={field}
              error={errors.file ? true : false}
              onChange={handleChangeFile}
            />
          )}
        />
      </form>
    </CustomDrower>
  );
}

export default ProfileAwardsAdd;
