import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { GetDiplomConfig } from "../config";
import { LoaderSVG } from "src/assets/svg";
import { InterviewTechnikumDiplom } from "../type";
import CustomAlert from "src/components/common/alert";

function InterviewAdmissionStep2() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);

  const schema = yup
    .object({
      technical_college_name: yup.string().required(),
      direction_name: yup.string().required(),
      profession_name: yup.string().required(),
      education_language: yup.string().required(),
      diploma_series: yup.string().required(),
      diploma_number: yup.string().required(),
    })
    .required();

  const { handleSubmit, control, setValue } = useForm<InterviewTechnikumDiplom>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    navigate("/service/admission/interview_admission/step3");
  };

  const getInfo = async () => {
    try {
      const { data } = await GetDiplomConfig();
      setValue("technical_college_name", data?.technical_college_name ?? "");
      setValue("direction_name", data?.direction_name ?? "");
      setValue("encryption_code", data?.encryption_code ?? "");
      setValue("profession_name", data?.profession_name ?? "");
      setValue("education_language", data?.education_language ?? "");
      setValue("diploma_series", data?.diploma_series ?? "");
      setValue("diploma_number", data?.diploma_number ?? "");
    } catch (error: any) {
      error?.response?.data?.detail ===
        `Diplom ma'lumotlari kiritilmagan, texnikumingizga murojaat qiling` && setError(true);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='application-box'>
        <div className='application-box-title'>
          {t("profile.cabinet.diplom_info")}{" "}
          {/* <CustomButton icon={<LoaderSVG />} left_icon color='green' outline>
            {t("profile.cabinet.refresh_info")}
          </CustomButton> */}
        </div>
        {error ? (
          <div className='application-box-form'>
            <CustomAlert
              type='warning'
              style={{ width: "100%" }}
              info="Diplom ma'lumotlari kiritilmagan, texnikumingizga murojaat qiling"
            />
          </div>
        ) : (
          <div className='application-box-form'>
            <Controller
              name='technical_college_name'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("interview.technikum_name") || ""}
                  field={field}
                  className='full'
                  disabled
                />
              )}
            />
            <Controller
              name='direction_name'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("profile.edu.dir_name") || ""}
                  field={field}
                  className='full'
                  disabled
                />
              )}
            />
            <Controller
              name='encryption_code'
              control={control}
              render={({ field }) => (
                <InputItem label={"Yo'nalish kodi"} field={field} className='full' disabled />
              )}
            />
            <Controller
              name='profession_name'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("interview.direction_name") || ""}
                  field={field}
                  disabled
                  className='half'
                />
              )}
            />
            <Controller
              name='education_language'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("profile.choices.edu_lang") || ""}
                  field={field}
                  disabled
                  className='half'
                />
              )}
            />
            <Controller
              name='diploma_series'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("interview.diplom_series") || ""}
                  field={field}
                  disabled
                  className='half'
                />
              )}
            />
            <Controller
              name='diploma_number'
              control={control}
              render={({ field }) => (
                <InputItem
                  label={t("interview.diplom_dates") || ""}
                  field={field}
                  disabled
                  className='half'
                />
              )}
            />
          </div>
        )}
      </div>

      <div className='application-box-footer'>
        <Link to={"/service/admission/interview_admission/step1"}>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps}>{t("profile.common.continue") || ""}</CustomButton>
      </div>
    </form>
  );
}

export default InterviewAdmissionStep2;
