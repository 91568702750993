import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { genderData } from "src/static/data";
import { useNavigate } from "react-router-dom";
import { backButtonProps, forwardButtonProps } from "src/static/const/props";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "src/hooks/context";
import { Step1Types } from "../type";
import { profile_info_edit_schema, profile_info_edit_schema2 } from "src/static/const/schema";

import CustomButton from "src/components/common/button";
import InputItem from "src/components/form/input";
import RadioItem from "src/components/form/radio";
import SelectItem from "src/components/form/select";
import UploadItem from "src/components/form/upload";
import { EditUserConfig } from "src/service/api/profile";
import { USER_DATA } from "src/static/const/auth";
import { UserPersonalTypes } from "src/static/interface/profile";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

function LyceumAdmissionStep1({ nextLink }: { nextLink?: string }) {
  const { t } = useTranslation();

  //Classifiers section
  const {
    userData,
    regions,
    districts,
    citizenships,
    nationalities,
    setUserData,
    getRegions,
    getDistricts,
    getCitizenships,
    getNationalities,
  } = useContext(MainContext);

  const getFunc = () => {
    !citizenships && getCitizenships?.();
    !nationalities && getNationalities?.();
    !regions && getRegions?.();
  };

  useEffect(() => {
    getFunc();
  }, []);

  const getDistrict = () => {
    !districts && userData?.district?.region && getDistricts?.(userData?.district?.region.id);
  };

  useEffect(() => {
    getDistrict();
  }, [userData]);

  const navigate = useNavigate();

  //Form section
  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<Step1Types>({
    resolver: yupResolver(
      userData?.passport ? profile_info_edit_schema : profile_info_edit_schema2,
    ),
  });

  const [loading, setLoading] = useState(false);
  const onSubmit = (data: Step1Types) => {
    if (userData?.photo) {
      let obj: UserPersonalTypes = {
        district: data.district,
        address: data.address,
      };
      if (!userData?.passport) {
        obj.citizenship = data.citizenship;
        obj.nationality = data.nationality;
      }
      if (!userData.nationality) {
        obj.nationality = data.nationality;
      }

      setLoading(true);
      EditUserConfig(obj)
        .then((res) => {
          navigate(nextLink ? nextLink : "/service/admission/lyceum/step2");
          setUserData?.(res.data);
          localStorage.setItem(USER_DATA, JSON.stringify(res.data));
          //Ma'lumotlar muvaffaqiyatli tahrirlandi
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    } else {
      toast.error(t("profile.common.photo_upload"));
    }
  };

  const handleSet = () => {
    if (userData) {
      const {
        citizenship,
        nationality,
        district,
        address,
        first_name,
        last_name,
        middle_name,
        birth_date,
        gender,
      } = userData;

      citizenship && setValue("citizenship", citizenship.id);
      nationality && setValue("nationality", nationality.id);
      district && setValue("district", district.id);
      district && setValue("region", district.region.id);
      address && setValue("address", address);
      first_name && setValue("first_name", first_name);
      last_name && setValue("last_name", last_name);
      middle_name && setValue("middle_name", middle_name);
      birth_date && setValue("birth_date", birth_date);
      gender && setValue("gender", gender);
    }
  };

  useEffect(() => {
    handleSet();
  }, [userData]);

  //Select change functions
  const handleChangeRegion = (val: string) => {
    getDistricts?.(val);
    setValue("region", val);
    clearErrors("region");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='application-box'>
        <div className='application-box-title'>{t("profile.common.personal_info")}</div>
        <div className='application-box-form'>
          <Controller
            name='citizenship'
            control={control}
            render={({ field }) => (
              <SelectItem
                className='half'
                label={t("profile.common.citizenship") || ""}
                field={field}
                options={citizenships}
                error={errors.citizenship ? true : false}
                message={t("profile.common.citizenship") || ""}
                placeholder={t("profile.common.citizenship") || ""}
                size='lg'
                disabled={userData?.passport && true}
              />
            )}
          />
          <Controller
            name='nationality'
            control={control}
            render={({ field }) => (
              <SelectItem
                className='half'
                label={t("profile.common.nationality") || ""}
                field={field}
                options={nationalities}
                error={errors.nationality ? true : false}
                message={t("profile.common.nationality") || ""}
                placeholder={t("profile.common.nationality") || ""}
                size='lg'
                disabled={userData?.nationality && true}
              />
            )}
          />
          <div className='application-line'></div>

          <Controller
            name='region'
            control={control}
            render={({ field }) => (
              <SelectItem
                className='half'
                label={t("profile.common.adress_region") || ""}
                field={field}
                options={regions}
                error={errors.region ? true : false}
                message={t("profile.common.select_region") || ""}
                placeholder={t("profile.common.select_region") || ""}
                size='lg'
                onChange={handleChangeRegion}
              />
            )}
          />

          <Controller
            name='district'
            control={control}
            render={({ field }) => (
              <SelectItem
                className='half'
                label={t("profile.common.adress_district") || ""}
                field={field}
                options={districts}
                error={errors.district ? true : false}
                message={t("profile.common.select_district") || ""}
                placeholder={t("profile.common.select_district") || ""}
                size='lg'
              />
            )}
          />

          <Controller
            name='address'
            control={control}
            render={({ field }) => (
              <InputItem
                className='full'
                label={t("profile.common.adress") || ""}
                field={field}
                error={errors.address ? true : false}
                message={t("profile.common.adress") || ""}
                placeholder={t("profile.common.adress") || ""}
                size='lg'
              />
            )}
          />
          <div className='application-line'></div>

          <UploadItem />

          <div className='application-box-fish application-box-form'>
            <Controller
              name='last_name'
              control={control}
              render={({ field }) => (
                <InputItem
                  className='half'
                  label={t("profile.common.last_name") || ""}
                  field={field}
                  disabled
                />
              )}
            />

            <Controller
              name='first_name'
              control={control}
              render={({ field }) => (
                <InputItem
                  className='half'
                  label={t("profile.common.name") || ""}
                  field={field}
                  disabled
                />
              )}
            />

            <Controller
              name='middle_name'
              control={control}
              render={({ field }) => (
                <InputItem
                  className='half'
                  label={t("profile.common.middle_name") || ""}
                  field={field}
                  disabled
                />
              )}
            />

            <Controller
              name='birth_date'
              control={control}
              render={({ field }) => (
                <InputItem
                  className='half'
                  label={t("profile.common.birth_date") || ""}
                  field={field}
                  disabled
                />
              )}
            />
          </div>
          <div className='application-line' style={{ marginTop: 12 }}></div>

          {userData?.gender && (
            <Controller
              name='gender'
              control={control}
              render={({ field }) => (
                <RadioItem
                  items={genderData}
                  label={t("profile.common.gender") || ""}
                  field={field}
                  active={userData?.gender}
                  defaultChecked={userData?.gender}
                  disabled
                />
              )}
            />
          )}
        </div>
      </div>

      <div className='application-box-footer'>
        <Link to='/service'>
          <CustomButton {...backButtonProps}>{t("profile.common.back") || ""}</CustomButton>
        </Link>
        <CustomButton {...forwardButtonProps} loading={loading}>
          {t("profile.common.continue") || ""}
        </CustomButton>
      </div>
    </form>
  );
}

export default LyceumAdmissionStep1;
