import { OptionTypes } from "src/static/interface/common";
import {
  ICountriesResSE,
  IDurationSE,
  IEduFormResSE,
  IEduFormSE,
  IInstitutionResSE,
  ILanguageSE,
  ISerialSE,
  ISpecialitiesAppSE,
  ISpecialitiesSE,
  IUniversitySE,
} from "./type";

export const makeCountriesItem = (list: ICountriesResSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: String(item.id),
      name_uz: item.name,
      name_ru: item.name,
      name_en: item.name,
    });
  });
  return arr;
};

export const makeEduFormItem = (list: IEduFormResSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: String(item.id),
      name_uz: item.nameOz,
      name_ru: item.nameOz,
      name_en: item.nameOz,
    });
  });
  return arr;
};

export const makeInstitutionItem = (list: IInstitutionResSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: String(item.id),
      name_uz: item.institutionOldNameOz,
      name_ru: item.institutionOldNameOz,
      name_en: item.institutionOldNameOz,
    });
  });
  return arr;
};

export const makeSpecialityItem = (list: ISpecialitiesSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: String(item.specialityId),
      name_uz: item.specialityName,
      name_ru: item.specialityName,
      name_en: item.specialityName,
    });
  });
  return arr;
};

export const makeDurationItem = (list: IDurationSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: String(item.durationId),
      name_uz: item.nameOz,
      name_ru: item.nameOz,
      name_en: item.nameOz,
    });
  });
  return arr;
};

export const makeSerialItem = (list: ISerialSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: String(item.serialId),
      name_uz: item.serial,
      name_ru: item.serial,
      name_en: item.serial,
    });
  });
  return arr;
};

export const makeUniversityItem = (list: IUniversitySE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: item.otmCode,
      name_uz: item.otmName,
      name_ru: item.otmName,
      name_en: item.otmName,
    });
  });
  return arr;
};

export const makeEduFormAppItem = (list: IEduFormSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: item.eduFormCode,
      name_uz: item.eduFormName,
      name_ru: item.eduFormName,
      name_en: item.eduFormName,
    });
  });
  return arr;
};

export const makeLanguageItem = (list: ILanguageSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: item.languageCode,
      name_uz: item.languageName,
      name_ru: item.languageName,
      name_en: item.languageName,
    });
  });
  return arr;
};

export const makeSpecialityAppItem = (list: ISpecialitiesAppSE[]) => {
  let arr: OptionTypes[] = [];
  list.forEach((item) => {
    arr.push({
      value: String(item.id),
      name_uz: item.specialityName,
      name_ru: item.specialityName,
      name_en: item.specialityName,
    });
  });
  return arr;
};

export const findStatusSE = (status: string) => {
  if (status === "Diplom Haqiqiyligi tekshirilmoqda") return 1;
  else if (
    // status === "Diplom Tasdiqlangan" ||
    // status === "Diplom Tasdiqlangan (Noto'liq)" ||
    // status === "Admin kiritgan" ||
    status === "Ariza tasdiqlandi"
  )
    return 2;
  else if (status === "Diplom Rad etildi" || status === "Ariza rad etildi") return 3;
  else return 0;
};

export const makeDateValue = (date: string) => {
  return {
    startDate: date,
    endDate: date,
  };
};
