import { useEffect, useState } from "react";
import { TerminateSessionsConfig } from "src/service/api/profile";
import { GetSessionsConfig } from "src/service/api/profile";
import { UserSessionTypes } from "src/static/interface/profile";
import CustomButton from "src/components/common/button";
import { useTranslation } from "react-i18next";

function Devices() {
  const { t } = useTranslation();
  const [loading, setLaoding] = useState(false);
  const [sessions, setSessions] = useState<UserSessionTypes[]>([]);

  const getFunc = () => {
    GetSessionsConfig().then((res) => {
      setSessions(res.data.slice(0, 10));
    });
  };
  useEffect(() => getFunc(), []);

  const terminateFunc = () => {
    setLaoding(true);
    TerminateSessionsConfig()
      .then((res) => {
        alert("Boshqa qurilmalarda muvaffaqiyatli chiqildi");
        getFunc();
      })
      .finally(() => setLaoding(false));
  };

  return (
    <div>
      <h3 className="title">{t("profile.settings.current_device")}</h3>
      <div className="sessionCard">
        <div className="sessionCard__header">
          <h2>{sessions[0]?.agent}</h2>
          <div className="flex">
            <span>
              IP: <b>{sessions[0]?.ip}</b>
            </span>
            <span>
              {t("profile.settings.enter_time")}:{" "}
              <b>
                {sessions[0]?.added_time &&
                  new Date(sessions[0]?.added_time).toLocaleString()}
              </b>
            </span>
          </div>
        </div>
        <h2>
          O‘zbekiston Respublikasi Yagona ta’lim xizmatlari portaliga kirildi
        </h2>
        <div className="flex">
          <p>
            {sessions[0]?.authorization_type === "with_phone_number" &&
              "Telefon raqami bo'yicha avtorizatsiya. Jismoniy shaxsning shaxsiy hisobi"}
            {sessions[0]?.authorization_type === "with_one_id" &&
              "ONE ID tizimi orqali kirish. Jismoniy shaxsning shaxsiy hisobi"}
          </p>
          <CustomButton
            onClick={terminateFunc}
            loading={loading}
            size="xs"
            color="red"
            outline
          >
            {t("profile.settings.end_sessions")}
          </CustomButton>
        </div>
      </div>

      <h3 className="mt-12 title">{t("profile.settings.active_devices")}</h3>

      <div className="sessionCard sessionCard_list">
        {sessions.map((item) => {
          return (
            <div className="sessionCard__wrap" key={crypto.randomUUID()}>
              <div className="sessionCard__header">
                <h2>{item.agent}</h2>
                <div className="flex">
                  <span>
                    IP: <b>{item.ip}</b>
                  </span>
                  <span>
                    {t("profile.settings.enter_time")}:{" "}
                    <b>
                      {item?.added_time &&
                        new Date(item?.added_time).toLocaleString()}
                    </b>
                  </span>
                </div>
              </div>

              <div className="flex">
                <p>
                  {item.authorization_type === "with_phone_number" &&
                    "Telefon raqami bo'yicha avtorizatsiya. Jismoniy shaxsning shaxsiy hisobi"}
                  {item.authorization_type === "with_one_id" &&
                    "ONE ID tizimi orqali kirish. Jismoniy shaxsning shaxsiy hisobi"}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Devices;
