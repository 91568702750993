import { TextInput, Label } from "flowbite-react";
import { useState } from "react";
import { HelpSvg } from "src/assets/svg";
import { InputItemProps } from "src/static/interface/common";

function InputItem({
  label,
  placeholder,
  addon,
  disabled,
  size,
  error,
  message,
  field,
  type,
  openDrower,
  className,
  rightIcon,
  ...rest
}: InputItemProps) {
  const [passwordOpen, setPasswordOpen] = useState(false);

  return (
    <div className={`custom-input ${className}`}>
      <div className="custom-input-top">
        <Label htmlFor={label} className="custom-input-label">
          {label}
        </Label>
        {openDrower && (
          <button
            className="custom-input-help"
            type="button"
            onClick={openDrower}
          >
            <HelpSvg />
          </button>
        )}
      </div>
      <TextInput
        {...field}
        id={label}
        type={
          type === "password" && passwordOpen
            ? "text"
            : type === "password" && !passwordOpen
            ? "password"
            : type !== "password"
            ? type
            : "text"
        }
        color={error ? "failure" : "gray"}
        helperText={
          <li className="custom-form-error">{error ? message : ""}</li>
        }
        disabled={disabled}
        placeholder={placeholder}
        addon={addon}
        sizing={size}
        {...rest}
      />
      {type === "password" && (
        <div className="eyeIcon" onClick={() => setPasswordOpen(!passwordOpen)}>
          {rightIcon}
        </div>
      )}
    </div>
  );
}

export default InputItem;
