import { SearchMiniSVG } from "src/assets/svg";

function Inputs({ name = "searchMini", text = "perevod" }) {
  return (
    <>
      {name === "searchMini" ? (
        <div className="searchMiniInput">
          <SearchMiniSVG />
          <span>{text}</span>
        </div>
      ) : name === "searchMini" ? (
        <div className="searchMiniInput">
          <SearchMiniSVG />
          <span>{text}</span>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default Inputs;
